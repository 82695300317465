import { type Event, type Payment } from '@kalos/kalos-rpc';

export const returnCorrectTimeField = (time: string) => {
  const splitString = time.split(' ');
  const timeValue = splitString[1].split(':');
  const hoursString = timeValue[0];
  const minutesString = timeValue[1];
  return `${hoursString}:${minutesString}`;
};
export const returnLegacyDate = (time: string) => {
  const splitString = time.split(' ');

  return `${splitString[0]} 00:00:00`;
};

export const createVerboseLogging = (
  entry: Event | Payment,
  firstname: string,
  lastname: string,
) => {
  if (entry.fieldMask.length > 0) {
    let logMessage = `User ${firstname} ${lastname} changed the following \n`;
    for (let i = 0; i < entry.fieldMask.length; i++) {
      const messageString = `Changed ${entry.fieldMask[i]} to ${
        // @ts-ignore
        entry[entry.fieldMask[i].charAt(0).toLowerCase() + entry.fieldMask[i].slice(1)]
      } \n`;
      logMessage += messageString;
    }
    return logMessage;
  } else {
    return '';
  }
};
