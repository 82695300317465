import {
  ActivityLog,
  Document,
  DocumentClient,
  Invoice,
  PropertyDocumentRequest,
  URLObject,
} from '@kalos/kalos-rpc';
import {
  Button,
  cn,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import {
  DownloadIcon,
  EnvelopeClosedIcon,
  ExternalLinkIcon,
  Pencil1Icon,
  TrashIcon,
} from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  type Column,
  type ColumnDef,
  getCoreRowModel,
  type Row,
  type SortingState,
  type Updater,
  useReactTable,
} from '@tanstack/react-table';
import {
  type FC,
  type MouseEvent as ReactMouseEvent,
  type ReactElement,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { z } from 'zod';

import { Prompt } from '../../../components/Prompt';
import { useGetLoadingColumns } from '../../../components/utils';
import { ENDPOINT, ROWS_PER_PAGE } from '../../../constants';
import { queryKeys } from '../../../hooks/react-query/constants';
import {
  type DocumentFilter,
  useDeleteDocumentMutation,
  useDocumentsQuery,
  useDocumentUpdateMutation,
} from '../../../hooks/react-query/useDocumentsQuery';
import { useUserQuery } from '../../../hooks/react-query/useUserQuery';
import {
  ActivityLogClientService,
  EmailClientService,
  formatDateTime,
  InvoiceClientService,
  S3ClientService,
} from '../../../tools/helpers';
import { Loader } from '../../Loader/main';
import { Button as MUIButton } from '../Button';
import { Link } from '../Link';
import { Modal } from '../Modal';
import { UploadCustomDocument } from '../UploadCustomDocument';
const DocumentClientService = new DocumentClient(ENDPOINT);

type DocumentType = Document;

const updateFileNameSchema = z.string().min(1, 'Filename cannot be empty');
type OrderByDirective = 'document_date_created' | 'document_filename' | 'document_description';
interface Props {
  title: string;
  smallTitle?: boolean;
  loggedUserId?: number;
  userId?: number;
  propertyId?: number;
  taskId?: number;
  contractId?: number;
  fieldMask?: Array<string>;
  actions?: (document: Document) => ReactElement[];
  addUrl?: string;
  className?: string;
  renderAdding?: (onClose: () => void, onReload: () => Promise<void>) => ReactNode;
  renderEditing?: (
    onClose: () => void,
    onReload: () => Promise<void>,
    document: Document,
  ) => ReactNode;
  withDateCreated?: boolean;
  withDownloadIcon?: boolean;
  deletable?: boolean;
  stickySectionBar?: boolean;
  displayInAscendingOrder?: boolean;
  orderBy?: OrderByDirective;
  ignoreUserId?: boolean;
  docsRefresh?: number;
}

const staticData = Array.from({ length: 5 }, (_, idx) => Document.create({ id: idx }));

export const Documents: FC<Props> = ({
  title,
  smallTitle,
  propertyId,
  loggedUserId,
  taskId,
  fieldMask,
  userId,
  actions = () => [],
  addUrl,
  className,
  renderAdding,
  renderEditing,
  displayInAscendingOrder,
  contractId,
  withDateCreated = false,
  withDownloadIcon,
  deletable = true,
  stickySectionBar = true,
  orderBy = 'document_date_created',
  ignoreUserId,
  docsRefresh,
}) => {
  const [customerId] = useState<number>(userId || 0);
  const [error, setError] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pendingAddEmail, setPendingAddEmail] = useState<string>();
  const [pendingSendDocument, setPendingSendDocument] = useState<Document>();
  const [openEmailModal, setOpenEmailModal] = useState<boolean>();
  const [emailList, setEmailList] = useState<string[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [adding, setAdding] = useState<boolean>(false);
  const [addingDocument, setAddingDocument] = useState<boolean>(false);
  const [emailErrors, setEmailErrors] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [editing, setEditing] = useState<DocumentType>();

  const [sorting, setSorting] = useState<SortingState>([
    { id: orderBy, desc: !displayInAscendingOrder },
  ]);

  const handleSortingChange = useCallback(
    (updater: Updater<SortingState>) => {
      setSorting(updater);
    },
    [setSorting],
  );

  const queryClient = useQueryClient();

  const documentRequestFilter = useMemo<DocumentFilter>(() => {
    const sort = sorting.at(0);
    return {
      pageNumber: page,
      fieldMask,
      userId: customerId && !ignoreUserId ? customerId : undefined,
      propertyId: propertyId,
      taskId: taskId,
      orderDir: sort?.desc ? 'desc' : 'asc',
      orderBy: sort?.id ?? undefined,
    };
  }, [customerId, fieldMask, ignoreUserId, page, propertyId, sorting, taskId]);

  const userEmailListQuery = useUserQuery({
    filters: {
      id: customerId,
    },
    enabled: !!customerId,
    select(data) {
      let emailList: string[] = [];
      if (data.email != '') {
        emailList = emailList.concat([data.email]);
      }
      if (data.altEmail != '') {
        const altEmails = data.altEmail.split(',');
        emailList = emailList.concat(altEmails);
      }
      return emailList;
    },
  });

  useEffect(() => {
    if (userEmailListQuery.isSuccess) {
      setEmailList(userEmailListQuery.data ?? []);
      setSelectedEmails(userEmailListQuery.data ?? []);
    }
  }, [userEmailListQuery.data, openEmailModal, userEmailListQuery.isSuccess]);

  const documentsQuery = useDocumentsQuery({
    filter: documentRequestFilter,
  });

  useEffect(() => {
    if (documentsQuery.data) {
      setCount(documentsQuery.data.totalCount);
    }
  }, [documentsQuery.data]);

  const updateDocumentMutation = useDocumentUpdateMutation();
  const deleteDocumentMutation = useDeleteDocumentMutation();

  const handleEditFilename = useCallback(
    (entry: Document) =>
      async ({ prompt }: { prompt: string }) => {
        const req = Document.create();
        req.id = entry.id;
        req.description = prompt;
        req.fieldMask = ['Description'];
        updateDocumentMutation.mutate(req);
      },
    [updateDocumentMutation],
  );

  const handleDownload = useCallback(async (filename: string, type: number) => {
    try {
      await S3ClientService.download(filename, type === 5 ? 'testbuckethelios' : 'kalosdocs-prod');
    } catch (err) {
      console.error(`An error occurred while getting the download URL: ${err}`);
    }
  }, []);

  const handleOpenModal = useCallback(
    (entry: Document) => () => {
      setPendingSendDocument(entry);
      setOpenEmailModal(true);
    },
    [],
  );

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedList = [...selectedEmails];
    if (event.target.checked) {
      updatedList = [...selectedEmails, event.target.value];
    } else {
      updatedList.splice(selectedEmails.indexOf(event.target.value), 1);
    }
    setSelectedEmails(updatedList);
  };

  const isChecked = (item: string) => {
    return selectedEmails.includes(item) ? true : false;
  };

  const handleOpenInNewTab = useCallback(
    (filename: string, type: number) =>
      async (event: ReactMouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        const res = filename.match(/\d{2}-(\d{3,})[A-z]?-/);
        if (filename.toLowerCase().startsWith('maintenance') && res) {
          // TODO
          alert('replace with modal to view maintenance sheet');
        } else {
          const url = URLObject.create();
          url.key = filename;
          url.bucket = type === 5 ? 'testbuckethelios' : 'kalosdocs-prod';
          const dlURL = await S3ClientService.GetDownloadURL(url);
          window.open(dlURL!.url, '_blank');
        }
      },
    [],
  );

  const handleChangePage = useCallback((newPage: number | ((prevPage: number) => number)) => {
    setPage((prevPage) => {
      const nextPage = typeof newPage === 'function' ? newPage(prevPage) : newPage;
      return nextPage;
    });
  }, []);

  const createCustomDocument = () => {
    const documentReq = Document.create();
    if (customerId) {
      documentReq.userId = customerId;
    }
    if (taskId) {
      documentReq.taskId = taskId;
    }
    if (propertyId) {
      documentReq.propertyId = propertyId;
    }
    if (contractId) {
      documentReq.contractId = contractId;
    }
    return documentReq;
  };

  const handleSendDocumentToEmailList = useCallback(async () => {
    const req = PropertyDocumentRequest.create();
    setIsLoading(true);
    if (pendingSendDocument) {
      const documentRes = await DocumentClientService.Get(
        Document.create({ id: pendingSendDocument.id }),
      );
      req.emailList = selectedEmails;
      req.propertyId = pendingSendDocument.propertyId;
      req.documentId = pendingSendDocument.id;

      try {
        let invoice = Invoice.create();
        if (pendingSendDocument.invoiceId) {
          await InvoiceClientService.SendInvoiceDocument(req);
          try {
            const invoiceRes = await InvoiceClientService.Get(
              Invoice.create({ id: pendingSendDocument.invoiceId }),
            );
            if (invoiceRes) {
              invoice = invoiceRes;
            }
          } catch (err) {
            console.log('error getting invoice', err);
          }
        } else {
          await EmailClientService.SendPropertyDocumentExpire(req);
        }

        const activityString = `Emailed Document ${
          documentRes!.description
        } TO ${selectedEmails.join()} ON ${new Date().toLocaleString()}}`;
        // HERE
        await ActivityLogClientService.Create(
          ActivityLog.create({
            userId: loggedUserId,
            activityName: activityString,
            propertyId: pendingSendDocument.propertyId,
            contractId: invoice.contractId,
            eventId: invoice.eventId,
          }),
        );
      } catch (err) {
        console.log('error sending to people', err);
        alert('ERROR: Sending document to one or more emails failed.');
        setPendingSendDocument(undefined);
        setOpenEmailModal(false);
        return;
      }
    }
    alert('Emails sent successfully!');
    queryClient.invalidateQueries({
      queryKey: [
        queryKeys.documents.root,
        queryKeys.documents.list,
        queryKeys.documents.getHash(documentRequestFilter),
      ],
    });

    setIsLoading(false);
    setPendingSendDocument(undefined);
    setOpenEmailModal(false);
  }, [pendingSendDocument, selectedEmails, queryClient, documentRequestFilter, loggedUserId]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKeys.documents.root],
    });
    queryClient.invalidateQueries({
      queryKey: [queryKeys.user.root],
    });
  }, [docsRefresh, queryClient]);

  const confirm = useConfirm();

  const handleDelete = useCallback(
    async (entry: Document) => {
      if (
        !(await confirm({
          title: 'Delete Document',
          body: 'Are you sure you want to delete this document?',
        }))
      ) {
        return;
      }

      try {
        await deleteDocumentMutation.mutateAsync({
          document: entry,
          bucket: entry.type === 5 ? 'testbuckethelios' : 'kalosdocs-prod',
        });
      } catch (e) {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.documents.root],
        });
        console.error(
          `An error was caught while deleting an S3 bucket entry and deleting a document: ${e}`,
        );
        setError(true);
      }
    },
    [confirm, deleteDocumentMutation, queryClient],
  );

  const handleToggleAdding = useCallback((adding: boolean) => () => setAdding(adding), [setAdding]);
  const handleToggleAddDocument = useCallback(
    (adding: boolean) => () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.documents.root],
      });
      setAddingDocument(adding);
    },
    [queryClient],
  );
  const handleToggleEditing = useCallback(
    (editing?: DocumentType) => () => setEditing(editing),
    [],
  );

  const handleAddPendingEmail = useCallback(() => {
    const newEmail = pendingAddEmail ?? '';
    const emailTxt = newEmail.trim().replaceAll(' ', '');
    const cond = !!emailTxt && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail) && emailTxt.length < 40;
    setEmailErrors(!cond);
    if (cond) {
      setEmailList((prev) => [...prev, newEmail]);
      setSelectedEmails((prev) => [...prev, newEmail]);
      setPendingAddEmail('');
    }
  }, [pendingAddEmail]);

  const handleDeletePendingEmail = () => {
    setEmailList((prev) => {
      return prev.filter((item) => !selectedEmails.includes(item));
    });
    setSelectedEmails([]);
  };

  const columns = useMemo<ColumnDef<Document>[]>(
    () => [
      ...(withDateCreated
        ? [
            {
              accessorKey: 'dateCreated',
              id: 'document_date_created',
              header: ({ column }: { column: Column<Document> }) => (
                <DataTableColumnHeader title="Date Created" column={column} hideVisibilityToggle />
              ),
              cell: ({ row }: { row: Row<Document> }) => formatDateTime(row.original.dateCreated),
            },
          ]
        : []),
      {
        enableSorting: false,
        accessorKey: 'description',
        header: ({ column }: { column: Column<Document> }) => (
          <DataTableColumnHeader title="Description" column={column} hideVisibilityToggle />
        ),
        meta: {
          className: 'w-40 md:w-52 break-all',
        },
        cell: ({ row, column }) => (
          <div className={column.columnDef.meta?.className}>
            <Link onClick={handleOpenInNewTab(row.original.filename, row.original.type)}>
              {row.original.description}
            </Link>
          </div>
        ),
      },
      {
        id: 'actions',
        header: ({ column }: { column: Column<Document> }) => (
          <DataTableColumnHeader title="Actions" column={column} />
        ),
        meta: {
          className: 'flex items-center justify-end gap-1 w-40 flex-wrap',
        },
        cell: ({ row, column }) => (
          <div className={column.columnDef.meta?.className}>
            <TooltipProvider delayDuration={150}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={handleOpenInNewTab(row.original.filename, row.original.type)}
                  >
                    <ExternalLinkIcon />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Open in other tab</TooltipContent>
              </Tooltip>

              {withDownloadIcon && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => handleDownload(row.original.filename, row.original.type)}
                    >
                      <DownloadIcon />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Download</TooltipContent>
                </Tooltip>
              )}

              {actions(row.original)}

              {renderEditing && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={handleToggleEditing(row.original)}
                    >
                      <Pencil1Icon />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Edit</TooltipContent>
                </Tooltip>
              )}
              {deletable && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => handleDelete(row.original)}
                    >
                      <TrashIcon />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Delete</TooltipContent>
                </Tooltip>
              )}
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="outline" size="icon" onClick={handleOpenModal(row.original)}>
                    <EnvelopeClosedIcon />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Email Document</TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipContent>Edit Filename</TooltipContent>
                <Prompt
                  title="Update Filename"
                  isLoading={updateDocumentMutation.isPending}
                  trigger={
                    <TooltipTrigger asChild>
                      <Button variant="outline" size="icon">
                        <Pencil1Icon />
                      </Button>
                    </TooltipTrigger>
                  }
                  label="Filename"
                  defaultValue={row.original.description}
                  promptSchema={updateFileNameSchema}
                  onSubmit={handleEditFilename(row.original)}
                />
              </Tooltip>
            </TooltipProvider>
          </div>
        ),
      },
    ],
    [
      withDateCreated,
      handleOpenInNewTab,
      withDownloadIcon,
      actions,
      renderEditing,
      handleToggleEditing,
      deletable,
      handleOpenModal,
      updateDocumentMutation.isPending,
      handleEditFilename,
      handleDownload,
      handleDelete,
    ],
  );

  const loadingColumns = useGetLoadingColumns(columns);

  const table = useReactTable<Document>({
    data: documentsQuery.isPending ? staticData : documentsQuery.data?.results ?? [],
    columns: documentsQuery.isPending ? loadingColumns : columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: handleSortingChange,
  });

  const pageCount = Math.ceil(count / ROWS_PER_PAGE);

  return (
    <div className={cn('relative', className)}>
      <div className="sticky top-0 z-50 bg-gray-200 p-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <h2 className={`text-2xl ${smallTitle ? 'text-lg' : ''}`}>{title}</h2>
            {!!count && <p>({count})</p>}
          </div>

          <div className="flex flex-wrap gap-2">
            {addUrl || renderAdding ? (
              <Button onClick={handleToggleAddDocument(true)} size="sm">
                Add
              </Button>
            ) : null}
          </div>
        </div>

        <div className="flex items-center justify-center p-2">
          <DataTablePagination
            className="max-w-max"
            currentPage={page}
            setPage={handleChangePage}
            pageCount={pageCount}
          />
        </div>
      </div>

      <div className="max-w-full overflow-auto">
        <DataTable table={table} />
      </div>
      {error && <p className="py-1 text-red-500">An error occurred while deleting the document.</p>}
      {adding && renderAdding && (
        <Modal open onClose={handleToggleAdding(false)}>
          {renderAdding(handleToggleAdding(false), async () => {
            Promise.allSettled([
              queryClient.invalidateQueries({
                queryKey: [queryKeys.documents.root],
              }),
              queryClient.invalidateQueries({
                queryKey: [queryKeys.user.root],
              }),
            ]);
          })}
        </Modal>
      )}
      {addingDocument && (
        <Modal open onClose={handleToggleAddDocument(false)}>
          <UploadCustomDocument
            documentReq={createCustomDocument()}
            onClose={handleToggleAddDocument(false)}
          />
        </Modal>
      )}
      {editing && renderEditing && (
        <Modal open onClose={handleToggleEditing()}>
          {renderEditing(
            handleToggleEditing(),
            async () => {
              Promise.allSettled([
                queryClient.invalidateQueries({
                  queryKey: [queryKeys.documents.root],
                }),
                queryClient.invalidateQueries({
                  queryKey: [queryKeys.user.root],
                }),
              ]);
            },
            editing,
          )}
        </Modal>
      )}
      {openEmailModal && (
        <Modal
          className="Document-Emails-Modal"
          open={openEmailModal}
          onClose={() => {
            setOpenEmailModal(false);
            setEmailErrors(false);
            setPendingAddEmail('');
            // setLoaded(true);
          }}
        >
          <div className="flex items-center justify-between bg-gray-200 p-2">
            <h2 className="text-2xl">Choose Emails to Send Document To</h2>
            <div className="flex flex-wrap gap-2">
              <Button
                onClick={handleSendDocumentToEmailList}
                disabled={selectedEmails.length <= 0}
                size="sm"
              >
                Send Document
              </Button>
              <Button
                onClick={() => {
                  setOpenEmailModal(false);
                  setEmailErrors(false);
                  setPendingAddEmail('');
                  // setLoaded(true);
                }}
                size="sm"
              >
                Close
              </Button>
            </div>
          </div>
          {isLoading && <Loader className="Document-Emails-Loader" />}
          <div className="px-6 pb-6 pt-5">
            <div className="flex flex-col gap-3 pb-10">
              {emailErrors && (
                <div className="bg-[#D32F2F] px-3 py-2">
                  <p className="pb-2 text-sm">
                    Please correct the following validation errors and try again.
                  </p>
                  <ul className="pl-6">
                    <li className="list-disc text-sm">
                      <strong>Email: </strong> please write correct email address
                    </li>
                  </ul>
                </div>
              )}
              <div className="flex w-full gap-3">
                <TextField
                  key="PendingEmailEntry"
                  label="Email Address:"
                  value={pendingAddEmail}
                  fullWidth={true}
                  error={emailErrors}
                  onChange={(el) => setPendingAddEmail(el.target.value)}
                />
                <MUIButton label="Add" onClick={handleAddPendingEmail} />
              </div>
            </div>
            <FormControl fullWidth className="w-full">
              <div className="flex w-full justify-between">
                <FormLabel>List Of Email Addresses</FormLabel>
                <MUIButton
                  label="Delete emails"
                  disabled={!emailList.length || !selectedEmails.length}
                  onClick={handleDeletePendingEmail}
                />
              </div>
              <FormGroup key="CheckBoxGroup">
                {emailList.map((item, index) => (
                  <FormControlLabel
                    key={index + 'FormControlLabelCheckBox'}
                    control={
                      <Checkbox
                        key={index + item}
                        checked={isChecked(item)}
                        value={item}
                        onChange={(event) => handleCheck(event)}
                        name={item}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </Modal>
      )}
    </div>
  );
};
