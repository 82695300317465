import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type CreateSpiffDialogStore = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const createSpiffDialogStore = create<CreateSpiffDialogStore>()(
  devtools((set, get) => ({
    open: false,
    setOpen: (open) => {
      typeof open === 'function' ? set({ open: open(get().open) }) : set({ open });
    },
  })),
);
