// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "task.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "task.proto" (syntax proto3)
// tslint:disable
import { StringList } from "./common";
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { User } from "./user";
// @generated message type with reflection information, may provide speed optimized methods
class SpiffToolAdminAction$Type extends MessageType {
    constructor() {
        super("SpiffToolAdminAction", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "task_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "reviewed_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "decision_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "granted_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "revoked_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "group_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "date_processed", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, taskId: 0, reviewedBy: "", status: 0, reason: "", decisionDate: "", createdDate: "", grantedDate: "", revokedDate: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", dateRange: [], dateTarget: [], groupBy: "", dateProcessed: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 task_id */ 2:
                    message.taskId = reader.int32();
                    break;
                case /* string reviewed_by */ 3:
                    message.reviewedBy = reader.string();
                    break;
                case /* int32 status */ 4:
                    message.status = reader.int32();
                    break;
                case /* string reason */ 5:
                    message.reason = reader.string();
                    break;
                case /* string decision_date */ 6:
                    message.decisionDate = reader.string();
                    break;
                case /* string created_date */ 7:
                    message.createdDate = reader.string();
                    break;
                case /* string granted_date */ 8:
                    message.grantedDate = reader.string();
                    break;
                case /* string revoked_date */ 9:
                    message.revokedDate = reader.string();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 11:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 12:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 13:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string date_range */ 14:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 15:
                    message.dateTarget.push(reader.string());
                    break;
                case /* string group_by */ 16:
                    message.groupBy = reader.string();
                    break;
                case /* string date_processed */ 17:
                    message.dateProcessed = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 task_id = 2; */
        if (message.taskId !== 0)
            writer.tag(2, WireType.Varint).int32(message.taskId);
        /* string reviewed_by = 3; */
        if (message.reviewedBy !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.reviewedBy);
        /* int32 status = 4; */
        if (message.status !== 0)
            writer.tag(4, WireType.Varint).int32(message.status);
        /* string reason = 5; */
        if (message.reason !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.reason);
        /* string decision_date = 6; */
        if (message.decisionDate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.decisionDate);
        /* string created_date = 7; */
        if (message.createdDate !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.createdDate);
        /* string granted_date = 8; */
        if (message.grantedDate !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.grantedDate);
        /* string revoked_date = 9; */
        if (message.revokedDate !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.revokedDate);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 11; */
        if (message.pageNumber !== 0)
            writer.tag(11, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 12; */
        if (message.orderBy !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 13; */
        if (message.orderDir !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string date_range = 14; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 15; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(15, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* string group_by = 16; */
        if (message.groupBy !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.groupBy);
        /* string date_processed = 17; */
        if (message.dateProcessed !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.dateProcessed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SpiffToolAdminAction
 */
export const SpiffToolAdminAction = new SpiffToolAdminAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpiffToolAdminActionList$Type extends MessageType {
    constructor() {
        super("SpiffToolAdminActionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SpiffToolAdminAction },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SpiffToolAdminAction results */ 1:
                    message.results.push(SpiffToolAdminAction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SpiffToolAdminAction results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SpiffToolAdminAction.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SpiffToolAdminActionList
 */
export const SpiffToolAdminActionList = new SpiffToolAdminActionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Task$Type extends MessageType {
    constructor() {
        super("Task", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "external_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "external_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "reference_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "creator_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "time_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "time_due", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "brief_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "details", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "priority_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "reference_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "billable", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "billable_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "flat_rate", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 18, name: "hourly_start", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "hourly_end", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "order_num", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "spiff_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 23, name: "spiff_job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "spiff_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 25, name: "spiff_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "toolpurchase_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "toolpurchase_cost", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 28, name: "toolpurchase_file", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "admin_action_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 30, name: "date_performed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "spiff_tool_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "spiff_tool_closeout_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 33, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 35, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 36, name: "event", kind: "message", T: () => TaskEventData },
            { no: 37, name: "owner_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "actions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SpiffToolAdminAction },
            { no: 39, name: "status", kind: "message", T: () => TaskStatus },
            { no: 40, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 41, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 43, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 44, name: "reference_action", kind: "message", T: () => SpiffToolAdminAction },
            { no: 45, name: "duplicates", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SpiffDuplicate },
            { no: 46, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 47, name: "group_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 49, name: "payroll_processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 50, name: "search_action", kind: "message", T: () => SpiffToolAdminAction },
            { no: 51, name: "needs_auditing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 52, name: "search_user", kind: "message", T: () => User },
            { no: 53, name: "spiff_type", kind: "message", T: () => SpiffType },
            { no: 54, name: "checked_in", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 55, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 56, name: "reviewed_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 57, name: "review_status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 58, name: "review_reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 59, name: "task_creator_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, externalId: 0, externalCode: "", referenceNumber: "", creatorUserId: 0, timeCreated: "", timeDue: "", briefDescription: "", details: "", notes: "", statusId: 0, priorityId: 0, referenceUrl: "", isActive: false, billable: 0, billableType: "", flatRate: 0, hourlyStart: "", hourlyEnd: "", address: "", orderNum: "", spiffAmount: 0, spiffJobNumber: "", spiffTypeId: 0, spiffAddress: "", toolpurchaseDate: "", toolpurchaseCost: 0, toolpurchaseFile: "", adminActionId: 0, datePerformed: "", spiffToolId: "", spiffToolCloseoutDate: "", fieldMask: [], pageNumber: 0, dateRange: [], ownerName: "", actions: [], orderBy: "", orderDir: "", dateTarget: [], duplicates: [], eventId: 0, groupBy: "", notEquals: [], payrollProcessed: false, needsAuditing: false, checkedIn: false, withoutLimit: false, reviewedBy: "", reviewStatus: 0, reviewReason: "", taskCreatorName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 external_id */ 2:
                    message.externalId = reader.int32();
                    break;
                case /* string external_code */ 3:
                    message.externalCode = reader.string();
                    break;
                case /* string reference_number */ 4:
                    message.referenceNumber = reader.string();
                    break;
                case /* int32 creator_user_id */ 5:
                    message.creatorUserId = reader.int32();
                    break;
                case /* string time_created */ 6:
                    message.timeCreated = reader.string();
                    break;
                case /* string time_due */ 7:
                    message.timeDue = reader.string();
                    break;
                case /* string brief_description */ 8:
                    message.briefDescription = reader.string();
                    break;
                case /* string details */ 9:
                    message.details = reader.string();
                    break;
                case /* string notes */ 10:
                    message.notes = reader.string();
                    break;
                case /* int32 status_id */ 11:
                    message.statusId = reader.int32();
                    break;
                case /* int32 priority_id */ 12:
                    message.priorityId = reader.int32();
                    break;
                case /* string reference_url */ 13:
                    message.referenceUrl = reader.string();
                    break;
                case /* bool is_active */ 14:
                    message.isActive = reader.bool();
                    break;
                case /* int32 billable */ 15:
                    message.billable = reader.int32();
                    break;
                case /* string billable_type */ 16:
                    message.billableType = reader.string();
                    break;
                case /* double flat_rate */ 17:
                    message.flatRate = reader.double();
                    break;
                case /* string hourly_start */ 18:
                    message.hourlyStart = reader.string();
                    break;
                case /* string hourly_end */ 19:
                    message.hourlyEnd = reader.string();
                    break;
                case /* string address */ 20:
                    message.address = reader.string();
                    break;
                case /* string order_num */ 21:
                    message.orderNum = reader.string();
                    break;
                case /* double spiff_amount */ 22:
                    message.spiffAmount = reader.double();
                    break;
                case /* string spiff_job_number */ 23:
                    message.spiffJobNumber = reader.string();
                    break;
                case /* int32 spiff_type_id */ 24:
                    message.spiffTypeId = reader.int32();
                    break;
                case /* string spiff_address */ 25:
                    message.spiffAddress = reader.string();
                    break;
                case /* string toolpurchase_date */ 26:
                    message.toolpurchaseDate = reader.string();
                    break;
                case /* double toolpurchase_cost */ 27:
                    message.toolpurchaseCost = reader.double();
                    break;
                case /* string toolpurchase_file */ 28:
                    message.toolpurchaseFile = reader.string();
                    break;
                case /* int32 admin_action_id */ 29:
                    message.adminActionId = reader.int32();
                    break;
                case /* string date_performed */ 30:
                    message.datePerformed = reader.string();
                    break;
                case /* string spiff_tool_id */ 31:
                    message.spiffToolId = reader.string();
                    break;
                case /* string spiff_tool_closeout_date */ 32:
                    message.spiffToolCloseoutDate = reader.string();
                    break;
                case /* repeated string field_mask */ 33:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 34:
                    message.pageNumber = reader.int32();
                    break;
                case /* repeated string date_range */ 35:
                    message.dateRange.push(reader.string());
                    break;
                case /* TaskEventData event */ 36:
                    message.event = TaskEventData.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                case /* string owner_name */ 37:
                    message.ownerName = reader.string();
                    break;
                case /* repeated SpiffToolAdminAction actions */ 38:
                    message.actions.push(SpiffToolAdminAction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* TaskStatus status */ 39:
                    message.status = TaskStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* string order_by */ 40:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 41:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string date_target */ 43:
                    message.dateTarget.push(reader.string());
                    break;
                case /* SpiffToolAdminAction reference_action */ 44:
                    message.referenceAction = SpiffToolAdminAction.internalBinaryRead(reader, reader.uint32(), options, message.referenceAction);
                    break;
                case /* repeated SpiffDuplicate duplicates */ 45:
                    message.duplicates.push(SpiffDuplicate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 event_id */ 46:
                    message.eventId = reader.int32();
                    break;
                case /* string group_by */ 47:
                    message.groupBy = reader.string();
                    break;
                case /* repeated string not_equals */ 48:
                    message.notEquals.push(reader.string());
                    break;
                case /* bool payroll_processed */ 49:
                    message.payrollProcessed = reader.bool();
                    break;
                case /* SpiffToolAdminAction search_action */ 50:
                    message.searchAction = SpiffToolAdminAction.internalBinaryRead(reader, reader.uint32(), options, message.searchAction);
                    break;
                case /* bool needs_auditing */ 51:
                    message.needsAuditing = reader.bool();
                    break;
                case /* User search_user */ 52:
                    message.searchUser = User.internalBinaryRead(reader, reader.uint32(), options, message.searchUser);
                    break;
                case /* SpiffType spiff_type */ 53:
                    message.spiffType = SpiffType.internalBinaryRead(reader, reader.uint32(), options, message.spiffType);
                    break;
                case /* bool checked_in */ 54:
                    message.checkedIn = reader.bool();
                    break;
                case /* bool without_limit */ 55:
                    message.withoutLimit = reader.bool();
                    break;
                case /* string reviewed_by */ 56:
                    message.reviewedBy = reader.string();
                    break;
                case /* int32 review_status */ 57:
                    message.reviewStatus = reader.int32();
                    break;
                case /* string review_reason */ 58:
                    message.reviewReason = reader.string();
                    break;
                case /* string task_creator_name */ 59:
                    message.taskCreatorName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 external_id = 2; */
        if (message.externalId !== 0)
            writer.tag(2, WireType.Varint).int32(message.externalId);
        /* string external_code = 3; */
        if (message.externalCode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.externalCode);
        /* string reference_number = 4; */
        if (message.referenceNumber !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.referenceNumber);
        /* int32 creator_user_id = 5; */
        if (message.creatorUserId !== 0)
            writer.tag(5, WireType.Varint).int32(message.creatorUserId);
        /* string time_created = 6; */
        if (message.timeCreated !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timeCreated);
        /* string time_due = 7; */
        if (message.timeDue !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.timeDue);
        /* string brief_description = 8; */
        if (message.briefDescription !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.briefDescription);
        /* string details = 9; */
        if (message.details !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.details);
        /* string notes = 10; */
        if (message.notes !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.notes);
        /* int32 status_id = 11; */
        if (message.statusId !== 0)
            writer.tag(11, WireType.Varint).int32(message.statusId);
        /* int32 priority_id = 12; */
        if (message.priorityId !== 0)
            writer.tag(12, WireType.Varint).int32(message.priorityId);
        /* string reference_url = 13; */
        if (message.referenceUrl !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.referenceUrl);
        /* bool is_active = 14; */
        if (message.isActive !== false)
            writer.tag(14, WireType.Varint).bool(message.isActive);
        /* int32 billable = 15; */
        if (message.billable !== 0)
            writer.tag(15, WireType.Varint).int32(message.billable);
        /* string billable_type = 16; */
        if (message.billableType !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.billableType);
        /* double flat_rate = 17; */
        if (message.flatRate !== 0)
            writer.tag(17, WireType.Bit64).double(message.flatRate);
        /* string hourly_start = 18; */
        if (message.hourlyStart !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.hourlyStart);
        /* string hourly_end = 19; */
        if (message.hourlyEnd !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.hourlyEnd);
        /* string address = 20; */
        if (message.address !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.address);
        /* string order_num = 21; */
        if (message.orderNum !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.orderNum);
        /* double spiff_amount = 22; */
        if (message.spiffAmount !== 0)
            writer.tag(22, WireType.Bit64).double(message.spiffAmount);
        /* string spiff_job_number = 23; */
        if (message.spiffJobNumber !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.spiffJobNumber);
        /* int32 spiff_type_id = 24; */
        if (message.spiffTypeId !== 0)
            writer.tag(24, WireType.Varint).int32(message.spiffTypeId);
        /* string spiff_address = 25; */
        if (message.spiffAddress !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.spiffAddress);
        /* string toolpurchase_date = 26; */
        if (message.toolpurchaseDate !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.toolpurchaseDate);
        /* double toolpurchase_cost = 27; */
        if (message.toolpurchaseCost !== 0)
            writer.tag(27, WireType.Bit64).double(message.toolpurchaseCost);
        /* string toolpurchase_file = 28; */
        if (message.toolpurchaseFile !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.toolpurchaseFile);
        /* int32 admin_action_id = 29; */
        if (message.adminActionId !== 0)
            writer.tag(29, WireType.Varint).int32(message.adminActionId);
        /* string date_performed = 30; */
        if (message.datePerformed !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.datePerformed);
        /* string spiff_tool_id = 31; */
        if (message.spiffToolId !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.spiffToolId);
        /* string spiff_tool_closeout_date = 32; */
        if (message.spiffToolCloseoutDate !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.spiffToolCloseoutDate);
        /* repeated string field_mask = 33; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(33, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 34; */
        if (message.pageNumber !== 0)
            writer.tag(34, WireType.Varint).int32(message.pageNumber);
        /* repeated string date_range = 35; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(35, WireType.LengthDelimited).string(message.dateRange[i]);
        /* TaskEventData event = 36; */
        if (message.event)
            TaskEventData.internalBinaryWrite(message.event, writer.tag(36, WireType.LengthDelimited).fork(), options).join();
        /* string owner_name = 37; */
        if (message.ownerName !== "")
            writer.tag(37, WireType.LengthDelimited).string(message.ownerName);
        /* repeated SpiffToolAdminAction actions = 38; */
        for (let i = 0; i < message.actions.length; i++)
            SpiffToolAdminAction.internalBinaryWrite(message.actions[i], writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        /* TaskStatus status = 39; */
        if (message.status)
            TaskStatus.internalBinaryWrite(message.status, writer.tag(39, WireType.LengthDelimited).fork(), options).join();
        /* string order_by = 40; */
        if (message.orderBy !== "")
            writer.tag(40, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 41; */
        if (message.orderDir !== "")
            writer.tag(41, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string date_target = 43; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(43, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* SpiffToolAdminAction reference_action = 44; */
        if (message.referenceAction)
            SpiffToolAdminAction.internalBinaryWrite(message.referenceAction, writer.tag(44, WireType.LengthDelimited).fork(), options).join();
        /* repeated SpiffDuplicate duplicates = 45; */
        for (let i = 0; i < message.duplicates.length; i++)
            SpiffDuplicate.internalBinaryWrite(message.duplicates[i], writer.tag(45, WireType.LengthDelimited).fork(), options).join();
        /* int32 event_id = 46; */
        if (message.eventId !== 0)
            writer.tag(46, WireType.Varint).int32(message.eventId);
        /* string group_by = 47; */
        if (message.groupBy !== "")
            writer.tag(47, WireType.LengthDelimited).string(message.groupBy);
        /* repeated string not_equals = 48; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(48, WireType.LengthDelimited).string(message.notEquals[i]);
        /* bool payroll_processed = 49; */
        if (message.payrollProcessed !== false)
            writer.tag(49, WireType.Varint).bool(message.payrollProcessed);
        /* SpiffToolAdminAction search_action = 50; */
        if (message.searchAction)
            SpiffToolAdminAction.internalBinaryWrite(message.searchAction, writer.tag(50, WireType.LengthDelimited).fork(), options).join();
        /* bool needs_auditing = 51; */
        if (message.needsAuditing !== false)
            writer.tag(51, WireType.Varint).bool(message.needsAuditing);
        /* User search_user = 52; */
        if (message.searchUser)
            User.internalBinaryWrite(message.searchUser, writer.tag(52, WireType.LengthDelimited).fork(), options).join();
        /* SpiffType spiff_type = 53; */
        if (message.spiffType)
            SpiffType.internalBinaryWrite(message.spiffType, writer.tag(53, WireType.LengthDelimited).fork(), options).join();
        /* bool checked_in = 54; */
        if (message.checkedIn !== false)
            writer.tag(54, WireType.Varint).bool(message.checkedIn);
        /* bool without_limit = 55; */
        if (message.withoutLimit !== false)
            writer.tag(55, WireType.Varint).bool(message.withoutLimit);
        /* string reviewed_by = 56; */
        if (message.reviewedBy !== "")
            writer.tag(56, WireType.LengthDelimited).string(message.reviewedBy);
        /* int32 review_status = 57; */
        if (message.reviewStatus !== 0)
            writer.tag(57, WireType.Varint).int32(message.reviewStatus);
        /* string review_reason = 58; */
        if (message.reviewReason !== "")
            writer.tag(58, WireType.LengthDelimited).string(message.reviewReason);
        /* string task_creator_name = 59; */
        if (message.taskCreatorName !== "")
            writer.tag(59, WireType.LengthDelimited).string(message.taskCreatorName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Task
 */
export const Task = new Task$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskList$Type extends MessageType {
    constructor() {
        super("TaskList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Task },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Task results */ 1:
                    message.results.push(Task.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Task results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Task.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TaskList
 */
export const TaskList = new TaskList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolFund$Type extends MessageType {
    constructor() {
        super("ToolFund", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, value: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double value */ 2:
                    message.value = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit64).double(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolFund
 */
export const ToolFund = new ToolFund$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Spiff$Type extends MessageType {
    constructor() {
        super("Spiff", [
            { no: 1, name: "time_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "brief_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "details", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "spiff_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "spiff_jobNumber", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "spiff_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "spiff_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "reviewed_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "decision_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "external_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { timeCreated: "", briefDescription: "", details: "", notes: "", statusId: 0, spiffAmount: 0, spiffJobNumber: "", spiffType: "", spiffAddress: "", reviewedBy: "", status: "", reason: "", decisionDate: "", externalId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string time_created */ 1:
                    message.timeCreated = reader.string();
                    break;
                case /* string brief_description */ 2:
                    message.briefDescription = reader.string();
                    break;
                case /* string details */ 3:
                    message.details = reader.string();
                    break;
                case /* string notes */ 4:
                    message.notes = reader.string();
                    break;
                case /* int32 status_id */ 5:
                    message.statusId = reader.int32();
                    break;
                case /* double spiff_amount */ 6:
                    message.spiffAmount = reader.double();
                    break;
                case /* string spiff_jobNumber */ 7:
                    message.spiffJobNumber = reader.string();
                    break;
                case /* string spiff_type */ 8:
                    message.spiffType = reader.string();
                    break;
                case /* string spiff_address */ 9:
                    message.spiffAddress = reader.string();
                    break;
                case /* string reviewed_by */ 10:
                    message.reviewedBy = reader.string();
                    break;
                case /* string status */ 11:
                    message.status = reader.string();
                    break;
                case /* string reason */ 12:
                    message.reason = reader.string();
                    break;
                case /* string decision_date */ 13:
                    message.decisionDate = reader.string();
                    break;
                case /* int32 external_id */ 14:
                    message.externalId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string time_created = 1; */
        if (message.timeCreated !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.timeCreated);
        /* string brief_description = 2; */
        if (message.briefDescription !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.briefDescription);
        /* string details = 3; */
        if (message.details !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.details);
        /* string notes = 4; */
        if (message.notes !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.notes);
        /* int32 status_id = 5; */
        if (message.statusId !== 0)
            writer.tag(5, WireType.Varint).int32(message.statusId);
        /* double spiff_amount = 6; */
        if (message.spiffAmount !== 0)
            writer.tag(6, WireType.Bit64).double(message.spiffAmount);
        /* string spiff_jobNumber = 7; */
        if (message.spiffJobNumber !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.spiffJobNumber);
        /* string spiff_type = 8; */
        if (message.spiffType !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.spiffType);
        /* string spiff_address = 9; */
        if (message.spiffAddress !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.spiffAddress);
        /* string reviewed_by = 10; */
        if (message.reviewedBy !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.reviewedBy);
        /* string status = 11; */
        if (message.status !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.status);
        /* string reason = 12; */
        if (message.reason !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.reason);
        /* string decision_date = 13; */
        if (message.decisionDate !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.decisionDate);
        /* int32 external_id = 14; */
        if (message.externalId !== 0)
            writer.tag(14, WireType.Varint).int32(message.externalId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Spiff
 */
export const Spiff = new Spiff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpiffList$Type extends MessageType {
    constructor() {
        super("SpiffList", [
            { no: 1, name: "results_list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Spiff }
        ]);
    }
    create(value) {
        const message = { resultsList: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Spiff results_list */ 1:
                    message.resultsList.push(Spiff.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Spiff results_list = 1; */
        for (let i = 0; i < message.resultsList.length; i++)
            Spiff.internalBinaryWrite(message.resultsList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SpiffList
 */
export const SpiffList = new SpiffList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskEventData$Type extends MessageType {
    constructor() {
        super("TaskEventData", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "contract_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "contract_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "log_job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, propertyId: 0, customerId: 0, contractId: 0, contractNumber: "", logJobNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 property_id */ 2:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 customer_id */ 3:
                    message.customerId = reader.int32();
                    break;
                case /* int32 contract_id */ 4:
                    message.contractId = reader.int32();
                    break;
                case /* string contract_number */ 5:
                    message.contractNumber = reader.string();
                    break;
                case /* string log_job_number */ 6:
                    message.logJobNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 property_id = 2; */
        if (message.propertyId !== 0)
            writer.tag(2, WireType.Varint).int32(message.propertyId);
        /* int32 customer_id = 3; */
        if (message.customerId !== 0)
            writer.tag(3, WireType.Varint).int32(message.customerId);
        /* int32 contract_id = 4; */
        if (message.contractId !== 0)
            writer.tag(4, WireType.Varint).int32(message.contractId);
        /* string contract_number = 5; */
        if (message.contractNumber !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.contractNumber);
        /* string log_job_number = 6; */
        if (message.logJobNumber !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.logJobNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TaskEventData
 */
export const TaskEventData = new TaskEventData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskStatus$Type extends MessageType {
    constructor() {
        super("TaskStatus", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "weight", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, code: "", description: "", weight: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* int32 weight */ 4:
                    message.weight = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* int32 weight = 4; */
        if (message.weight !== 0)
            writer.tag(4, WireType.Varint).int32(message.weight);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TaskStatus
 */
export const TaskStatus = new TaskStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskPriority$Type extends MessageType {
    constructor() {
        super("TaskPriority", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, code: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TaskPriority
 */
export const TaskPriority = new TaskPriority$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskPriorityList$Type extends MessageType {
    constructor() {
        super("TaskPriorityList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TaskPriority }
        ]);
    }
    create(value) {
        const message = { results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TaskPriority results */ 1:
                    message.results.push(TaskPriority.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TaskPriority results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TaskPriority.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TaskPriorityList
 */
export const TaskPriorityList = new TaskPriorityList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpiffDuplicate$Type extends MessageType {
    constructor() {
        super("SpiffDuplicate", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "spiff_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "time_due", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "owner_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "actions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SpiffToolAdminAction }
        ]);
    }
    create(value) {
        const message = { id: 0, spiffTypeId: 0, timeDue: "", ownerName: "", actions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 spiff_type_id */ 2:
                    message.spiffTypeId = reader.int32();
                    break;
                case /* string time_due */ 3:
                    message.timeDue = reader.string();
                    break;
                case /* string owner_name */ 4:
                    message.ownerName = reader.string();
                    break;
                case /* repeated SpiffToolAdminAction actions */ 5:
                    message.actions.push(SpiffToolAdminAction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 spiff_type_id = 2; */
        if (message.spiffTypeId !== 0)
            writer.tag(2, WireType.Varint).int32(message.spiffTypeId);
        /* string time_due = 3; */
        if (message.timeDue !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timeDue);
        /* string owner_name = 4; */
        if (message.ownerName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.ownerName);
        /* repeated SpiffToolAdminAction actions = 5; */
        for (let i = 0; i < message.actions.length; i++)
            SpiffToolAdminAction.internalBinaryWrite(message.actions[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SpiffDuplicate
 */
export const SpiffDuplicate = new SpiffDuplicate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpiffType$Type extends MessageType {
    constructor() {
        super("SpiffType", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "ext", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "payout", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "duration", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, ext: "", type: "", payout: "", duration: "", isActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string ext */ 2:
                    message.ext = reader.string();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* string payout */ 4:
                    message.payout = reader.string();
                    break;
                case /* string duration */ 5:
                    message.duration = reader.string();
                    break;
                case /* bool is_active */ 6:
                    message.isActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string ext = 2; */
        if (message.ext !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ext);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* string payout = 4; */
        if (message.payout !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.payout);
        /* string duration = 5; */
        if (message.duration !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.duration);
        /* bool is_active = 6; */
        if (message.isActive !== false)
            writer.tag(6, WireType.Varint).bool(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SpiffType
 */
export const SpiffType = new SpiffType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpiffTypeList$Type extends MessageType {
    constructor() {
        super("SpiffTypeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SpiffType },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SpiffType results */ 1:
                    message.results.push(SpiffType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SpiffType results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SpiffType.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SpiffTypeList
 */
export const SpiffTypeList = new SpiffTypeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectTask$Type extends MessageType {
    constructor() {
        super("ProjectTask", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "external_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "external_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "reference_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "creator_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "time_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "brief_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "details", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "priority_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "owner_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "status", kind: "message", T: () => TaskStatus },
            { no: 22, name: "priority", kind: "message", T: () => TaskPriority },
            { no: 23, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "group_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "checked_in", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, externalId: 0, externalCode: "", referenceNumber: "", creatorUserId: 0, timeCreated: "", briefDescription: "", details: "", notes: "", statusId: 0, priorityId: 0, isActive: false, startDate: "", endDate: "", eventId: 0, fieldMask: [], pageNumber: 0, dateRange: [], dateTarget: [], ownerName: "", orderBy: "", orderDir: "", groupBy: "", checkedIn: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 external_id */ 2:
                    message.externalId = reader.int32();
                    break;
                case /* string external_code */ 3:
                    message.externalCode = reader.string();
                    break;
                case /* string reference_number */ 4:
                    message.referenceNumber = reader.string();
                    break;
                case /* int32 creator_user_id */ 5:
                    message.creatorUserId = reader.int32();
                    break;
                case /* string time_created */ 6:
                    message.timeCreated = reader.string();
                    break;
                case /* string brief_description */ 7:
                    message.briefDescription = reader.string();
                    break;
                case /* string details */ 8:
                    message.details = reader.string();
                    break;
                case /* string notes */ 9:
                    message.notes = reader.string();
                    break;
                case /* int32 status_id */ 10:
                    message.statusId = reader.int32();
                    break;
                case /* int32 priority_id */ 11:
                    message.priorityId = reader.int32();
                    break;
                case /* bool is_active */ 12:
                    message.isActive = reader.bool();
                    break;
                case /* string start_date */ 13:
                    message.startDate = reader.string();
                    break;
                case /* string end_date */ 14:
                    message.endDate = reader.string();
                    break;
                case /* int32 event_id */ 15:
                    message.eventId = reader.int32();
                    break;
                case /* repeated string field_mask */ 16:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 17:
                    message.pageNumber = reader.int32();
                    break;
                case /* repeated string date_range */ 18:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 19:
                    message.dateTarget.push(reader.string());
                    break;
                case /* string owner_name */ 20:
                    message.ownerName = reader.string();
                    break;
                case /* TaskStatus status */ 21:
                    message.status = TaskStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* TaskPriority priority */ 22:
                    message.priority = TaskPriority.internalBinaryRead(reader, reader.uint32(), options, message.priority);
                    break;
                case /* string order_by */ 23:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 24:
                    message.orderDir = reader.string();
                    break;
                case /* string group_by */ 25:
                    message.groupBy = reader.string();
                    break;
                case /* bool checked_in */ 26:
                    message.checkedIn = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 external_id = 2; */
        if (message.externalId !== 0)
            writer.tag(2, WireType.Varint).int32(message.externalId);
        /* string external_code = 3; */
        if (message.externalCode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.externalCode);
        /* string reference_number = 4; */
        if (message.referenceNumber !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.referenceNumber);
        /* int32 creator_user_id = 5; */
        if (message.creatorUserId !== 0)
            writer.tag(5, WireType.Varint).int32(message.creatorUserId);
        /* string time_created = 6; */
        if (message.timeCreated !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timeCreated);
        /* string brief_description = 7; */
        if (message.briefDescription !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.briefDescription);
        /* string details = 8; */
        if (message.details !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.details);
        /* string notes = 9; */
        if (message.notes !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.notes);
        /* int32 status_id = 10; */
        if (message.statusId !== 0)
            writer.tag(10, WireType.Varint).int32(message.statusId);
        /* int32 priority_id = 11; */
        if (message.priorityId !== 0)
            writer.tag(11, WireType.Varint).int32(message.priorityId);
        /* bool is_active = 12; */
        if (message.isActive !== false)
            writer.tag(12, WireType.Varint).bool(message.isActive);
        /* string start_date = 13; */
        if (message.startDate !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.startDate);
        /* string end_date = 14; */
        if (message.endDate !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.endDate);
        /* int32 event_id = 15; */
        if (message.eventId !== 0)
            writer.tag(15, WireType.Varint).int32(message.eventId);
        /* repeated string field_mask = 16; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(16, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 17; */
        if (message.pageNumber !== 0)
            writer.tag(17, WireType.Varint).int32(message.pageNumber);
        /* repeated string date_range = 18; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(18, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 19; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(19, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* string owner_name = 20; */
        if (message.ownerName !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.ownerName);
        /* TaskStatus status = 21; */
        if (message.status)
            TaskStatus.internalBinaryWrite(message.status, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* TaskPriority priority = 22; */
        if (message.priority)
            TaskPriority.internalBinaryWrite(message.priority, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* string order_by = 23; */
        if (message.orderBy !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 24; */
        if (message.orderDir !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.orderDir);
        /* string group_by = 25; */
        if (message.groupBy !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.groupBy);
        /* bool checked_in = 26; */
        if (message.checkedIn !== false)
            writer.tag(26, WireType.Varint).bool(message.checkedIn);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectTask
 */
export const ProjectTask = new ProjectTask$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectTaskList$Type extends MessageType {
    constructor() {
        super("ProjectTaskList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProjectTask },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ProjectTask results */ 1:
                    message.results.push(ProjectTask.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ProjectTask results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ProjectTask.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectTaskList
 */
export const ProjectTaskList = new ProjectTaskList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskStatusList$Type extends MessageType {
    constructor() {
        super("TaskStatusList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TaskStatus }
        ]);
    }
    create(value) {
        const message = { results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TaskStatus results */ 1:
                    message.results.push(TaskStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TaskStatus results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TaskStatus.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TaskStatusList
 */
export const TaskStatusList = new TaskStatusList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskBillableTypeList$Type extends MessageType {
    constructor() {
        super("TaskBillableTypeList", [
            { no: 1, name: "types", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { types: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string types */ 1:
                    message.types.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string types = 1; */
        for (let i = 0; i < message.types.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.types[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TaskBillableTypeList
 */
export const TaskBillableTypeList = new TaskBillableTypeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskEvent$Type extends MessageType {
    constructor() {
        super("TaskEvent", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "task_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "action_taken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "action_needed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "time_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "time_finished", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "technician_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "latitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 15, name: "longitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 16, name: "technician_user_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, taskId: 0, statusId: 0, actionTaken: "", actionNeeded: "", timeStarted: "", timeFinished: "", technicianUserId: 0, isActive: false, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", latitude: 0, longitude: 0, technicianUserName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 task_id */ 2:
                    message.taskId = reader.int32();
                    break;
                case /* int32 status_id */ 3:
                    message.statusId = reader.int32();
                    break;
                case /* string action_taken */ 4:
                    message.actionTaken = reader.string();
                    break;
                case /* string action_needed */ 5:
                    message.actionNeeded = reader.string();
                    break;
                case /* string time_started */ 6:
                    message.timeStarted = reader.string();
                    break;
                case /* string time_finished */ 7:
                    message.timeFinished = reader.string();
                    break;
                case /* int32 technician_user_id */ 8:
                    message.technicianUserId = reader.int32();
                    break;
                case /* bool is_active */ 9:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 11:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 12:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 13:
                    message.orderDir = reader.string();
                    break;
                case /* double latitude */ 14:
                    message.latitude = reader.double();
                    break;
                case /* double longitude */ 15:
                    message.longitude = reader.double();
                    break;
                case /* string technician_user_name */ 16:
                    message.technicianUserName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 task_id = 2; */
        if (message.taskId !== 0)
            writer.tag(2, WireType.Varint).int32(message.taskId);
        /* int32 status_id = 3; */
        if (message.statusId !== 0)
            writer.tag(3, WireType.Varint).int32(message.statusId);
        /* string action_taken = 4; */
        if (message.actionTaken !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.actionTaken);
        /* string action_needed = 5; */
        if (message.actionNeeded !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.actionNeeded);
        /* string time_started = 6; */
        if (message.timeStarted !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timeStarted);
        /* string time_finished = 7; */
        if (message.timeFinished !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.timeFinished);
        /* int32 technician_user_id = 8; */
        if (message.technicianUserId !== 0)
            writer.tag(8, WireType.Varint).int32(message.technicianUserId);
        /* bool is_active = 9; */
        if (message.isActive !== false)
            writer.tag(9, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 11; */
        if (message.pageNumber !== 0)
            writer.tag(11, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 12; */
        if (message.orderBy !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 13; */
        if (message.orderDir !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.orderDir);
        /* double latitude = 14; */
        if (message.latitude !== 0)
            writer.tag(14, WireType.Bit64).double(message.latitude);
        /* double longitude = 15; */
        if (message.longitude !== 0)
            writer.tag(15, WireType.Bit64).double(message.longitude);
        /* string technician_user_name = 16; */
        if (message.technicianUserName !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.technicianUserName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TaskEvent
 */
export const TaskEvent = new TaskEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskEventList$Type extends MessageType {
    constructor() {
        super("TaskEventList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TaskEvent },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TaskEvent results */ 1:
                    message.results.push(TaskEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TaskEvent results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TaskEvent.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TaskEventList
 */
export const TaskEventList = new TaskEventList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskAssignment$Type extends MessageType {
    constructor() {
        super("TaskAssignment", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "task_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, taskId: 0, isActive: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 task_id */ 3:
                    message.taskId = reader.int32();
                    break;
                case /* int32 is_active */ 4:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 6:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 task_id = 3; */
        if (message.taskId !== 0)
            writer.tag(3, WireType.Varint).int32(message.taskId);
        /* int32 is_active = 4; */
        if (message.isActive !== 0)
            writer.tag(4, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 6; */
        if (message.pageNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TaskAssignment
 */
export const TaskAssignment = new TaskAssignment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskAssignmentList$Type extends MessageType {
    constructor() {
        super("TaskAssignmentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TaskAssignment },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TaskAssignment results */ 1:
                    message.results.push(TaskAssignment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TaskAssignment results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TaskAssignment.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TaskAssignmentList
 */
export const TaskAssignmentList = new TaskAssignmentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpiffOption$Type extends MessageType {
    constructor() {
        super("SpiffOption", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", code: "", amount: 0, isActive: false, eventId: 0, pageNumber: 0, fieldMask: [], orderBy: "", orderDir: "", withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string code */ 3:
                    message.code = reader.string();
                    break;
                case /* int32 amount */ 4:
                    message.amount = reader.int32();
                    break;
                case /* bool is_active */ 5:
                    message.isActive = reader.bool();
                    break;
                case /* int32 event_id */ 6:
                    message.eventId = reader.int32();
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 9:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 10:
                    message.orderDir = reader.string();
                    break;
                case /* bool without_limit */ 11:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string code = 3; */
        if (message.code !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.code);
        /* int32 amount = 4; */
        if (message.amount !== 0)
            writer.tag(4, WireType.Varint).int32(message.amount);
        /* bool is_active = 5; */
        if (message.isActive !== false)
            writer.tag(5, WireType.Varint).bool(message.isActive);
        /* int32 event_id = 6; */
        if (message.eventId !== 0)
            writer.tag(6, WireType.Varint).int32(message.eventId);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 9; */
        if (message.orderBy !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 10; */
        if (message.orderDir !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.orderDir);
        /* bool without_limit = 11; */
        if (message.withoutLimit !== false)
            writer.tag(11, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SpiffOption
 */
export const SpiffOption = new SpiffOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpiffOptionList$Type extends MessageType {
    constructor() {
        super("SpiffOptionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SpiffOption },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SpiffOption results */ 1:
                    message.results.push(SpiffOption.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SpiffOption results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SpiffOption.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SpiffOptionList
 */
export const SpiffOptionList = new SpiffOptionList$Type();
/**
 * @generated ServiceType for protobuf service TaskService
 */
export const TaskService = new ServiceType("TaskService", [
    { name: "Create", options: {}, I: Task, O: Task },
    { name: "CreateProjectTask", options: {}, I: ProjectTask, O: ProjectTask },
    { name: "Get", options: {}, I: Task, O: Task },
    { name: "GetProjectTask", options: {}, I: ProjectTask, O: ProjectTask },
    { name: "BatchGet", options: {}, I: Task, O: TaskList },
    { name: "BatchGetProjectTasks", options: {}, I: ProjectTask, O: ProjectTaskList },
    { name: "Update", options: {}, I: Task, O: Task },
    { name: "UpdateProjectTask", options: {}, I: ProjectTask, O: ProjectTask },
    { name: "Delete", options: {}, I: Task, O: Task },
    { name: "DeleteProjectTask", options: {}, I: ProjectTask, O: ProjectTask },
    { name: "GetSpiffTypes", options: {}, I: SpiffType, O: SpiffTypeList },
    { name: "GetToolFundBalanceByID", options: {}, I: ToolFund, O: ToolFund },
    { name: "GetAppliedSpiffs", options: {}, I: Spiff, O: SpiffList },
    { name: "GetTaskStatusList", options: {}, I: TaskStatus, O: TaskStatusList },
    { name: "GetTaskPriorityList", options: {}, I: TaskPriority, O: TaskPriorityList },
    { name: "GetTaskBillableTypeList", options: {}, I: Empty, O: StringList },
    { name: "CreateTaskEvent", options: {}, I: TaskEvent, O: TaskEvent },
    { name: "GetTaskEvent", options: {}, I: TaskEvent, O: TaskEvent },
    { name: "BatchGetTaskEvent", options: {}, I: TaskEvent, O: TaskEventList },
    { name: "UpdateTaskEvent", options: {}, I: TaskEvent, O: TaskEvent },
    { name: "DeleteTaskEvent", options: {}, I: TaskEvent, O: TaskEvent },
    { name: "CreateTaskAssignment", options: {}, I: TaskAssignment, O: TaskAssignment },
    { name: "GetTaskAssignment", options: {}, I: TaskAssignment, O: TaskAssignment },
    { name: "BatchGetTaskAssignment", options: {}, I: TaskAssignment, O: TaskAssignmentList },
    { name: "UpdateTaskAssignment", options: {}, I: TaskAssignment, O: TaskAssignment },
    { name: "DeleteTaskAssignment", options: {}, I: TaskAssignment, O: TaskAssignment },
    { name: "CreateSpiffToolAdminAction", options: {}, I: SpiffToolAdminAction, O: SpiffToolAdminAction },
    { name: "GetSpiffToolAdminAction", options: {}, I: SpiffToolAdminAction, O: SpiffToolAdminAction },
    { name: "BatchGetSpiffToolAdminAction", options: {}, I: SpiffToolAdminAction, O: SpiffToolAdminActionList },
    { name: "UpdateSpiffToolAdminAction", options: {}, I: SpiffToolAdminAction, O: SpiffToolAdminAction },
    { name: "DeleteSpiffToolAdminAction", options: {}, I: SpiffToolAdminAction, O: SpiffToolAdminAction },
    { name: "CreateSpiffOption", options: {}, I: SpiffOption, O: SpiffOption },
    { name: "GetSpiffOption", options: {}, I: SpiffOption, O: SpiffOption },
    { name: "BatchGetSpiffOption", options: {}, I: SpiffOption, O: SpiffOptionList },
    { name: "UpdateSpiffOption", options: {}, I: SpiffOption, O: SpiffOption },
    { name: "DeleteSpiffOption", options: {}, I: SpiffOption, O: SpiffOption }
]);
