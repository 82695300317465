import { type User } from '@kalos/kalos-rpc';
import { Button, Popover, PopoverContent, PopoverTrigger, Skeleton } from '@kalos/ui';

import { useBatchUserQuery } from '../hooks/react-query/useUserQuery';
import { techniciansUserFilter } from '../modules/ComponentsLibrary/Pickers/newPickers/utils';

export const TechnicianPreview = ({
  userId,
  children,
}: {
  userId: number;
  children?: (props: { user: User }) => React.ReactNode;
}) => {
  const technicianQuery = useBatchUserQuery({
    filters: techniciansUserFilter,
    select(data) {
      return data.results.find((user) => user.id === userId);
    },
  });

  if (technicianQuery.isLoading) {
    return <Skeleton className="h-4 w-full" />;
  }

  if (technicianQuery.isSuccess) {
    if (technicianQuery.data) {
      return (
        <Popover>
          <PopoverTrigger asChild>
            {children ? (
              children({ user: technicianQuery.data })
            ) : (
              <Button size="sm">{userId}</Button>
            )}
          </PopoverTrigger>
          <PopoverContent>
            <div className="flex flex-col gap-1 text-xs">
              <div>
                <span className="font-semibold">Name:</span> {technicianQuery.data.firstname}{' '}
                {technicianQuery.data.lastname}
              </div>
              <div>
                {' '}
                <span className="font-semibold">department:</span>{' '}
                {technicianQuery.data.department?.description}
              </div>
              <div>
                {' '}
                <span className="font-semibold">eMail:</span>{' '}
                {technicianQuery.data.email || 'missing'}
              </div>
              <div>
                <span className="font-semibold">Phone:</span>{' '}
                {technicianQuery.data.phone || 'missing'}
              </div>
              <div>
                <span className="font-semibold">AltPhone:</span>{' '}
                {technicianQuery.data.altphone || 'missing'}
              </div>
              <div>
                <span className="font-semibold">Address:</span> {technicianQuery.data.address || ''}
              </div>
              <div>
                <span className="font-semibold">City:</span> {technicianQuery.data.city || ''}
              </div>
              <div>
                <span className="font-semibold">State:</span> {technicianQuery.data.state || ''}
              </div>
              <div>
                <span className="font-semibold">Zip:</span> {technicianQuery.data.zip || ''}
              </div>
            </div>
          </PopoverContent>
        </Popover>
      );
    }
    return <span>{userId}</span>;
  }
};
