import { type User } from '@kalos/kalos-rpc';
import { createContext, useContext } from 'react';

export interface TokenExchangeResult {
  accessToken: string;
  refreshToken: string;
  expiryDate: string;
}

export interface AuthContextType {
  user: User;
  authChecked: boolean;
  signin: (token: string, cb?: VoidFunction) => Promise<void>;
  signout: (callback?: VoidFunction) => void;
  setUser: (user: User) => void;
  refresh: (userId: number) => Promise<TokenExchangeResult>;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const value = useContext(AuthContext);
  if (!value) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return value;
}
