import { ToolFundSettingsForm } from './ToolFundSettingsForm';
import { ToolFundTransactions } from './ToolFundTransactions';

interface ToolFundProfileProps {
  userId: number;
}

export const ToolFundProfile = ({ userId }: ToolFundProfileProps) => {
  return (
    <div className="space-y-8 p-4">
      <ToolFundSettingsForm userId={userId} />
      <ToolFundTransactions userId={userId} />
    </div>
  );
};
