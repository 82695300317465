import { zodResolver } from '@hookform/resolvers/zod';
import { ToolFundTransaction } from '@kalos/kalos-rpc';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Textarea,
  toast,
} from '@kalos/ui';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useAuth } from '../../../context/AuthContext';
import {
  useBatchGetToolFundTransactionQuery,
  useBatchGetToolFundTransactionTypeQuery,
  useToolFundTransactionCreateMutation,
  useToolFundTransactionUpdateMutation,
} from '../../../hooks/react-query/useToolFundTransaction';

const transactionSchema = z.object({
  id: z.number(),
  userId: z.number(),
  amount: z.number().min(0.01, 'Amount must be greater than 0'),
  notes: z.string().min(1, 'Notes are required'),
  dateCreated: z.string(),
  isActive: z.boolean(),
  createdBy: z.number(),
  transactionTypeId: z.number().min(1, 'Transaction type is required'),
});

type TransactionFormData = z.infer<typeof transactionSchema>;

const defaultValues: TransactionFormData = {
  id: 0,
  userId: 0,
  amount: 0,
  notes: '',
  dateCreated: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
  isActive: true,
  createdBy: 0,
  transactionTypeId: 0,
};

interface TransactionFormProps {
  userId: number;
  defaultData?: Partial<TransactionFormData>;
  onSuccess?: () => void;
}

const TransactionForm = ({ userId, defaultData, onSuccess }: TransactionFormProps) => {
  const { user: loggedUser } = useAuth();
  const { mutate: createTransaction, isPending: isCreating } =
    useToolFundTransactionCreateMutation();
  const { mutate: updateTransaction, isPending: isUpdating } =
    useToolFundTransactionUpdateMutation();
  const { data: transactionTypes } = useBatchGetToolFundTransactionTypeQuery({});

  const form = useForm<TransactionFormData>({
    resolver: zodResolver(transactionSchema),
    defaultValues: {
      ...defaultValues,
      ...defaultData,
      userId,
      createdBy: loggedUser.id,
    },
  });

  const onSubmit = useCallback(
    (data: TransactionFormData) => {
      const isNewRecord = !data.id;
      const transaction = ToolFundTransaction.create({
        ...data,
        userId,
        createdBy: loggedUser.id,
        dateCreated: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      });

      if (isNewRecord) {
        createTransaction(transaction, {
          onSuccess: () => {
            toast({
              variant: 'success',
              title: 'Transaction created successfully',
            });
            form.reset(defaultValues);
            onSuccess?.();
          },
          onError: (error) => {
            toast({
              variant: 'destructive',
              title: 'Failed to create transaction',
              description: error.message,
            });
          },
        });
      } else {
        updateTransaction(transaction, {
          onSuccess: () => {
            toast({
              variant: 'success',
              title: 'Transaction updated successfully',
            });
            onSuccess?.();
          },
          onError: (error) => {
            toast({
              variant: 'destructive',
              title: 'Failed to update transaction',
              description: error.message,
            });
          },
        });
      }
    },
    [userId, loggedUser.id, createTransaction, updateTransaction, form, onSuccess],
  );

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="rounded-lg border bg-white p-4 shadow">
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="transactionTypeId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Transaction Type</FormLabel>
                  <Select
                    value={field.value?.toString()}
                    onValueChange={(value) => field.onChange(Number(value))}
                  >
                    <SelectTrigger>
                      {transactionTypes?.results.find((type) => type.id === field.value)?.name ||
                        'Select Type'}
                    </SelectTrigger>
                    <SelectContent>
                      {transactionTypes?.results.map((type) => (
                        <SelectItem key={type.id} value={type.id.toString()}>
                          {type.name} - {type.description}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Amount</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      step="0.01"
                      {...field}
                      onChange={(e) => field.onChange(Number(e.target.value))}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Notes</FormLabel>
                  <FormControl>
                    <Textarea {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          <div className="mt-4 flex justify-end">
            <Button
              type="submit"
              disabled={isCreating || isUpdating}
              isLoading={isCreating || isUpdating}
            >
              {defaultData?.id ? 'Save' : 'Create'}
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

interface ToolFundTransactionsProps {
  userId: number;
}

export const ToolFundTransactions: React.FC<ToolFundTransactionsProps> = ({ userId }) => {
  const [showNewForm, setShowNewForm] = useState(false);
  const { data: transactions, refetch } = useBatchGetToolFundTransactionQuery({
    filters: { userId },
    enabled: !!userId,
  });

  const handleSuccess = useCallback(() => {
    setShowNewForm(false);
    refetch();
  }, [refetch]);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-semibold tracking-wide">Transactions</h3>
        <Button onClick={() => setShowNewForm(true)} disabled={showNewForm}>
          Add Transaction
        </Button>
      </div>

      <div className="space-y-4">
        {showNewForm && (
          <div className="border-b pb-4">
            <h4 className="mb-2 text-lg font-medium">New Transaction</h4>
            <TransactionForm userId={userId} onSuccess={handleSuccess} />
          </div>
        )}

        {transactions?.results.map((transaction) => (
          <div key={transaction.id}>
            <h4 className="mb-2 text-lg font-medium">
              Transaction #{transaction.id} - {format(new Date(transaction.dateCreated), 'PPp')}
            </h4>
            <TransactionForm
              userId={userId}
              defaultData={{
                ...transaction,
                amount: transaction.amount,
                notes: transaction.notes,
              }}
              onSuccess={handleSuccess}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
