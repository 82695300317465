import { type TradeType } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { InventoryClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';
export type TradeTypeFilter = EntityFilter<TradeType>;

export const useBatchGetTradeTypes = (req: TradeType) => {
  return useQuery({
    queryKey: [
      queryKeys.tradeType.root,
      queryKeys.tradeType.list,
      queryKeys.tradeType.getHash(req),
    ],
    queryFn: async () => {
      const response = await InventoryClientService.BatchGetToolFundTrade(req);
      return response.results || [];
    },
  });
};

export const useTradeTypeUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (tradeType: TradeType) => {
      return await InventoryClientService.UpdateTradeType(tradeType);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tradeType.root],
      });
    },
  });
};
