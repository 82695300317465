import './PrintPage.module.less';

import DownloadIcon from '@mui/icons-material/CloudDownload';
import PrintIcon from '@mui/icons-material/Print';
import CircularProgress from '@mui/material/CircularProgress';
import { default as IconButton } from '@mui/material/IconButton';
import { default as clsx } from 'clsx';
import { type FC, useCallback, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import { PDFClientService, setInlineStyles } from '../../../tools/helpers';
import { Alert } from '../Alert';
import { Button, type Props as ButtonProps } from '../Button';
import { PrintFooter, type Props as FooterProps } from '../PrintFooter';
import { PrintHeader, type Props as HeaderProps } from '../PrintHeader';

export type Status = 'idle' | 'loading' | 'loaded';

interface Props {
  headerProps?: HeaderProps;
  footerProps?: FooterProps;
  buttonProps?: ButtonProps;
  uploadBucket?: string;
  onPrint?: () => void;
  onPrinted?: () => void;
  status?: Status;
  downloadPdfFilename?: string;
  className?: string;
  downloadLabel?: string;
  icons?: boolean;
  onFileCreated?: (file: Uint8Array) => void;
  downloadFile?: boolean;
  showDownloadBtn?: boolean;
  children?: React.ReactNode;
}

export const PrintPage: FC<Props> = ({
  headerProps,
  footerProps,
  buttonProps,
  uploadBucket,
  onPrint,
  onPrinted,
  children,
  status,
  downloadPdfFilename,
  className = '',
  downloadLabel = 'Download',
  downloadFile = false,
  showDownloadBtn = true,
  icons = false,
  onFileCreated,
}) => {
  const printRef = useRef<HTMLDivElement>(null);
  const isFileReturned = useRef<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: '',
  });
  const handleSetAlertOpen = useCallback((isOpen: boolean) => setAlertOpen(isOpen), [setAlertOpen]);
  const handleDownload = useCallback(
    (open: boolean) => async () => {
      if (buttonProps?.loading) {
        handleSetAlertOpen(true);
        return;
      }
      if (printRef.current) {
        setDownloading(true);
        setInlineStyles(printRef.current);
        const content = printRef.current.innerHTML;
        const html = `
        <!DOCTYPE html>
        <html>
          <head>
            <title>${downloadPdfFilename}</title>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `;

        const url = await PDFClientService.getUploadedHTMLUrl(
          html,
          `${downloadPdfFilename}.pdf`,
          uploadBucket,
        );
        console.log('Our cool URL', url);
        if (open) {
          window.open(url?.url, '_blank');
        }
        setDownloading(false);
        return url;
      }
    },
    [buttonProps?.loading, handleSetAlertOpen, uploadBucket, downloadPdfFilename],
  );
  const handleFileCreated = useCallback(async () => {
    const url = await handleDownload(false)();
    if (url) {
      const file = await fetch(url.url);
      const blob = await file.blob();
      const fr = new FileReader();
      fr.onload = () => {
        if (onFileCreated || downloadFile) {
          console.log('on file created');
          if (onFileCreated) {
            onFileCreated(new Uint8Array(fr.result as ArrayBuffer));
          }
        } else {
          const el = document.createElement('a');
          console.log({ downloadPdfFilename });
          el.download = `${downloadPdfFilename}_affadavit.pdf`;
          el.href = URL.createObjectURL(blob);
          el.target = '_blank';
          el.click();
          el.remove();
        }
      };
      fr.readAsArrayBuffer(blob);
    }
  }, [downloadPdfFilename, handleDownload, onFileCreated, downloadFile]);

  useEffect(() => {
    if (status === 'loaded') {
      handlePrint!();
      if (onPrinted) {
        onPrinted();
      }
    }
    if ((onFileCreated || downloadFile) && !isFileReturned.current) {
      isFileReturned.current = true;
      handleFileCreated();
    }
  }, [status, handlePrint, onPrinted, downloadFile, onFileCreated, handleFileCreated]);

  return (
    <>
      {alertOpen && (
        <Alert title="Notice" open={true} onClose={() => handleSetAlertOpen(false)}>
          The file is not ready to view yet.{' '}
        </Alert>
      )}
      <span className={clsx('PrintPage-actions', className)}>
        {downloadPdfFilename &&
          showDownloadBtn &&
          (icons ? (
            <IconButton
              onClick={handleDownload(true)}
              size="small"
              disabled={status === 'loading' || downloading || buttonProps?.disabled}
            >
              {(status === 'loading' || downloading) && (
                <CircularProgress style={{ position: 'absolute', color: '#FFF' }} size={12} />
              )}
              <DownloadIcon />
            </IconButton>
          ) : (
            <Button
              {...buttonProps}
              onClick={(e) => {
                buttonProps?.onClick?.(e);
                handleDownload(true)();
              }}
              disabled={status === 'loading' || downloading || buttonProps?.disabled}
              label={downloadLabel}
            >
              {(status === 'loading' || downloading) && (
                <CircularProgress style={{ color: '#FFF', marginRight: 8 }} size={16} />
              )}
            </Button>
          ))}
        {onFileCreated || downloadFile ? null : icons ? (
          <IconButton
            onClick={
              buttonProps?.loading ? () => handleSetAlertOpen(true) : onPrint || handlePrint!
            }
            size="small"
            disabled={status === 'loading' || buttonProps?.disabled}
          >
            <PrintIcon />
          </IconButton>
        ) : (
          <Button
            {...buttonProps}
            onClick={(e) => {
              if (onPrint) {
                onPrint();
              } else {
                handlePrint();
              }
              buttonProps?.onClick?.(e);
            }}
            label={buttonProps?.label ?? 'Print'}
            disabled={status === 'loading' || buttonProps?.disabled}
          >
            {status === 'loading' ? (
              <CircularProgress style={{ color: '#FFF', marginRight: 8 }} size={16} />
            ) : null}
          </Button>
        )}
      </span>
      <div className="PrintPage">
        <div ref={printRef}>
          {headerProps && <PrintHeader {...headerProps} />}
          <table className="PrintPage_table">
            <tbody>
              <tr>
                <td>{children}</td>
              </tr>
            </tbody>
            {footerProps && (
              <tfoot className="PrintPage_tfoot">
                <tr>
                  <td>
                    <div style={{ height: footerProps.height }}>
                      <PrintFooter {...footerProps} />
                    </div>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </>
  );
};
