import { EmployeeItem, type User } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { getLoadingColumns } from '../../components/utils';
import { ROWS_PER_PAGE } from '../../constants';
import {
  useBatchGetEmployeeItemsQuery,
  useEmployeeItemDeleteMutation,
} from '../../hooks/react-query/employeeItems';
import { EmployeeItemCreateDialog } from './EmployeeItemCreateDialog';
import { EmployeeItemsEditDialog } from './EmployeeItemEditDialog';

const staticEmployeeItems = Array.from({ length: 3 }, (_, id) => EmployeeItem.create({ id }));

export const EmployeeItemsTable = ({ userId }: { userId: User['id'] }) => {
  const columnDefs = useMemo<ColumnDef<EmployeeItem>[]>(
    () => [
      {
        accessorKey: 'name',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Title" />;
        },
        meta: {
          className: 'w-32 text-sm',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>{props.row.original.name}</div>
          );
        },
      },
      {
        accessorKey: 'date',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Date" />;
        },
        meta: {
          className: 'w-32',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {format(parseISO(props.row.original.date), 'yyyy-MM-dd')}
            </div>
          );
        },
      },
      {
        accessorKey: 'notes',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Notes" />;
        },
        meta: {
          className: 'w-52 text-sm',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>{props.row.original.notes}</div>
          );
        },
      },
      {
        id: 'actions',
        header: () => null,
        meta: {
          className: 'w-40 ml-auto flex gap-1 items-center',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <TooltipProvider>
                <Tooltip>
                  <TooltipContent>Edit</TooltipContent>
                  <EmployeeItemsEditDialog
                    original={props.row.original}
                    trigger={
                      <TooltipTrigger asChild>
                        <Button size="icon" variant="outline">
                          <Pencil1Icon />
                        </Button>
                      </TooltipTrigger>
                    }
                  />
                </Tooltip>

                <DeleteEmployeeItemAction itemId={props.row.original.id} />
              </TooltipProvider>
            </div>
          );
        },
      },
    ],
    [],
  );

  const [page, setPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const loadingColumns = useMemo(() => getLoadingColumns(columnDefs), [columnDefs]);

  const itemsQuery = useBatchGetEmployeeItemsQuery({
    filter: {
      userId,
      isActive: true,
    },
  });

  useEffect(() => {
    if (itemsQuery.isSuccess) {
      setTotalEntries(itemsQuery.data.totalCount);
    }
  }, [itemsQuery.data?.totalCount, itemsQuery.isSuccess]);

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    data: itemsQuery.isPending ? staticEmployeeItems : itemsQuery.data?.results ?? [],
    columns: itemsQuery.isPending ? loadingColumns : columnDefs,
    enableSorting: false,
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 px-4">
        <DataTablePagination
          currentPage={page}
          pageCount={Math.ceil(totalEntries / ROWS_PER_PAGE)}
          setPage={setPage}
        />

        <EmployeeItemCreateDialog
          assignToUserId={userId}
          trigger={<Button className="ml-auto max-w-max">Create Item</Button>}
        />
      </div>
      <div className="max-w-full overflow-auto">
        <DataTable table={table} />
      </div>
    </div>
  );
};

const DeleteEmployeeItemAction: React.FC<{ itemId: EmployeeItem['id'] }> = ({ itemId }) => {
  const deleteMutation = useEmployeeItemDeleteMutation();
  const confirm = useConfirm();

  const onDeleteClick = async () => {
    if (!(await confirm('Are you sure you want to delete this item?'))) return;

    try {
      await deleteMutation.mutateAsync(EmployeeItem.create({ id: itemId }));
      toast({
        variant: 'success',
        title: 'Employee Item Deleted',
      });
    } catch (err) {
      console.error(err);
      toast({
        variant: 'destructive',
        title: 'Employee Item Deletion failed',
      });
    }
  };

  return (
    <Tooltip>
      <TooltipContent>Delete</TooltipContent>
      <TooltipTrigger asChild>
        <Button
          isLoading={deleteMutation.isPending}
          disabled={deleteMutation.isPending}
          variant="outline"
          size="icon"
          onClick={onDeleteClick}
        >
          <TrashIcon />
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
};
