import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Button } from '@kalos/ui';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { type MenuItem, type NavOption, type SubMenu } from '../main';

type Props = {
  item: MenuItem;
};

const KalosMenuItem = ({ item }: Props) => {
  if ('items' in item) {
    return <DropdownSubMenu item={item} />;
  }

  return <NavOptionComponent item={item} />;
};

const itemValue = 'itemValue';
const DropdownSubMenu: React.FC<{ item: SubMenu }> = ({ item }) => {
  const [sideMenuState, setSideMenuState] = useLocalStorage(
    'sideMenuDropdownState',
    useMemo(
      () => ({
        admin: false,
        dispatch: false,
        profile: true,
        resources: false,
        search: false,
        tools: false,
      }),
      [],
    ),
  );

  const selectedValues = useMemo(
    () => (sideMenuState[item.localStorageKey] ? [itemValue] : []),
    [item.localStorageKey, sideMenuState],
  );

  return (
    <Accordion
      className="border-foreground/20 border-b"
      type="multiple"
      onValueChange={(newValues) => {
        setSideMenuState((prev) => ({
          ...prev,
          [item.localStorageKey]: newValues.includes(itemValue),
        }));
      }}
      value={selectedValues}
    >
      <AccordionItem value={itemValue}>
        <AccordionTrigger className="pb-1 pt-0">{item.heading}</AccordionTrigger>
        <AccordionContent className="py-1">
          {selectedValues.includes(itemValue) && (
            <ul>
              {item.items.map((subItem, index) => (
                <NavOptionComponent key={index} item={subItem} />
              ))}
            </ul>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

type NavOptionProps = {
  item: NavOption;
};

const NavOptionComponent: React.FC<NavOptionProps> = ({ item }) => {
  const location = useLocation();

  const children = (
    <>
      <div>{item.icon}</div>
      <p>{item.title}</p>
    </>
  );

  return 'button' in item ? (
    <li className="flex">
      <Button
        className="mx-1 flex flex-1 items-center justify-start gap-2"
        onClick={item.onClick}
        variant="ghost"
      >
        {children}
      </Button>
    </li>
  ) : (
    <li>
      <Button
        className="mx-1 flex items-center justify-start gap-2"
        variant={'route' in item && item.route === location.pathname ? 'default' : 'ghost'}
        asChild
        onClick={item.onClick}
      >
        <Link
          to={'route' in item ? item.route : item.href}
          target={'target' in item ? item.target : undefined}
        >
          {children}
        </Link>
      </Button>
    </li>
  );
};

export default KalosMenuItem;
