// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "event.proto" (syntax proto3)
// tslint:disable
import { EventService } from "./event";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service EventService
 */
export class EventServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = EventService.typeName;
        this.methods = EventService.methods;
        this.options = EventService.options;
    }
    /**
     * @generated from protobuf rpc: Create(Event) returns (Event);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(Event) returns (Event);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(Event) returns (EventList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(Event) returns (Event);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(Event) returns (Event);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCalendarData(Event) returns (CalendarData);
     */
    getCalendarData(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: WriteQuote(Quotable) returns (Quotable);
     */
    writeQuote(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReadQuotes(QuotableRead) returns (QuotableList);
     */
    readQuotes(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProjectTasks(ProjectTask) returns (ProjectTaskList);
     */
    getProjectTasks(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLaborHoursByEventID(Int32) returns (Double);
     */
    getLaborHoursByEventID(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetCostReportData(CostReportReq) returns (CostReportData);
     */
    batchGetCostReportData(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateJobAdmin(JobAdmin) returns (JobAdmin);
     */
    createJobAdmin(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetJobAdmin(JobAdmin) returns (JobAdmin);
     */
    getJobAdmin(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetJobAdmin(JobAdmin) returns (JobAdminList);
     */
    batchGetJobAdmin(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateJobAdmin(JobAdmin) returns (JobAdmin);
     */
    updateJobAdmin(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteJobAdmin(JobAdmin) returns (JobAdmin);
     */
    deleteJobAdmin(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetClosestProperty(ClosestPropertyRequest) returns (ClosestPropertyResult);
     */
    getClosestProperty(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GeolocationCheckout(Int32) returns (Bool);
     */
    geolocationCheckout(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateEventAssignment(EventAssignment) returns (EventAssignment);
     */
    createEventAssignment(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEventAssignment(EventAssignment) returns (EventAssignment);
     */
    getEventAssignment(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetEventAssignment(EventAssignment) returns (EventAssignmentList);
     */
    batchGetEventAssignment(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateEventAssignment(EventAssignment) returns (EventAssignment);
     */
    updateEventAssignment(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteEventAssignment(EventAssignment) returns (EventAssignment);
     */
    deleteEventAssignment(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateQuickBooksTrackingCode(QuickBooksTrackingCode) returns (QuickBooksTrackingCode);
     */
    createQuickBooksTrackingCode(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQuickBooksTrackingCode(QuickBooksTrackingCode) returns (QuickBooksTrackingCode);
     */
    getQuickBooksTrackingCode(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetQuickBooksTrackingCode(QuickBooksTrackingCode) returns (QuickBooksTrackingCodeList);
     */
    batchGetQuickBooksTrackingCode(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateQuickBooksTrackingCode(QuickBooksTrackingCode) returns (QuickBooksTrackingCode);
     */
    updateQuickBooksTrackingCode(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteQuickBooksTrackingCode(QuickBooksTrackingCode) returns (QuickBooksTrackingCode);
     */
    deleteQuickBooksTrackingCode(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPendingBilling(PendingBilling) returns (PendingBilling);
     */
    getPendingBilling(input, options) {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPendingBilling(PendingBilling) returns (PendingBillingList);
     */
    batchGetPendingBilling(input, options) {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateContract(Contract) returns (Contract);
     */
    createContract(input, options) {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetContract(Contract) returns (Contract);
     */
    getContract(input, options) {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetContract(Contract) returns (ContractList);
     */
    batchGetContract(input, options) {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateContract(Contract) returns (Contract);
     */
    updateContract(input, options) {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteContract(Contract) returns (Contract);
     */
    deleteContract(input, options) {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMaterialsByContractID(Contract) returns (ContractMaterialList);
     */
    getMaterialsByContractID(input, options) {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateContractFrequency(ContractFrequency) returns (ContractFrequency);
     */
    createContractFrequency(input, options) {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetContractFrequency(ContractFrequency) returns (ContractFrequency);
     */
    getContractFrequency(input, options) {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetContractFrequency(ContractFrequency) returns (ContractFrequencyList);
     */
    batchGetContractFrequency(input, options) {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateContractFrequency(ContractFrequency) returns (ContractFrequency);
     */
    updateContractFrequency(input, options) {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteContractFrequency(ContractFrequency) returns (ContractFrequency);
     */
    deleteContractFrequency(input, options) {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateInvoice(Invoice) returns (Invoice);
     */
    createInvoice(input, options) {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInvoice(Invoice) returns (Invoice);
     */
    getInvoice(input, options) {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetInvoice(Invoice) returns (InvoiceList);
     */
    batchGetInvoice(input, options) {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateInvoice(Invoice) returns (Invoice);
     */
    updateInvoice(input, options) {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteInvoice(Invoice) returns (Invoice);
     */
    deleteInvoice(input, options) {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendSQSInvoiceEmail(SQSEmailAndDocument) returns (Bool);
     */
    sendSQSInvoiceEmail(input, options) {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInvoiceBody(InvoiceBodyRequest) returns (InvoiceBodyReturn);
     */
    getInvoiceBody(input, options) {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateSiLink(SiLink) returns (SiLink);
     */
    createSiLink(input, options) {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSiLink(SiLink) returns (SiLink);
     */
    getSiLink(input, options) {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetSiLink(SiLink) returns (SiLinkList);
     */
    batchGetSiLink(input, options) {
        const method = this.methods[50], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSiLink(SiLink) returns (SiLink);
     */
    updateSiLink(input, options) {
        const method = this.methods[51], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteSiLink(SiLink) returns (SiLink);
     */
    deleteSiLink(input, options) {
        const method = this.methods[52], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateMaintenanceQuestion(MaintenanceQuestion) returns (MaintenanceQuestion);
     */
    createMaintenanceQuestion(input, options) {
        const method = this.methods[53], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMaintenanceQuestion(MaintenanceQuestion) returns (MaintenanceQuestion);
     */
    getMaintenanceQuestion(input, options) {
        const method = this.methods[54], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetMaintenanceQuestion(MaintenanceQuestion) returns (MaintenanceQuestionList);
     */
    batchGetMaintenanceQuestion(input, options) {
        const method = this.methods[55], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateMaintenanceQuestion(MaintenanceQuestion) returns (MaintenanceQuestion);
     */
    updateMaintenanceQuestion(input, options) {
        const method = this.methods[56], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteMaintenanceQuestion(MaintenanceQuestion) returns (MaintenanceQuestion);
     */
    deleteMaintenanceQuestion(input, options) {
        const method = this.methods[57], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePayment(Payment) returns (Payment);
     */
    createPayment(input, options) {
        const method = this.methods[58], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPayment(Payment) returns (Payment);
     */
    getPayment(input, options) {
        const method = this.methods[59], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPayment(Payment) returns (PaymentList);
     */
    batchGetPayment(input, options) {
        const method = this.methods[60], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePayment(Payment) returns (Payment);
     */
    updatePayment(input, options) {
        const method = this.methods[61], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePayment(Payment) returns (Payment);
     */
    deletePayment(input, options) {
        const method = this.methods[62], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPaymentsByEventID(Int32) returns (PaymentList);
     */
    getPaymentsByEventID(input, options) {
        const method = this.methods[63], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateServicesRendered(ServicesRendered) returns (ServicesRendered);
     */
    createServicesRendered(input, options) {
        const method = this.methods[64], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetServicesRendered(ServicesRendered) returns (ServicesRendered);
     */
    getServicesRendered(input, options) {
        const method = this.methods[65], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetServicesRendered(ServicesRendered) returns (ServicesRenderedList);
     */
    batchGetServicesRendered(input, options) {
        const method = this.methods[66], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateServicesRendered(ServicesRendered) returns (ServicesRendered);
     */
    updateServicesRendered(input, options) {
        const method = this.methods[67], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteServicesRendered(ServicesRendered) returns (ServicesRendered);
     */
    deleteServicesRendered(input, options) {
        const method = this.methods[68], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEventDetails(JobNumberRequest) returns (EventDetails);
     */
    getEventDetails(input, options) {
        const method = this.methods[69], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GeolocationCheckoutReturnTimesheets(GeolocationCheckoutRequest) returns (GeolocationTimesheetData);
     */
    geolocationCheckoutReturnTimesheets(input, options) {
        const method = this.methods[70], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GenerateStripePaymentLink(GenerateStripePaymentLinkRequest) returns (GenerateStripePaymentLinkResponse);
     */
    generateStripePaymentLink(input, options) {
        const method = this.methods[71], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPurchaseOrder(PurchaseOrderBatchGetRequest) returns (PurchaseOrderList);
     */
    batchGetPurchaseOrder(input, options) {
        const method = this.methods[72], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPurchaseOrder(IDInt32) returns (PurchaseOrder);
     */
    getPurchaseOrder(input, options) {
        const method = this.methods[73], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePurchaseOrder(PurchaseOrder) returns (IDInt32);
     */
    createPurchaseOrder(input, options) {
        const method = this.methods[74], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePurchaseOrder(PurchaseOrderUpdateRequest) returns (Empty);
     */
    updatePurchaseOrder(input, options) {
        const method = this.methods[75], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePurchaseOrder(IDInt32) returns (Empty);
     */
    deletePurchaseOrder(input, options) {
        const method = this.methods[76], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPurchaseOrderRequisitionItem(PurchaseOrderRequisitionItemBatchGetRequest) returns (PurchaseOrderRequisitionItemList);
     */
    batchGetPurchaseOrderRequisitionItem(input, options) {
        const method = this.methods[77], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPurchaseOrderRequisitionItem(IDInt32) returns (PurchaseOrderRequisitionItem);
     */
    getPurchaseOrderRequisitionItem(input, options) {
        const method = this.methods[78], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePurchaseOrderRequisitionItem(PurchaseOrderRequisitionItem) returns (IDInt32);
     */
    createPurchaseOrderRequisitionItem(input, options) {
        const method = this.methods[79], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePurchaseOrderRequisitionItem(PurchaseOrderRequisitionItemUpdateRequest) returns (Empty);
     */
    updatePurchaseOrderRequisitionItem(input, options) {
        const method = this.methods[80], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePurchaseOrderRequisitionItem(IDInt32) returns (Empty);
     */
    deletePurchaseOrderRequisitionItem(input, options) {
        const method = this.methods[81], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FetchUpcomingSubAccountEvents(SubAccountEventRequest) returns (EventList);
     */
    fetchUpcomingSubAccountEvents(input, options) {
        const method = this.methods[82], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FetchSubAccountEventHistory(SubAccountEventRequest) returns (EventList);
     */
    fetchSubAccountEventHistory(input, options) {
        const method = this.methods[83], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: MostLikelyJob(MostLikelyJobRequest) returns (EventList);
     */
    mostLikelyJob(input, options) {
        const method = this.methods[84], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProjectEvent(ProjectEvent) returns (ProjectEvent);
     */
    getProjectEvent(input, options) {
        const method = this.methods[85], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetProjectEvent(ProjectEvent) returns (ProjectEventList);
     */
    batchGetProjectEvent(input, options) {
        const method = this.methods[86], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateProjectEvent(ProjectEvent) returns (ProjectEvent);
     */
    createProjectEvent(input, options) {
        const method = this.methods[87], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProjectEvent(ProjectEvent) returns (ProjectEvent);
     */
    updateProjectEvent(input, options) {
        const method = this.methods[88], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteProjectEvent(ProjectEvent) returns (ProjectEvent);
     */
    deleteProjectEvent(input, options) {
        const method = this.methods[89], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInvoicePayment(InvoicePayment) returns (InvoicePayment);
     */
    getInvoicePayment(input, options) {
        const method = this.methods[90], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetInvoicePayment(InvoicePayment) returns (InvoicePaymentList);
     */
    batchGetInvoicePayment(input, options) {
        const method = this.methods[91], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateInvoicePayment(InvoicePayment) returns (InvoicePayment);
     */
    createInvoicePayment(input, options) {
        const method = this.methods[92], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateInvoicePayment(InvoicePayment) returns (InvoicePayment);
     */
    updateInvoicePayment(input, options) {
        const method = this.methods[93], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteInvoicePayment(InvoicePayment) returns (InvoicePayment);
     */
    deleteInvoicePayment(input, options) {
        const method = this.methods[94], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInvoicePaymentLine(InvoicePaymentLine) returns (InvoicePaymentLine);
     */
    getInvoicePaymentLine(input, options) {
        const method = this.methods[95], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetInvoicePaymentLine(InvoicePaymentLine) returns (InvoicePaymentLineList);
     */
    batchGetInvoicePaymentLine(input, options) {
        const method = this.methods[96], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateInvoicePaymentLine(InvoicePaymentLine) returns (InvoicePaymentLine);
     */
    createInvoicePaymentLine(input, options) {
        const method = this.methods[97], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateInvoicePaymentLine(InvoicePaymentLine) returns (InvoicePaymentLine);
     */
    updateInvoicePaymentLine(input, options) {
        const method = this.methods[98], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteInvoicePaymentLine(InvoicePaymentLine) returns (InvoicePaymentLine);
     */
    deleteInvoicePaymentLine(input, options) {
        const method = this.methods[99], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCallCountForTimeRange(CallCountRequest) returns (CallCountResponse);
     */
    getCallCountForTimeRange(input, options) {
        const method = this.methods[100], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendInvoiceDocumentLinkWithStripe(PropertyDocumentRequest) returns (Bool);
     */
    sendInvoiceDocumentLinkWithStripe(input, options) {
        const method = this.methods[101], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchUserByPhone(SearchUserByPhoneRequest) returns (SearchUserByPhoneResponse);
     */
    searchUserByPhone(input, options) {
        const method = this.methods[102], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
