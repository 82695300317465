import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { SHIRT_SIZES } from '../../../../../constants';
import { requiredPhoneNumberSchema } from '../../../../../tools/schemas';

const employeeFormSchema = z.object({
  id: z.number().optional(),
  firstname: z.string().min(1, 'Required'),
  lastname: z.string().min(1, 'Required'),
  address: z.string().min(1, 'Required'),
  city: z.string(),
  zip: z.string().min(1, 'Required'),
  state: z.string(),
  empTitle: z.string(),
  employeeDepartmentID: z.number().min(1, 'Please select segment'),
  managedBy: z.number(),
  empBadgeId: z.number(),
  phone: requiredPhoneNumberSchema,
  cellphone: z.string(),
  ext: z.string().optional(),
  toolFund: z.number().nonnegative(),
  email: z.string().email(),
  phoneEmail: z.string().optional(),
  serviceCalls: z.number(),
  isAdmin: z.number(),
  paidServiceCallStatus: z.number(),
  showBilling: z.number(),
  isOfficeStaff: z.number(),
  isHvacTech: z.number(),
  techAssist: z.number(),
  isColorMute: z.number(),
  hireDate: z.date(),
  overrideMonth: z.coerce.number().optional(),
  pantSize: z.string(),
  shirtSize: z.enum(SHIRT_SIZES).optional(),
  ptoValue: z.number().optional(),
  image: z.any().optional(),
});

export type EmployeeFormType = z.infer<typeof employeeFormSchema>;

const defaultFormValues: EmployeeFormType = {
  firstname: '',
  lastname: '',
  address: '',
  city: '',
  zip: '',
  state: '',
  empTitle: '',
  employeeDepartmentID: 0,
  managedBy: 0,
  empBadgeId: 0,
  phone: '',
  cellphone: '',
  ext: '',
  toolFund: 0,
  email: '',
  phoneEmail: '',
  serviceCalls: 0,
  isAdmin: 0,
  paidServiceCallStatus: 0,
  showBilling: 0,
  isOfficeStaff: 0,
  isHvacTech: 0,
  techAssist: 0,
  isColorMute: 0,
  hireDate: new Date(),
  overrideMonth: undefined,
  ptoValue: 0,
  image: undefined,
  pantSize: '',
  shirtSize: undefined,
};

export const getEmployeeFormDefaults = ({
  defaultValues,
}: {
  defaultValues?: Partial<EmployeeFormType>;
}): EmployeeFormType => {
  return {
    ...defaultFormValues,
    ...defaultValues,
  };
};

export const useEmployeeForm = ({
  defaultValues,
  disabled,
}: {
  defaultValues: Partial<EmployeeFormType>;
  disabled?: boolean;
}) => {
  return useForm<EmployeeFormType>({
    resolver: zodResolver(employeeFormSchema),
    mode: 'onSubmit',
    defaultValues: getEmployeeFormDefaults({ defaultValues }),
    disabled,
  });
};
