import { type InvoicePayment } from '@kalos/kalos-rpc';
import { z } from 'zod';

import { PAYMENT_TYPE_LIST } from '../../../../../constants';
import { isMoneyValue } from '../../../../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../../../../tools/typeguargs';

type InvoicePaymentFormFields = Pick<InvoicePayment, 'amount' | 'paymentDate' | 'paymentTypeId'>;
export const permitFormSchema = z.object({
  amount: z.coerce.number().positive().refine(isMoneyValue, '2 digits at max'),
  paymentDate: z.date(),
  paymentTypeId: z.enum(PAYMENT_TYPE_LIST),
}) satisfies ZodObjectWithModelKeys<InvoicePaymentFormFields>;

export type InvoicePaymentFormSchema = z.infer<typeof permitFormSchema>;
