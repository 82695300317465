import { zodResolver } from '@hookform/resolvers/zod';
import { type Inspection } from '@kalos/kalos-rpc';
import {
  Button,
  Checkbox,
  DateInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
} from '@kalos/ui';
import { useCallback } from 'react';
import { type SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';

import { type DirtyModelFields } from '../../../../../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../../../../../tools/typeguargs';
import { getFieldMaskFromDirtyField } from '../../../../../ComponentsLibrary/EmployeeDepartments/utils';
import { InspectionStatusSelect } from '../../../../components/InspectionStatusSelect/InspectionStatusSelect';
export const PENDING_INSPECTION_STATUS_ID = 1;
export const SUCCESS_INSPECTION_STATUS_ID = 2;
export const FAILED_INSPECTION_STATUS_ID = 3;

type InspectionFormFields = Pick<
  Inspection,
  'notes' | 'statusId' | 'inspectionType' | 'requestorScheduledDate' | 'scheduledDate'
>;

const inspectionSchema = z.object({
  notes: z.string(),
  statusId: z.coerce.number().positive(),
  notify: z.boolean().optional(),
  inspectionType: z.string(),
  requestorScheduledDate: z.date(),
  scheduledDate: z.date(),
}) satisfies ZodObjectWithModelKeys<InspectionFormFields>;

type InspectionFormSchema = z.infer<typeof inspectionSchema>;

const notifiableStatuses = [SUCCESS_INSPECTION_STATUS_ID, FAILED_INSPECTION_STATUS_ID];

const getDefaultInspectionFormValues = (arg?: Partial<InspectionFormSchema>) => ({
  notes: '',
  statusId: 1,
  inspectionType: '',
  requestorScheduledDate: new Date(),
  scheduledDate: new Date(),
  ...arg,
});

export const InspectionForm = ({
  defaultValues,
  onSave,
  disabled,
  isLoading,
  includeNotificationCheckbox = false,
  requestorName,
}: {
  defaultValues: InspectionFormSchema;
  onSave: (arg: {
    data: InspectionFormSchema;
    dirtyFields: DirtyModelFields<InspectionFormSchema>;
  }) => void;
  isLoading?: boolean;
  disabled?: boolean;
  includeNotificationCheckbox?: boolean;
  requestorName?: string;
}) => {
  const form = useForm<InspectionFormSchema>({
    resolver: zodResolver(inspectionSchema),
    defaultValues,
    disabled: disabled,
  });

  const currentSelectedStatus = useWatch({ control: form.control, name: 'statusId' });

  const isApplicableForNotification = currentSelectedStatus !== defaultValues.statusId;
  const showNotificationCheckbox =
    includeNotificationCheckbox &&
    isApplicableForNotification &&
    notifiableStatuses.includes(currentSelectedStatus);

  const onSubmit: SubmitHandler<InspectionFormSchema> = useCallback(
    (data) => {
      onSave({
        data,
        dirtyFields: form.formState.dirtyFields,
      });
    },
    [form.formState.dirtyFields, onSave],
  );
  return (
    <form className="grid gap-2" onSubmit={form.handleSubmit(onSubmit)}>
      <Form {...form}>
        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem className="flex flex-col justify-between">
              <FormLabel>Notes</FormLabel>
              <FormMessage />
              <FormControl>
                <Textarea {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="statusId"
          render={({ field }) => (
            <FormItem className="flex flex-col justify-between">
              <div className="flex items-center justify-between gap-2">
                <FormLabel>Status</FormLabel>
                {showNotificationCheckbox && (
                  <FormField
                    control={form.control}
                    name="notify"
                    render={({ field }) => (
                      <FormItem className="flex flex-row gap-2 space-y-0">
                        <FormLabel>Notify requestor on change ({requestorName})?</FormLabel>
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={(newState) => field.onChange(!!newState)}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                )}
              </div>
              <FormMessage />
              <InspectionStatusSelect
                onChange={(val) => field.onChange(Number(val))}
                selectedValue={field.value.toString()}
                disabled={field.disabled}
                placeholder="Select status"
              />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="inspectionType"
          render={({ field }) => (
            <FormItem className="flex flex-col justify-between">
              <FormLabel>Inspection Type</FormLabel>
              <FormMessage />
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="scheduledDate"
          render={({ field }) => (
            <FormItem className="flex flex-col justify-between">
              <FormLabel>Scheduled Date</FormLabel>
              <FormMessage />
              <FormControl>
                <DateInput value={field.value} onChange={field.onChange} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="requestorScheduledDate"
          render={({ field }) => (
            <FormItem className="flex flex-col justify-between">
              <FormLabel>Requestor Scheduled Date</FormLabel>
              <FormMessage />
              <FormControl>
                <DateInput value={field.value} onChange={field.onChange} />
              </FormControl>
            </FormItem>
          )}
        />
        <Button
          type="submit"
          disabled={disabled || !getFieldMaskFromDirtyField(form.formState.dirtyFields).length}
          isLoading={isLoading}
        >
          Save
        </Button>
      </Form>
    </form>
  );
};

InspectionForm.getDefaultValues = getDefaultInspectionFormValues;
