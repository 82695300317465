import { Quotable, QuoteLine, QuoteUsed } from '@kalos/kalos-rpc';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { default as IconButton } from '@mui/material/IconButton';
import { useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';

import { queryKeys } from '../../../hooks/react-query/constants';
import { useEventQuotesQuery } from '../../../hooks/react-query/useEventQuotes';
import { useQuoteLineQuery } from '../../../hooks/react-query/useQuoteLineQuery';
import {
  makeFakeRows,
  QuoteLineClientService,
  QuoteUsedClientService,
  usd,
} from '../../../tools/helpers';
import { type ActionsProps } from '../Actions';
import { Field, type Value } from '../Field';
import { Form, type Schema } from '../Form';
import { type Columns, type Data, InfoTable } from '../InfoTable';
import { Modal } from '../Modal';
import { SectionBar } from '../SectionBar';
import { Tooltip } from '../Tooltip';
import { Filter } from './filter';

export type SelectedQuote = {
  quotePart: Quotable;
  billable: boolean;
  quantity: number;
};
interface Props {
  servicesRenderedId?: number;
  onUpdate?: () => Promise<void>;
  savePendingQuotes?: (servicesRenderedId: number, selectedQuotes: Quotable[]) => void;
  pendingQuotableProp?: Quotable[];
  pendingNewQuotableProp?: Quotable[];
  onClose?: () => void;
  archived?: boolean;
  setPendingQuotableProp?: (quotes: Quotable[]) => void;
  setPendingNewQuotableProp?: (quotes: Quotable[]) => void;
}

const COLUMNS: Columns = [
  { name: 'Selected' },
  { name: 'Billable' },
  { name: 'Part/Labor' },
  { name: 'Quantity' },
  { name: 'Price' },
];

const COLUMNS_QUOTABLE: Columns = [
  { name: 'Description', align: 'center' },
  { name: 'Quantity', align: 'center' },
  { name: 'Price', align: 'center' },
  { name: 'Total Price', align: 'center' },
  { name: 'Actions', align: 'center' },
];

const SCHEMA_NEW_QUOTABLE: Schema<Quotable> = [
  [
    {
      name: 'description',
      label: 'Item/Labor Name',
      type: 'text',
      multiline: true,
      required: true,
      validationOnSave(value) {
        if (!value.trim().replaceAll(' ', '')) return 'This field is required';
        if (value.length > 1000) return 'The name cannot exceed 1000 characters';
        return '';
      },
    },
  ],

  [
    {
      name: 'isLmpc',
      type: 'checkbox',
      label: 'LMPC',
    },
    {
      name: 'isBillable',
      type: 'checkbox',
      label: 'Billable?',
    },
    {
      name: 'quotedPrice',
      type: 'number',
      label: 'Price',
      required: true,
      startAdornment: '$',
      validationOnSave(value) {
        if (!value.trim().replaceAll(' ', '')) return 'This field is required';
        if (Number(value) < 0) return "This field can't be negative";
        return '';
      },
    },
    {
      name: 'quantity',
      type: 'number',
      label: 'QTY',
      required: true,
      validationOnSave(value) {
        if (!value.trim().replaceAll(' ', '')) return 'This field is required';
        if (Number(value) <= 0) return "This field can't be negative";
        return '';
      },
    },
    {
      name: 'quoteUsedId',
      type: 'hidden',
    },
    {
      name: 'quoteLineId',
      type: 'hidden',
    },
  ],
];

export const QuoteSelector: FC<Props> = ({
  servicesRenderedId,
  onUpdate,
  pendingNewQuotableProp,
  pendingQuotableProp,
  archived,
  setPendingNewQuotableProp,
  setPendingQuotableProp,
  onClose,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [newQuotable, setNewQuotable] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [quotable, setQuotable] = useState<Quotable[]>([]);
  const [pendingQuotable, setPendingQuotable] = useState<Quotable[]>(
    pendingQuotableProp ? pendingQuotableProp : [],
  );
  const [pendingNewQuotable, setPendingNewQuotable] = useState<Quotable[]>(
    pendingNewQuotableProp ? pendingNewQuotableProp : [],
  );
  const [pendingDeleteQuotable, setPendingDeleteQuotable] = useState<Quotable[]>([]);

  const [selectedQuoteLineIds, setSelectedQuoteLineIds] = useState<number[]>([]);
  const [pendingUpdateExistingQuotable, setPendingUpdateExistingQuotable] = useState<Quotable[]>(
    [],
  );
  const [editingQuote, setEditingQuote] = useState<Quotable | undefined>(undefined);
  const [editPendingQuote, setEditPendingQuote] = useState<Quotable | undefined>(undefined);
  const [editPendingNewQuote, setEditPendingNewQuote] = useState<Quotable | undefined>(undefined);
  const [billable, setBillable] = useState<{
    [key: number]: {
      billable: boolean;
      lmpc: boolean;
      quantity: number;
    };
  }>({});

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isSelectedValid, setIsSelectedValid] = useState<boolean>(true);

  const isEventQuotesQueryEnabled = !!servicesRenderedId;

  const serviceRenderedQuotesQuery = useEventQuotesQuery({
    filter: {
      servicesRenderedId,
    },
    enabled: isEventQuotesQueryEnabled,
  });

  useEffect(() => {
    if (serviceRenderedQuotesQuery.isSuccess) {
      setQuotable(serviceRenderedQuotesQuery.data?.data || []);
    }
  }, [serviceRenderedQuotesQuery.data?.data, serviceRenderedQuotesQuery.isSuccess]);

  const quotablePartsQuery = useQuoteLineQuery({
    filters: {
      isFlatrate: '1',
      isActive: 1,
      withoutLimit: true,
    },
    enabled: isEventQuotesQueryEnabled ? serviceRenderedQuotesQuery.isSuccess : true,
    select(data) {
      return data.results;
    },
  });

  const quotableParts = useMemo(() => {
    if (quotablePartsQuery.isSuccess) {
      if (isEventQuotesQueryEnabled) {
        if (!serviceRenderedQuotesQuery.isSuccess) return [];
        // if event Id is passed, we need to filter out the quotes that are already selected

        const alreadySelectedQuotesIds = serviceRenderedQuotesQuery.data?.data.map(
          (q) => q.quoteLineId,
        );
        return quotablePartsQuery.data.filter(
          (quote) => !alreadySelectedQuotesIds.includes(quote.id),
        );
      }

      return quotablePartsQuery.data;
    }

    return [];
  }, [
    isEventQuotesQueryEnabled,
    quotablePartsQuery.data,
    quotablePartsQuery.isSuccess,
    serviceRenderedQuotesQuery.data?.data,
    serviceRenderedQuotesQuery.isSuccess,
  ]);

  const handleToggleOpen = useCallback(() => setOpen((open) => !open), [setOpen]);

  const handlePendingAddToUpdateExsting = useCallback(
    (updatedQuote: Quotable) => {
      const quotableIndex = pendingUpdateExistingQuotable.findIndex(
        (item) => item.quoteUsedId == updatedQuote.quoteUsedId,
      );

      if (quotableIndex !== -1) {
        setPendingUpdateExistingQuotable((prevQuotables) => {
          const updatedQuotables = [...prevQuotables];
          updatedQuotables[quotableIndex] = updatedQuote;

          return updatedQuotables;
        });
      } else {
        setPendingUpdateExistingQuotable((prevQuotables) => [...prevQuotables, updatedQuote]);
      }

      const updatedQuotableIndex = quotable.findIndex(
        (item) => item.quoteUsedId == updatedQuote.quoteUsedId,
      );

      if (updatedQuotableIndex !== -1) {
        setQuotable((prevQuotables) => {
          const updatedQuotables = [...prevQuotables];
          updatedQuotables[updatedQuotableIndex] = updatedQuote;

          return updatedQuotables;
        });
      }
      console.log(pendingUpdateExistingQuotable);
      setEditingQuote(undefined);
    },

    [pendingUpdateExistingQuotable, quotable],
  );
  const handleToggleQuoteLineSelect = useCallback(
    (id: number) => (value: Value) => {
      setSelectedQuoteLineIds(
        value ? [...selectedQuoteLineIds, id] : selectedQuoteLineIds.filter((_id) => _id !== id),
      );
      const temp = {
        ...billable,
        [id]: {
          billable: true,
          quantity: 1,
          lmpc: false,
        },
      };
      if (!value) {
        delete temp[id];
      }
      setBillable(temp);
      setIsSelected(!!Object.keys(temp).length);
    },
    [selectedQuoteLineIds, billable],
  );
  const handleToggleBillable = useCallback(
    (id: number) => (value: Value) => {
      setBillable({
        ...billable,
        [id]: {
          billable: !!value,
          quantity: billable[id].quantity || 1,
          lmpc: false,
        },
      });
    },
    [billable],
  );
  const handleToggleBillableQuantity = useCallback(
    (id: number) => (value: Value) => {
      const temp = {
        ...billable,
        [id]: {
          billable: billable[id].billable,
          quantity: +value,
          lmpc: false,
        },
      };
      const cond = Object.values(temp).some((item) => item.quantity <= 0);
      setIsSelectedValid(!cond);
      setBillable(temp);
    },
    [billable],
  );
  const handleDeleteQuoteLine = useCallback(async () => {
    for (let i = 0; i < pendingDeleteQuotable.length; i++) {
      const temp = pendingDeleteQuotable[i];
      const quoteUsed = QuoteUsed.create();
      quoteUsed.id = temp.quoteUsedId;
      await QuoteUsedClientService.Delete(quoteUsed);
    }
    setPendingDeleteQuotable([]);
  }, [pendingDeleteQuotable]);
  const handleUpdateQuoteLine = useCallback(async () => {
    for (let i = 0; i < pendingUpdateExistingQuotable.length; i++) {
      const temp = pendingUpdateExistingQuotable[i];
      const quoteUsed = QuoteUsed.create();
      quoteUsed.id = temp.quoteUsedId;
      quoteUsed.quantity = temp.quantity;
      quoteUsed.lmpc = temp.isLmpc == true ? 1 : 0;
      quoteUsed.billable = temp.isBillable == true ? 1 : 0;

      quoteUsed.quotedPrice = temp.quotedPrice;
      quoteUsed.fieldMask = temp.fieldMask;
      quoteUsed.fieldMask = quoteUsed.fieldMask.map((field) =>
        field == 'IsBillable' ? 'Billable' : field,
      );
      quoteUsed.fieldMask = quoteUsed.fieldMask.map((field) =>
        field == 'IsLmpc' ? 'Lmpc' : field,
      );
      console.log('our request to update', quoteUsed);
      await QuoteUsedClientService.Update(quoteUsed);
    }
    setPendingUpdateExistingQuotable([]);
  }, [pendingUpdateExistingQuotable]);
  const handleAddToPendingDelete = useCallback(
    async (pendingRemoveQuotable: Quotable) => {
      const tempQuotable = quotable.filter(
        (quote) => quote.quoteUsedId != pendingRemoveQuotable.quoteUsedId,
      );
      setQuotable(tempQuotable);
      const tempPendingDelete = pendingDeleteQuotable;
      tempPendingDelete.push(pendingRemoveQuotable);
      setPendingDeleteQuotable(tempPendingDelete);
    },

    [pendingDeleteQuotable, quotable],
  );
  const handleRemovePending = useCallback(
    async (pendingRemoveQuotable: Quotable) => {
      const tempSelected = selectedQuoteLineIds.filter(
        (quote) => quote != pendingRemoveQuotable.quoteLineId,
      );
      setSelectedQuoteLineIds(tempSelected);
      const newPendingList = pendingQuotable.filter(
        (quote) => quote.quoteLineId != pendingRemoveQuotable.quoteLineId,
      );
      setPendingQuotable(newPendingList);
      if (setPendingQuotableProp) {
        setPendingQuotableProp(newPendingList);
      }
    },
    [pendingQuotable, setPendingQuotableProp, selectedQuoteLineIds],
  );
  const handleRemoveNewPending = useCallback(
    async (pendingRemoveQuotable: Quotable) => {
      const tempSelected = selectedQuoteLineIds.filter(
        (quote) => quote != pendingRemoveQuotable.quoteLineId,
      );
      setSelectedQuoteLineIds(tempSelected);
      const newPendingList = pendingNewQuotable.filter(
        (quote) => quote.quoteLineId != pendingRemoveQuotable.quoteLineId,
      );
      setPendingNewQuotable(newPendingList);
      if (setPendingNewQuotableProp) {
        setPendingNewQuotableProp(newPendingList);
      }
    },
    [pendingNewQuotable, setPendingNewQuotableProp, selectedQuoteLineIds],
  );
  const handleAddQuotes = useCallback(async () => {
    const temp: Quotable[] = [];
    const existingIds = new Set(pendingQuotable.map((item) => item.quoteLineId));

    Object.keys(billable).forEach((id) => {
      const quote = quotablePartsQuery.data?.find(
        (q) => q.id == +id && selectedQuoteLineIds.includes(q.id),
      );

      if (quote && !existingIds.has(quote.id)) {
        temp.push(
          Quotable.create({
            quantity: billable[+id].quantity,
            isBillable: billable[+id].billable,
            isLmpc: billable[+id].lmpc,
            isActive: true,
            isFlatrate: true,
            quotedPrice: parseInt(quote.adjustment),
            id: quote.id,
            quoteLineId: quote.id,
            description: quote.description,
          }),
        );
      }
    });

    const cond = !!temp.length;
    setIsSelected(cond);
    if (cond) {
      const updatedQuotable = [...pendingQuotable, ...temp];
      setPendingQuotable(updatedQuotable);
      setPendingQuotableProp?.(updatedQuotable);
      setOpen(false);
    }
  }, [
    billable,
    quotablePartsQuery.data,
    selectedQuoteLineIds,
    pendingQuotable,
    setPendingQuotableProp,
  ]);

  const handleToggleNewQuotable = useCallback(() => setNewQuotable((isShown) => !isShown), []);

  const handleUpdatePendingQuotable = useCallback(
    async (editingPendingNew: Quotable) => {
      const temp = pendingQuotable;
      console.log('we are updating a quotable, but it is pending');

      const quotableIndex = temp.findIndex(
        (item) => item.quoteLineId == editingPendingNew.quoteLineId,
      );

      if (quotableIndex !== -1) {
        setPendingQuotable((prevQuotables) => {
          const updatedQuotables = [...prevQuotables];
          updatedQuotables[quotableIndex] = editingPendingNew;
          console.log('our new quotables', updatedQuotables);
          setPendingQuotableProp?.(updatedQuotables);
          return updatedQuotables;
        });
      }

      setEditPendingQuote(undefined);
    },

    [pendingQuotable, setPendingQuotableProp],
  );

  const handleUpdatePendingNewQuotable = useCallback(
    async (editingPendingNew: Quotable) => {
      const temp = pendingNewQuotable;
      console.log('we are updating a new quotable, but it is pending');
      const quotableIndex = temp.findIndex(
        (item) => item.quoteLineId == editingPendingNew.quoteLineId,
      );

      if (quotableIndex !== -1) {
        setPendingNewQuotable((prevQuotables) => {
          const updatedQuotables = [...prevQuotables];
          updatedQuotables[quotableIndex] = editingPendingNew;

          return updatedQuotables;
        });
      }

      setEditPendingNewQuote(undefined);
    },

    [pendingNewQuotable],
  );

  const queryClient = useQueryClient();

  const handleSavePendingQuotable = useCallback(async () => {
    // TODO mutation
    const tempPendingQuotable = pendingQuotable;
    const tempPendingNewQuotable = pendingNewQuotable;
    if (pendingUpdateExistingQuotable.length > 0) {
      await handleUpdateQuoteLine();
    }
    if (pendingDeleteQuotable.length > 0) {
      await handleDeleteQuoteLine();
    }
    if (
      (tempPendingQuotable.length > 0 || tempPendingNewQuotable.length > 0) &&
      servicesRenderedId
    ) {
      for (let i = 0; i < tempPendingQuotable.length; i++) {
        const quotePart = tempPendingQuotable[i];
        const req = QuoteUsed.create();
        req.quoteLineId = quotePart.quoteLineId;
        req.quotedPrice = quotePart.quotedPrice;
        req.quantity = quotePart.quantity;
        req.servicesRenderedId = servicesRenderedId;
        console.log('the part', quotePart);
        req.billable = quotePart.isBillable == true ? 1 : 0;
        req.lmpc = quotePart.isLmpc == true ? 1 : 0;

        try {
          console.log('we creating here', req);
          req.fieldMask = ['Billable', 'Lmpc', 'Quantity', 'QuotedPrice'];
          await QuoteUsedClientService.Create(req);
          if (req.billable == 0) {
            await QuoteUsedClientService.Update(QuoteUsed.create({ id: req.id, billable: 0 }));
          }
        } catch (err) {
          console.error(err);
        }
      }
      for (let i = 0; i < tempPendingNewQuotable.length; i++) {
        const quotePart = tempPendingNewQuotable[i];
        const req = QuoteUsed.create();
        const quotelineReq = QuoteLine.create();
        quotelineReq.description = quotePart.description;
        quotelineReq.adjustment = quotePart.quotedPrice.toString();
        quotelineReq.warranty = 2;
        const quotelineRes = await QuoteLineClientService.Create(quotelineReq);
        req.quoteLineId = quotelineRes!.id;
        req.quotedPrice = quotePart.quotedPrice;
        req.quantity = quotePart.quantity;
        req.lmpc = quotePart.isLmpc == true ? 1 : 0;
        req.servicesRenderedId = servicesRenderedId;
        req.billable = quotePart.isBillable == true ? 1 : 0;

        try {
          console.log('we creating here instead', req);

          await QuoteUsedClientService.Create(req);
          if (req.billable == 0) {
            await QuoteUsedClientService.Update(QuoteUsed.create({ id: req.id, billable: 0 }));
          }
        } catch {
          console.log('failed to add quotable item');
        }
      }

      if (setPendingQuotableProp) {
        setPendingQuotableProp([]);
      }
      if (setPendingNewQuotableProp) {
        setPendingNewQuotableProp([]);
      }

      setSelectedQuoteLineIds([]);
    }

    setPendingNewQuotable([]);
    setPendingQuotable([]);
    if (onUpdate) {
      console.log('we are updating event materials');
      await onUpdate();
    }
    queryClient.invalidateQueries({
      queryKey: [
        queryKeys.eventQuotes.root,
        queryKeys.eventQuotes.list,
        queryKeys.eventQuotes.getHash({ servicesRenderedId }),
      ],
    });
  }, [
    pendingQuotable,
    pendingNewQuotable,
    pendingUpdateExistingQuotable.length,
    pendingDeleteQuotable.length,
    servicesRenderedId,
    onUpdate,
    queryClient,
    handleUpdateQuoteLine,
    handleDeleteQuoteLine,
    setPendingQuotableProp,
    setPendingNewQuotableProp,
  ]);

  const loading =
    (isEventQuotesQueryEnabled && serviceRenderedQuotesQuery.isFetching) ||
    quotablePartsQuery.isPending;

  const handleSaveNewQuotable = useCallback(
    (safeData: Quotable) => {
      const quantity = safeData.quantity;
      const billable = safeData.isBillable;
      const lmpc = safeData.isLmpc;

      const quotePart = Quotable.create();

      Object.assign(quotePart, {
        ...safeData,
        quantity,
        billable,
        lmpc,
        isActive: true, // TODO rest default props?
      });
      const newPendingNewQuotable = [...pendingNewQuotable, quotePart];
      setPendingNewQuotable(newPendingNewQuotable);
      if (setPendingNewQuotableProp) {
        setPendingNewQuotableProp(newPendingNewQuotable);
      }

      setNewQuotable(false);
      setOpen(false);
    },
    [pendingNewQuotable, setPendingNewQuotableProp],
  );

  const actions = useMemo(() => {
    let actions: ActionsProps = [];
    if (onUpdate) {
      actions = [
        ...actions,
        {
          label: 'Add',
          onClick: handleToggleOpen,
          disabled: loading || archived,
        },
      ];
    }
    if (onClose) {
      actions = [
        ...actions,
        {
          label: 'Close',
          onClick: onClose,
          disabled: loading,
        },
      ];
    }
    if (servicesRenderedId && onUpdate) {
      actions = [
        ...actions,
        {
          label: 'Save Material Changes',

          onClick: servicesRenderedId ? handleSavePendingQuotable : undefined,
          disabled:
            loading ||
            archived ||
            (pendingQuotable.length == 0 &&
              pendingDeleteQuotable.length == 0 &&
              pendingNewQuotable.length == 0 &&
              pendingUpdateExistingQuotable.length == 0),
        },
      ];
    }
    return actions;
  }, [
    handleSavePendingQuotable,
    handleToggleOpen,
    archived,
    loading,
    onClose,
    onUpdate,
    pendingDeleteQuotable.length,
    pendingNewQuotable.length,
    pendingQuotable.length,
    pendingUpdateExistingQuotable.length,
    servicesRenderedId,
  ]);

  const data: Data = loading
    ? makeFakeRows(6, 20)
    : quotableParts
        .filter((quote) =>
          filter
            ? quote.description.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
            : true,
        )
        .map((quote) => [
          {
            value: (
              <Field
                name="selected"
                type="checkbox"
                style={{ marginBottom: 0 }}
                value={selectedQuoteLineIds.includes(quote.id)}
                onChange={handleToggleQuoteLineSelect(quote.id)}
              />
            ),
          },
          {
            value: selectedQuoteLineIds.includes(quote.id) ? (
              <Field
                name="selectedBillable"
                type="checkbox"
                style={{ marginBottom: 0 }}
                value={billable[quote.id].billable}
                onChange={handleToggleBillable(quote.id)}
              />
            ) : (
              ''
            ),
          },
          { value: quote.description },
          {
            value: selectedQuoteLineIds.includes(quote.id) ? (
              <Field
                name="selected"
                type="number"
                style={{ marginBottom: 0 }}
                value={billable[quote.id].quantity}
                onChange={handleToggleBillableQuantity(quote.id)}
              />
            ) : (
              ''
            ),
          },
          {
            value: usd(
              selectedQuoteLineIds.includes(quote.id)
                ? billable[quote.id].quantity * parseInt(quote.adjustment)
                : parseInt(quote.adjustment),
            ),
          },
        ]);
  const dataQuotable: Data = loading
    ? makeFakeRows(4, 5)
    : [
        ...pendingQuotable.map((quote) => [
          { value: <strong>{quote.description}</strong> },
          { value: <strong>{quote.quantity}</strong> },
          { value: <strong>{usd(quote.quotedPrice)}</strong> },
          {
            value: <strong>{usd(quote.quantity * quote.quotedPrice)}</strong>,
          },
          {
            value: (
              <div>
                <Tooltip key="delete" content="Delete">
                  <IconButton
                    key="deleteIcon"
                    size="small"
                    onClick={() => handleRemovePending(quote)}
                    disabled={archived}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip key="edit" content="Edit">
                  <IconButton
                    key="editIcon"
                    size="small"
                    onClick={() => setEditPendingQuote(quote)}
                    disabled={archived}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ),
          },
        ]),
        ...pendingNewQuotable.map((quote) => [
          { value: <strong>{quote.description}</strong> },
          { value: <strong>{quote.quantity}</strong> },
          { value: <strong>{usd(quote.quotedPrice)}</strong> },
          {
            value: <strong>{usd(quote.quantity * quote.quotedPrice)}</strong>,
          },
          {
            value: (
              <div>
                <Tooltip key="delete" content="Delete">
                  <IconButton
                    key="deleteIcon"
                    size="small"
                    onClick={() => handleRemoveNewPending(quote)}
                    disabled={archived}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip key="edit" content="Edit">
                  <IconButton
                    key="editIcon"
                    size="small"
                    onClick={() => setEditPendingNewQuote(quote)}
                    disabled={archived}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ),
          },
        ]),
        ...quotable.map((quote) => [
          { value: quote.description },
          { value: quote.quantity },
          { value: usd(quote.quotedPrice) },
          { value: usd(quote.quantity * quote.quotedPrice) },
          {
            value: (
              <div>
                <Tooltip key="delete" content="Delete">
                  <IconButton
                    key="deleteIcon"
                    size="small"
                    disabled={archived}
                    onClick={() => handleAddToPendingDelete(quote)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip key="edit" content="Edit">
                  <IconButton
                    key="editIcon"
                    size="small"
                    onClick={() => setEditingQuote(quote)}
                    disabled={archived}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ),
          },
        ]),
      ];

  return (
    <div>
      <SectionBar title="Supplies / Services" actions={actions} fixedActions />
      <InfoTable
        styles={{ minWidth: '600px' }}
        columns={COLUMNS_QUOTABLE}
        data={dataQuotable}
        loading={loading}
      />
      {open && (
        <Modal open onClose={handleToggleOpen} fullScreen>
          <SectionBar
            title="Select Item(s)"
            actions={[
              {
                label: 'New Part/Labor',
                variant: 'outlined',
                onClick: handleToggleNewQuotable,
              },

              {
                label: 'Add Selected',
                onClick: handleAddQuotes,
                disabled: !isSelected || !isSelectedValid,
              },

              { label: 'Close', onClick: handleToggleOpen },
            ]}
            fixedActions
          />
          <Filter onSearch={setFilter} />
          <InfoTable
            className={clsx('services-table', {
              error: !isSelectedValid,
            })}
            columns={COLUMNS}
            data={data}
            hoverable
            loading={quotablePartsQuery.isPending}
          />
        </Modal>
      )}
      {newQuotable && (
        <Modal open onClose={handleToggleNewQuotable}>
          <Form<Quotable>
            title="Adding Part/Service To Current Job"
            schema={SCHEMA_NEW_QUOTABLE}
            onClose={handleToggleNewQuotable}
            onSave={handleSaveNewQuotable}
            data={Quotable.create({ isBillable: true, quantity: 1 })}
          />
        </Modal>
      )}
      {editingQuote && (
        <Modal open onClose={() => setEditingQuote(undefined)}>
          <Form<Quotable>
            title="Edit Existing "
            schema={SCHEMA_NEW_QUOTABLE}
            onClose={() => setEditingQuote(undefined)}
            onSave={handlePendingAddToUpdateExsting}
            data={editingQuote}
          />
        </Modal>
      )}
      {editPendingQuote && (
        <Modal open onClose={() => setEditPendingQuote(undefined)}>
          <Form<Quotable>
            title="Edit Pending Quote "
            schema={SCHEMA_NEW_QUOTABLE}
            onClose={() => setEditPendingQuote(undefined)}
            onSave={handleUpdatePendingQuotable}
            data={editPendingQuote}
          />
        </Modal>
      )}
      {editPendingNewQuote && (
        <Modal open onClose={() => setEditPendingNewQuote(undefined)}>
          <Form<Quotable>
            title="Edit Pending Quote "
            schema={SCHEMA_NEW_QUOTABLE}
            onClose={() => setEditPendingNewQuote(undefined)}
            onSave={handleUpdatePendingNewQuotable}
            data={editPendingNewQuote}
          />
        </Modal>
      )}
    </div>
  );
};
