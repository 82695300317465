import { BaseClient } from '../BaseClient';
import { timestamp } from '../Common';
import { Empty } from '../compiled-protos/common';
import {
  type Document
  ,
  DocumentKey,
  DocumentKeyList,
  InternalDocument,
  InternalDocumentList
} from '../compiled-protos/s3';
import { S3ServiceClient as InternalDocumentServiceClient } from '../compiled-protos/s3.client';
import { File, FileClient } from '../File';

class InternalDocumentClient extends BaseClient {
  self: InternalDocumentServiceClient

  FileClient: FileClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.FileClient = new FileClient(host);
    this.self = new InternalDocumentServiceClient(this.transport)
  }

  public async Create(req: InternalDocument) {
    let res = InternalDocument.create()
    try {
      res = await this.self.createInternalDocument(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async Get(req: InternalDocument) {
    let res = InternalDocument.create()
    try {
      res = await this.self.getInternalDocument(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async Update(req: InternalDocument) {
    let res = InternalDocument.create()
    try {
      res = await this.self.updateInternalDocument(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async Delete(req: InternalDocument) {
    let res = InternalDocument.create()
    try {
      res = await this.self.deleteInternalDocument(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGet(req: InternalDocument) {
    let res = InternalDocumentList.create()
    try {
      res = await this.self.batchGetInternalDocument(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetDocumentKeys(req: DocumentKey) {
    let res = DocumentKeyList.create()
    try {
      res = await this.self.getDocumentKeys(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async WriteDocumentKey(req: DocumentKey) {
    let res = DocumentKey.create()
    try {
      res = await this.self.writeDocumentKey(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async DeleteDocumentKey(req: DocumentKey) {
    let res = Empty.create()
    try {
      res = await this.self.deleteDocumentKey(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public deleteDocumentKeyById = async (id: number) => {
    await this.self.deleteDocumentKey(
      DocumentKey.create({ id }),
      this.getMetaData(),
    )
  };

  public deleteInternalDocumentById = async (id: number) => {
    const req = InternalDocument.create();
    req.id = id;
    await this.Delete(req);
  };

  public loadDocumentKeys = async () => {
    const req = DocumentKey.create();
    req.isActive = true;
    let res = DocumentKeyList.create()
    try {
      res = await (await this.GetDocumentKeys(req))
    } catch (err) {
      console.error(err)
    }
    return res
  };


  public saveDocumentKey = async (data: DocumentKey, id?: number) => {
    if (id) {
      data.id = id;
    }
    return await this.self.writeDocumentKey(data, this.getMetaData());
  };


  public upsertInternalDocument = async (data: InternalDocument) => {

    const id = data.id
    const reqFile = File.create();

    reqFile.bucket = 'kalos-internal-docs'

    reqFile.name = data.filename

    if (data.fileId != 0) {

      reqFile.id = data.fileId
    }
    const file = await this.FileClient.upsertFile(reqFile);
    if (!id) {

      data.dateCreated = timestamp()

      data.fileId = file!.id
    }

    data.dateModified = timestamp()
    if (id !== 0 && data.fieldMask.length === 0) {
      throw new Error(
        'Attempting to update entity without providing a field mask will result in a no op'
      );
    }
    if (id) {
      return await this.Update(data)
    }
    else {
      return await this.Create(data)
    }

  };

  public loadInternalDocuments = async ({
    page,
    filter: { tag, description },
    sort: { orderBy, orderDir },
  }: LoadInternalDocuments) => {
    //const req = new InternalDocument();
    const req = InternalDocument.create()
    //const keys = Object.keys(req);
    const keys = Object.keys(req)
    req.orderBy = orderBy;
    if (!keys.includes(orderBy)) {
      //const dk = new DocumentKey();
      const dk = DocumentKey.create()
      dk.isActive = true;
      req.tagData = dk;
    }
    req.orderDir = orderDir;
    req.pageNumber = page;
    if (tag && tag > 0) {
      req.tag = tag;
    }
    if (description) {
      req.description = `%${description}%`;
    }
    return await this.BatchGet(req)
  };
}

type LoadInternalDocuments = {
  page: number;
  filter: InternalDocumentsFilter;
  sort: InternalDocumentsSort;
};

type InternalDocumentsFilter = {
  tag?: number;
  description?: string;
};

type InternalDocumentsSort = {
  orderByField: keyof InternalDocument | keyof Document;
  orderBy: string;
  orderDir: 'ASC' | 'DESC';
};

export type { LoadInternalDocuments, InternalDocumentsFilter, InternalDocumentsSort }

export {
  InternalDocument,
  InternalDocumentList,
  InternalDocumentClient,
  DocumentKey,
  DocumentKeyList,
};
