import { toast } from '@kalos/ui';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { QueryCache, QueryClient } from '@tanstack/react-query';

import { outsideAuthSignout, outsideAuthUpdateToken } from '../App';
import { CopyButton } from '../components/CopyButton';

const handleAuthIssue = async () => {
  try {
    await outsideAuthUpdateToken?.();
    return true;
  } catch {
    outsideAuthSignout?.();
    // then in RequireAuth component user will be redirected to login page
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      gcTime: 20 * 1000, // 20 sec
      retry(failureCount) {
        const shouldRetry = failureCount <= 1;
        return shouldRetry;
      },
    },
    mutations: {
      async onError(error) {
        if (error instanceof RpcError && error.code === 'UNAUTHENTICATED') {
          console.log('Handling unauthenticated error - signing off', error);
          await handleAuthIssue();
        } else {
          console.log('Unexpected Error from mutation', { error });
        }
      },
    },
  },
  queryCache: new QueryCache({
    async onError(error, query) {
      console.log('onError queryCache', { error, query });
      if (error instanceof RpcError && error.code === 'UNAUTHENTICATED') {
        if (await handleAuthIssue()) {
          query.fetch().catch(console.log);
        }
      } else {
        if (error instanceof RpcError) {
          // valid cases
          if (error.code === 'NOT_FOUND') return;

          console.log('Unexpected Error from query', { error, query });
          const location = [error.serviceName, error.methodName].filter(Boolean).join('.');
          const fullError = [location, error.message, error.code, error.cause, error.stack]
            .filter(Boolean)
            .join(' - ');
          toast({
            title: 'Unexpected request error',
            description: (
              <div className="space-y-2 text-xs">
                {location && <p className="font-bold ">{location}</p>}
                <p className="text-sm font-bold">{error.message}</p>
                <p className="text-sm font-bold">Code: {error.code}</p>
                <p>PLease contact engineering support with this message</p>
                <CopyButton text={fullError} />
              </div>
            ),
            variant: 'destructive',
            duration: 15_000,
          });
        }
      }
    },
  }),
});
