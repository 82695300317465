import { type VariantProps } from 'class-variance-authority';

import { cn } from '../../utils';
import { badgeVariants } from './badge.variants';

export type BadgeProps<T extends React.ElementType> = React.ComponentPropsWithoutRef<T> &
  VariantProps<typeof badgeVariants> & {
    as?: T;
  };

export function Badge<T extends React.ElementType = 'div'>({
  as,
  className,
  variant,
  ...props
}: BadgeProps<T>) {
  const Tag = as ?? 'div';

  return <Tag className={cn(badgeVariants({ variant }), className)} {...props} />;
}
