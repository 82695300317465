import { Contract, type ContractList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ContractClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type ContractFilter = EntityFilter<Contract>;
export const useContractsBatchGetQuery = <TSelectData = ContractList,>({
  enabled = true,
  filter,
  select,
}: {
  enabled?: boolean;
  filter: ContractFilter;
  select?: (data: ContractList) => TSelectData;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.contracts.root,
      queryKeys.contracts.list,
      queryKeys.contracts.getHash(filter),
    ],
    queryFn: async () => {
      return await ContractClientService.BatchGet(Contract.create(filter));
    },
    enabled,
    select,
  });
};

export const useContractQuery = ({
  filter = {},
  enabled = true,
}: {
  filter?: ContractFilter;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [queryKeys.contracts.root, queryKeys.contracts.getHash(filter)],
    enabled,
    queryFn: async () => {
      const req = Contract.create(filter);
      return await ContractClientService.Get(req);
    },
  });
};

export const useDeleteContractByIdMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: Contract['id']) => {
      const req = Contract.create({ id });
      return ContractClientService.Delete(req);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contracts.root],
      });
    },
  });
};
