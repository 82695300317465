import { EmployeeItemOption } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTitle, DialogTrigger, toast } from '@kalos/ui';
import { useCallback, useMemo, useState } from 'react';

import { useEmployeeItemOptionUpdateMutation } from '../../../../hooks/react-query/employeeItems';
import { getFieldMaskFromDirtyField } from '../../../ComponentsLibrary/EmployeeDepartments/utils';
import { EmployeeItemOptionForm } from './EmployeeItemOptionForm';

export const EmployeeItemOptionFormEditDialog = ({
  trigger,
  original,
}: {
  trigger: React.ReactNode;
  original: EmployeeItemOption;
}) => {
  const updateEmployeeItemOptionMutation = useEmployeeItemOptionUpdateMutation();
  const isLoading = updateEmployeeItemOptionMutation.isPending;
  const [isOpen, setIsOpen] = useState(false);

  const handleCreate = useCallback<React.ComponentProps<typeof EmployeeItemOptionForm>['onSave']>(
    async ({ data, dirtyFields }) => {
      try {
        await updateEmployeeItemOptionMutation.mutateAsync(
          EmployeeItemOption.create({
            ...data,
            id: original.id,
            fieldMask: getFieldMaskFromDirtyField(dirtyFields),
          }),
        );
        toast({
          variant: 'success',
          title: 'Successfully edited item option',
        });
        setIsOpen(false);
      } catch {
        toast({
          variant: 'destructive',
          title: 'Failed to edit item option',
        });
      }
    },
    [original.id, updateEmployeeItemOptionMutation],
  );

  const defaultValues = useMemo(
    () => EmployeeItemOptionForm.getDefaultValues(original),
    [original],
  );

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>

      <DialogContent className="overflow-auto">
        <DialogTitle>Edit Employee Item Option</DialogTitle>
        <EmployeeItemOptionForm
          isLoading={isLoading}
          disabled={isLoading}
          onSave={handleCreate}
          defaultValues={defaultValues}
        />
      </DialogContent>
    </Dialog>
  );
};
