import {
  EmployeeItem,
  EmployeeItemCategory,
  type EmployeeItemCategoryList,
  EmployeeItemList,
  EmployeeItemOption,
  EmployeeItemOptionList,
} from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { UserClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export const useEmployeeItemCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (employeeItem: EmployeeItem) => {
      return await UserClientService.CreateEmployeeItem(employeeItem);
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [queryKeys.employeeItems.root] });
    },
  });
};

export const useEmployeeItemUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (employeeItem: EmployeeItem) => {
      return await UserClientService.UpdateEmployeeItem(employeeItem);
    },
    onSuccess(data, variables, context) {
      queryClient.setQueriesData<EmployeeItemList>(
        { queryKey: [queryKeys.employeeItems.root, queryKeys.employeeItems.list] },
        (oldData) => {
          if (oldData && oldData.results.find((item) => item.id === data.id)) {
            return EmployeeItemList.create({
              totalCount: oldData.totalCount,
              results: oldData.results.map((item) => (item.id === data.id ? data : item)),
            });
          }
        },
      );
    },
  });
};

export const useEmployeeItemDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (employeeItem: EmployeeItem) => {
      return await UserClientService.DeleteEmployeeItem(employeeItem);
    },
    onSuccess(data, variables, context) {
      queryClient.setQueriesData<EmployeeItemList>(
        { queryKey: [queryKeys.employeeItems.root, queryKeys.employeeItems.list] },
        (oldData) => {
          if (oldData && oldData.results.find((item) => item.id === data.id)) {
            return EmployeeItemList.create({
              totalCount: oldData.totalCount - 1,
              results: oldData.results.filter((item) => item.id !== data.id),
            });
          }
        },
      );
      queryClient.invalidateQueries({ queryKey: [queryKeys.employeeItems.root] });
    },
  });
};

export type EmployeeItemFilter = EntityFilter<EmployeeItem>;
export const useBatchGetEmployeeItemsQuery = ({ filter = {} }: { filter?: EmployeeItemFilter }) => {
  return useQuery({
    queryKey: [
      queryKeys.employeeItems.root,
      queryKeys.employeeItems.list,
      queryKeys.employeeItems.getHash(filter),
    ],
    queryFn: async () => {
      return await UserClientService.BatchGetEmployeeItem(EmployeeItem.create(filter));
    },
  });
};

export type EmployeeItemOptionFilter = EntityFilter<EmployeeItemOption>;
export const useEmployeeItemOptionsBatchGetQuery = <TSelectData = EmployeeItemOptionList,>({
  filter = {},
  select,
}: {
  filter?: EmployeeItemOptionFilter;
  select?: (data: EmployeeItemOptionList) => TSelectData;
} = {}) => {
  return useQuery({
    queryKey: [
      queryKeys.employeeItems.root,
      queryKeys.employeeItems.employeeItemsOptions,
      queryKeys.employeeItems.employeeItemsOptionsList,
      queryKeys.employeeItems.getEmployeeItemOptionsHash(filter),
    ],
    queryFn: async () => {
      return await UserClientService.BatchGetEmployeeItemOption(EmployeeItemOption.create(filter));
    },
    select,
  });
};

export const useEmployeeItemOptionCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (employeeItemOption: EmployeeItemOption) => {
      return await UserClientService.CreateEmployeeItemOption(employeeItemOption);
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [queryKeys.employeeItems.root] });
    },
  });
};

export const useEmployeeItemOptionUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (employeeItemOption: EmployeeItemOption) => {
      return await UserClientService.UpdateEmployeeItemOption(employeeItemOption);
    },
    onSuccess(data, variables, context) {
      queryClient.setQueriesData<EmployeeItemOptionList>(
        {
          queryKey: [
            queryKeys.employeeItems.root,
            queryKeys.employeeItems.employeeItemsOptions,
            queryKeys.employeeItems.employeeItemsOptionsList,
          ],
        },
        (oldData) => {
          if (oldData && oldData.results.find((item) => item.id === data.id)) {
            return EmployeeItemOptionList.create({
              results: oldData.results.map((item) => (item.id === data.id ? data : item)),
              totalCount: oldData.totalCount,
            });
          }
        },
      );

      queryClient.invalidateQueries({ queryKey: [queryKeys.employeeItems.root] });
    },
  });
};

export const useEmployeeItemOptionDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (employeeItemOption: EmployeeItemOption) => {
      return await UserClientService.DeleteEmployeeItemOption(employeeItemOption);
    },
    onSuccess(data, variables, context) {
      queryClient.setQueriesData<EmployeeItemOptionList>(
        {
          queryKey: [
            queryKeys.employeeItems.root,
            queryKeys.employeeItems.employeeItemsOptions,
            queryKeys.employeeItems.employeeItemsOptionsList,
          ],
        },
        (oldData) => {
          if (oldData && oldData.results.find((item) => item.id === variables.id)) {
            return EmployeeItemOptionList.create({
              results: oldData.results.filter((item) => item.id !== variables.id),
              totalCount: oldData.totalCount - 1,
            });
          }
        },
      );
      queryClient.invalidateQueries({ queryKey: [queryKeys.employeeItems.root] });
    },
  });
};

export type EmployeeItemCategoryFilter = EntityFilter<EmployeeItemCategory>;
export const useBatchGetEmployeeItemCategoryQuery = <TSelectData = EmployeeItemCategoryList,>({
  filter = {},
  select,
}: {
  filter?: EmployeeItemCategoryFilter;
  select?: (data: EmployeeItemCategoryList) => TSelectData;
} = {}) => {
  return useQuery({
    queryKey: [
      queryKeys.employeeItemCategories.root,
      queryKeys.employeeItemCategories.list,
      queryKeys.employeeItemCategories.getHash(filter),
    ],
    queryFn: async () => {
      return await UserClientService.BatchGetEmployeeItemCategory(
        EmployeeItemCategory.create(filter),
      );
    },
    select,
  });
};
