import { BaseClient } from '../BaseClient';
import { Empty, Int32 } from '../compiled-protos/common';
import { 
  AssetInstance,
  type CatalogItem,
  type CatalogItemCategory,
  CatalogItemCategoryList,
  CatalogItemList,
  Requisition,
 RequisitionList,
  ToolFundBalance,
   ToolFundDashboardMetrics,
  type ToolFundHistoryRequest,
  ToolFundHistoryResponse,
  type ToolFundRequisitionRequest,
  ToolFundSettings,
  ToolFundTransaction,
  ToolFundTransactionList,
  type ToolFundTransactionType,
  ToolFundTransactionTypeList,
  TradeType,
  TradeTypeList,
  type TransferAssetRequest,
  type UploadApprovedToolsRequest,
   UploadApprovedToolsResponse} from '../compiled-protos/inventory';
import { InventoryServiceClient } from '../compiled-protos/inventory.client';
import { type User } from '../compiled-protos/user';

class InventoryClient extends BaseClient {
  self: InventoryServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new InventoryServiceClient(this.transport);
  }

  // Tool Fund Request Management
  async GetToolFundRequests(req: ToolFundHistoryRequest): Promise<ToolFundHistoryResponse> {
    let res = ToolFundHistoryResponse.create();
    try {
      const response = await this.self.getToolFundHistory(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('GetToolFundRequests failed:', err);
      return res;
    }
    return res;
  }

  async CreateToolFundRequest(req: ToolFundRequisitionRequest): Promise<Requisition> {
    let res = Requisition.create();
    try {
      const response = await this.self.createToolFundRequisition(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('CreateToolFundRequest failed:', err);
      return res;
    }
    return res;
  }

  async GetUserToolFundBalance(user: User): Promise<ToolFundBalance> {
    let res = ToolFundBalance.create();
    try {
      const response = await this.self.getUserToolFundBalance(user, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('GetUserToolFundBalance failed:', err);
      return res;
    }
    return res;
  }

  async TransferToolAsset(req: TransferAssetRequest): Promise<AssetInstance> {
    let res = AssetInstance.create();
    try {
      const response = await this.self.transferToolAsset(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('TransferToolAsset failed:', err);
      return res;
    }
    return res;
  }

  async CreateToolFundTransaction(req: ToolFundTransaction): Promise<ToolFundTransaction> {
    let res = ToolFundTransaction.create();
    try {
      const response = await this.self.createToolFundTransaction(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('CreateToolFundTransaction failed:', err);
      return res;
    }
    return res;
  }
  async GetToolFundTransaction(req: ToolFundTransaction): Promise<ToolFundTransaction> {
    let res = ToolFundTransaction.create();
    try {
      const response = await this.self.getToolFundTransaction(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('getToolFundTransaction failed:', err);
      return res;
    }
    return res;
  }
  async BatchGetToolFundTransaction(req: ToolFundTransaction): Promise<ToolFundTransactionList> {
    let res = ToolFundTransactionList.create();
    try {
      const response = await this.self.batchGetToolFundTransaction(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('BatchGetToolFundTransaction failed:', err);
      return res;
    }
    return res;
  }
  async UpdateToolFundTransaction(req: ToolFundTransaction): Promise<ToolFundTransaction> {
    let res = ToolFundTransaction.create();
    try {
      const response = await this.self.updateToolFundTransaction(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('updateToolFundTransaction failed:', err);
      return res;
    }
    return res;
  }
  async DeleteToolFundTransaction(req: ToolFundTransaction): Promise<ToolFundTransaction> {
    let res = ToolFundTransaction.create();
    try {
      const response = await this.self.deleteToolFundTransaction(req, this.getMetaData());
      res = req;
    } catch (err) {
      console.error('deleteToolFundTransaction failed:', err);
      return res;
    }
    return res;
  }
  async BatchGetRequisition(req: Requisition): Promise<RequisitionList> {
    let res = RequisitionList.create();
    try {
      const response = await this.self.batchGetRequisition(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('BatchGetRequisition failed:', err);
      return res;
    }
    return res;
  
  }
  async UpdateRequisition(req: Requisition): Promise<Requisition> {
    let res = Requisition.create();
    try {
      const response = await this.self.updateRequisition(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('UpdateRequisition failed:', err);
      return res;
    }
    return res;
  }

  async BatchGetToolFundTrade(req: TradeType): Promise<TradeTypeList> {
    let res = TradeTypeList.create();
    try {
      const response = await this.self.batchGetTradeType(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('BatchGetTrade failed:', err);
      return res;
    }
    return res;
  }
  async UpdateTradeType(req: TradeType): Promise<TradeType> {
    let res = TradeType.create();
    try {
      const response = await this.self.updateTradeType(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('UpdateTradeType failed:', err);
      return res;
    }
    return res;
  }
  async UploadApprovedToolList(req: UploadApprovedToolsRequest): Promise<UploadApprovedToolsResponse> {
    let res = UploadApprovedToolsResponse.create()
    try {
      const response = await this.self.uploadApprovedTools(req, this.getMetaData());
      res = response.response;
    } catch (err) {
      console.error('upload of approved tool list failed:', err);
      return res;
    }
    return res;
  }

async FetchDashboardMetrics(): Promise<ToolFundDashboardMetrics> {
  let res = ToolFundDashboardMetrics.create()
  const req = Empty.create();
  try {
    const response = await this.self.getToolFundDashboardMetrics(req, this.getMetaData());
    res = response.response;
  } catch (err) {
    console.error('Failed to fetch dashboard metrics:', err);
    return res;
  }
  return res;
}

async GetToolFundSettings(req: ToolFundSettings): Promise<ToolFundSettings> {
  let res = ToolFundSettings.create();
  try {
    const response = await this.self.getToolFundSettings(req, this.getMetaData());
    res = response.response;
  } catch (err) {
    console.error('GetToolFundSettings failed:', err);
    return res;
  }
  return res;
}

async UpdateToolFundSettings(req: ToolFundSettings): Promise<ToolFundSettings> {
  let res = ToolFundSettings.create();
  try {
    const response = await this.self.updateToolFundSettings(req, this.getMetaData());
    res = response.response;
  } catch (err) {
    console.error('UpdateToolFundSettings failed:', err);
    return res;
  }
  return res;
}
async CreateToolFundSettings(req: ToolFundSettings): Promise<ToolFundSettings> {
  let res = ToolFundSettings.create();
  try {
    const response = await this.self.createToolFundSettings(req, this.getMetaData());
    res = response.response;
  } catch (err) {
    console.error('UpdateToolFundSettings failed:', err);
    return res;
  }
  return res;
}
async BatchGetToolFundTransactionType(req: ToolFundTransactionType): Promise<ToolFundTransactionTypeList> {
  let res = ToolFundTransactionTypeList.create();
  try {
    const response = await this.self.batchGetTransactionType(req, this.getMetaData());
    res = response.response;
  } catch (err) {
    console.error('BatchGetToolFundTransactionType failed:', err);
    return res;
  }
  return res;

}
async BatchGetCatalogItem(req: CatalogItem): Promise<CatalogItemList> {
  let res = CatalogItemList.create();
  try {

    const response = await this.self.batchGetCatalogItem(req, this.getMetaData());
    res = response.response;
  } catch (err) {
    console.error('BatchGetCatalogItem failed:', err);
    return res;
  }
  return res;
}
async GetPendingRequistionRequests(): Promise<Int32> {
  let res = Int32.create({value:0});
  try {

    const response = await this.self.getPendingRequisitionCount(Empty.create(), this.getMetaData());
    res = response.response;
  } catch (err) {
    console.error('BatchGetCatalogItem failed:', err);
    return res;
  }
  return res;
}
async BatchGetCatalogItemCategory(req: CatalogItemCategory): Promise<CatalogItemCategoryList> {
  let res = CatalogItemCategoryList.create();
  try {
    const response = await this.self.batchGetCatalogItemCategory(req, this.getMetaData());
    res = response.response;
  } catch (err) {
    console.error('BatchGetCatalogItemCategory failed:', err);
    return res;
  }
  return res;

}
}
export { InventoryClient };

// Re-export types
export type {
  TransferAssetRequest,
  AssetInstance,
  ToolFundTransaction,
  ToolFundTransactionList,
  ToolFundSettings,
  ToolFundBalance,
  ToolFundHistoryRequest,
  ToolFundHistoryResponse,
  ToolFundRequisitionRequest,
  Requisition,
  RequisitionList,
  TradeType,
  TradeTypeList
};
