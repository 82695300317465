import { Document, DocumentList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DocumentClientService, S3ClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type DocumentFilter = EntityFilter<Document>;
export const useDocumentsQuery = ({ filter = {} }: { filter?: DocumentFilter } = {}) => {
  return useQuery({
    queryFn: () => {
      const req = Document.create(filter);
      return DocumentClientService.BatchGet(req);
    },
    queryKey: [
      queryKeys.documents.root,
      queryKeys.documents.list,
      queryKeys.documents.getHash(filter),
    ],
  });
};

export const useDeleteDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ document, bucket }: { document: Document; bucket: string }) => {
      const result = await DocumentClientService.Delete(document);
      S3ClientService.deleteFileFromS3Buckets(document.filename, bucket);
      return result;
    },
    onSuccess: async (_, { document }) => {
      queryClient.setQueriesData<DocumentList>(
        { queryKey: [queryKeys.documents.root, queryKeys.documents.list] },
        (cache) => {
          if (cache && cache.results.find((doc) => doc.id === document.id)) {
            return DocumentList.create({
              totalCount: cache.totalCount - 1,
              results: cache.results.filter((doc) => doc.id !== document.id),
            });
          }
          return cache;
        },
      );
      await queryClient.invalidateQueries({ queryKey: [queryKeys.documents.root] });
    },
  });
};

export const useDocumentUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (document: Document) => {
      return DocumentClientService.Update(document);
    },
    onSuccess: (updatedDoc) => {
      queryClient.setQueriesData<DocumentList>(
        { queryKey: [queryKeys.documents.root] },
        (cache) => {
          if (cache) {
            if (cache.results.find((doc) => doc.id === updatedDoc.id)) {
              return DocumentList.create({
                totalCount: cache.totalCount,
                results: cache.results.map((doc) => (doc.id === updatedDoc.id ? updatedDoc : doc)),
              });
            }

            return cache;
          }
        },
      );
      queryClient.invalidateQueries({ queryKey: [queryKeys.documents.root] });
    },
  });
};
