import { type Property } from '@kalos/kalos-rpc';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

import {
  useGetDefaultSearchFormValues,
  useSearchParamsForm,
} from '../../../../hooks/useFormWithSearchParams';
import { type ZodObjectWithModelKeys } from '../../../../tools/typeguargs';

type PropertiesSearchFields = Pick<Property, 'address' | 'subdivision' | 'city' | 'zip'>;

const propertiesSearchScheme = z.object({
  address: z.string(),
  subdivision: z.string(),
  city: z.string(),
  zip: z.string(),
}) satisfies ZodObjectWithModelKeys<PropertiesSearchFields>;

export type PropertiesSearchScheme = z.infer<typeof propertiesSearchScheme>;
export const defaultPropertiesSearchValues: PropertiesSearchScheme = {
  address: '',
  subdivision: '',
  city: '',
  zip: '',
};

export const usePropertySearchForm = ({ disableParamsSync }: { disableParamsSync?: boolean }) => {
  return useSearchParamsForm({
    schema: propertiesSearchScheme,
    defaultValues: useGetDefaultSearchFormValues(
      propertiesSearchScheme,
      defaultPropertiesSearchValues,
    ),
    defaultResetValues: defaultPropertiesSearchValues,
    disableParamsSync,
  });
};

export const usePropertySearchFormContext = () => {
  return useFormContext<PropertiesSearchScheme>();
};
