import { type Invoice, type InvoicePayment, InvoicePaymentLine, type User } from '@kalos/kalos-rpc';
import { createContext, useContext } from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { timestamp } from '../../../../../tools/helpers';

type InvoicePaymentLineStoreType = {
  invoicePaymentLines: InvoicePaymentLine[];
  setInvoicePaymentLines: (
    updater: InvoicePaymentLine[] | ((prevValue: InvoicePaymentLine[]) => InvoicePaymentLine[]),
  ) => void;
  original: InvoicePayment;
  addRemainder: (arg: { creatorId: User['id'] }) => InvoicePaymentLine;
  isEditing: boolean;
  setIsEditing: (updater: boolean | ((preValue: boolean) => boolean)) => void;
  deleteLineItem: (item: InvoicePaymentLine) => void;
  currentInvoiceId: Invoice['id'];
  resetInvoicePaymentLines: () => void;
};

export const createInvoicePaymentLineContext = ({
  invoicePayment,
  currentInvoiceId,
}: {
  invoicePayment: InvoicePayment;
  currentInvoiceId: Invoice['id'];
}) => {
  return create<InvoicePaymentLineStoreType>()(
    devtools(
      (set, get) => ({
        currentInvoiceId,
        invoicePaymentLines: invoicePayment.paymentLine,
        original: invoicePayment,
        setInvoicePaymentLines: (updater) => {
          set({
            invoicePaymentLines:
              typeof updater === 'function' ? updater(get().invoicePaymentLines) : updater,
          });
        },
        addRemainder({ creatorId }) {
          const currentTotal = get().invoicePaymentLines.reduce(
            (acc, line) => acc + line.amount,
            0,
          );
          const remainder = InvoicePaymentLine.create({
            amount: Number((invoicePayment.amount - currentTotal).toFixed(2)),
            paymentId: invoicePayment.id,
            creatorId,
            createdDate: timestamp(true, new Date()),
            isActive: true,
          });

          const currentState = get();

          set({
            ...currentState,
            invoicePaymentLines: [...currentState.invoicePaymentLines, remainder],
          });
          return remainder;
        },
        isEditing: false,
        setIsEditing: (updater) => {
          set({ isEditing: typeof updater === 'function' ? updater(get().isEditing) : updater });
        },
        deleteLineItem(item) {
          const currentState = get();
          currentState.invoicePaymentLines = currentState.invoicePaymentLines.filter(
            (ipl) => ipl !== item,
          );
          set({ ...currentState });
        },
        resetInvoicePaymentLines() {
          set({ invoicePaymentLines: get().original.paymentLine });
        },
      }),
      { name: `InvoicePaymentLinesStore-${invoicePayment.id}` },
    ),
  );
};

export const InvoiceIdContext = createContext<number | null>(null);
export const useInvoiceIdContext = () => {
  const value = useContext(InvoiceIdContext);
  if (!value) {
    throw new Error('useInvoiceIdContext must be used within a InvoiceIdProvider');
  }
  return value;
};

export const InvoicePaymentLinesStoreContext = createContext<ReturnType<
  typeof createInvoicePaymentLineContext
> | null>(null);

export const useInvoicePaymentLinesStore = () => {
  const value = useContext(InvoicePaymentLinesStoreContext);
  if (!value) {
    throw new Error(
      'useAmountValidationStoreContext must be used within a AmountValidationStoreContext.Provider',
    );
  }
  return value;
};
