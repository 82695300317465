import { format } from 'date-fns';
import { type z } from 'zod';

import { SEARCH_DATE_FORMAT } from '../constants';

export const isDefined = <T>(value: T | undefined | null): value is T => {
  return value !== undefined && value !== null;
};

export const isTruthy = <T>(value: T | undefined | null | false | 0 | ''): value is T => {
  return !!value;
};

export type ZodObjectWithModelKeys<T extends Record<string, unknown>> = z.ZodObject<{
  [key in keyof T]: z.ZodFirstPartySchemaTypes;
}>;

export const dateToStringPreprocessor = (data: unknown) => {
  if (data instanceof Date) {
    return format(data, SEARCH_DATE_FORMAT);
  }
  return '';
};

export const isFulfilled = <T>(
  settledPromise: PromiseSettledResult<T>,
): settledPromise is PromiseFulfilledResult<T> => {
  return settledPromise.status === 'fulfilled';
};

export function includesTypeGuard<T>(arr: readonly T[], value: unknown): value is T {
  return arr.includes(value as T);
}
