import React from 'react';

export const AlertDialogContext = React.createContext<
  <T extends AlertAction>(
    params: T,
  ) => Promise<T['type'] extends 'alert' | 'confirm' ? boolean : null | string>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
>(() => null!);

export type AlertAction =
  | { type: 'alert'; title: string; body?: React.ReactNode; cancelButton?: string }
  | {
      type: 'confirm';
      title: string;
      body?: React.ReactNode;
      cancelButton?: string;
      actionButton?: string;
    }
  | {
      type: 'prompt';
      title: string;
      body?: React.ReactNode;
      cancelButton?: string;
      actionButton?: string;
      defaultValue?: string;
      inputProps?: React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >;
    }
  | { type: 'close' };
type Params<T extends 'alert' | 'confirm' | 'prompt'> =
  | Omit<Extract<AlertAction, { type: T }>, 'type'>
  | string;

export function useConfirm() {
  const dialog = React.useContext(AlertDialogContext);

  return React.useCallback(
    (params: Params<'confirm'>) => {
      return dialog({
        ...(typeof params === 'string' ? { title: params, body: undefined } : params),
        type: 'confirm',
      });
    },
    [dialog],
  );
}
export function usePrompt() {
  const dialog = React.useContext(AlertDialogContext);

  return (params: Params<'prompt'>) =>
    dialog({
      ...(typeof params === 'string' ? { title: params, body: undefined } : params),
      type: 'prompt',
    });
}
export function useAlert() {
  const dialog = React.useContext(AlertDialogContext);
  return (params: Params<'alert'>) =>
    dialog({
      ...(typeof params === 'string' ? { title: params, body: undefined } : params),
      type: 'alert',
    });
}
