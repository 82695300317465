import { type DirtyModelFields } from '../../../tools/helpers';

export const getFieldMaskFromString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const getFieldMaskFromDirtyField = (
  dirtyFields: DirtyModelFields<Record<string, unknown>>,
) => {
  const fieldMask: string[] = [];
  for (const key in dirtyFields) {
    fieldMask.push(getFieldMaskFromString(key));
  }
  return fieldMask;
};
