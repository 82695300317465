import { type Invoice, InvoicePayment, type User } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  ScrollArea,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import { LayersIcon, Pencil1Icon, PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { useStore } from 'zustand';

import { getLoadingColumns } from '../../../components/utils';
import { useAuth } from '../../../context/AuthContext';
import {
  useInvoicePaymentBatchGetQuery,
  useInvoicePaymentDeleteMutation,
} from '../../../hooks/react-query/useInvoicePayment';
import { InvoicePaymentLineContextProviderDataTableWrapper } from './components/InvoicePayementLineTable/InvoicePaymentLineContext';
import { useInvoicePaymentLinesStore } from './components/InvoicePayementLineTable/InvoicePaymentLineContext.context';
import { InvoicePaymentLineTable } from './components/InvoicePayementLineTable/InvoicePaymentLineTable';
import { withInvoiceIdProvider } from './components/InvoicePayementLineTable/withInvoiceProvider';
import { InvoicePaymentUpdateDialog } from './components/InvoicePaymentForm/InvoicePaymentEditDialog';

const staticInvoicePayments = Array.from({ length: 3 }, (_, idx) =>
  InvoicePayment.create({ id: idx }),
);

export const InvoicePaymentTable = ({
  customerId,
  invoiceId,
}: {
  customerId: User['id'];
  invoiceId: Invoice['id'];
}) => {
  const invoicePaymentQuery = useInvoicePaymentBatchGetQuery({
    filter: {
      customerId,
      isActive: true,
      includePaymentLine: true,
    },
  });

  const columnDefs = useMemo<ColumnDef<InvoicePayment>[]>(
    () => [
      {
        accessorKey: 'amount',
        meta: {
          className: 'w-32',
        },
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Amount" />;
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.amount}
            </div>
          );
        },
      },
      {
        accessorKey: 'paymentDate',
        meta: {
          className: 'w-32',
        },
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Payment date" />;
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.paymentDate}
            </div>
          );
        },
      },
      {
        accessorKey: 'paymentTypeId',
        meta: {
          className: 'w-32',
        },
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Type" />;
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.paymentTypeId}
            </div>
          );
        },
      },
      {
        id: 'actions',
        meta: {
          className: 'w-40 ml-auto flex gap-2 justify-center',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <TooltipProvider>
                <InvoicePaymentLinesActions />

                <Tooltip>
                  <InvoicePaymentUpdateDialog
                    origin={props.row.original}
                    trigger={
                      <TooltipTrigger asChild>
                        <Button size="icon" variant="outline">
                          <Pencil1Icon />
                        </Button>
                      </TooltipTrigger>
                    }
                  />
                  <TooltipContent>Edit</TooltipContent>
                </Tooltip>

                <DeleteInvoicePaymentAction invoicePayment={props.row.original} />
              </TooltipProvider>
            </div>
          );
        },
      },
    ],
    [],
  );

  const loadingColumns = useMemo(() => getLoadingColumns(columnDefs), [columnDefs]);

  const table = useReactTable({
    columns: invoicePaymentQuery.isPending ? loadingColumns : columnDefs,
    data: invoicePaymentQuery.isPending
      ? staticInvoicePayments
      : invoicePaymentQuery.data?.results ?? [],
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
    getRowId: (row) => row.id.toString(),
  });

  return (
    <DataTable
      table={table}
      RowWrapper={withInvoiceIdProvider(
        InvoicePaymentLineContextProviderDataTableWrapper,
        invoiceId,
      )}
    />
  );
};

const DeleteInvoicePaymentAction = ({ invoicePayment }: { invoicePayment: InvoicePayment }) => {
  const confirm = useConfirm();
  const deleteInvoicePaymentMutation = useInvoicePaymentDeleteMutation();
  const deleteInvoicePayment = useCallback(async () => {
    try {
      if (!(await confirm('Are you sure you want to delete this invoice payment?'))) {
        return;
      }

      await deleteInvoicePaymentMutation.mutateAsync(invoicePayment);

      toast({
        variant: 'success',
        title: 'Invoice Payment deleted',
      });
    } catch {
      toast({
        variant: 'destructive',
        title: 'Error during deletion',
      });
    }
  }, [confirm, deleteInvoicePaymentMutation, invoicePayment]);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="outline"
          isLoading={deleteInvoicePaymentMutation.isPending}
          disabled={deleteInvoicePaymentMutation.isPending}
          onClick={deleteInvoicePayment}
          size="icon"
        >
          <TrashIcon />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Delete</TooltipContent>
    </Tooltip>
  );
};

const InvoicePaymentLinesActions = () => {
  const loggedUserId = useAuth().user.id;
  const lineItemsStore = useInvoicePaymentLinesStore();
  const items = useStore(lineItemsStore, (state) => state.invoicePaymentLines);
  const addRemainder = useStore(lineItemsStore, (state) => state.addRemainder);
  const setIsEditing = useStore(lineItemsStore, (state) => state.setIsEditing);
  const resetLines = useStore(lineItemsStore, (state) => state.resetInvoicePaymentLines);
  const diff = useStore(lineItemsStore, (state) => {
    const currentAmount = state.invoicePaymentLines.reduce((acc, line) => {
      return acc + line.amount;
    }, 0);
    const originalAmount = state.original.amount;

    const diff = originalAmount - currentAmount;
    return diff;
  });

  const onAddRemainderClick = () => {
    setIsEditing(true);
    addRemainder({ creatorId: loggedUserId });
  };

  const [, setIsDialogOpen] = useState(false);

  const onOpenChange = (open: boolean) => {
    setIsDialogOpen(open);
    if (!open) {
      resetLines();
    }
  };

  return (
    <Dialog onOpenChange={onOpenChange}>
      <Tooltip>
        <DialogTrigger asChild>
          <TooltipTrigger asChild>
            <Button variant="outline" size="icon">
              <LayersIcon />
            </Button>
          </TooltipTrigger>
        </DialogTrigger>
        <TooltipContent>Payment lines</TooltipContent>
      </Tooltip>

      <DialogContent className="max-h-[80%] max-w-[1000px] overflow-auto px-0">
        <DialogTitle className="px-4">Payment lines</DialogTitle>
        <div className="flex min-w-[700px] max-w-[1000px] flex-col gap-2">
          <ScrollArea className="h-[100%] overflow-auto">
            <InvoicePaymentLineTable data={items} />
            <Button
              onClick={onAddRemainderClick}
              className="mx-auto flex items-center gap-0.5"
              size="sm"
            >
              <PlusIcon />
              {diff > 0 && <span>Add remainder</span>}
            </Button>
          </ScrollArea>
        </div>
      </DialogContent>
    </Dialog>
  );
};
