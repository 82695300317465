import {
  Event,
  EventAssignment,
  JobAdmin,
  type JobSubtype,
  type JobType,
  type JobTypeSubtype,
} from '@kalos/kalos-rpc';
import { type FC, useCallback, useEffect, useReducer, useRef } from 'react';

import { OPTION_BLANK } from '../../../constants';
import {
  EventAssignmentClientService,
  EventClientService,
  JobSubtypeClientService,
  JobTypeClientService,
  JobTypeSubtypeClientService,
  timestamp,
} from '../../../tools/helpers';
import { type Option } from '../Field';
import { JobAdminComponent } from '../JobAdmin';
import { SectionBar } from '../SectionBar';
import { Request } from './components/Request';
import { returnCorrectTimeField } from './utils';
export interface Props {
  propertyId: number;
  serviceCallId: number;
  serviceEvent?: Event;
  onClose?: () => void;
  onSave?: () => void;
}

interface JobErrors {
  [key: string]: string;
}

interface State {
  entry: Event;
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  serviceCallID: number;
  jobTypes: JobType[];
  jobSubtypes: JobSubtype[];
  jobTypeSubtypes: JobTypeSubtype[];
  propertyEvents: Event[];
  pendingSave: boolean;
  requestValid: boolean;
  requestFields: string[];
  jobAdmins: JobAdmin[];
  jobErrors: JobErrors;
}

type Action =
  | { type: 'setServiceCallId'; data: number }
  | {
      type: 'setData';
      data: {
        entry: Event;
        propertyEvents: Event[];
        jobTypes: JobType[];
        jobSubtypes: JobSubtype[];
        jobTypeSubtypes: JobTypeSubtype[];
        loaded: boolean;
        loading: boolean;
      };
    }
  | {
      type: 'setChangeEntry';
      data: {
        entry: Event;
        pendingSave: boolean;
      };
    }
  | {
      type: 'setHandleSave';
      data: {
        pendingSave: boolean;
        requestValid: boolean;
      };
    }
  | { type: 'setLoading'; data: boolean }
  | {
      type: 'setLoadedLoading';
      data: {
        loaded: boolean;
        loading: boolean;
      };
    }
  | {
      type: 'setSaveServiceCall';
      data: {
        saving: boolean;
        loading: boolean;
        pendingSave: boolean;
      };
    }
  | { type: 'setRequestValid'; data: boolean }
  | { type: 'setRequestFields'; data: string[] }
  | { type: 'setPendingSave'; data: boolean }
  | { type: 'setJobAdmins'; data: JobAdmin[] }
  | { type: 'setJobErrors'; data: JobErrors };

const checkJobErrors = (job: Event): JobErrors => {
  const errors: JobErrors = {};
  if (!job.departmentId) {
    errors.departmentId = 'This field is required';
  }
  if (!job.name.trim().length) {
    errors.name = 'This field is required';
  }
  if (!job.description.trim().length) {
    errors.description = 'This field is required';
  }
  if (!job.logPaymentType) {
    errors.logPaymentType = 'This field is required';
  }
  if (!job.logNotes.trim().length) {
    errors.logNotes = 'This field is required';
  }
  return errors;
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setServiceCallId':
      return {
        ...state,
        serviceCallID: action.data,
      };
    case 'setJobAdmins':
      return {
        ...state,
        jobAdmins: action.data,
      };
    case 'setData':
      return {
        ...state,
        entry: action.data.entry,
        propertyEvents: action.data.propertyEvents,
        jobTypes: action.data.jobTypes,
        jobSubtypes: action.data.jobSubtypes,
        jobTypeSubtypes: action.data.jobTypeSubtypes,
        loaded: action.data.loaded,
        loading: action.data.loading,
      };
    case 'setChangeEntry':
      return {
        ...state,
        entry: action.data.entry,
        pendingSave: action.data.pendingSave,
      };
    case 'setHandleSave':
      return {
        ...state,
        pendingSave: action.data.pendingSave,
        requestValid: action.data.requestValid,
      };
    case 'setLoading':
      return {
        ...state,
        loading: action.data,
      };
    case 'setLoadedLoading':
      return {
        ...state,
        loaded: action.data.loaded,
        loading: action.data.loading,
      };
    case 'setSaveServiceCall':
      return {
        ...state,
        saving: action.data.saving,
        loading: action.data.loading,
        pendingSave: action.data.pendingSave,
      };
    case 'setRequestValid':
      return {
        ...state,
        requestValid: action.data,
      };
    case 'setRequestFields':
      return {
        ...state,
        requestFields: action.data,
      };
    case 'setPendingSave':
      return {
        ...state,
        pendingSave: action.data,
      };
    case 'setJobErrors':
      return {
        ...state,
        jobErrors: action.data,
      };
    default:
      return state;
  }
};

const initialState: State = {
  entry: Event.create(),
  loading: true,
  loaded: false,
  saving: false,
  serviceCallID: 0,
  jobTypes: [],
  jobSubtypes: [],
  jobTypeSubtypes: [],
  propertyEvents: [],
  pendingSave: false,
  jobAdmins: [],
  requestValid: false,
  requestFields: [],
  jobErrors: {},
};

export const ServiceRequest: FC<Props> = (props) => {
  const { propertyId, serviceCallId, serviceEvent, onClose, onSave } = props;
  const [state, serviceCall] = useReducer(reducer, initialState);
  const requestRef = useRef(null);

  const jobTypeOptions: Option[] = state.jobTypes.map((id) => ({
    label: id.name,
    value: id.id,
  }));

  const jobSubtypeOptions: Option[] = [
    { label: OPTION_BLANK, value: 0 },
    ...state.jobTypeSubtypes
      .filter((jobTypeId) => jobTypeId.jobTypeId === state.entry.jobTypeId)
      .map((jobSubtypeId) => ({
        value: jobSubtypeId.jobSubtypeId,
        label: state.jobSubtypes.find((id) => id.id === jobSubtypeId.jobSubtypeId)?.name || '',
      })),
  ];

  const handleChangeEntry = useCallback((data: Event) => {
    serviceCall({
      type: 'setChangeEntry',
      data: {
        entry: data,
        pendingSave: false,
      },
    });
  }, []);

  const handleSetRequestValid = useCallback((data: boolean) => {
    serviceCall({ type: 'setRequestValid', data: data });
  }, []);

  const handleSetRequestfields = useCallback(
    (fields: string[]) => {
      serviceCall({
        type: 'setRequestFields',
        data: [...state.requestFields, ...fields],
      });
    },
    [state.requestFields],
  );
  const returnLegacyDate = (time: string) => {
    const splitString = time.split(' ');

    return `${splitString[0]} 00:00:00`;
  };
  const load = useCallback(async () => {
    serviceCall({ type: 'setLoading', data: true });
    const req = Event.create();
    let entry = Event.create();
    req.id = serviceCallId;

    try {
      const propertyEvents = await EventClientService.loadEventsByPropertyId(propertyId);
      const jobTypes = await JobTypeClientService.loadJobTypes();

      const jobSubtypes = await JobSubtypeClientService.loadJobSubtypes();
      const jobTypeSubtypes = await JobTypeSubtypeClientService.loadJobTypeSubtypes();
      if (serviceEvent) {
        entry = Event.clone(serviceEvent);
      } else {
        // @ts-ignore
        entry = await EventClientService.Get(req);
        if (entry.id != 0) {
          const admins = await EventClientService.BatchGetJobAdmin(
            JobAdmin.create({ jobNumber: entry.id, isActive: true }),
          );
          if (admins) {
            console.log('my admins', admins);
            serviceCall({
              type: 'setJobAdmins',
              data: admins.results,
            });
          }
        }
      }
      const startTimeData = entry.timeStarted;
      const endTimeData = entry.timeEnded;
      const startSplit = startTimeData.split(':');
      const endSplit = endTimeData.split(':');
      const startDate = entry.dateStarted;
      const endDate = entry.dateEnded;
      const startTimeDate = startDate.split(' ')[0];
      const endTimeDate = endDate.split(' ')[0];
      const fullStartDate = `${startTimeDate} ${startSplit[0]}:${startSplit[1]}:00`;
      const fullEndDate = `${endTimeDate} ${endSplit[0]}:${endSplit[1]}:00`;
      entry.dateStarted = fullStartDate;
      entry.dateEnded = fullEndDate;
      entry.dateUpdated = timestamp();

      serviceCall({
        type: 'setData',
        data: {
          entry: entry,
          propertyEvents: propertyEvents,
          jobTypes: jobTypes,
          jobSubtypes: jobSubtypes || [],
          jobTypeSubtypes: jobTypeSubtypes || [],
          loaded: true,
          loading: false,
        },
      });
    } catch (err) {
      console.error(err);
      serviceCall({
        type: 'setLoadedLoading',
        data: {
          loaded: true,
          loading: false,
        },
      });
    }
  }, [propertyId, serviceCallId, serviceEvent]);

  const handleSave = useCallback(async () => {
    const errors = checkJobErrors(state.entry);
    serviceCall({ type: 'setJobErrors', data: errors });
    if (!Object.keys(errors).length) {
      serviceCall({ type: `setPendingSave`, data: true });
    }
  }, [state.entry]);

  const saveServiceCall = useCallback(async () => {
    serviceCall({
      type: 'setSaveServiceCall',
      data: {
        saving: true,
        loading: true,
        pendingSave: false,
      },
    });
    const temp = Event.clone(state.entry);
    console.log('saving existing ID');
    try {
      temp.timeStarted = returnCorrectTimeField(temp.dateStarted);
      temp.timeEnded = returnCorrectTimeField(temp.dateEnded);
      temp.dateStarted = returnLegacyDate(temp.dateStarted);
      temp.dateEnded = returnLegacyDate(temp.dateEnded);
      if (temp.fieldMask.length > 0 || temp.id === 0) {
        if (temp.fieldMask.find((el) => el == 'DateStarted')) {
          temp.fieldMask.push('TimeStarted');
        }
        if (temp.fieldMask.find((el) => el == 'DateEnded')) {
          temp.fieldMask.push('TimeEnded');
        }
        if (temp.id > 0) {
          await EventClientService.Update(temp);
        } else {
          temp.id = (await EventClientService.Create(temp))!.id;

          for (let i = 0; i < state.jobAdmins.length; i++) {
            console.log('let us create job admins');
            const jobAdminReq = JobAdmin.clone(state.jobAdmins[i]);
            jobAdminReq.jobNumber = temp.id;
            EventClientService.CreateJobAdmin(jobAdminReq);
          }
        }
      }
      const idArray = String(temp.logTechnicianAssigned).split(',');
      console.log('ID array in request index', idArray);
      let results: EventAssignment[] = [];
      try {
        console.log('getting assignment data');
        const assignmentReq = EventAssignment.create();
        assignmentReq.eventId = temp.id;
        const assignedEvents = await EventAssignmentClientService.BatchGet(assignmentReq);
        results = assignedEvents!.results;
      } catch {
        console.log('no one assigned, just create');
      }
      try {
        console.log('create and delete');
        for (const event in results) {
          const assignment = EventAssignment.create();
          assignment.id = results[event].id;

          await EventAssignmentClientService.Delete(assignment);
          console.log('delete');
        }
        for (const id in idArray) {
          const assignment = EventAssignment.create();
          assignment.userId = Number(idArray[id]);
          assignment.eventId = temp.id;
          if (assignment.userId != 0 && assignment.userId != undefined) {
            await EventAssignmentClientService.Create(assignment);
          }
          console.log('create');
        }
      } catch (err) {
        console.error('error updating event assignment: ', err);
      }
      console.log('finished Update');
    } catch (err) {
      console.error(err);
    }
    if (onSave) {
      onSave();
    }
    if (onClose) {
      onClose();
    }
  }, [state.entry, state.jobAdmins, onSave, onClose]);

  useEffect(() => {
    if (!state.loaded) {
      load();
    }
    if (state.pendingSave) {
      saveServiceCall();
    }
    if (state.pendingSave && requestRef.current) {
      //@ts-ignore
      requestRef.current.click();
    }
  }, [load, state.loaded, state.pendingSave, state.requestValid, saveServiceCall, requestRef]);

  return (
    <SectionBar
      title="Job Data"
      actions={[
        {
          label: 'Save Job',
          onClick: handleSave,
          disabled: state.loading || state.saving,
        },
        {
          label: 'Open Property',
          onClick: () => {
            const url = `${window.location.origin}/properties/${propertyId}`;
            window.open(url, `_blank`);
          },
          disabled: state.loading || state.saving,
        },
        {
          label: 'Close',
          onClick: onClose,
          disabled: state.loading || state.saving,
        },
      ]}
    >
      <SectionBar title="Job Admins" uncollapsable={true}>
        <JobAdminComponent
          loading={state.loading}
          onUpdateJobAdmins={(data) => serviceCall({ type: 'setJobAdmins', data: data })}
          key={state.jobAdmins.toLocaleString()}
          jobAdminEntries={state.jobAdmins}
          jobNumber={serviceCallId}
        />
      </SectionBar>
      <Request
        key={state.loading.toString()}
        serviceItem={state.entry}
        propertyEvents={state.propertyEvents}
        loading={state.loading}
        jobTypeOptions={jobTypeOptions}
        jobSubtypeOptions={jobSubtypeOptions}
        onChange={handleChangeEntry}
        disabled={state.saving}
        onValid={handleSetRequestValid}
        onInitSchema={handleSetRequestfields}
        formErrors={state.jobErrors}
      />
    </SectionBar>
  );
};
