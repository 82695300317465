// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "user.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "user.proto" (syntax proto3)
// tslint:disable
import { Int32List } from "./common";
import { Bool } from "./common";
import { Empty } from "./common";
import { Int32 } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TimesheetDepartment } from "./timesheet_department";
import { Property } from "./property";
// @generated message type with reflection information, may provide speed optimized methods
class Group$Type extends MessageType {
    constructor() {
        super("Group", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string field_mask */ 3:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string field_mask = 3; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Group
 */
export const Group = new Group$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupList$Type extends MessageType {
    constructor() {
        super("GroupList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Group },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Group results */ 1:
                    message.results.push(Group.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Group results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Group.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GroupList
 */
export const GroupList = new GroupList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserGroupLink$Type extends MessageType {
    constructor() {
        super("UserGroupLink", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "group_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, groupId: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 group_id */ 3:
                    message.groupId = reader.int32();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 group_id = 3; */
        if (message.groupId !== 0)
            writer.tag(3, WireType.Varint).int32(message.groupId);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserGroupLink
 */
export const UserGroupLink = new UserGroupLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserGroupLinkList$Type extends MessageType {
    constructor() {
        super("UserGroupLinkList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserGroupLink },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated UserGroupLink results */ 1:
                    message.results.push(UserGroupLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated UserGroupLink results = 1; */
        for (let i = 0; i < message.results.length; i++)
            UserGroupLink.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserGroupLinkList
 */
export const UserGroupLinkList = new UserGroupLinkList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType {
    constructor() {
        super("User", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "businessname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "altphone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "cellphone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "fax", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "alt_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "phone_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "preferred_contact", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "receiveemail", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "last_login", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "annual_hours_pto", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 22, name: "bonus_hours_pto", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 23, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "is_SU", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 25, name: "is_admin", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 26, name: "is_office_staff", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 27, name: "office_group", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 28, name: "is_hvac_tech", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 29, name: "tech_assist", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 30, name: "calendar_pref", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "multi_property", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 32, name: "is_employee", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 33, name: "employee_function_iD", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 34, name: "employee_department_iD", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 35, name: "login", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 36, name: "pwd", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 37, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "int_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 39, name: "notification", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 40, name: "billing_terms", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 41, name: "rebate", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 42, name: "discount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 43, name: "managed_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 44, name: "current_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 45, name: "current_status_job_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 46, name: "current_status_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 47, name: "emp_title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "ext", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 49, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 50, name: "service_calls", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 51, name: "show_billing", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 52, name: "paid_service_call_status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 53, name: "is_color_mute", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 54, name: "service_call_refresh", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 55, name: "tool_fund", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 56, name: "spiff_fund", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 57, name: "geolocation_lat", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 58, name: "geolocation_lng", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 67, name: "timesheet_administration", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 68, name: "properties", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Property },
            { no: 59, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 60, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 61, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 62, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 66, name: "property", kind: "message", T: () => Property },
            { no: 69, name: "with_properties", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 70, name: "override_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 71, name: "permissions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Permission },
            { no: 72, name: "hire_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 73, name: "recommended_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 74, name: "permission_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PermissionGroup },
            { no: 75, name: "department_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 76, name: "department", kind: "message", T: () => TimesheetDepartment },
            { no: 77, name: "user_id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 78, name: "department_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 80, name: "emp_badge_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 81, name: "user_attributes", kind: "message", T: () => UserAttributes },
            { no: 82, name: "employee_item_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, firstname: "", lastname: "", businessname: "", city: "", state: "", zip: "", address: "", phone: "", altphone: "", cellphone: "", fax: "", email: "", altEmail: "", phoneEmail: "", preferredContact: "", receiveemail: 0, dateCreated: "", lastLogin: "", annualHoursPto: 0, bonusHoursPto: 0, isActive: 0, isSU: 0, isAdmin: 0, isOfficeStaff: 0, officeGroup: 0, isHvacTech: 0, techAssist: 0, calendarPref: "", multiProperty: 0, isEmployee: 0, employeeFunctionID: 0, employeeDepartmentID: 0, login: "", pwd: "", notes: "", intNotes: "", notification: "", billingTerms: "", rebate: 0, discount: 0, managedBy: 0, currentStatus: "", currentStatusJobNumber: 0, currentStatusTimestamp: "", empTitle: "", ext: "", image: "", serviceCalls: 0, showBilling: 0, paidServiceCallStatus: 0, isColorMute: 0, serviceCallRefresh: 0, toolFund: 0, spiffFund: 0, geolocationLat: 0, geolocationLng: 0, timesheetAdministration: false, properties: [], fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", withProperties: false, overrideLimit: false, permissions: [], hireDate: "", recommendedBy: "", permissionGroups: [], departmentString: "", userIdList: "", departmentList: "", empBadgeId: 0, employeeItemName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string firstname */ 2:
                    message.firstname = reader.string();
                    break;
                case /* string lastname */ 3:
                    message.lastname = reader.string();
                    break;
                case /* string businessname */ 4:
                    message.businessname = reader.string();
                    break;
                case /* string city */ 5:
                    message.city = reader.string();
                    break;
                case /* string state */ 6:
                    message.state = reader.string();
                    break;
                case /* string zip */ 7:
                    message.zip = reader.string();
                    break;
                case /* string address */ 8:
                    message.address = reader.string();
                    break;
                case /* string phone */ 9:
                    message.phone = reader.string();
                    break;
                case /* string altphone */ 10:
                    message.altphone = reader.string();
                    break;
                case /* string cellphone */ 11:
                    message.cellphone = reader.string();
                    break;
                case /* string fax */ 12:
                    message.fax = reader.string();
                    break;
                case /* string email */ 13:
                    message.email = reader.string();
                    break;
                case /* string alt_email */ 14:
                    message.altEmail = reader.string();
                    break;
                case /* string phone_email */ 15:
                    message.phoneEmail = reader.string();
                    break;
                case /* string preferred_contact */ 16:
                    message.preferredContact = reader.string();
                    break;
                case /* int32 receiveemail */ 17:
                    message.receiveemail = reader.int32();
                    break;
                case /* string date_created */ 18:
                    message.dateCreated = reader.string();
                    break;
                case /* string last_login */ 19:
                    message.lastLogin = reader.string();
                    break;
                case /* double annual_hours_pto */ 21:
                    message.annualHoursPto = reader.double();
                    break;
                case /* double bonus_hours_pto */ 22:
                    message.bonusHoursPto = reader.double();
                    break;
                case /* int32 is_active */ 23:
                    message.isActive = reader.int32();
                    break;
                case /* int32 is_SU */ 24:
                    message.isSU = reader.int32();
                    break;
                case /* int32 is_admin */ 25:
                    message.isAdmin = reader.int32();
                    break;
                case /* int32 is_office_staff */ 26:
                    message.isOfficeStaff = reader.int32();
                    break;
                case /* int32 office_group */ 27:
                    message.officeGroup = reader.int32();
                    break;
                case /* int32 is_hvac_tech */ 28:
                    message.isHvacTech = reader.int32();
                    break;
                case /* int32 tech_assist */ 29:
                    message.techAssist = reader.int32();
                    break;
                case /* string calendar_pref */ 30:
                    message.calendarPref = reader.string();
                    break;
                case /* int32 multi_property */ 31:
                    message.multiProperty = reader.int32();
                    break;
                case /* int32 is_employee */ 32:
                    message.isEmployee = reader.int32();
                    break;
                case /* int32 employee_function_iD */ 33:
                    message.employeeFunctionID = reader.int32();
                    break;
                case /* int32 employee_department_iD */ 34:
                    message.employeeDepartmentID = reader.int32();
                    break;
                case /* string login */ 35:
                    message.login = reader.string();
                    break;
                case /* string pwd */ 36:
                    message.pwd = reader.string();
                    break;
                case /* string notes */ 37:
                    message.notes = reader.string();
                    break;
                case /* string int_notes */ 38:
                    message.intNotes = reader.string();
                    break;
                case /* string notification */ 39:
                    message.notification = reader.string();
                    break;
                case /* string billing_terms */ 40:
                    message.billingTerms = reader.string();
                    break;
                case /* int32 rebate */ 41:
                    message.rebate = reader.int32();
                    break;
                case /* int32 discount */ 42:
                    message.discount = reader.int32();
                    break;
                case /* int32 managed_by */ 43:
                    message.managedBy = reader.int32();
                    break;
                case /* string current_status */ 44:
                    message.currentStatus = reader.string();
                    break;
                case /* int32 current_status_job_number */ 45:
                    message.currentStatusJobNumber = reader.int32();
                    break;
                case /* string current_status_timestamp */ 46:
                    message.currentStatusTimestamp = reader.string();
                    break;
                case /* string emp_title */ 47:
                    message.empTitle = reader.string();
                    break;
                case /* string ext */ 48:
                    message.ext = reader.string();
                    break;
                case /* string image */ 49:
                    message.image = reader.string();
                    break;
                case /* int32 service_calls */ 50:
                    message.serviceCalls = reader.int32();
                    break;
                case /* int32 show_billing */ 51:
                    message.showBilling = reader.int32();
                    break;
                case /* int32 paid_service_call_status */ 52:
                    message.paidServiceCallStatus = reader.int32();
                    break;
                case /* int32 is_color_mute */ 53:
                    message.isColorMute = reader.int32();
                    break;
                case /* int32 service_call_refresh */ 54:
                    message.serviceCallRefresh = reader.int32();
                    break;
                case /* double tool_fund */ 55:
                    message.toolFund = reader.double();
                    break;
                case /* double spiff_fund */ 56:
                    message.spiffFund = reader.double();
                    break;
                case /* double geolocation_lat */ 57:
                    message.geolocationLat = reader.double();
                    break;
                case /* double geolocation_lng */ 58:
                    message.geolocationLng = reader.double();
                    break;
                case /* bool timesheet_administration */ 67:
                    message.timesheetAdministration = reader.bool();
                    break;
                case /* repeated Property properties */ 68:
                    message.properties.push(Property.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string field_mask */ 59:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 60:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 61:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 62:
                    message.orderDir = reader.string();
                    break;
                case /* Property property */ 66:
                    message.property = Property.internalBinaryRead(reader, reader.uint32(), options, message.property);
                    break;
                case /* bool with_properties */ 69:
                    message.withProperties = reader.bool();
                    break;
                case /* bool override_limit */ 70:
                    message.overrideLimit = reader.bool();
                    break;
                case /* repeated Permission permissions */ 71:
                    message.permissions.push(Permission.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string hire_date */ 72:
                    message.hireDate = reader.string();
                    break;
                case /* string recommended_by */ 73:
                    message.recommendedBy = reader.string();
                    break;
                case /* repeated PermissionGroup permission_groups */ 74:
                    message.permissionGroups.push(PermissionGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string department_string */ 75:
                    message.departmentString = reader.string();
                    break;
                case /* TimesheetDepartment department */ 76:
                    message.department = TimesheetDepartment.internalBinaryRead(reader, reader.uint32(), options, message.department);
                    break;
                case /* string user_id_list */ 77:
                    message.userIdList = reader.string();
                    break;
                case /* string department_list */ 78:
                    message.departmentList = reader.string();
                    break;
                case /* int32 emp_badge_id */ 80:
                    message.empBadgeId = reader.int32();
                    break;
                case /* UserAttributes user_attributes */ 81:
                    message.userAttributes = UserAttributes.internalBinaryRead(reader, reader.uint32(), options, message.userAttributes);
                    break;
                case /* string employee_item_name */ 82:
                    message.employeeItemName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string firstname = 2; */
        if (message.firstname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstname);
        /* string lastname = 3; */
        if (message.lastname !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastname);
        /* string businessname = 4; */
        if (message.businessname !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.businessname);
        /* string city = 5; */
        if (message.city !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.city);
        /* string state = 6; */
        if (message.state !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.state);
        /* string zip = 7; */
        if (message.zip !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.zip);
        /* string address = 8; */
        if (message.address !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.address);
        /* string phone = 9; */
        if (message.phone !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.phone);
        /* string altphone = 10; */
        if (message.altphone !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.altphone);
        /* string cellphone = 11; */
        if (message.cellphone !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.cellphone);
        /* string fax = 12; */
        if (message.fax !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.fax);
        /* string email = 13; */
        if (message.email !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.email);
        /* string alt_email = 14; */
        if (message.altEmail !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.altEmail);
        /* string phone_email = 15; */
        if (message.phoneEmail !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.phoneEmail);
        /* string preferred_contact = 16; */
        if (message.preferredContact !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.preferredContact);
        /* int32 receiveemail = 17; */
        if (message.receiveemail !== 0)
            writer.tag(17, WireType.Varint).int32(message.receiveemail);
        /* string date_created = 18; */
        if (message.dateCreated !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.dateCreated);
        /* string last_login = 19; */
        if (message.lastLogin !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.lastLogin);
        /* double annual_hours_pto = 21; */
        if (message.annualHoursPto !== 0)
            writer.tag(21, WireType.Bit64).double(message.annualHoursPto);
        /* double bonus_hours_pto = 22; */
        if (message.bonusHoursPto !== 0)
            writer.tag(22, WireType.Bit64).double(message.bonusHoursPto);
        /* int32 is_active = 23; */
        if (message.isActive !== 0)
            writer.tag(23, WireType.Varint).int32(message.isActive);
        /* int32 is_SU = 24; */
        if (message.isSU !== 0)
            writer.tag(24, WireType.Varint).int32(message.isSU);
        /* int32 is_admin = 25; */
        if (message.isAdmin !== 0)
            writer.tag(25, WireType.Varint).int32(message.isAdmin);
        /* int32 is_office_staff = 26; */
        if (message.isOfficeStaff !== 0)
            writer.tag(26, WireType.Varint).int32(message.isOfficeStaff);
        /* int32 office_group = 27; */
        if (message.officeGroup !== 0)
            writer.tag(27, WireType.Varint).int32(message.officeGroup);
        /* int32 is_hvac_tech = 28; */
        if (message.isHvacTech !== 0)
            writer.tag(28, WireType.Varint).int32(message.isHvacTech);
        /* int32 tech_assist = 29; */
        if (message.techAssist !== 0)
            writer.tag(29, WireType.Varint).int32(message.techAssist);
        /* string calendar_pref = 30; */
        if (message.calendarPref !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.calendarPref);
        /* int32 multi_property = 31; */
        if (message.multiProperty !== 0)
            writer.tag(31, WireType.Varint).int32(message.multiProperty);
        /* int32 is_employee = 32; */
        if (message.isEmployee !== 0)
            writer.tag(32, WireType.Varint).int32(message.isEmployee);
        /* int32 employee_function_iD = 33; */
        if (message.employeeFunctionID !== 0)
            writer.tag(33, WireType.Varint).int32(message.employeeFunctionID);
        /* int32 employee_department_iD = 34; */
        if (message.employeeDepartmentID !== 0)
            writer.tag(34, WireType.Varint).int32(message.employeeDepartmentID);
        /* string login = 35; */
        if (message.login !== "")
            writer.tag(35, WireType.LengthDelimited).string(message.login);
        /* string pwd = 36; */
        if (message.pwd !== "")
            writer.tag(36, WireType.LengthDelimited).string(message.pwd);
        /* string notes = 37; */
        if (message.notes !== "")
            writer.tag(37, WireType.LengthDelimited).string(message.notes);
        /* string int_notes = 38; */
        if (message.intNotes !== "")
            writer.tag(38, WireType.LengthDelimited).string(message.intNotes);
        /* string notification = 39; */
        if (message.notification !== "")
            writer.tag(39, WireType.LengthDelimited).string(message.notification);
        /* string billing_terms = 40; */
        if (message.billingTerms !== "")
            writer.tag(40, WireType.LengthDelimited).string(message.billingTerms);
        /* int32 rebate = 41; */
        if (message.rebate !== 0)
            writer.tag(41, WireType.Varint).int32(message.rebate);
        /* int32 discount = 42; */
        if (message.discount !== 0)
            writer.tag(42, WireType.Varint).int32(message.discount);
        /* int32 managed_by = 43; */
        if (message.managedBy !== 0)
            writer.tag(43, WireType.Varint).int32(message.managedBy);
        /* string current_status = 44; */
        if (message.currentStatus !== "")
            writer.tag(44, WireType.LengthDelimited).string(message.currentStatus);
        /* int32 current_status_job_number = 45; */
        if (message.currentStatusJobNumber !== 0)
            writer.tag(45, WireType.Varint).int32(message.currentStatusJobNumber);
        /* string current_status_timestamp = 46; */
        if (message.currentStatusTimestamp !== "")
            writer.tag(46, WireType.LengthDelimited).string(message.currentStatusTimestamp);
        /* string emp_title = 47; */
        if (message.empTitle !== "")
            writer.tag(47, WireType.LengthDelimited).string(message.empTitle);
        /* string ext = 48; */
        if (message.ext !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.ext);
        /* string image = 49; */
        if (message.image !== "")
            writer.tag(49, WireType.LengthDelimited).string(message.image);
        /* int32 service_calls = 50; */
        if (message.serviceCalls !== 0)
            writer.tag(50, WireType.Varint).int32(message.serviceCalls);
        /* int32 show_billing = 51; */
        if (message.showBilling !== 0)
            writer.tag(51, WireType.Varint).int32(message.showBilling);
        /* int32 paid_service_call_status = 52; */
        if (message.paidServiceCallStatus !== 0)
            writer.tag(52, WireType.Varint).int32(message.paidServiceCallStatus);
        /* int32 is_color_mute = 53; */
        if (message.isColorMute !== 0)
            writer.tag(53, WireType.Varint).int32(message.isColorMute);
        /* int32 service_call_refresh = 54; */
        if (message.serviceCallRefresh !== 0)
            writer.tag(54, WireType.Varint).int32(message.serviceCallRefresh);
        /* double tool_fund = 55; */
        if (message.toolFund !== 0)
            writer.tag(55, WireType.Bit64).double(message.toolFund);
        /* double spiff_fund = 56; */
        if (message.spiffFund !== 0)
            writer.tag(56, WireType.Bit64).double(message.spiffFund);
        /* double geolocation_lat = 57; */
        if (message.geolocationLat !== 0)
            writer.tag(57, WireType.Bit64).double(message.geolocationLat);
        /* double geolocation_lng = 58; */
        if (message.geolocationLng !== 0)
            writer.tag(58, WireType.Bit64).double(message.geolocationLng);
        /* bool timesheet_administration = 67; */
        if (message.timesheetAdministration !== false)
            writer.tag(67, WireType.Varint).bool(message.timesheetAdministration);
        /* repeated Property properties = 68; */
        for (let i = 0; i < message.properties.length; i++)
            Property.internalBinaryWrite(message.properties[i], writer.tag(68, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 59; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(59, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 60; */
        if (message.pageNumber !== 0)
            writer.tag(60, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 61; */
        if (message.orderBy !== "")
            writer.tag(61, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 62; */
        if (message.orderDir !== "")
            writer.tag(62, WireType.LengthDelimited).string(message.orderDir);
        /* Property property = 66; */
        if (message.property)
            Property.internalBinaryWrite(message.property, writer.tag(66, WireType.LengthDelimited).fork(), options).join();
        /* bool with_properties = 69; */
        if (message.withProperties !== false)
            writer.tag(69, WireType.Varint).bool(message.withProperties);
        /* bool override_limit = 70; */
        if (message.overrideLimit !== false)
            writer.tag(70, WireType.Varint).bool(message.overrideLimit);
        /* repeated Permission permissions = 71; */
        for (let i = 0; i < message.permissions.length; i++)
            Permission.internalBinaryWrite(message.permissions[i], writer.tag(71, WireType.LengthDelimited).fork(), options).join();
        /* string hire_date = 72; */
        if (message.hireDate !== "")
            writer.tag(72, WireType.LengthDelimited).string(message.hireDate);
        /* string recommended_by = 73; */
        if (message.recommendedBy !== "")
            writer.tag(73, WireType.LengthDelimited).string(message.recommendedBy);
        /* repeated PermissionGroup permission_groups = 74; */
        for (let i = 0; i < message.permissionGroups.length; i++)
            PermissionGroup.internalBinaryWrite(message.permissionGroups[i], writer.tag(74, WireType.LengthDelimited).fork(), options).join();
        /* string department_string = 75; */
        if (message.departmentString !== "")
            writer.tag(75, WireType.LengthDelimited).string(message.departmentString);
        /* TimesheetDepartment department = 76; */
        if (message.department)
            TimesheetDepartment.internalBinaryWrite(message.department, writer.tag(76, WireType.LengthDelimited).fork(), options).join();
        /* string user_id_list = 77; */
        if (message.userIdList !== "")
            writer.tag(77, WireType.LengthDelimited).string(message.userIdList);
        /* string department_list = 78; */
        if (message.departmentList !== "")
            writer.tag(78, WireType.LengthDelimited).string(message.departmentList);
        /* int32 emp_badge_id = 80; */
        if (message.empBadgeId !== 0)
            writer.tag(80, WireType.Varint).int32(message.empBadgeId);
        /* UserAttributes user_attributes = 81; */
        if (message.userAttributes)
            UserAttributes.internalBinaryWrite(message.userAttributes, writer.tag(81, WireType.LengthDelimited).fork(), options).join();
        /* string employee_item_name = 82; */
        if (message.employeeItemName !== "")
            writer.tag(82, WireType.LengthDelimited).string(message.employeeItemName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserList$Type extends MessageType {
    constructor() {
        super("UserList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated User results */ 1:
                    message.results.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated User results = 1; */
        for (let i = 0; i < message.results.length; i++)
            User.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserList
 */
export const UserList = new UserList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardData$Type extends MessageType {
    constructor() {
        super("CardData", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "card_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "account", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "account_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "with_department", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "department_data", kind: "message", T: () => TimesheetDepartment },
            { no: 10, name: "with_user", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value) {
        const message = { id: 0, departmentId: 0, cardNumber: 0, account: "", accountNumber: "", name: "", isActive: false, withDepartment: false, withUser: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 department_id */ 2:
                    message.departmentId = reader.int32();
                    break;
                case /* int32 card_number */ 3:
                    message.cardNumber = reader.int32();
                    break;
                case /* string account */ 4:
                    message.account = reader.string();
                    break;
                case /* string account_number */ 5:
                    message.accountNumber = reader.string();
                    break;
                case /* string name */ 6:
                    message.name = reader.string();
                    break;
                case /* bool is_active */ 7:
                    message.isActive = reader.bool();
                    break;
                case /* bool with_department */ 8:
                    message.withDepartment = reader.bool();
                    break;
                case /* TimesheetDepartment department_data */ 9:
                    message.departmentData = TimesheetDepartment.internalBinaryRead(reader, reader.uint32(), options, message.departmentData);
                    break;
                case /* bool with_user */ 10:
                    message.withUser = reader.bool();
                    break;
                case /* User user */ 11:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 department_id = 2; */
        if (message.departmentId !== 0)
            writer.tag(2, WireType.Varint).int32(message.departmentId);
        /* int32 card_number = 3; */
        if (message.cardNumber !== 0)
            writer.tag(3, WireType.Varint).int32(message.cardNumber);
        /* string account = 4; */
        if (message.account !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.account);
        /* string account_number = 5; */
        if (message.accountNumber !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.accountNumber);
        /* string name = 6; */
        if (message.name !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.name);
        /* bool is_active = 7; */
        if (message.isActive !== false)
            writer.tag(7, WireType.Varint).bool(message.isActive);
        /* bool with_department = 8; */
        if (message.withDepartment !== false)
            writer.tag(8, WireType.Varint).bool(message.withDepartment);
        /* TimesheetDepartment department_data = 9; */
        if (message.departmentData)
            TimesheetDepartment.internalBinaryWrite(message.departmentData, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* bool with_user = 10; */
        if (message.withUser !== false)
            writer.tag(10, WireType.Varint).bool(message.withUser);
        /* User user = 11; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CardData
 */
export const CardData = new CardData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardDataList$Type extends MessageType {
    constructor() {
        super("CardDataList", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CardData }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CardData data */ 1:
                    message.data.push(CardData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated CardData data = 1; */
        for (let i = 0; i < message.data.length; i++)
            CardData.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CardDataList
 */
export const CardDataList = new CardDataList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Permission$Type extends MessageType {
    constructor() {
        super("Permission", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "action", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "resource", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", action: "", resource: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string action */ 3:
                    message.action = reader.string();
                    break;
                case /* string resource */ 4:
                    message.resource = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string action = 3; */
        if (message.action !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.action);
        /* string resource = 4; */
        if (message.resource !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.resource);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Permission
 */
export const Permission = new Permission$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermissionGroup$Type extends MessageType {
    constructor() {
        super("PermissionGroup", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "filter_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", description: "", type: "", filterData: "", isActive: false, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string type */ 4:
                    message.type = reader.string();
                    break;
                case /* string filter_data */ 5:
                    message.filterData = reader.string();
                    break;
                case /* bool is_active */ 6:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string type = 4; */
        if (message.type !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.type);
        /* string filter_data = 5; */
        if (message.filterData !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.filterData);
        /* bool is_active = 6; */
        if (message.isActive !== false)
            writer.tag(6, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermissionGroup
 */
export const PermissionGroup = new PermissionGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermissionGroupList$Type extends MessageType {
    constructor() {
        super("PermissionGroupList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PermissionGroup },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PermissionGroup results */ 1:
                    message.results.push(PermissionGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PermissionGroup results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PermissionGroup.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermissionGroupList
 */
export const PermissionGroupList = new PermissionGroupList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPermissionGroupReq$Type extends MessageType {
    constructor() {
        super("UserPermissionGroupReq", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "permission_group_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "permission_group_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, permissionGroupId: 0, permissionGroupName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* int32 permission_group_id */ 2:
                    message.permissionGroupId = reader.int32();
                    break;
                case /* string permission_group_name */ 3:
                    message.permissionGroupName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* int32 permission_group_id = 2; */
        if (message.permissionGroupId !== 0)
            writer.tag(2, WireType.Varint).int32(message.permissionGroupId);
        /* string permission_group_name = 3; */
        if (message.permissionGroupName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.permissionGroupName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserPermissionGroupReq
 */
export const UserPermissionGroupReq = new UserPermissionGroupReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermissionGroupUser$Type extends MessageType {
    constructor() {
        super("PermissionGroupUser", [
            { no: 1, name: "permission_group_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "permission_group_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "permission_group_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "permission_group_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "permission_group_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "filter_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "user_firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "user_lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { permissionGroupUserId: 0, permissionGroupId: 0, permissionGroupName: "", permissionGroupDescription: "", permissionGroupType: "", filterData: "", userId: 0, userFirstname: "", userLastname: "", fieldMask: [], notEquals: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 permission_group_user_id */ 1:
                    message.permissionGroupUserId = reader.int32();
                    break;
                case /* int32 permission_group_id */ 2:
                    message.permissionGroupId = reader.int32();
                    break;
                case /* string permission_group_name */ 3:
                    message.permissionGroupName = reader.string();
                    break;
                case /* string permission_group_description */ 4:
                    message.permissionGroupDescription = reader.string();
                    break;
                case /* string permission_group_type */ 5:
                    message.permissionGroupType = reader.string();
                    break;
                case /* string filter_data */ 6:
                    message.filterData = reader.string();
                    break;
                case /* int32 user_id */ 7:
                    message.userId = reader.int32();
                    break;
                case /* string user_firstname */ 8:
                    message.userFirstname = reader.string();
                    break;
                case /* string user_lastname */ 9:
                    message.userLastname = reader.string();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* repeated string not_equals */ 11:
                    message.notEquals.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 permission_group_user_id = 1; */
        if (message.permissionGroupUserId !== 0)
            writer.tag(1, WireType.Varint).int32(message.permissionGroupUserId);
        /* int32 permission_group_id = 2; */
        if (message.permissionGroupId !== 0)
            writer.tag(2, WireType.Varint).int32(message.permissionGroupId);
        /* string permission_group_name = 3; */
        if (message.permissionGroupName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.permissionGroupName);
        /* string permission_group_description = 4; */
        if (message.permissionGroupDescription !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.permissionGroupDescription);
        /* string permission_group_type = 5; */
        if (message.permissionGroupType !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.permissionGroupType);
        /* string filter_data = 6; */
        if (message.filterData !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.filterData);
        /* int32 user_id = 7; */
        if (message.userId !== 0)
            writer.tag(7, WireType.Varint).int32(message.userId);
        /* string user_firstname = 8; */
        if (message.userFirstname !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.userFirstname);
        /* string user_lastname = 9; */
        if (message.userLastname !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.userLastname);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* repeated string not_equals = 11; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.notEquals[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermissionGroupUser
 */
export const PermissionGroupUser = new PermissionGroupUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermissionGroupUserList$Type extends MessageType {
    constructor() {
        super("PermissionGroupUserList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PermissionGroupUser },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PermissionGroupUser results */ 1:
                    message.results.push(PermissionGroupUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PermissionGroupUser results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PermissionGroupUser.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermissionGroupUserList
 */
export const PermissionGroupUserList = new PermissionGroupUserList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserAttributes$Type extends MessageType {
    constructor() {
        super("UserAttributes", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "override_month", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "organization_group_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "pant_size", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "shirt_size", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, overrideMonth: 0, fieldMask: [], organizationGroupId: 0, pantSize: "", shirtSize: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 override_month */ 3:
                    message.overrideMonth = reader.int32();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 organization_group_id */ 5:
                    message.organizationGroupId = reader.int32();
                    break;
                case /* string pant_size */ 6:
                    message.pantSize = reader.string();
                    break;
                case /* string shirt_size */ 7:
                    message.shirtSize = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 override_month = 3; */
        if (message.overrideMonth !== 0)
            writer.tag(3, WireType.Varint).int32(message.overrideMonth);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 organization_group_id = 5; */
        if (message.organizationGroupId !== 0)
            writer.tag(5, WireType.Varint).int32(message.organizationGroupId);
        /* string pant_size = 6; */
        if (message.pantSize !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.pantSize);
        /* string shirt_size = 7; */
        if (message.shirtSize !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.shirtSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserAttributes
 */
export const UserAttributes = new UserAttributes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Team$Type extends MessageType {
    constructor() {
        super("Team", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "desc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "supervisor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "auditor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", desc: "", supervisorId: 0, auditorId: 0, isActive: false, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string desc */ 3:
                    message.desc = reader.string();
                    break;
                case /* int32 supervisor_id */ 4:
                    message.supervisorId = reader.int32();
                    break;
                case /* int32 auditor_id */ 5:
                    message.auditorId = reader.int32();
                    break;
                case /* bool is_active */ 6:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string desc = 3; */
        if (message.desc !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.desc);
        /* int32 supervisor_id = 4; */
        if (message.supervisorId !== 0)
            writer.tag(4, WireType.Varint).int32(message.supervisorId);
        /* int32 auditor_id = 5; */
        if (message.auditorId !== 0)
            writer.tag(5, WireType.Varint).int32(message.auditorId);
        /* bool is_active = 6; */
        if (message.isActive !== false)
            writer.tag(6, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Team
 */
export const Team = new Team$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamList$Type extends MessageType {
    constructor() {
        super("TeamList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Team },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Team results */ 1:
                    message.results.push(Team.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Team results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Team.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamList
 */
export const TeamList = new TeamList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamMember$Type extends MessageType {
    constructor() {
        super("TeamMember", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "employee_level_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, employeeId: 0, employeeLevelId: 0, isActive: false, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 employee_id */ 2:
                    message.employeeId = reader.int32();
                    break;
                case /* int32 employee_level_id */ 3:
                    message.employeeLevelId = reader.int32();
                    break;
                case /* bool is_active */ 4:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 employee_id = 2; */
        if (message.employeeId !== 0)
            writer.tag(2, WireType.Varint).int32(message.employeeId);
        /* int32 employee_level_id = 3; */
        if (message.employeeLevelId !== 0)
            writer.tag(3, WireType.Varint).int32(message.employeeLevelId);
        /* bool is_active = 4; */
        if (message.isActive !== false)
            writer.tag(4, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamMember
 */
export const TeamMember = new TeamMember$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamMemberList$Type extends MessageType {
    constructor() {
        super("TeamMemberList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TeamMember },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TeamMember results */ 1:
                    message.results.push(TeamMember.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TeamMember results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TeamMember.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamMemberList
 */
export const TeamMemberList = new TeamMemberList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamLevel$Type extends MessageType {
    constructor() {
        super("TeamLevel", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "team_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "level_positions", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, teamId: 0, levelPositions: "", isActive: false, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 team_id */ 2:
                    message.teamId = reader.int32();
                    break;
                case /* string level_positions */ 3:
                    message.levelPositions = reader.string();
                    break;
                case /* bool is_active */ 4:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 team_id = 2; */
        if (message.teamId !== 0)
            writer.tag(2, WireType.Varint).int32(message.teamId);
        /* string level_positions = 3; */
        if (message.levelPositions !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.levelPositions);
        /* bool is_active = 4; */
        if (message.isActive !== false)
            writer.tag(4, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamLevel
 */
export const TeamLevel = new TeamLevel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationGroup$Type extends MessageType {
    constructor() {
        super("OrganizationGroup", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "parent_group_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "sort_order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", parentGroupId: 0, sortOrder: 0, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 parent_group_id */ 3:
                    message.parentGroupId = reader.int32();
                    break;
                case /* int32 sort_order */ 4:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 parent_group_id = 3; */
        if (message.parentGroupId !== 0)
            writer.tag(3, WireType.Varint).int32(message.parentGroupId);
        /* int32 sort_order = 4; */
        if (message.sortOrder !== 0)
            writer.tag(4, WireType.Varint).int32(message.sortOrder);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OrganizationGroup
 */
export const OrganizationGroup = new OrganizationGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationGroupList$Type extends MessageType {
    constructor() {
        super("OrganizationGroupList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrganizationGroup },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated OrganizationGroup results */ 1:
                    message.results.push(OrganizationGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated OrganizationGroup results = 1; */
        for (let i = 0; i < message.results.length; i++)
            OrganizationGroup.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OrganizationGroupList
 */
export const OrganizationGroupList = new OrganizationGroupList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationGroupUser$Type extends MessageType {
    constructor() {
        super("OrganizationGroupUser", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "user_lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "organization_group_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "organization_group_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, userFirstname: "", userLastname: "", organizationGroupId: 0, organizationGroupName: "", fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string user_firstname */ 2:
                    message.userFirstname = reader.string();
                    break;
                case /* string user_lastname */ 3:
                    message.userLastname = reader.string();
                    break;
                case /* int32 organization_group_id */ 4:
                    message.organizationGroupId = reader.int32();
                    break;
                case /* string organization_group_name */ 5:
                    message.organizationGroupName = reader.string();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string user_firstname = 2; */
        if (message.userFirstname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userFirstname);
        /* string user_lastname = 3; */
        if (message.userLastname !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userLastname);
        /* int32 organization_group_id = 4; */
        if (message.organizationGroupId !== 0)
            writer.tag(4, WireType.Varint).int32(message.organizationGroupId);
        /* string organization_group_name = 5; */
        if (message.organizationGroupName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.organizationGroupName);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OrganizationGroupUser
 */
export const OrganizationGroupUser = new OrganizationGroupUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationGroupUserList$Type extends MessageType {
    constructor() {
        super("OrganizationGroupUserList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrganizationGroupUser },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated OrganizationGroupUser results */ 1:
                    message.results.push(OrganizationGroupUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated OrganizationGroupUser results = 1; */
        for (let i = 0; i < message.results.length; i++)
            OrganizationGroupUser.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OrganizationGroupUserList
 */
export const OrganizationGroupUserList = new OrganizationGroupUserList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamLevelList$Type extends MessageType {
    constructor() {
        super("TeamLevelList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TeamLevel },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TeamLevel results */ 1:
                    message.results.push(TeamLevel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TeamLevel results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TeamLevel.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamLevelList
 */
export const TeamLevelList = new TeamLevelList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeFunction$Type extends MessageType {
    constructor() {
        super("EmployeeFunction", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "isdeleted", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "addeddate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "modifydate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "addeduserid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "modifyuserid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", color: "", status: 0, isdeleted: 0, addeddate: "", modifydate: "", addeduserid: 0, modifyuserid: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string color */ 3:
                    message.color = reader.string();
                    break;
                case /* int32 status */ 4:
                    message.status = reader.int32();
                    break;
                case /* int32 isdeleted */ 5:
                    message.isdeleted = reader.int32();
                    break;
                case /* string addeddate */ 6:
                    message.addeddate = reader.string();
                    break;
                case /* string modifydate */ 7:
                    message.modifydate = reader.string();
                    break;
                case /* int32 addeduserid */ 8:
                    message.addeduserid = reader.int32();
                    break;
                case /* int32 modifyuserid */ 9:
                    message.modifyuserid = reader.int32();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 11:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string color = 3; */
        if (message.color !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.color);
        /* int32 status = 4; */
        if (message.status !== 0)
            writer.tag(4, WireType.Varint).int32(message.status);
        /* int32 isdeleted = 5; */
        if (message.isdeleted !== 0)
            writer.tag(5, WireType.Varint).int32(message.isdeleted);
        /* string addeddate = 6; */
        if (message.addeddate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.addeddate);
        /* string modifydate = 7; */
        if (message.modifydate !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.modifydate);
        /* int32 addeduserid = 8; */
        if (message.addeduserid !== 0)
            writer.tag(8, WireType.Varint).int32(message.addeduserid);
        /* int32 modifyuserid = 9; */
        if (message.modifyuserid !== 0)
            writer.tag(9, WireType.Varint).int32(message.modifyuserid);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 11; */
        if (message.pageNumber !== 0)
            writer.tag(11, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeFunction
 */
export const EmployeeFunction = new EmployeeFunction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeFunctionList$Type extends MessageType {
    constructor() {
        super("EmployeeFunctionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EmployeeFunction },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated EmployeeFunction results */ 1:
                    message.results.push(EmployeeFunction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated EmployeeFunction results = 1; */
        for (let i = 0; i < message.results.length; i++)
            EmployeeFunction.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeFunctionList
 */
export const EmployeeFunctionList = new EmployeeFunctionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewHire$Type extends MessageType {
    constructor() {
        super("NewHire", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "job_title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "direct_supervisor", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "employee_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "full_time_employee", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "employee_start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "phone_stipend", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "commute_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "vehicle_stipend_amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "admin_benefits_activated", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "it_benefits_activated", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "supplier_accounts_activated", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "it_device_needed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "referred_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "finished_onboarding", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "confirmed_start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "additional_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 25, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 29, name: "rehire_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 30, name: "orientation_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "drug_test_status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 32, name: "background_check_status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 33, name: "finalized", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 34, name: "address_additional_field", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, firstName: "", lastName: "", jobTitle: "", directSupervisor: 0, phoneNumber: "", email: "", address: "", departmentId: 0, employeeType: "", fullTimeEmployee: false, employeeStartDate: "", phoneStipend: false, commuteTypeId: 0, vehicleStipendAmount: "", adminBenefitsActivated: "", itBenefitsActivated: "", supplierAccountsActivated: "", itDeviceNeeded: "", referredBy: 0, finishedOnboarding: false, confirmedStartDate: "", additionalNotes: "", isActive: false, fieldMask: [], orderBy: "", orderDir: "", pageNumber: 0, rehireUserId: 0, orientationDate: "", drugTestStatus: 0, backgroundCheckStatus: 0, finalized: false, addressAdditionalField: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 3:
                    message.lastName = reader.string();
                    break;
                case /* string job_title */ 4:
                    message.jobTitle = reader.string();
                    break;
                case /* int32 direct_supervisor */ 5:
                    message.directSupervisor = reader.int32();
                    break;
                case /* string phone_number */ 6:
                    message.phoneNumber = reader.string();
                    break;
                case /* string email */ 7:
                    message.email = reader.string();
                    break;
                case /* string address */ 8:
                    message.address = reader.string();
                    break;
                case /* int32 department_id */ 9:
                    message.departmentId = reader.int32();
                    break;
                case /* string employee_type */ 10:
                    message.employeeType = reader.string();
                    break;
                case /* bool full_time_employee */ 11:
                    message.fullTimeEmployee = reader.bool();
                    break;
                case /* string employee_start_date */ 12:
                    message.employeeStartDate = reader.string();
                    break;
                case /* bool phone_stipend */ 13:
                    message.phoneStipend = reader.bool();
                    break;
                case /* int32 commute_type_id */ 14:
                    message.commuteTypeId = reader.int32();
                    break;
                case /* string vehicle_stipend_amount */ 15:
                    message.vehicleStipendAmount = reader.string();
                    break;
                case /* string admin_benefits_activated */ 16:
                    message.adminBenefitsActivated = reader.string();
                    break;
                case /* string it_benefits_activated */ 17:
                    message.itBenefitsActivated = reader.string();
                    break;
                case /* string supplier_accounts_activated */ 18:
                    message.supplierAccountsActivated = reader.string();
                    break;
                case /* string it_device_needed */ 19:
                    message.itDeviceNeeded = reader.string();
                    break;
                case /* int32 referred_by */ 20:
                    message.referredBy = reader.int32();
                    break;
                case /* bool finished_onboarding */ 21:
                    message.finishedOnboarding = reader.bool();
                    break;
                case /* string confirmed_start_date */ 22:
                    message.confirmedStartDate = reader.string();
                    break;
                case /* string additional_notes */ 23:
                    message.additionalNotes = reader.string();
                    break;
                case /* bool is_active */ 24:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 25:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 26:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 27:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 28:
                    message.pageNumber = reader.int32();
                    break;
                case /* int32 rehire_user_id */ 29:
                    message.rehireUserId = reader.int32();
                    break;
                case /* string orientation_date */ 30:
                    message.orientationDate = reader.string();
                    break;
                case /* int32 drug_test_status */ 31:
                    message.drugTestStatus = reader.int32();
                    break;
                case /* int32 background_check_status */ 32:
                    message.backgroundCheckStatus = reader.int32();
                    break;
                case /* bool finalized */ 33:
                    message.finalized = reader.bool();
                    break;
                case /* string address_additional_field */ 34:
                    message.addressAdditionalField = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* string job_title = 4; */
        if (message.jobTitle !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.jobTitle);
        /* int32 direct_supervisor = 5; */
        if (message.directSupervisor !== 0)
            writer.tag(5, WireType.Varint).int32(message.directSupervisor);
        /* string phone_number = 6; */
        if (message.phoneNumber !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.phoneNumber);
        /* string email = 7; */
        if (message.email !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.email);
        /* string address = 8; */
        if (message.address !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.address);
        /* int32 department_id = 9; */
        if (message.departmentId !== 0)
            writer.tag(9, WireType.Varint).int32(message.departmentId);
        /* string employee_type = 10; */
        if (message.employeeType !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.employeeType);
        /* bool full_time_employee = 11; */
        if (message.fullTimeEmployee !== false)
            writer.tag(11, WireType.Varint).bool(message.fullTimeEmployee);
        /* string employee_start_date = 12; */
        if (message.employeeStartDate !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.employeeStartDate);
        /* bool phone_stipend = 13; */
        if (message.phoneStipend !== false)
            writer.tag(13, WireType.Varint).bool(message.phoneStipend);
        /* int32 commute_type_id = 14; */
        if (message.commuteTypeId !== 0)
            writer.tag(14, WireType.Varint).int32(message.commuteTypeId);
        /* string vehicle_stipend_amount = 15; */
        if (message.vehicleStipendAmount !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.vehicleStipendAmount);
        /* string admin_benefits_activated = 16; */
        if (message.adminBenefitsActivated !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.adminBenefitsActivated);
        /* string it_benefits_activated = 17; */
        if (message.itBenefitsActivated !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.itBenefitsActivated);
        /* string supplier_accounts_activated = 18; */
        if (message.supplierAccountsActivated !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.supplierAccountsActivated);
        /* string it_device_needed = 19; */
        if (message.itDeviceNeeded !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.itDeviceNeeded);
        /* int32 referred_by = 20; */
        if (message.referredBy !== 0)
            writer.tag(20, WireType.Varint).int32(message.referredBy);
        /* bool finished_onboarding = 21; */
        if (message.finishedOnboarding !== false)
            writer.tag(21, WireType.Varint).bool(message.finishedOnboarding);
        /* string confirmed_start_date = 22; */
        if (message.confirmedStartDate !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.confirmedStartDate);
        /* string additional_notes = 23; */
        if (message.additionalNotes !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.additionalNotes);
        /* bool is_active = 24; */
        if (message.isActive !== false)
            writer.tag(24, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 25; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(25, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 26; */
        if (message.orderBy !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 27; */
        if (message.orderDir !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 28; */
        if (message.pageNumber !== 0)
            writer.tag(28, WireType.Varint).int32(message.pageNumber);
        /* int32 rehire_user_id = 29; */
        if (message.rehireUserId !== 0)
            writer.tag(29, WireType.Varint).int32(message.rehireUserId);
        /* string orientation_date = 30; */
        if (message.orientationDate !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.orientationDate);
        /* int32 drug_test_status = 31; */
        if (message.drugTestStatus !== 0)
            writer.tag(31, WireType.Varint).int32(message.drugTestStatus);
        /* int32 background_check_status = 32; */
        if (message.backgroundCheckStatus !== 0)
            writer.tag(32, WireType.Varint).int32(message.backgroundCheckStatus);
        /* bool finalized = 33; */
        if (message.finalized !== false)
            writer.tag(33, WireType.Varint).bool(message.finalized);
        /* string address_additional_field = 34; */
        if (message.addressAdditionalField !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.addressAdditionalField);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewHire
 */
export const NewHire = new NewHire$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewHireList$Type extends MessageType {
    constructor() {
        super("NewHireList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NewHire },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated NewHire results */ 1:
                    message.results.push(NewHire.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated NewHire results = 1; */
        for (let i = 0; i < message.results.length; i++)
            NewHire.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewHireList
 */
export const NewHireList = new NewHireList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewHireOption$Type extends MessageType {
    constructor() {
        super("NewHireOption", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, category: "", isActive: false, name: "", fieldMask: [], orderBy: "", orderDir: "", idList: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string category */ 2:
                    message.category = reader.string();
                    break;
                case /* bool is_active */ 3:
                    message.isActive = reader.bool();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 6:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 7:
                    message.orderDir = reader.string();
                    break;
                case /* string id_list */ 8:
                    message.idList = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string category = 2; */
        if (message.category !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.category);
        /* bool is_active = 3; */
        if (message.isActive !== false)
            writer.tag(3, WireType.Varint).bool(message.isActive);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 6; */
        if (message.orderBy !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 7; */
        if (message.orderDir !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.orderDir);
        /* string id_list = 8; */
        if (message.idList !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.idList);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewHireOption
 */
export const NewHireOption = new NewHireOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewHireOptionList$Type extends MessageType {
    constructor() {
        super("NewHireOptionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NewHireOption },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated NewHireOption results */ 1:
                    message.results.push(NewHireOption.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated NewHireOption results = 1; */
        for (let i = 0; i < message.results.length; i++)
            NewHireOption.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewHireOptionList
 */
export const NewHireOptionList = new NewHireOptionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeItem$Type extends MessageType {
    constructor() {
        super("EmployeeItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, name: "", notes: "", date: "", isActive: false, fieldMask: [], orderBy: "", orderDir: "", pageNumber: 0, withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string notes */ 4:
                    message.notes = reader.string();
                    break;
                case /* string date */ 5:
                    message.date = reader.string();
                    break;
                case /* bool is_active */ 6:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 8:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 9:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 10:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 11:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string notes = 4; */
        if (message.notes !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.notes);
        /* string date = 5; */
        if (message.date !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.date);
        /* bool is_active = 6; */
        if (message.isActive !== false)
            writer.tag(6, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 8; */
        if (message.orderBy !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 9; */
        if (message.orderDir !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 10; */
        if (message.pageNumber !== 0)
            writer.tag(10, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 11; */
        if (message.withoutLimit !== false)
            writer.tag(11, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeItem
 */
export const EmployeeItem = new EmployeeItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeItemList$Type extends MessageType {
    constructor() {
        super("EmployeeItemList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EmployeeItem },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated EmployeeItem results */ 1:
                    message.results.push(EmployeeItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated EmployeeItem results = 1; */
        for (let i = 0; i < message.results.length; i++)
            EmployeeItem.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeItemList
 */
export const EmployeeItemList = new EmployeeItemList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewHireFinalizeRequest$Type extends MessageType {
    constructor() {
        super("NewHireFinalizeRequest", [
            { no: 1, name: "new_hire", kind: "message", T: () => NewHire }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* NewHire new_hire */ 1:
                    message.newHire = NewHire.internalBinaryRead(reader, reader.uint32(), options, message.newHire);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* NewHire new_hire = 1; */
        if (message.newHire)
            NewHire.internalBinaryWrite(message.newHire, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewHireFinalizeRequest
 */
export const NewHireFinalizeRequest = new NewHireFinalizeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewHireFinalizeResponse$Type extends MessageType {
    constructor() {
        super("NewHireFinalizeResponse", [
            { no: 1, name: "new_hire", kind: "message", T: () => NewHire },
            { no: 2, name: "new_user", kind: "message", T: () => User },
            { no: 3, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EmployeeItem },
            { no: 4, name: "errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { items: [], errors: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* NewHire new_hire */ 1:
                    message.newHire = NewHire.internalBinaryRead(reader, reader.uint32(), options, message.newHire);
                    break;
                case /* User new_user */ 2:
                    message.newUser = User.internalBinaryRead(reader, reader.uint32(), options, message.newUser);
                    break;
                case /* repeated EmployeeItem items */ 3:
                    message.items.push(EmployeeItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string errors */ 4:
                    message.errors.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* NewHire new_hire = 1; */
        if (message.newHire)
            NewHire.internalBinaryWrite(message.newHire, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* User new_user = 2; */
        if (message.newUser)
            User.internalBinaryWrite(message.newUser, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated EmployeeItem items = 3; */
        for (let i = 0; i < message.items.length; i++)
            EmployeeItem.internalBinaryWrite(message.items[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated string errors = 4; */
        for (let i = 0; i < message.errors.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.errors[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewHireFinalizeResponse
 */
export const NewHireFinalizeResponse = new NewHireFinalizeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TerminationForm$Type extends MessageType {
    constructor() {
        super("TerminationForm", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "creator_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "final_employment_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "last_day_worked", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "manager_id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "termination_reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "rehire_eligible", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "rehire_explanation", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "severance_amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "required_items", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "missing_receipt", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "commission_amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, creatorId: 0, finalEmploymentDate: "", lastDayWorked: "", managerIdList: "", terminationReason: "", reason: "", rehireEligible: 0, rehireExplanation: "", severanceAmount: "", requiredItems: "", missingReceipt: false, isActive: false, statusId: 0, fieldMask: [], orderBy: "", orderDir: "", pageNumber: 0, withoutLimit: false, commissionAmount: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 creator_id */ 3:
                    message.creatorId = reader.int32();
                    break;
                case /* string final_employment_date */ 4:
                    message.finalEmploymentDate = reader.string();
                    break;
                case /* string last_day_worked */ 5:
                    message.lastDayWorked = reader.string();
                    break;
                case /* string manager_id_list */ 6:
                    message.managerIdList = reader.string();
                    break;
                case /* string termination_reason */ 7:
                    message.terminationReason = reader.string();
                    break;
                case /* string reason */ 8:
                    message.reason = reader.string();
                    break;
                case /* int32 rehire_eligible */ 9:
                    message.rehireEligible = reader.int32();
                    break;
                case /* string rehire_explanation */ 10:
                    message.rehireExplanation = reader.string();
                    break;
                case /* string severance_amount */ 11:
                    message.severanceAmount = reader.string();
                    break;
                case /* string required_items */ 12:
                    message.requiredItems = reader.string();
                    break;
                case /* bool missing_receipt */ 13:
                    message.missingReceipt = reader.bool();
                    break;
                case /* bool is_active */ 14:
                    message.isActive = reader.bool();
                    break;
                case /* int32 status_id */ 15:
                    message.statusId = reader.int32();
                    break;
                case /* repeated string field_mask */ 16:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 17:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 18:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 19:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 20:
                    message.withoutLimit = reader.bool();
                    break;
                case /* string commission_amount */ 21:
                    message.commissionAmount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 creator_id = 3; */
        if (message.creatorId !== 0)
            writer.tag(3, WireType.Varint).int32(message.creatorId);
        /* string final_employment_date = 4; */
        if (message.finalEmploymentDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.finalEmploymentDate);
        /* string last_day_worked = 5; */
        if (message.lastDayWorked !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.lastDayWorked);
        /* string manager_id_list = 6; */
        if (message.managerIdList !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.managerIdList);
        /* string termination_reason = 7; */
        if (message.terminationReason !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.terminationReason);
        /* string reason = 8; */
        if (message.reason !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.reason);
        /* int32 rehire_eligible = 9; */
        if (message.rehireEligible !== 0)
            writer.tag(9, WireType.Varint).int32(message.rehireEligible);
        /* string rehire_explanation = 10; */
        if (message.rehireExplanation !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.rehireExplanation);
        /* string severance_amount = 11; */
        if (message.severanceAmount !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.severanceAmount);
        /* string required_items = 12; */
        if (message.requiredItems !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.requiredItems);
        /* bool missing_receipt = 13; */
        if (message.missingReceipt !== false)
            writer.tag(13, WireType.Varint).bool(message.missingReceipt);
        /* bool is_active = 14; */
        if (message.isActive !== false)
            writer.tag(14, WireType.Varint).bool(message.isActive);
        /* int32 status_id = 15; */
        if (message.statusId !== 0)
            writer.tag(15, WireType.Varint).int32(message.statusId);
        /* repeated string field_mask = 16; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(16, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 17; */
        if (message.orderBy !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 18; */
        if (message.orderDir !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 19; */
        if (message.pageNumber !== 0)
            writer.tag(19, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 20; */
        if (message.withoutLimit !== false)
            writer.tag(20, WireType.Varint).bool(message.withoutLimit);
        /* string commission_amount = 21; */
        if (message.commissionAmount !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.commissionAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TerminationForm
 */
export const TerminationForm = new TerminationForm$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TerminationFormList$Type extends MessageType {
    constructor() {
        super("TerminationFormList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TerminationForm },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TerminationForm results */ 1:
                    message.results.push(TerminationForm.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TerminationForm results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TerminationForm.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TerminationFormList
 */
export const TerminationFormList = new TerminationFormList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeItemOption$Type extends MessageType {
    constructor() {
        super("EmployeeItemOption", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", isActive: false, quantity: 0, category: "", notes: "", fieldMask: [], orderBy: "", orderDir: "", pageNumber: 0, withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* bool is_active */ 3:
                    message.isActive = reader.bool();
                    break;
                case /* int32 quantity */ 4:
                    message.quantity = reader.int32();
                    break;
                case /* string category */ 5:
                    message.category = reader.string();
                    break;
                case /* string notes */ 6:
                    message.notes = reader.string();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 8:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 9:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 10:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 11:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* bool is_active = 3; */
        if (message.isActive !== false)
            writer.tag(3, WireType.Varint).bool(message.isActive);
        /* int32 quantity = 4; */
        if (message.quantity !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantity);
        /* string category = 5; */
        if (message.category !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.category);
        /* string notes = 6; */
        if (message.notes !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.notes);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 8; */
        if (message.orderBy !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 9; */
        if (message.orderDir !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 10; */
        if (message.pageNumber !== 0)
            writer.tag(10, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 11; */
        if (message.withoutLimit !== false)
            writer.tag(11, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeItemOption
 */
export const EmployeeItemOption = new EmployeeItemOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeItemOptionList$Type extends MessageType {
    constructor() {
        super("EmployeeItemOptionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EmployeeItemOption },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated EmployeeItemOption results */ 1:
                    message.results.push(EmployeeItemOption.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated EmployeeItemOption results = 1; */
        for (let i = 0; i < message.results.length; i++)
            EmployeeItemOption.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeItemOptionList
 */
export const EmployeeItemOptionList = new EmployeeItemOptionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeItemCategory$Type extends MessageType {
    constructor() {
        super("EmployeeItemCategory", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", isActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* bool is_active */ 3:
                    message.isActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* bool is_active = 3; */
        if (message.isActive !== false)
            writer.tag(3, WireType.Varint).bool(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeItemCategory
 */
export const EmployeeItemCategory = new EmployeeItemCategory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeItemCategoryList$Type extends MessageType {
    constructor() {
        super("EmployeeItemCategoryList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EmployeeItemCategory },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated EmployeeItemCategory results */ 1:
                    message.results.push(EmployeeItemCategory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated EmployeeItemCategory results = 1; */
        for (let i = 0; i < message.results.length; i++)
            EmployeeItemCategory.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeItemCategoryList
 */
export const EmployeeItemCategoryList = new EmployeeItemCategoryList$Type();
/**
 * @generated ServiceType for protobuf service UserService
 */
export const UserService = new ServiceType("UserService", [
    { name: "Create", options: {}, I: User, O: User },
    { name: "Get", options: {}, I: User, O: User },
    { name: "BatchGet", options: {}, I: User, O: UserList },
    { name: "Update", options: {}, I: User, O: User },
    { name: "Delete", options: {}, I: User, O: User },
    { name: "GetCardList", options: {}, I: CardData, O: CardDataList },
    { name: "GetUserManager", options: {}, I: User, O: User },
    { name: "CreatePermissionGroup", options: {}, I: PermissionGroup, O: Int32 },
    { name: "GetPermissionGroupUser", options: {}, I: PermissionGroupUser, O: PermissionGroupUser },
    { name: "BatchGetPermissionGroupUser", options: {}, I: PermissionGroupUser, O: PermissionGroupUserList },
    { name: "DeletePermissionGroup", options: {}, I: Int32, O: Empty },
    { name: "UpdatePermissionGroup", options: {}, I: PermissionGroup, O: Empty },
    { name: "AddUserToPermissionGroup", options: {}, I: PermissionGroupUser, O: Bool },
    { name: "RemoveUserFromPermissionGroup", options: {}, I: PermissionGroupUser, O: Empty },
    { name: "UserInPermissionGroup", options: {}, I: PermissionGroupUser, O: Bool },
    { name: "BatchGetUserPermissions", options: {}, I: PermissionGroupUser, O: PermissionGroupUserList },
    { name: "BatchGetPermissions", options: {}, I: PermissionGroup, O: PermissionGroupList },
    { name: "GetUserIdsInPermissionGroup", options: {}, I: Int32, O: Int32List },
    { name: "PushNotificationDebug", options: {}, I: Empty, O: Empty },
    { name: "GetUserAttributes", options: {}, I: UserAttributes, O: UserAttributes },
    { name: "UpdateUserAttributes", options: {}, I: UserAttributes, O: UserAttributes },
    { name: "CreateUserAttributes", options: {}, I: UserAttributes, O: UserAttributes },
    { name: "CreateTeam", options: {}, I: Team, O: Team },
    { name: "GetTeam", options: {}, I: Team, O: Team },
    { name: "UpdateTeam", options: {}, I: Team, O: Team },
    { name: "BatchGetTeams", options: {}, I: Team, O: TeamList },
    { name: "DeleteTeam", options: {}, I: Team, O: Team },
    { name: "CreateTeamMember", options: {}, I: TeamMember, O: TeamMember },
    { name: "GetTeamMember", options: {}, I: TeamMember, O: TeamMember },
    { name: "UpdateTeamMember", options: {}, I: TeamMember, O: TeamMember },
    { name: "BatchGetTeamMembers", options: {}, I: TeamMember, O: TeamMemberList },
    { name: "DeleteTeamMember", options: {}, I: TeamMember, O: TeamMember },
    { name: "CreateTeamLevel", options: {}, I: TeamLevel, O: TeamLevel },
    { name: "GetTeamLevel", options: {}, I: TeamLevel, O: TeamLevel },
    { name: "UpdateTeamLevel", options: {}, I: TeamLevel, O: TeamLevel },
    { name: "BatchGetTeamLevels", options: {}, I: TeamLevel, O: TeamLevelList },
    { name: "DeleteTeamLevel", options: {}, I: TeamLevel, O: TeamLevel },
    { name: "CreateEmployeeFunction", options: {}, I: EmployeeFunction, O: EmployeeFunction },
    { name: "GetEmployeeFunction", options: {}, I: EmployeeFunction, O: EmployeeFunction },
    { name: "BatchGetEmployeeFunction", options: {}, I: EmployeeFunction, O: EmployeeFunctionList },
    { name: "UpdateEmployeeFunction", options: {}, I: EmployeeFunction, O: EmployeeFunction },
    { name: "DeleteEmployeeFunction", options: {}, I: EmployeeFunction, O: EmployeeFunction },
    { name: "CreateUserGroupLink", options: {}, I: UserGroupLink, O: UserGroupLink },
    { name: "GetUserGroupLink", options: {}, I: UserGroupLink, O: UserGroupLink },
    { name: "BatchGetUserGroupLink", options: {}, I: UserGroupLink, O: UserGroupLinkList },
    { name: "UpdateUserGroupLink", options: {}, I: UserGroupLink, O: UserGroupLink },
    { name: "DeleteUserGroupLink", options: {}, I: UserGroupLink, O: UserGroupLink },
    { name: "CreateGroup", options: {}, I: Group, O: Group },
    { name: "GetGroup", options: {}, I: Group, O: Group },
    { name: "BatchGetGroup", options: {}, I: Group, O: GroupList },
    { name: "UpdateGroup", options: {}, I: Group, O: Group },
    { name: "DeleteGroup", options: {}, I: Group, O: Group },
    { name: "BatchGetOrganizationGroup", options: {}, I: OrganizationGroup, O: OrganizationGroupList },
    { name: "BatchGetOrganizationGroupUser", options: {}, I: OrganizationGroupUser, O: OrganizationGroupUserList },
    { name: "CreateNewHire", options: {}, I: NewHire, O: NewHire },
    { name: "GetNewHire", options: {}, I: NewHire, O: NewHire },
    { name: "BatchGetNewHire", options: {}, I: NewHire, O: NewHireList },
    { name: "UpdateNewHire", options: {}, I: NewHire, O: NewHire },
    { name: "DeleteNewHire", options: {}, I: NewHire, O: NewHire },
    { name: "BatchGetNewHireOption", options: {}, I: NewHireOption, O: NewHireOptionList },
    { name: "CreateEmployeeItem", options: {}, I: EmployeeItem, O: EmployeeItem },
    { name: "GetEmployeeItem", options: {}, I: EmployeeItem, O: EmployeeItem },
    { name: "BatchGetEmployeeItem", options: {}, I: EmployeeItem, O: EmployeeItemList },
    { name: "UpdateEmployeeItem", options: {}, I: EmployeeItem, O: EmployeeItem },
    { name: "DeleteEmployeeItem", options: {}, I: EmployeeItem, O: EmployeeItem },
    { name: "NewHireFinalize", options: {}, I: NewHireFinalizeRequest, O: NewHireFinalizeResponse },
    { name: "CreateTerminationForm", options: {}, I: TerminationForm, O: TerminationForm },
    { name: "GetTerminationForm", options: {}, I: TerminationForm, O: TerminationForm },
    { name: "BatchGetTerminationForm", options: {}, I: TerminationForm, O: TerminationFormList },
    { name: "UpdateTerminationForm", options: {}, I: TerminationForm, O: TerminationForm },
    { name: "DeleteTerminationForm", options: {}, I: TerminationForm, O: Empty },
    { name: "CreateEmployeeItemOption", options: {}, I: EmployeeItemOption, O: EmployeeItemOption },
    { name: "GetEmployeeItemOption", options: {}, I: EmployeeItemOption, O: EmployeeItemOption },
    { name: "BatchGetEmployeeItemOption", options: {}, I: EmployeeItemOption, O: EmployeeItemOptionList },
    { name: "UpdateEmployeeItemOption", options: {}, I: EmployeeItemOption, O: EmployeeItemOption },
    { name: "DeleteEmployeeItemOption", options: {}, I: EmployeeItemOption, O: Empty },
    { name: "BatchGetEmployeeItemCategory", options: {}, I: EmployeeItemCategory, O: EmployeeItemCategoryList }
]);
