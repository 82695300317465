import './Proposal.module.less';

import {
  Document,
  File as FileType,
  type Property,
  QuoteLine,
  type ServicesRendered,
  SQSEmail,
  StoredQuote,
  type User,
} from '@kalos/kalos-rpc';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { default as IconButton } from '@mui/material/IconButton';
import { type FC, useCallback, useEffect, useState } from 'react';

import { useDocumentUpdateMutation } from '../../../../hooks/react-query/useDocumentsQuery';
import {
  DocumentClientService,
  EmailClientService,
  FileClientService,
  formatDateDay,
  QuoteLineClientService,
} from '../../../../tools/helpers';
import { Form } from '../../Form';
import { type Columns, type Data, InfoTable } from '../../InfoTable';
import { Modal } from '../../Modal';
import { PlainForm, type Schema } from '../../PlainForm';
import { SectionBar } from '../../SectionBar';
import { StoredQuotes } from '../../StoredQuotes';
import { Tooltip } from '../../Tooltip';
import { type EventType } from '..';
import { ProposalPrint } from './ProposalPrint';

interface Props {
  serviceItem: EventType;
  property: Property;
  customer: User;
  servicesRendered: ServicesRendered[];
  propStoredQuotes?: StoredQuote[];
  className?: string;
  initalForm?: ProposalsForm;
  handleSetPropStoredQuotes?: (storedQuotes: StoredQuote[]) => void;
  reload?: () => void;
  onFormChange?: (data: ProposalsForm) => void;
}

export type ProposalsForm = {
  displayName: string;
  withJobNotes: number;
  notes: string;
};

type File = {
  localCopyName: string;
  fileDescription: string;
};

const bucket = 'testbuckethelios';
const SCHEMA_ENTRY: Schema<StoredQuote> = [
  [{ name: 'id', type: 'hidden' }],
  [
    {
      label: 'Description',
      name: 'description',
      multiline: true,
      minRows: 6,
      maxRows: 10,
      required: true,
      validationOnSave(value) {
        if (value.length > 10000) return 'The description cannot exceed 10000 characters';
        return !value.trim().replaceAll(' ', '').length ? 'This field is required' : '';
      },
    },
  ],
  [
    {
      label: 'Price',
      name: 'price',
      type: 'number',
      startAdornment: '$',
      required: true,
      validationOnSave(value) {
        if (value === '') return 'Please write correct price';
        return value < 0 ? "This can't be negative" : '';
      },
    },
  ],
];
const SCHEMA_FILE: Schema<File> = [
  [{ label: 'File', headline: true }],
  [
    {
      label: 'File Name',
      name: 'fileDescription',
      helperText: 'This will be the display name for the file.',
      disabled: true,
    },
  ],
];

export const Proposal: FC<Props> = ({
  serviceItem,
  customer,
  property,
  propStoredQuotes,
  servicesRendered,
  className,
  initalForm,
  handleSetPropStoredQuotes,
  reload,
  onFormChange,
}) => {
  const [editing, setEditing] = useState<StoredQuote>();

  const [file, setFile] = useState<File>({
    localCopyName: '',
    fileDescription: `${serviceItem.id}_pending_proposal_${customer?.id || ''}`,
  });

  const filteredServicesRendered = servicesRendered.filter(
    (sr) => sr.serviceRendered != '' && sr.serviceRendered != null,
  );
  const concatServicesRendered = filteredServicesRendered.reduce(function (
    concatedString,
    servicesRendered,
  ) {
    return (
      concatedString +
      `${formatDateDay(servicesRendered.timeStarted)}-${servicesRendered.name}-${
        servicesRendered.serviceRendered
      } \n`
    );
  }, '');

  const [quickAddOpen, setQuickAddOpen] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(false);
  const [table, setTable] = useState<StoredQuote[]>(propStoredQuotes || []);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loadedQuotes, setLoadedQuotes] = useState<QuoteLine[]>([]);
  const customerName = `${customer?.firstname} ${customer?.lastname}`;
  const [generateFile, setGenerateFile] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [form, setForm] = useState<ProposalsForm>(
    initalForm || {
      displayName: customerName,
      withJobNotes: 0,
      notes: concatServicesRendered,
    },
  );
  const handleToggleQuickAdd = useCallback(
    () => setQuickAddOpen(!quickAddOpen),
    [quickAddOpen, setQuickAddOpen],
  );
  const handleSetTable = useCallback(
    (table: StoredQuote[]) => {
      if (handleSetPropStoredQuotes) {
        handleSetPropStoredQuotes(table);
      }
      setTable(table);
    },
    [handleSetPropStoredQuotes],
  );

  const handleToggleGenerateFile = useCallback(
    () => setGenerateFile(!generateFile),
    [setGenerateFile, generateFile],
  );

  const handleGenerateProposalAndSendToCustomer = () => {
    handleToggleGenerateFile();
    handleSendToCustomer();
  };
  const load = useCallback(async () => {
    const req = QuoteLine.create();
    req.isActive = 1;
    req.jobNumber = serviceItem.id.toString();
    if (propStoredQuotes && propStoredQuotes.length > 0) {
      handleSetTable(propStoredQuotes);
      const results = (await QuoteLineClientService.BatchGet(req))!.results;
      setLoadedQuotes(results);
    } else {
      try {
        const storedQuotes: StoredQuote[] = [];

        const results = (await QuoteLineClientService.BatchGet(req))!.results;
        setLoadedQuotes(results);
        for (let i = 0; i < results.length; i++) {
          const ql = results[i];
          const storedQuote = StoredQuote.create();
          storedQuote.id = ql.id;

          storedQuote.description = ql.description;
          storedQuote.price = parseInt(ql.adjustment);
          storedQuotes.push(storedQuote);
        }
        {
          handleSetTable(storedQuotes);
        }
      } catch {
        setLoaded(true);
      }
    }
    setLoaded(true);
  }, [serviceItem.id, propStoredQuotes, handleSetTable]);
  const handleAddEntry = useCallback(
    (entry?: StoredQuote) => {
      setEditing(entry);
    },
    [setEditing],
  );
  const handleDeleteEntry = useCallback(
    (id: number) => handleSetTable(table.filter((item) => item.id !== id)),
    [handleSetTable, table],
  );
  const handleSaveEntry = useCallback(
    (entry: StoredQuote) => {
      entry.price = entry.price || 0;
      handleSetTable(
        table.map((map) => map.id).includes(entry.id)
          ? table.map((item) => (item.id === entry.id ? entry : item))
          : [...table, entry],
      );
      setEditing(undefined);
    },
    [handleSetTable, table],
  );
  const handleQuickAdd = useCallback(
    (entry: StoredQuote) => {
      handleSaveEntry(entry);
    },
    [handleSaveEntry],
  );
  const emailTemplate = `<body>
  <h3>Hello ${form.displayName},</h3>

  <h3>
    You have a pending proposal from Kalos Services for:
  </h3>

  <h3>
    ${property.address}
  </h3>


  <div>

    Please select which services you would like performed, and authorize with your signature.
  </div>
  <div>
  <a href="https://app.kalosflorida.com/index.cfm?action=customer:service.accept_proposal&job_number=${serviceItem.id}&user_id=${customer.id}&property_id=${property.id}&user_name=1">Click here to approve</a>
  </div>
  <br>
  <div>
  If the link above does not work, please copy and paste the following into your address bar:
  </div>
  <br>
  <a href="https://app.kalosflorida.com/index.cfm?action=customer:service.accept_proposal&job_number=${serviceItem.id}&user_id=${customer.id}&property_id=${property.id}&user_name=1">https://app.kalosflorida.com/index.cfm?action=customer:service.accept_proposal&job_number=${serviceItem.id}&user_id=${customer.id}&property_id=${property.id}&user_name=1</a>
  <br>
  <div>
  <br>
  <a href='https://app.kalosflorida.com/index.cfm?action=customer:service.preview_proposal&job_number=${serviceItem.id}&user_id=${customer.id}&property_id=${property.id}&user_name=1'>Dowload PDF here!</a>
  </div>
  <br>
  <div>
    If the above download link does not work, copy and paste the following into your address bar:
  </div>
  <br>
  <a href='https://app.kalosflorida.com/index.cfm?action=customer:service.preview_proposal&job_number=${serviceItem.id}&user_id=${customer.id}&property_id=${property.id}&user_name=1'>https://app.kalosflorida.com/index.cfm?action=customer:service.preview_proposal&job_number=${serviceItem.id}&user_id=${customer.id}&property_id=${property.id}&user_name=1 </a>
</body>
`;

  //Just a function to return a string for the status
  //this just removes the turnary logic that was getting out of hand
  const getStatus = useCallback(
    (id: number) => {
      if (loadedQuotes.length > 0) {
        const quote = loadedQuotes.find((item) => item.id === id);
        if (quote) {
          if (quote.quoteStatus === 1) {
            return 'Quote Accepted';
          } else {
            return 'Quote Not Accepted';
          }
        } else {
          return 'Pending Submit';
        }
      } else {
        return 'Pending Submit';
      }
    },
    [loadedQuotes],
  );
  const handleSetPreview = useCallback(
    (preview: boolean) => () => {
      setPreview(preview);
    },
    [setPreview],
  );

  const handleSaveQuotesOnly = useCallback(async () => {
    //first, let's add or update the quote line records
    setSaving(true);
    for (let i = 0; i < table.length; i++) {
      const item = table[i];
      const found = loadedQuotes.find((loaded) => loaded.id === item.id);
      if (found) {
        //it exists, so update
        const quote = QuoteLine.create();
        quote.id = found.id;
        quote.description = item.description;
        quote.adjustment = item.price.toString();
        quote.fieldMask = ['Description', 'Adjustment'];
        await QuoteLineClientService.Update(quote);
      } else {
        //not found, so create
        const quote = QuoteLine.create();
        quote.description = item.description;
        quote.adjustment = item.price.toString();
        quote.isActive = 1;
        quote.warranty = 2;
        quote.jobNumber = serviceItem.id.toString();
        quote.forUser = customer.id;
        await QuoteLineClientService.Create(quote);
      }
    }
    for (let i = 0; i < loadedQuotes.length; i++) {
      const existingQuote = loadedQuotes[i];
      const found = table.find((item) => item.id === existingQuote.id);
      if (!found) {
        //we deleted this, so we should commit it
        await QuoteLineClientService.Delete(existingQuote);
      }
    }
    setSaving(false);
    if (reload) {
      reload();
    }
    if (propStoredQuotes && handleSetPropStoredQuotes) {
      handleSetPropStoredQuotes([]);
    }
  }, [
    reload,
    propStoredQuotes,
    handleSetPropStoredQuotes,
    table,
    loadedQuotes,
    serviceItem.id,
    customer.id,
  ]);

  const updateDocumentMutation = useDocumentUpdateMutation();

  const handleSendToCustomer = useCallback(async () => {
    //first, let's add or update the quote line records
    setSaving(true);
    for (let i = 0; i < table.length; i++) {
      const item = table[i];
      const found = loadedQuotes.find((loaded) => loaded.id === item.id);
      if (found) {
        //it exists, so update
        const quote = QuoteLine.create();
        quote.id = found.id;
        quote.description = item.description;
        quote.adjustment = item.price.toString();
        quote.fieldMask = ['Description', 'Adjustment'];
        await QuoteLineClientService.Update(quote);
      } else {
        //not found, so create
        const quote = QuoteLine.create();
        quote.description = item.description;
        quote.adjustment = item.price.toString();
        quote.isActive = 1;
        quote.warranty = 2;
        quote.jobNumber = serviceItem.id.toString();
        quote.forUser = customer.id;
        await QuoteLineClientService.Create(quote);
      }
    }
    for (let i = 0; i < loadedQuotes.length; i++) {
      const existingQuote = loadedQuotes[i];
      const found = table.find((item) => item.id === existingQuote.id);
      if (!found) {
        //we deleted this, so we should commit it
        await QuoteLineClientService.Delete(existingQuote);
      }
    }
    const fileCheckReq = FileType.create();
    const documentCheckReq = Document.create();

    const fullFileName = `${file.fileDescription}.pdf`;
    fileCheckReq.name = fullFileName;
    documentCheckReq.filename = fullFileName;

    let fileCheckRes = FileType.create();
    let documentCheckRes = Document.create();

    try {
      const fileResponse = await FileClientService.Get(fileCheckReq);
      if (fileResponse !== undefined) {
        fileCheckRes = fileResponse;
      }
    } catch (err) {
      console.log('file not found, create new records', err);
    }

    try {
      const documentResponse = await DocumentClientService.Get(documentCheckReq);
      if (documentResponse !== undefined) {
        documentCheckRes = documentResponse;
      }
    } catch (err) {
      console.log('document not found, create new records', err);
    }
    //create file and document if neither exist
    if (fileCheckRes.id == 0 && documentCheckRes.id === 0) {
      const mime = 'application/pdf';
      const fileReq = FileType.create();
      fileReq.name = fullFileName;

      const document = Document.create();

      document.filename = fullFileName;
      document.description = file.fileDescription;
      document.type = 5;
      document.propertyId = property.id;
      document.userId = customer.id;

      fileReq.bucket = bucket;
      fileReq.mimeType = mime;
      const fileRes = await FileClientService.Create(fileReq);
      document.fileId = fileRes!.id;

      await DocumentClientService.Create(document);
    }
    //if file exist, but not document, create document record
    if (documentCheckRes.id == 0 && fileCheckRes.id != 0) {
      const document = Document.create();
      document.filename = fullFileName;
      document.description = file.fileDescription;
      document.propertyId = property.id;
      document.type = 5;
      document.userId = customer.id;
      document.fileId = fileCheckRes.id;
      await DocumentClientService.Create(document);
    }
    //if document and file exist, just update to be safe
    if (documentCheckRes.id != 0 && fileCheckRes.id != 0) {
      const document = Document.create();
      document.fileId = fileCheckRes.id;
      document.fieldMask = ['FileId'];
      await updateDocumentMutation.mutateAsync(document);
    }
    const email = SQSEmail.create();
    email.body = emailTemplate;
    email.subject = `Pending Proposal`;
    email.to = customer.email;
    await EmailClientService.SendSQSMail(email);
    handleToggleGenerateFile();
    setSaving(false);
    if (propStoredQuotes && handleSetPropStoredQuotes) {
      handleSetPropStoredQuotes([]);
    }
    if (reload) {
      reload();
    }
  }, [
    file.fileDescription,
    emailTemplate,
    customer.email,
    customer.id,
    handleToggleGenerateFile,
    propStoredQuotes,
    handleSetPropStoredQuotes,
    reload,
    table,
    loadedQuotes,
    serviceItem.id,
    property.id,
    updateDocumentMutation,
  ]);

  const COLUMNS: Columns = [
    { name: 'Description' },
    {
      name: 'Price',
    },
    {
      name: 'Status',
      actions: [
        {
          label: 'Quick Add',
          compact: true,
          onClick: handleToggleQuickAdd,
        },
        {
          label: 'Add',
          compact: true,
          onClick: () => {
            const newEntry = StoredQuote.create();
            newEntry.id = Math.max(-1, ...table.map((map) => map.id)) + 1;
            newEntry.description = '';
            newEntry.price = 0;
            handleAddEntry(newEntry);
          },
        },
      ],
      fixedActions: true,
    },
  ];
  const SCHEMA: Schema<ProposalsForm> = [
    [
      {
        label: 'Display Name',
        name: 'displayName',
        options: [customerName, customer?.businessname || ''],
      },
      {
        name: 'withJobNotes',
        label: 'With Job Notes',
        type: 'checkbox',
      },
      {
        label: 'Job Notes',
        name: 'notes',
        multiline: true,
        disabled: !form.withJobNotes,
      },
    ],
  ];
  useEffect(() => {
    if (!loaded) {
      load();
    }
  }, [loaded, setLoaded, load]);
  const data: Data = table.map((props: StoredQuote) => {
    return [
      { value: props.description },
      {
        value: `$ ${props.price}`,
      },
      {
        value: getStatus(props.id),
        actions: [
          <Tooltip key="edit" content="Edit" placement="top">
            <IconButton
              key={1}
              style={{ marginLeft: 4 }}
              size="small"
              onClick={() => handleAddEntry(props)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip key="delete" content="Delete" placement="top">
            <IconButton
              key={2}
              style={{ marginLeft: 4 }}
              size="small"
              onClick={() => handleDeleteEntry(props.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>,
        ],
      },
    ];
  });
  const dataPreview: Data = table.map((props) => [
    { value: props.description },
    { value: `$ ${props.price}` },
  ]);
  return (
    <>
      <SectionBar
        className={className}
        asideContent={
          <ProposalPrint
            displayName={form.displayName}
            logJobNumber={serviceItem.logJobNumber}
            property={property}
            downloadPDdfFileName={generateFile ? file.fileDescription : 'Proposal'}
            notes={form.withJobNotes ? form.notes : undefined}
            entries={table.map((props) => ({
              description: props.description,
              price: props.price,
            }))}
            downloadFile={generateFile}
            uploadBucket={bucket}
          />
        }
      />
      <PlainForm
        schema={SCHEMA}
        data={form}
        onChange={(form) => {
          setForm(form);
          onFormChange?.(form);
        }}
      />
      <InfoTable columns={COLUMNS} data={data} />

      <SectionBar
        actions={[
          {
            label: 'Submit And Send To Customer',
            onClick: handleSetPreview(true),
          },
          {
            label: saving ? 'Saving' : 'Save Only',
            disabled: saving,
            onClick: handleSaveQuotesOnly,
          },
        ]}
        fixedActions
      />
      {editing && (
        <Modal open onClose={() => handleAddEntry()} styles={{ width: '50%' }}>
          <Form<StoredQuote>
            title="Edit"
            fullWidth={true}
            schema={SCHEMA_ENTRY}
            onSave={(quote) => {
              handleSaveEntry(quote);
            }}
            data={editing}
            onClose={() => handleAddEntry()}
            submitLabel="Done"
          />
        </Modal>
      )}
      {preview && (
        <Modal open onClose={handleSetPreview(false)}>
          <SectionBar
            loading={saving == true}
            title="Please Review Your Proposal Carefully"
            actions={[
              {
                label: saving ? 'Sending' : 'Send To Customer',
                disabled: saving,
                onClick: handleGenerateProposalAndSendToCustomer,
              },

              {
                label: 'Cancel',
                disabled: saving,
                onClick: handleSetPreview(false),

                variant: 'outlined',
              },
            ]}
          />
          <div className="ProposalInfo">
            Submitting this proposal will send it to the customer.
            <br />
            Only send the proposal if you are certain it is correct.
            <br />
            If you need to make changes, click cancel to go back.
          </div>
          <InfoTable columns={[{ name: 'Display Name' }]} data={[[{ value: form.displayName }]]} />
          {form.notes !== '' && form.withJobNotes == 1 && (
            <InfoTable columns={[{ name: 'Notes' }]} data={[[{ value: form.notes }]]} />
          )}
          <InfoTable columns={[{ name: 'Description' }, { name: 'Price' }]} data={dataPreview} />
          <PlainForm schema={SCHEMA_FILE} data={file} onChange={setFile} />
        </Modal>
      )}
      {/* TODO Converting to object as a temporary thing til I figure out how to convert this */}
      {quickAddOpen && (
        <StoredQuotes open onClose={handleToggleQuickAdd} onSelect={(out) => handleQuickAdd(out)} />
      )}
    </>
  );
};
