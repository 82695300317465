import { Button, toast } from '@kalos/ui';
import { CheckIcon, CopyIcon } from '@radix-ui/react-icons';
import { useState } from 'react';

import { useCopyToClipboard } from '../hooks/useCopyToClipboard';

export const CopyButton = ({ text }: { text: string }) => {
  const [, copy] = useCopyToClipboard();
  const [isSuccess, setIsSuccess] = useState(false);

  const handleCopy = async () => {
    if (await copy(text)) {
      setIsSuccess(true);
      setTimeout(() => setIsSuccess(false), 2000);
    } else {
      toast({ variant: 'destructive', title: 'Failed to copy' });
    }
  };

  return (
    <Button
      onClick={handleCopy}
      variant="secondary"
      size="sm"
      className="flex items-center gap-0.5"
    >
      {isSuccess ? (
        <CheckIcon className="text-green-500" />
      ) : (
        <>
          <div>Copy error message</div> <CopyIcon />
        </>
      )}
    </Button>
  );
};
