import { BaseClient } from '../BaseClient';
import {
  Contract,
  ContractList,
  ContractMaterial,
  ContractMaterialList,
} from '../compiled-protos/event';
import { EventServiceClient as ContractServiceClient } from '../compiled-protos/event.client';

class ContractClient extends BaseClient {
  self: ContractServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ContractServiceClient(this.transport);
  }

  public async Create(req: Contract) {

    let res = Contract.create();
    try {
      res = await this.self.createContract(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }

    return res;
  }

  public async Get(req: Contract) {
    let res = Contract.create();
    try {
      res = await this.self.getContract(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return res;
    }
    return res;
  }

  public async Update(req: Contract) {
    let res = Contract.create();
    try {
      res = await this.self.updateContract(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: Contract) {
    let res = Contract.create();
    try {
      res = await this.self.deleteContract(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Contract) {
    const res = await this.self.batchGetContract(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async GetMaterialsByContractID(req: Contract) {
    let res = ContractMaterialList.create();
    try {
      res = await this.self.getMaterialsByContractID(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export {
  Contract,
  ContractList,
  ContractClient,
  ContractMaterial,
  ContractMaterialList,
};
