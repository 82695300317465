// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "transaction.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "transaction.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class PendingInvoiceTransaction$Type extends MessageType {
    constructor() {
        super("PendingInvoiceTransaction", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "invoice_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "vendor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "is_committed", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "order_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "duplicate_flag", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, invoiceNumber: "", timestamp: "", amount: "", vendorId: 0, departmentId: 0, eventId: 0, isCommitted: 0, isActive: 0, notes: "", orderNumber: "", duplicateFlag: false, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string invoice_number */ 2:
                    message.invoiceNumber = reader.string();
                    break;
                case /* string timestamp */ 3:
                    message.timestamp = reader.string();
                    break;
                case /* string amount */ 4:
                    message.amount = reader.string();
                    break;
                case /* int32 vendor_id */ 5:
                    message.vendorId = reader.int32();
                    break;
                case /* int32 department_id */ 6:
                    message.departmentId = reader.int32();
                    break;
                case /* int32 event_id */ 7:
                    message.eventId = reader.int32();
                    break;
                case /* int32 is_committed */ 8:
                    message.isCommitted = reader.int32();
                    break;
                case /* int32 is_active */ 9:
                    message.isActive = reader.int32();
                    break;
                case /* string notes */ 10:
                    message.notes = reader.string();
                    break;
                case /* string order_number */ 11:
                    message.orderNumber = reader.string();
                    break;
                case /* bool duplicate_flag */ 12:
                    message.duplicateFlag = reader.bool();
                    break;
                case /* repeated string field_mask */ 13:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 14:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 15:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 16:
                    message.orderDir = reader.string();
                    break;
                case /* bool without_limit */ 17:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string invoice_number = 2; */
        if (message.invoiceNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.invoiceNumber);
        /* string timestamp = 3; */
        if (message.timestamp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timestamp);
        /* string amount = 4; */
        if (message.amount !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.amount);
        /* int32 vendor_id = 5; */
        if (message.vendorId !== 0)
            writer.tag(5, WireType.Varint).int32(message.vendorId);
        /* int32 department_id = 6; */
        if (message.departmentId !== 0)
            writer.tag(6, WireType.Varint).int32(message.departmentId);
        /* int32 event_id = 7; */
        if (message.eventId !== 0)
            writer.tag(7, WireType.Varint).int32(message.eventId);
        /* int32 is_committed = 8; */
        if (message.isCommitted !== 0)
            writer.tag(8, WireType.Varint).int32(message.isCommitted);
        /* int32 is_active = 9; */
        if (message.isActive !== 0)
            writer.tag(9, WireType.Varint).int32(message.isActive);
        /* string notes = 10; */
        if (message.notes !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.notes);
        /* string order_number = 11; */
        if (message.orderNumber !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderNumber);
        /* bool duplicate_flag = 12; */
        if (message.duplicateFlag !== false)
            writer.tag(12, WireType.Varint).bool(message.duplicateFlag);
        /* repeated string field_mask = 13; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 14; */
        if (message.pageNumber !== 0)
            writer.tag(14, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 15; */
        if (message.orderBy !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 16; */
        if (message.orderDir !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.orderDir);
        /* bool without_limit = 17; */
        if (message.withoutLimit !== false)
            writer.tag(17, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PendingInvoiceTransaction
 */
export const PendingInvoiceTransaction = new PendingInvoiceTransaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PendingInvoiceTransactionList$Type extends MessageType {
    constructor() {
        super("PendingInvoiceTransactionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PendingInvoiceTransaction },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PendingInvoiceTransaction results */ 1:
                    message.results.push(PendingInvoiceTransaction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PendingInvoiceTransaction results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PendingInvoiceTransaction.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PendingInvoiceTransactionList
 */
export const PendingInvoiceTransactionList = new PendingInvoiceTransactionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Transaction$Type extends MessageType {
    constructor() {
        super("Transaction", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "job_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "vendor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "cost_center_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "owner_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "card_used", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "documents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionDocument },
            { no: 17, name: "activity_log", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionActivity },
            { no: 18, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "department", kind: "message", T: () => TxnDepartment },
            { no: 21, name: "cost_center", kind: "message", T: () => TransactionAccount },
            { no: 22, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "is_audited", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 25, name: "is_recorded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 26, name: "search_phrase", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "artificial_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "department_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "cost_center_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "activity_log_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "department_id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 33, name: "vendor_category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 35, name: "assigned_employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 36, name: "assigned_employee_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 37, name: "order_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "is_billing_recorded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 39, name: "invoice_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 40, name: "state_tax_applied", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 41, name: "vendor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 42, name: "csi_division", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 43, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 44, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 45, name: "include_stored_ocr_for_owner_id", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 46, name: "best_match_transaction_ocr", kind: "message", T: () => TransactionOCR },
            { no: 47, name: "parent_transaction_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 48, name: "include_transaction_line_items", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 49, name: "transaction_line_items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionLineItem },
            { no: 50, name: "id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 51, name: "include_transaction_validation", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 52, name: "transaction_validation", kind: "message", T: () => TransactionValidation },
            { no: 53, name: "pre_validated", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 54, name: "pre_validated_error", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, jobId: 0, departmentId: 0, ownerId: 0, vendor: "", costCenterId: 0, description: "", amount: 0, timestamp: "", notes: "", isActive: 0, statusId: 0, status: "", ownerName: "", cardUsed: "", documents: [], activityLog: [], pageNumber: 0, fieldMask: [], orderBy: "", orderDir: "", isAudited: false, isRecorded: false, searchPhrase: "", artificialId: "", departmentString: "", costCenterString: "", activityLogString: "", notEquals: [], departmentIdList: "", vendorCategory: "", withoutLimit: false, assignedEmployeeId: 0, assignedEmployeeName: "", orderNumber: "", isBillingRecorded: false, invoiceNumber: "", stateTaxApplied: false, vendorId: 0, csiDivision: 0, dateRange: [], dateTarget: [], includeStoredOcrForOwnerId: false, parentTransactionId: 0, includeTransactionLineItems: false, transactionLineItems: [], idList: "", includeTransactionValidation: false, preValidated: false, preValidatedError: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 job_id */ 2:
                    message.jobId = reader.int32();
                    break;
                case /* int32 department_id */ 3:
                    message.departmentId = reader.int32();
                    break;
                case /* int32 owner_id */ 4:
                    message.ownerId = reader.int32();
                    break;
                case /* string vendor */ 5:
                    message.vendor = reader.string();
                    break;
                case /* int32 cost_center_id */ 6:
                    message.costCenterId = reader.int32();
                    break;
                case /* string description */ 7:
                    message.description = reader.string();
                    break;
                case /* double amount */ 8:
                    message.amount = reader.double();
                    break;
                case /* string timestamp */ 9:
                    message.timestamp = reader.string();
                    break;
                case /* string notes */ 10:
                    message.notes = reader.string();
                    break;
                case /* int32 is_active */ 11:
                    message.isActive = reader.int32();
                    break;
                case /* int32 status_id */ 12:
                    message.statusId = reader.int32();
                    break;
                case /* string status */ 13:
                    message.status = reader.string();
                    break;
                case /* string owner_name */ 14:
                    message.ownerName = reader.string();
                    break;
                case /* string card_used */ 15:
                    message.cardUsed = reader.string();
                    break;
                case /* repeated TransactionDocument documents */ 16:
                    message.documents.push(TransactionDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated TransactionActivity activity_log */ 17:
                    message.activityLog.push(TransactionActivity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 page_number */ 18:
                    message.pageNumber = reader.int32();
                    break;
                case /* repeated string field_mask */ 19:
                    message.fieldMask.push(reader.string());
                    break;
                case /* TxnDepartment department */ 20:
                    message.department = TxnDepartment.internalBinaryRead(reader, reader.uint32(), options, message.department);
                    break;
                case /* TransactionAccount cost_center */ 21:
                    message.costCenter = TransactionAccount.internalBinaryRead(reader, reader.uint32(), options, message.costCenter);
                    break;
                case /* string order_by */ 22:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 23:
                    message.orderDir = reader.string();
                    break;
                case /* bool is_audited */ 24:
                    message.isAudited = reader.bool();
                    break;
                case /* bool is_recorded */ 25:
                    message.isRecorded = reader.bool();
                    break;
                case /* string search_phrase */ 26:
                    message.searchPhrase = reader.string();
                    break;
                case /* string artificial_id */ 27:
                    message.artificialId = reader.string();
                    break;
                case /* string department_string */ 28:
                    message.departmentString = reader.string();
                    break;
                case /* string cost_center_string */ 29:
                    message.costCenterString = reader.string();
                    break;
                case /* string activity_log_string */ 30:
                    message.activityLogString = reader.string();
                    break;
                case /* repeated string not_equals */ 31:
                    message.notEquals.push(reader.string());
                    break;
                case /* string department_id_list */ 32:
                    message.departmentIdList = reader.string();
                    break;
                case /* string vendor_category */ 33:
                    message.vendorCategory = reader.string();
                    break;
                case /* bool without_limit */ 34:
                    message.withoutLimit = reader.bool();
                    break;
                case /* int32 assigned_employee_id */ 35:
                    message.assignedEmployeeId = reader.int32();
                    break;
                case /* string assigned_employee_name */ 36:
                    message.assignedEmployeeName = reader.string();
                    break;
                case /* string order_number */ 37:
                    message.orderNumber = reader.string();
                    break;
                case /* bool is_billing_recorded */ 38:
                    message.isBillingRecorded = reader.bool();
                    break;
                case /* string invoice_number */ 39:
                    message.invoiceNumber = reader.string();
                    break;
                case /* bool state_tax_applied */ 40:
                    message.stateTaxApplied = reader.bool();
                    break;
                case /* int32 vendor_id */ 41:
                    message.vendorId = reader.int32();
                    break;
                case /* int32 csi_division */ 42:
                    message.csiDivision = reader.int32();
                    break;
                case /* repeated string date_range */ 43:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 44:
                    message.dateTarget.push(reader.string());
                    break;
                case /* bool include_stored_ocr_for_owner_id */ 45:
                    message.includeStoredOcrForOwnerId = reader.bool();
                    break;
                case /* TransactionOCR best_match_transaction_ocr */ 46:
                    message.bestMatchTransactionOcr = TransactionOCR.internalBinaryRead(reader, reader.uint32(), options, message.bestMatchTransactionOcr);
                    break;
                case /* int32 parent_transaction_id */ 47:
                    message.parentTransactionId = reader.int32();
                    break;
                case /* bool include_transaction_line_items */ 48:
                    message.includeTransactionLineItems = reader.bool();
                    break;
                case /* repeated TransactionLineItem transaction_line_items */ 49:
                    message.transactionLineItems.push(TransactionLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string id_list */ 50:
                    message.idList = reader.string();
                    break;
                case /* bool include_transaction_validation */ 51:
                    message.includeTransactionValidation = reader.bool();
                    break;
                case /* TransactionValidation transaction_validation */ 52:
                    message.transactionValidation = TransactionValidation.internalBinaryRead(reader, reader.uint32(), options, message.transactionValidation);
                    break;
                case /* bool pre_validated */ 53:
                    message.preValidated = reader.bool();
                    break;
                case /* string pre_validated_error */ 54:
                    message.preValidatedError = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 job_id = 2; */
        if (message.jobId !== 0)
            writer.tag(2, WireType.Varint).int32(message.jobId);
        /* int32 department_id = 3; */
        if (message.departmentId !== 0)
            writer.tag(3, WireType.Varint).int32(message.departmentId);
        /* int32 owner_id = 4; */
        if (message.ownerId !== 0)
            writer.tag(4, WireType.Varint).int32(message.ownerId);
        /* string vendor = 5; */
        if (message.vendor !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.vendor);
        /* int32 cost_center_id = 6; */
        if (message.costCenterId !== 0)
            writer.tag(6, WireType.Varint).int32(message.costCenterId);
        /* string description = 7; */
        if (message.description !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.description);
        /* double amount = 8; */
        if (message.amount !== 0)
            writer.tag(8, WireType.Bit64).double(message.amount);
        /* string timestamp = 9; */
        if (message.timestamp !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.timestamp);
        /* string notes = 10; */
        if (message.notes !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.notes);
        /* int32 is_active = 11; */
        if (message.isActive !== 0)
            writer.tag(11, WireType.Varint).int32(message.isActive);
        /* int32 status_id = 12; */
        if (message.statusId !== 0)
            writer.tag(12, WireType.Varint).int32(message.statusId);
        /* string status = 13; */
        if (message.status !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.status);
        /* string owner_name = 14; */
        if (message.ownerName !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.ownerName);
        /* string card_used = 15; */
        if (message.cardUsed !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.cardUsed);
        /* repeated TransactionDocument documents = 16; */
        for (let i = 0; i < message.documents.length; i++)
            TransactionDocument.internalBinaryWrite(message.documents[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* repeated TransactionActivity activity_log = 17; */
        for (let i = 0; i < message.activityLog.length; i++)
            TransactionActivity.internalBinaryWrite(message.activityLog[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* int32 page_number = 18; */
        if (message.pageNumber !== 0)
            writer.tag(18, WireType.Varint).int32(message.pageNumber);
        /* repeated string field_mask = 19; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(19, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* TxnDepartment department = 20; */
        if (message.department)
            TxnDepartment.internalBinaryWrite(message.department, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* TransactionAccount cost_center = 21; */
        if (message.costCenter)
            TransactionAccount.internalBinaryWrite(message.costCenter, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* string order_by = 22; */
        if (message.orderBy !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 23; */
        if (message.orderDir !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.orderDir);
        /* bool is_audited = 24; */
        if (message.isAudited !== false)
            writer.tag(24, WireType.Varint).bool(message.isAudited);
        /* bool is_recorded = 25; */
        if (message.isRecorded !== false)
            writer.tag(25, WireType.Varint).bool(message.isRecorded);
        /* string search_phrase = 26; */
        if (message.searchPhrase !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.searchPhrase);
        /* string artificial_id = 27; */
        if (message.artificialId !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.artificialId);
        /* string department_string = 28; */
        if (message.departmentString !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.departmentString);
        /* string cost_center_string = 29; */
        if (message.costCenterString !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.costCenterString);
        /* string activity_log_string = 30; */
        if (message.activityLogString !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.activityLogString);
        /* repeated string not_equals = 31; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(31, WireType.LengthDelimited).string(message.notEquals[i]);
        /* string department_id_list = 32; */
        if (message.departmentIdList !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.departmentIdList);
        /* string vendor_category = 33; */
        if (message.vendorCategory !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.vendorCategory);
        /* bool without_limit = 34; */
        if (message.withoutLimit !== false)
            writer.tag(34, WireType.Varint).bool(message.withoutLimit);
        /* int32 assigned_employee_id = 35; */
        if (message.assignedEmployeeId !== 0)
            writer.tag(35, WireType.Varint).int32(message.assignedEmployeeId);
        /* string assigned_employee_name = 36; */
        if (message.assignedEmployeeName !== "")
            writer.tag(36, WireType.LengthDelimited).string(message.assignedEmployeeName);
        /* string order_number = 37; */
        if (message.orderNumber !== "")
            writer.tag(37, WireType.LengthDelimited).string(message.orderNumber);
        /* bool is_billing_recorded = 38; */
        if (message.isBillingRecorded !== false)
            writer.tag(38, WireType.Varint).bool(message.isBillingRecorded);
        /* string invoice_number = 39; */
        if (message.invoiceNumber !== "")
            writer.tag(39, WireType.LengthDelimited).string(message.invoiceNumber);
        /* bool state_tax_applied = 40; */
        if (message.stateTaxApplied !== false)
            writer.tag(40, WireType.Varint).bool(message.stateTaxApplied);
        /* int32 vendor_id = 41; */
        if (message.vendorId !== 0)
            writer.tag(41, WireType.Varint).int32(message.vendorId);
        /* int32 csi_division = 42; */
        if (message.csiDivision !== 0)
            writer.tag(42, WireType.Varint).int32(message.csiDivision);
        /* repeated string date_range = 43; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(43, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 44; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(44, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* bool include_stored_ocr_for_owner_id = 45; */
        if (message.includeStoredOcrForOwnerId !== false)
            writer.tag(45, WireType.Varint).bool(message.includeStoredOcrForOwnerId);
        /* TransactionOCR best_match_transaction_ocr = 46; */
        if (message.bestMatchTransactionOcr)
            TransactionOCR.internalBinaryWrite(message.bestMatchTransactionOcr, writer.tag(46, WireType.LengthDelimited).fork(), options).join();
        /* int32 parent_transaction_id = 47; */
        if (message.parentTransactionId !== 0)
            writer.tag(47, WireType.Varint).int32(message.parentTransactionId);
        /* bool include_transaction_line_items = 48; */
        if (message.includeTransactionLineItems !== false)
            writer.tag(48, WireType.Varint).bool(message.includeTransactionLineItems);
        /* repeated TransactionLineItem transaction_line_items = 49; */
        for (let i = 0; i < message.transactionLineItems.length; i++)
            TransactionLineItem.internalBinaryWrite(message.transactionLineItems[i], writer.tag(49, WireType.LengthDelimited).fork(), options).join();
        /* string id_list = 50; */
        if (message.idList !== "")
            writer.tag(50, WireType.LengthDelimited).string(message.idList);
        /* bool include_transaction_validation = 51; */
        if (message.includeTransactionValidation !== false)
            writer.tag(51, WireType.Varint).bool(message.includeTransactionValidation);
        /* TransactionValidation transaction_validation = 52; */
        if (message.transactionValidation)
            TransactionValidation.internalBinaryWrite(message.transactionValidation, writer.tag(52, WireType.LengthDelimited).fork(), options).join();
        /* bool pre_validated = 53; */
        if (message.preValidated !== false)
            writer.tag(53, WireType.Varint).bool(message.preValidated);
        /* string pre_validated_error = 54; */
        if (message.preValidatedError !== "")
            writer.tag(54, WireType.LengthDelimited).string(message.preValidatedError);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Transaction
 */
export const Transaction = new Transaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionList$Type extends MessageType {
    constructor() {
        super("TransactionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Transaction },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Transaction results */ 1:
                    message.results.push(Transaction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Transaction results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Transaction.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionList
 */
export const TransactionList = new TransactionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordPageReq$Type extends MessageType {
    constructor() {
        super("RecordPageReq", [
            { no: 1, name: "transaction_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "request_data", kind: "message", T: () => Transaction },
            { no: 3, name: "admin_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { transactionIds: [], adminId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 transaction_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionIds.push(reader.int32());
                    else
                        message.transactionIds.push(reader.int32());
                    break;
                case /* Transaction request_data */ 2:
                    message.requestData = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.requestData);
                    break;
                case /* int32 admin_id */ 3:
                    message.adminId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated int32 transaction_ids = 1; */
        if (message.transactionIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionIds.length; i++)
                writer.int32(message.transactionIds[i]);
            writer.join();
        }
        /* Transaction request_data = 2; */
        if (message.requestData)
            Transaction.internalBinaryWrite(message.requestData, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 admin_id = 3; */
        if (message.adminId !== 0)
            writer.tag(3, WireType.Varint).int32(message.adminId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RecordPageReq
 */
export const RecordPageReq = new RecordPageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuditPageReq$Type extends MessageType {
    constructor() {
        super("AuditPageReq", [
            { no: 1, name: "transaction_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "request_data", kind: "message", T: () => Transaction },
            { no: 3, name: "admin_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { transactionIds: [], adminId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 transaction_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionIds.push(reader.int32());
                    else
                        message.transactionIds.push(reader.int32());
                    break;
                case /* Transaction request_data */ 2:
                    message.requestData = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.requestData);
                    break;
                case /* int32 admin_id */ 3:
                    message.adminId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated int32 transaction_ids = 1; */
        if (message.transactionIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionIds.length; i++)
                writer.int32(message.transactionIds[i]);
            writer.join();
        }
        /* Transaction request_data = 2; */
        if (message.requestData)
            Transaction.internalBinaryWrite(message.requestData, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 admin_id = 3; */
        if (message.adminId !== 0)
            writer.tag(3, WireType.Varint).int32(message.adminId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AuditPageReq
 */
export const AuditPageReq = new AuditPageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TxnDepartment$Type extends MessageType {
    constructor() {
        super("TxnDepartment", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "manager_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "classification", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, description: "", managerId: 0, classification: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* int32 manager_id */ 3:
                    message.managerId = reader.int32();
                    break;
                case /* string classification */ 4:
                    message.classification = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* int32 manager_id = 3; */
        if (message.managerId !== 0)
            writer.tag(3, WireType.Varint).int32(message.managerId);
        /* string classification = 4; */
        if (message.classification !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.classification);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TxnDepartment
 */
export const TxnDepartment = new TxnDepartment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CSIDivision$Type extends MessageType {
    constructor() {
        super("CSIDivision", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "division_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, divisionName: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", notEquals: [], isActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string division_name */ 2:
                    message.divisionName = reader.string();
                    break;
                case /* repeated string field_mask */ 3:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 4:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 5:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 6:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string not_equals */ 7:
                    message.notEquals.push(reader.string());
                    break;
                case /* bool is_active */ 8:
                    message.isActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string division_name = 2; */
        if (message.divisionName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.divisionName);
        /* repeated string field_mask = 3; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 4; */
        if (message.pageNumber !== 0)
            writer.tag(4, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 5; */
        if (message.orderBy !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 6; */
        if (message.orderDir !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string not_equals = 7; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.notEquals[i]);
        /* bool is_active = 8; */
        if (message.isActive !== false)
            writer.tag(8, WireType.Varint).bool(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CSIDivision
 */
export const CSIDivision = new CSIDivision$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CSIDivisionList$Type extends MessageType {
    constructor() {
        super("CSIDivisionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CSIDivision },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CSIDivision results */ 1:
                    message.results.push(CSIDivision.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated CSIDivision results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CSIDivision.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CSIDivisionList
 */
export const CSIDivisionList = new CSIDivisionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionStatus$Type extends MessageType {
    constructor() {
        super("TransactionStatus", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionStatus
 */
export const TransactionStatus = new TransactionStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionStatusList$Type extends MessageType {
    constructor() {
        super("TransactionStatusList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionStatus }
        ]);
    }
    create(value) {
        const message = { results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionStatus results */ 1:
                    message.results.push(TransactionStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionStatus results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TransactionStatus.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionStatusList
 */
export const TransactionStatusList = new TransactionStatusList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionDocument$Type extends MessageType {
    constructor() {
        super("TransactionDocument", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "transaction_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "reference", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "uploader_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, transactionId: 0, fileId: 0, typeId: 0, description: "", reference: "", uploaderId: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 transaction_id */ 2:
                    message.transactionId = reader.int32();
                    break;
                case /* int32 file_id */ 3:
                    message.fileId = reader.int32();
                    break;
                case /* int32 type_id */ 4:
                    message.typeId = reader.int32();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* string reference */ 6:
                    message.reference = reader.string();
                    break;
                case /* int32 uploader_id */ 7:
                    message.uploaderId = reader.int32();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 transaction_id = 2; */
        if (message.transactionId !== 0)
            writer.tag(2, WireType.Varint).int32(message.transactionId);
        /* int32 file_id = 3; */
        if (message.fileId !== 0)
            writer.tag(3, WireType.Varint).int32(message.fileId);
        /* int32 type_id = 4; */
        if (message.typeId !== 0)
            writer.tag(4, WireType.Varint).int32(message.typeId);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* string reference = 6; */
        if (message.reference !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.reference);
        /* int32 uploader_id = 7; */
        if (message.uploaderId !== 0)
            writer.tag(7, WireType.Varint).int32(message.uploaderId);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionDocument
 */
export const TransactionDocument = new TransactionDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionDocumentList$Type extends MessageType {
    constructor() {
        super("TransactionDocumentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionDocument },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionDocument results */ 1:
                    message.results.push(TransactionDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionDocument results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TransactionDocument.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionDocumentList
 */
export const TransactionDocumentList = new TransactionDocumentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionActivity$Type extends MessageType {
    constructor() {
        super("TransactionActivity", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "transaction_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, transactionId: 0, userId: 0, statusId: 0, description: "", timestamp: "", isActive: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 transaction_id */ 2:
                    message.transactionId = reader.int32();
                    break;
                case /* int32 user_id */ 3:
                    message.userId = reader.int32();
                    break;
                case /* int32 status_id */ 4:
                    message.statusId = reader.int32();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* string timestamp */ 6:
                    message.timestamp = reader.string();
                    break;
                case /* int32 is_active */ 7:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 transaction_id = 2; */
        if (message.transactionId !== 0)
            writer.tag(2, WireType.Varint).int32(message.transactionId);
        /* int32 user_id = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        /* int32 status_id = 4; */
        if (message.statusId !== 0)
            writer.tag(4, WireType.Varint).int32(message.statusId);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* string timestamp = 6; */
        if (message.timestamp !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timestamp);
        /* int32 is_active = 7; */
        if (message.isActive !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionActivity
 */
export const TransactionActivity = new TransactionActivity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionActivityList$Type extends MessageType {
    constructor() {
        super("TransactionActivityList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionActivity },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionActivity results */ 1:
                    message.results.push(TransactionActivity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionActivity results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TransactionActivity.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionActivityList
 */
export const TransactionActivityList = new TransactionActivityList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MergeTransactionIds$Type extends MessageType {
    constructor() {
        super("MergeTransactionIds", [
            { no: 1, name: "mergedTransaction", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "transactionIdList", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { mergedTransaction: 0, transactionIdList: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 mergedTransaction */ 1:
                    message.mergedTransaction = reader.int32();
                    break;
                case /* repeated int32 transactionIdList */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.transactionIdList.push(reader.int32());
                    else
                        message.transactionIdList.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 mergedTransaction = 1; */
        if (message.mergedTransaction !== 0)
            writer.tag(1, WireType.Varint).int32(message.mergedTransaction);
        /* repeated int32 transactionIdList = 2; */
        if (message.transactionIdList.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.transactionIdList.length; i++)
                writer.int32(message.transactionIdList[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MergeTransactionIds
 */
export const MergeTransactionIds = new MergeTransactionIds$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccount$Type extends MessageType {
    constructor() {
        super("TransactionAccount", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "account_category", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "threshold_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "needs_po", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "popularity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "short_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "admin_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, accountCategory: 0, thresholdAmount: 0, description: "", needsPo: 0, isActive: 0, popularity: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", shortDescription: "", adminOnly: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 account_category */ 2:
                    message.accountCategory = reader.int32();
                    break;
                case /* double threshold_amount */ 3:
                    message.thresholdAmount = reader.double();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* int32 needs_po */ 5:
                    message.needsPo = reader.int32();
                    break;
                case /* int32 is_active */ 6:
                    message.isActive = reader.int32();
                    break;
                case /* int32 popularity */ 7:
                    message.popularity = reader.int32();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 10:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 11:
                    message.orderDir = reader.string();
                    break;
                case /* string short_description */ 12:
                    message.shortDescription = reader.string();
                    break;
                case /* bool admin_only */ 13:
                    message.adminOnly = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 account_category = 2; */
        if (message.accountCategory !== 0)
            writer.tag(2, WireType.Varint).int32(message.accountCategory);
        /* double threshold_amount = 3; */
        if (message.thresholdAmount !== 0)
            writer.tag(3, WireType.Bit64).double(message.thresholdAmount);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* int32 needs_po = 5; */
        if (message.needsPo !== 0)
            writer.tag(5, WireType.Varint).int32(message.needsPo);
        /* int32 is_active = 6; */
        if (message.isActive !== 0)
            writer.tag(6, WireType.Varint).int32(message.isActive);
        /* int32 popularity = 7; */
        if (message.popularity !== 0)
            writer.tag(7, WireType.Varint).int32(message.popularity);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 10; */
        if (message.orderBy !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 11; */
        if (message.orderDir !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderDir);
        /* string short_description = 12; */
        if (message.shortDescription !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.shortDescription);
        /* bool admin_only = 13; */
        if (message.adminOnly !== false)
            writer.tag(13, WireType.Varint).bool(message.adminOnly);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccount
 */
export const TransactionAccount = new TransactionAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccountList$Type extends MessageType {
    constructor() {
        super("TransactionAccountList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionAccount },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionAccount results */ 1:
                    message.results.push(TransactionAccount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionAccount results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TransactionAccount.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccountList
 */
export const TransactionAccountList = new TransactionAccountList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Block$Type extends MessageType {
    constructor() {
        super("Block", [
            { no: 1, name: "ExpenseDocuments", kind: "scalar", jsonName: "ExpenseDocuments", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "FileName", kind: "scalar", jsonName: "FileName", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Label", kind: "scalar", jsonName: "Label", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "LabelConfidence", kind: "scalar", jsonName: "LabelConfidence", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "Type", kind: "scalar", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "Value", kind: "scalar", jsonName: "Value", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "ValueConfidence", kind: "scalar", jsonName: "ValueConfidence", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { expenseDocuments: "", fileName: "", label: "", labelConfidence: "", type: "", value: "", valueConfidence: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ExpenseDocuments = 1 [json_name = "ExpenseDocuments"];*/ 1:
                    message.expenseDocuments = reader.string();
                    break;
                case /* string FileName = 2 [json_name = "FileName"];*/ 2:
                    message.fileName = reader.string();
                    break;
                case /* string Label = 3 [json_name = "Label"];*/ 3:
                    message.label = reader.string();
                    break;
                case /* string LabelConfidence = 4 [json_name = "LabelConfidence"];*/ 4:
                    message.labelConfidence = reader.string();
                    break;
                case /* string Type = 5 [json_name = "Type"];*/ 5:
                    message.type = reader.string();
                    break;
                case /* string Value = 6 [json_name = "Value"];*/ 6:
                    message.value = reader.string();
                    break;
                case /* string ValueConfidence = 7 [json_name = "ValueConfidence"];*/ 7:
                    message.valueConfidence = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string ExpenseDocuments = 1 [json_name = "ExpenseDocuments"]; */
        if (message.expenseDocuments !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.expenseDocuments);
        /* string FileName = 2 [json_name = "FileName"]; */
        if (message.fileName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.fileName);
        /* string Label = 3 [json_name = "Label"]; */
        if (message.label !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.label);
        /* string LabelConfidence = 4 [json_name = "LabelConfidence"]; */
        if (message.labelConfidence !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.labelConfidence);
        /* string Type = 5 [json_name = "Type"]; */
        if (message.type !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.type);
        /* string Value = 6 [json_name = "Value"]; */
        if (message.value !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.value);
        /* string ValueConfidence = 7 [json_name = "ValueConfidence"]; */
        if (message.valueConfidence !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.valueConfidence);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Block
 */
export const Block = new Block$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AIIntValue$Type extends MessageType {
    constructor() {
        super("AIIntValue", [
            { no: 1, name: "Confidence", kind: "scalar", jsonName: "Confidence", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Value", kind: "scalar", jsonName: "Value", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "Changed", kind: "scalar", jsonName: "Changed", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { confidence: "", value: 0, changed: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Confidence = 1 [json_name = "Confidence"];*/ 1:
                    message.confidence = reader.string();
                    break;
                case /* int32 Value = 2 [json_name = "Value"];*/ 2:
                    message.value = reader.int32();
                    break;
                case /* bool Changed = 3 [json_name = "Changed"];*/ 3:
                    message.changed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string Confidence = 1 [json_name = "Confidence"]; */
        if (message.confidence !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.confidence);
        /* int32 Value = 2 [json_name = "Value"]; */
        if (message.value !== 0)
            writer.tag(2, WireType.Varint).int32(message.value);
        /* bool Changed = 3 [json_name = "Changed"]; */
        if (message.changed !== false)
            writer.tag(3, WireType.Varint).bool(message.changed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AIIntValue
 */
export const AIIntValue = new AIIntValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AIStringValue$Type extends MessageType {
    constructor() {
        super("AIStringValue", [
            { no: 1, name: "Confidence", kind: "scalar", jsonName: "Confidence", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Value", kind: "scalar", jsonName: "Value", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Changed", kind: "scalar", jsonName: "Changed", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { confidence: "", value: "", changed: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Confidence = 1 [json_name = "Confidence"];*/ 1:
                    message.confidence = reader.string();
                    break;
                case /* string Value = 2 [json_name = "Value"];*/ 2:
                    message.value = reader.string();
                    break;
                case /* bool Changed = 3 [json_name = "Changed"];*/ 3:
                    message.changed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string Confidence = 1 [json_name = "Confidence"]; */
        if (message.confidence !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.confidence);
        /* string Value = 2 [json_name = "Value"]; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        /* bool Changed = 3 [json_name = "Changed"]; */
        if (message.changed !== false)
            writer.tag(3, WireType.Varint).bool(message.changed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AIStringValue
 */
export const AIStringValue = new AIStringValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AIDoubleValue$Type extends MessageType {
    constructor() {
        super("AIDoubleValue", [
            { no: 1, name: "Confidence", kind: "scalar", jsonName: "Confidence", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Value", kind: "scalar", jsonName: "Value", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "Changed", kind: "scalar", jsonName: "Changed", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { confidence: "", value: 0, changed: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Confidence = 1 [json_name = "Confidence"];*/ 1:
                    message.confidence = reader.string();
                    break;
                case /* double Value = 2 [json_name = "Value"];*/ 2:
                    message.value = reader.double();
                    break;
                case /* bool Changed = 3 [json_name = "Changed"];*/ 3:
                    message.changed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string Confidence = 1 [json_name = "Confidence"]; */
        if (message.confidence !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.confidence);
        /* double Value = 2 [json_name = "Value"]; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit64).double(message.value);
        /* bool Changed = 3 [json_name = "Changed"]; */
        if (message.changed !== false)
            writer.tag(3, WireType.Varint).bool(message.changed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AIDoubleValue
 */
export const AIDoubleValue = new AIDoubleValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LineItem$Type extends MessageType {
    constructor() {
        super("LineItem", [
            { no: 1, name: "Description", kind: "scalar", jsonName: "Description", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Item", kind: "scalar", jsonName: "Item", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Price", kind: "scalar", jsonName: "Price", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "Quantity", kind: "scalar", jsonName: "Quantity", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { description: "", item: "", price: 0, quantity: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Description = 1 [json_name = "Description"];*/ 1:
                    message.description = reader.string();
                    break;
                case /* string Item = 2 [json_name = "Item"];*/ 2:
                    message.item = reader.string();
                    break;
                case /* double Price = 3 [json_name = "Price"];*/ 3:
                    message.price = reader.double();
                    break;
                case /* string Quantity = 4 [json_name = "Quantity"];*/ 4:
                    message.quantity = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string Description = 1 [json_name = "Description"]; */
        if (message.description !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.description);
        /* string Item = 2 [json_name = "Item"]; */
        if (message.item !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.item);
        /* double Price = 3 [json_name = "Price"]; */
        if (message.price !== 0)
            writer.tag(3, WireType.Bit64).double(message.price);
        /* string Quantity = 4 [json_name = "Quantity"]; */
        if (message.quantity !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.quantity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LineItem
 */
export const LineItem = new LineItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LineItemDocument$Type extends MessageType {
    constructor() {
        super("LineItemDocument", [
            { no: 1, name: "ExpenseRow", kind: "message", jsonName: "ExpenseRow", T: () => AIStringValue },
            { no: 2, name: "Item", kind: "message", jsonName: "Item", T: () => AIStringValue },
            { no: 3, name: "Price", kind: "message", jsonName: "Price", T: () => AIStringValue },
            { no: 4, name: "ProductCode", kind: "message", jsonName: "ProductCode", T: () => AIStringValue },
            { no: 5, name: "Quantity", kind: "message", jsonName: "Quantity", T: () => AIStringValue },
            { no: 6, name: "UnitPrice", kind: "message", jsonName: "UnitPrice", T: () => AIStringValue }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* AIStringValue ExpenseRow = 1 [json_name = "ExpenseRow"];*/ 1:
                    message.expenseRow = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.expenseRow);
                    break;
                case /* AIStringValue Item = 2 [json_name = "Item"];*/ 2:
                    message.item = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                case /* AIStringValue Price = 3 [json_name = "Price"];*/ 3:
                    message.price = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.price);
                    break;
                case /* AIStringValue ProductCode = 4 [json_name = "ProductCode"];*/ 4:
                    message.productCode = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.productCode);
                    break;
                case /* AIStringValue Quantity = 5 [json_name = "Quantity"];*/ 5:
                    message.quantity = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.quantity);
                    break;
                case /* AIStringValue UnitPrice = 6 [json_name = "UnitPrice"];*/ 6:
                    message.unitPrice = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.unitPrice);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* AIStringValue ExpenseRow = 1 [json_name = "ExpenseRow"]; */
        if (message.expenseRow)
            AIStringValue.internalBinaryWrite(message.expenseRow, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* AIStringValue Item = 2 [json_name = "Item"]; */
        if (message.item)
            AIStringValue.internalBinaryWrite(message.item, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* AIStringValue Price = 3 [json_name = "Price"]; */
        if (message.price)
            AIStringValue.internalBinaryWrite(message.price, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* AIStringValue ProductCode = 4 [json_name = "ProductCode"]; */
        if (message.productCode)
            AIStringValue.internalBinaryWrite(message.productCode, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* AIStringValue Quantity = 5 [json_name = "Quantity"]; */
        if (message.quantity)
            AIStringValue.internalBinaryWrite(message.quantity, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* AIStringValue UnitPrice = 6 [json_name = "UnitPrice"]; */
        if (message.unitPrice)
            AIStringValue.internalBinaryWrite(message.unitPrice, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LineItemDocument
 */
export const LineItemDocument = new LineItemDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Receiver$Type extends MessageType {
    constructor() {
        super("Receiver", [
            { no: 1, name: "ReceiverAddress", kind: "message", jsonName: "ReceiverAddress", T: () => AIStringValue },
            { no: 2, name: "ReceiverName", kind: "message", jsonName: "ReceiverName", T: () => AIStringValue }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* AIStringValue ReceiverAddress = 1 [json_name = "ReceiverAddress"];*/ 1:
                    message.receiverAddress = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.receiverAddress);
                    break;
                case /* AIStringValue ReceiverName = 2 [json_name = "ReceiverName"];*/ 2:
                    message.receiverName = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.receiverName);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* AIStringValue ReceiverAddress = 1 [json_name = "ReceiverAddress"]; */
        if (message.receiverAddress)
            AIStringValue.internalBinaryWrite(message.receiverAddress, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* AIStringValue ReceiverName = 2 [json_name = "ReceiverName"]; */
        if (message.receiverName)
            AIStringValue.internalBinaryWrite(message.receiverName, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Receiver
 */
export const Receiver = new Receiver$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Reference$Type extends MessageType {
    constructor() {
        super("Reference", [
            { no: 1, name: "InvoiceReceiptID", kind: "message", jsonName: "InvoiceReceiptID", T: () => AIStringValue }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* AIStringValue InvoiceReceiptID = 1 [json_name = "InvoiceReceiptID"];*/ 1:
                    message.invoiceReceiptID = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.invoiceReceiptID);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* AIStringValue InvoiceReceiptID = 1 [json_name = "InvoiceReceiptID"]; */
        if (message.invoiceReceiptID)
            AIStringValue.internalBinaryWrite(message.invoiceReceiptID, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Reference
 */
export const Reference = new Reference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Total$Type extends MessageType {
    constructor() {
        super("Total", [
            { no: 1, name: "Total", kind: "message", jsonName: "Total", T: () => AIStringValue }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* AIStringValue Total = 1 [json_name = "Total"];*/ 1:
                    message.total = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* AIStringValue Total = 1 [json_name = "Total"]; */
        if (message.total)
            AIStringValue.internalBinaryWrite(message.total, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Total
 */
export const Total = new Total$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionDate$Type extends MessageType {
    constructor() {
        super("TransactionDate", [
            { no: 1, name: "InvoiceReceiptDate", kind: "message", jsonName: "InvoiceReceiptDate", T: () => AIStringValue }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* AIStringValue InvoiceReceiptDate = 1 [json_name = "InvoiceReceiptDate"];*/ 1:
                    message.invoiceReceiptDate = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.invoiceReceiptDate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* AIStringValue InvoiceReceiptDate = 1 [json_name = "InvoiceReceiptDate"]; */
        if (message.invoiceReceiptDate)
            AIStringValue.internalBinaryWrite(message.invoiceReceiptDate, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionDate
 */
export const TransactionDate = new TransactionDate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VectorMatches$Type extends MessageType {
    constructor() {
        super("VectorMatches", [
            { no: 1, name: "FileName", kind: "scalar", jsonName: "FileName", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Score", kind: "scalar", jsonName: "Score", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { fileName: "", score: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string FileName = 1 [json_name = "FileName"];*/ 1:
                    message.fileName = reader.string();
                    break;
                case /* string Score = 2 [json_name = "Score"];*/ 2:
                    message.score = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string FileName = 1 [json_name = "FileName"]; */
        if (message.fileName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fileName);
        /* string Score = 2 [json_name = "Score"]; */
        if (message.score !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.score);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VectorMatches
 */
export const VectorMatches = new VectorMatches$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileMatches$Type extends MessageType {
    constructor() {
        super("FileMatches", [
            { no: 1, name: "FileName", kind: "scalar", jsonName: "FileName", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Comment", kind: "scalar", jsonName: "Comment", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Certainty", kind: "scalar", jsonName: "Certainty", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { fileName: "", comment: "", certainty: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string FileName = 1 [json_name = "FileName"];*/ 1:
                    message.fileName = reader.string();
                    break;
                case /* string Comment = 2 [json_name = "Comment"];*/ 2:
                    message.comment = reader.string();
                    break;
                case /* int32 Certainty = 3 [json_name = "Certainty"];*/ 3:
                    message.certainty = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string FileName = 1 [json_name = "FileName"]; */
        if (message.fileName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fileName);
        /* string Comment = 2 [json_name = "Comment"]; */
        if (message.comment !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.comment);
        /* int32 Certainty = 3 [json_name = "Certainty"]; */
        if (message.certainty !== 0)
            writer.tag(3, WireType.Varint).int32(message.certainty);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FileMatches
 */
export const FileMatches = new FileMatches$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OCRVendor$Type extends MessageType {
    constructor() {
        super("OCRVendor", [
            { no: 1, name: "VendorAddress", kind: "message", jsonName: "VendorAddress", T: () => AIStringValue },
            { no: 2, name: "VendorName", kind: "message", jsonName: "VendorName", T: () => AIStringValue },
            { no: 3, name: "VendorPhone", kind: "message", jsonName: "VendorPhone", T: () => AIStringValue }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* AIStringValue VendorAddress = 1 [json_name = "VendorAddress"];*/ 1:
                    message.vendorAddress = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.vendorAddress);
                    break;
                case /* AIStringValue VendorName = 2 [json_name = "VendorName"];*/ 2:
                    message.vendorName = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.vendorName);
                    break;
                case /* AIStringValue VendorPhone = 3 [json_name = "VendorPhone"];*/ 3:
                    message.vendorPhone = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.vendorPhone);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* AIStringValue VendorAddress = 1 [json_name = "VendorAddress"]; */
        if (message.vendorAddress)
            AIStringValue.internalBinaryWrite(message.vendorAddress, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* AIStringValue VendorName = 2 [json_name = "VendorName"]; */
        if (message.vendorName)
            AIStringValue.internalBinaryWrite(message.vendorName, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* AIStringValue VendorPhone = 3 [json_name = "VendorPhone"]; */
        if (message.vendorPhone)
            AIStringValue.internalBinaryWrite(message.vendorPhone, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OCRVendor
 */
export const OCRVendor = new OCRVendor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AITransactionData$Type extends MessageType {
    constructor() {
        super("AITransactionData", [
            { no: 1, name: "Receiver", kind: "message", jsonName: "Receiver", T: () => Receiver },
            { no: 2, name: "Reference", kind: "message", jsonName: "Reference", T: () => Reference },
            { no: 3, name: "Total", kind: "message", jsonName: "Total", T: () => Total },
            { no: 4, name: "TransactionDate", kind: "message", jsonName: "TransactionDate", T: () => TransactionDate },
            { no: 5, name: "Vendor", kind: "message", jsonName: "Vendor", T: () => OCRVendor },
            { no: 6, name: "CostCenter", kind: "message", jsonName: "CostCenter", T: () => AIIntValue },
            { no: 7, name: "DocumentType", kind: "message", jsonName: "DocumentType", T: () => AIStringValue },
            { no: 8, name: "Filename", kind: "scalar", jsonName: "Filename", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { filename: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Receiver Receiver = 1 [json_name = "Receiver"];*/ 1:
                    message.receiver = Receiver.internalBinaryRead(reader, reader.uint32(), options, message.receiver);
                    break;
                case /* Reference Reference = 2 [json_name = "Reference"];*/ 2:
                    message.reference = Reference.internalBinaryRead(reader, reader.uint32(), options, message.reference);
                    break;
                case /* Total Total = 3 [json_name = "Total"];*/ 3:
                    message.total = Total.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* TransactionDate TransactionDate = 4 [json_name = "TransactionDate"];*/ 4:
                    message.transactionDate = TransactionDate.internalBinaryRead(reader, reader.uint32(), options, message.transactionDate);
                    break;
                case /* OCRVendor Vendor = 5 [json_name = "Vendor"];*/ 5:
                    message.vendor = OCRVendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* AIIntValue CostCenter = 6 [json_name = "CostCenter"];*/ 6:
                    message.costCenter = AIIntValue.internalBinaryRead(reader, reader.uint32(), options, message.costCenter);
                    break;
                case /* AIStringValue DocumentType = 7 [json_name = "DocumentType"];*/ 7:
                    message.documentType = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.documentType);
                    break;
                case /* string Filename = 8 [json_name = "Filename"];*/ 8:
                    message.filename = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* Receiver Receiver = 1 [json_name = "Receiver"]; */
        if (message.receiver)
            Receiver.internalBinaryWrite(message.receiver, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Reference Reference = 2 [json_name = "Reference"]; */
        if (message.reference)
            Reference.internalBinaryWrite(message.reference, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Total Total = 3 [json_name = "Total"]; */
        if (message.total)
            Total.internalBinaryWrite(message.total, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* TransactionDate TransactionDate = 4 [json_name = "TransactionDate"]; */
        if (message.transactionDate)
            TransactionDate.internalBinaryWrite(message.transactionDate, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* OCRVendor Vendor = 5 [json_name = "Vendor"]; */
        if (message.vendor)
            OCRVendor.internalBinaryWrite(message.vendor, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* AIIntValue CostCenter = 6 [json_name = "CostCenter"]; */
        if (message.costCenter)
            AIIntValue.internalBinaryWrite(message.costCenter, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* AIStringValue DocumentType = 7 [json_name = "DocumentType"]; */
        if (message.documentType)
            AIStringValue.internalBinaryWrite(message.documentType, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string Filename = 8 [json_name = "Filename"]; */
        if (message.filename !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.filename);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AITransactionData
 */
export const AITransactionData = new AITransactionData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionMatchingData$Type extends MessageType {
    constructor() {
        super("TransactionMatchingData", [
            { no: 1, name: "LineItems", kind: "message", jsonName: "LineItems", repeat: 1 /*RepeatType.PACKED*/, T: () => LineItem },
            { no: 2, name: "PONumber", kind: "message", jsonName: "PONumber", T: () => AIStringValue },
            { no: 3, name: "ReferenceNumber", kind: "message", jsonName: "ReferenceNumber", T: () => AIStringValue },
            { no: 4, name: "TransactionDate", kind: "scalar", jsonName: "TransactionDate", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "VendorAddress", kind: "message", jsonName: "VendorAddress", T: () => AIStringValue },
            { no: 6, name: "VectorMatches", kind: "message", jsonName: "VectorMatches", repeat: 1 /*RepeatType.PACKED*/, T: () => VectorMatches },
            { no: 7, name: "FileMatches", kind: "message", jsonName: "FileMatches", repeat: 1 /*RepeatType.PACKED*/, T: () => FileMatches }
        ]);
    }
    create(value) {
        const message = { lineItems: [], transactionDate: "", vectorMatches: [], fileMatches: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated LineItem LineItems = 1 [json_name = "LineItems"];*/ 1:
                    message.lineItems.push(LineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* AIStringValue PONumber = 2 [json_name = "PONumber"];*/ 2:
                    message.pONumber = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.pONumber);
                    break;
                case /* AIStringValue ReferenceNumber = 3 [json_name = "ReferenceNumber"];*/ 3:
                    message.referenceNumber = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.referenceNumber);
                    break;
                case /* string TransactionDate = 4 [json_name = "TransactionDate"];*/ 4:
                    message.transactionDate = reader.string();
                    break;
                case /* AIStringValue VendorAddress = 5 [json_name = "VendorAddress"];*/ 5:
                    message.vendorAddress = AIStringValue.internalBinaryRead(reader, reader.uint32(), options, message.vendorAddress);
                    break;
                case /* repeated VectorMatches VectorMatches = 6 [json_name = "VectorMatches"];*/ 6:
                    message.vectorMatches.push(VectorMatches.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated FileMatches FileMatches = 7 [json_name = "FileMatches"];*/ 7:
                    message.fileMatches.push(FileMatches.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated LineItem LineItems = 1 [json_name = "LineItems"]; */
        for (let i = 0; i < message.lineItems.length; i++)
            LineItem.internalBinaryWrite(message.lineItems[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* AIStringValue PONumber = 2 [json_name = "PONumber"]; */
        if (message.pONumber)
            AIStringValue.internalBinaryWrite(message.pONumber, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* AIStringValue ReferenceNumber = 3 [json_name = "ReferenceNumber"]; */
        if (message.referenceNumber)
            AIStringValue.internalBinaryWrite(message.referenceNumber, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string TransactionDate = 4 [json_name = "TransactionDate"]; */
        if (message.transactionDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.transactionDate);
        /* AIStringValue VendorAddress = 5 [json_name = "VendorAddress"]; */
        if (message.vendorAddress)
            AIStringValue.internalBinaryWrite(message.vendorAddress, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated VectorMatches VectorMatches = 6 [json_name = "VectorMatches"]; */
        for (let i = 0; i < message.vectorMatches.length; i++)
            VectorMatches.internalBinaryWrite(message.vectorMatches[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated FileMatches FileMatches = 7 [json_name = "FileMatches"]; */
        for (let i = 0; i < message.fileMatches.length; i++)
            FileMatches.internalBinaryWrite(message.fileMatches[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionMatchingData
 */
export const TransactionMatchingData = new TransactionMatchingData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DocumentResponse$Type extends MessageType {
    constructor() {
        super("DocumentResponse", [
            { no: 1, name: "Blocks", kind: "message", jsonName: "Blocks", repeat: 1 /*RepeatType.PACKED*/, T: () => Block },
            { no: 2, name: "Data", kind: "message", jsonName: "Data", T: () => AITransactionData },
            { no: 3, name: "MatchingData", kind: "message", jsonName: "MatchingData", T: () => TransactionMatchingData },
            { no: 4, name: "LineItems", kind: "message", jsonName: "LineItems", repeat: 1 /*RepeatType.PACKED*/, T: () => LineItemDocument }
        ]);
    }
    create(value) {
        const message = { blocks: [], lineItems: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Block Blocks = 1 [json_name = "Blocks"];*/ 1:
                    message.blocks.push(Block.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* AITransactionData Data = 2 [json_name = "Data"];*/ 2:
                    message.data = AITransactionData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* TransactionMatchingData MatchingData = 3 [json_name = "MatchingData"];*/ 3:
                    message.matchingData = TransactionMatchingData.internalBinaryRead(reader, reader.uint32(), options, message.matchingData);
                    break;
                case /* repeated LineItemDocument LineItems = 4 [json_name = "LineItems"];*/ 4:
                    message.lineItems.push(LineItemDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Block Blocks = 1 [json_name = "Blocks"]; */
        for (let i = 0; i < message.blocks.length; i++)
            Block.internalBinaryWrite(message.blocks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* AITransactionData Data = 2 [json_name = "Data"]; */
        if (message.data)
            AITransactionData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* TransactionMatchingData MatchingData = 3 [json_name = "MatchingData"]; */
        if (message.matchingData)
            TransactionMatchingData.internalBinaryWrite(message.matchingData, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated LineItemDocument LineItems = 4 [json_name = "LineItems"]; */
        for (let i = 0; i < message.lineItems.length; i++)
            LineItemDocument.internalBinaryWrite(message.lineItems[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DocumentResponse
 */
export const DocumentResponse = new DocumentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JSONTransactionOCR$Type extends MessageType {
    constructor() {
        super("JSONTransactionOCR", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "blocks", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "ocr_transaction_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "ocr_matching_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "corrected_transaction_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "transaction_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "line_items", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "corrected_line_items", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "owner_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, ownerId: 0, blocks: "", ocrTransactionData: "", ocrMatchingData: "", correctedTransactionData: "", fieldMask: [], fileName: "", transactionId: 0, dateCreated: "", lineItems: "", correctedLineItems: "", ownerNotes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 owner_id */ 2:
                    message.ownerId = reader.int32();
                    break;
                case /* string blocks */ 3:
                    message.blocks = reader.string();
                    break;
                case /* string ocr_transaction_data */ 4:
                    message.ocrTransactionData = reader.string();
                    break;
                case /* string ocr_matching_data */ 5:
                    message.ocrMatchingData = reader.string();
                    break;
                case /* string corrected_transaction_data */ 6:
                    message.correctedTransactionData = reader.string();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string file_name */ 8:
                    message.fileName = reader.string();
                    break;
                case /* int32 transaction_id */ 9:
                    message.transactionId = reader.int32();
                    break;
                case /* string date_created */ 10:
                    message.dateCreated = reader.string();
                    break;
                case /* string line_items */ 11:
                    message.lineItems = reader.string();
                    break;
                case /* string corrected_line_items */ 12:
                    message.correctedLineItems = reader.string();
                    break;
                case /* string owner_notes */ 13:
                    message.ownerNotes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 owner_id = 2; */
        if (message.ownerId !== 0)
            writer.tag(2, WireType.Varint).int32(message.ownerId);
        /* string blocks = 3; */
        if (message.blocks !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.blocks);
        /* string ocr_transaction_data = 4; */
        if (message.ocrTransactionData !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.ocrTransactionData);
        /* string ocr_matching_data = 5; */
        if (message.ocrMatchingData !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.ocrMatchingData);
        /* string corrected_transaction_data = 6; */
        if (message.correctedTransactionData !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.correctedTransactionData);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string file_name = 8; */
        if (message.fileName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.fileName);
        /* int32 transaction_id = 9; */
        if (message.transactionId !== 0)
            writer.tag(9, WireType.Varint).int32(message.transactionId);
        /* string date_created = 10; */
        if (message.dateCreated !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.dateCreated);
        /* string line_items = 11; */
        if (message.lineItems !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.lineItems);
        /* string corrected_line_items = 12; */
        if (message.correctedLineItems !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.correctedLineItems);
        /* string owner_notes = 13; */
        if (message.ownerNotes !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.ownerNotes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JSONTransactionOCR
 */
export const JSONTransactionOCR = new JSONTransactionOCR$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionOCR$Type extends MessageType {
    constructor() {
        super("TransactionOCR", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "blocks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Block },
            { no: 4, name: "ocr_transaction_data", kind: "message", T: () => AITransactionData },
            { no: 5, name: "ocr_matching_data", kind: "message", T: () => TransactionMatchingData },
            { no: 6, name: "corrected_transaction_data", kind: "message", T: () => AITransactionData },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "transaction_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "line_items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LineItemDocument },
            { no: 11, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "corrected_line_items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LineItemDocument },
            { no: 13, name: "owner_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, ownerId: 0, blocks: [], fieldMask: [], fileName: "", transactionId: 0, lineItems: [], dateCreated: "", correctedLineItems: [], ownerNotes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 owner_id */ 2:
                    message.ownerId = reader.int32();
                    break;
                case /* repeated Block blocks */ 3:
                    message.blocks.push(Block.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* AITransactionData ocr_transaction_data */ 4:
                    message.ocrTransactionData = AITransactionData.internalBinaryRead(reader, reader.uint32(), options, message.ocrTransactionData);
                    break;
                case /* TransactionMatchingData ocr_matching_data */ 5:
                    message.ocrMatchingData = TransactionMatchingData.internalBinaryRead(reader, reader.uint32(), options, message.ocrMatchingData);
                    break;
                case /* AITransactionData corrected_transaction_data */ 6:
                    message.correctedTransactionData = AITransactionData.internalBinaryRead(reader, reader.uint32(), options, message.correctedTransactionData);
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string file_name */ 8:
                    message.fileName = reader.string();
                    break;
                case /* int32 transaction_id */ 9:
                    message.transactionId = reader.int32();
                    break;
                case /* repeated LineItemDocument line_items */ 10:
                    message.lineItems.push(LineItemDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string date_created */ 11:
                    message.dateCreated = reader.string();
                    break;
                case /* repeated LineItemDocument corrected_line_items */ 12:
                    message.correctedLineItems.push(LineItemDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string owner_notes */ 13:
                    message.ownerNotes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 owner_id = 2; */
        if (message.ownerId !== 0)
            writer.tag(2, WireType.Varint).int32(message.ownerId);
        /* repeated Block blocks = 3; */
        for (let i = 0; i < message.blocks.length; i++)
            Block.internalBinaryWrite(message.blocks[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* AITransactionData ocr_transaction_data = 4; */
        if (message.ocrTransactionData)
            AITransactionData.internalBinaryWrite(message.ocrTransactionData, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* TransactionMatchingData ocr_matching_data = 5; */
        if (message.ocrMatchingData)
            TransactionMatchingData.internalBinaryWrite(message.ocrMatchingData, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* AITransactionData corrected_transaction_data = 6; */
        if (message.correctedTransactionData)
            AITransactionData.internalBinaryWrite(message.correctedTransactionData, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string file_name = 8; */
        if (message.fileName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.fileName);
        /* int32 transaction_id = 9; */
        if (message.transactionId !== 0)
            writer.tag(9, WireType.Varint).int32(message.transactionId);
        /* repeated LineItemDocument line_items = 10; */
        for (let i = 0; i < message.lineItems.length; i++)
            LineItemDocument.internalBinaryWrite(message.lineItems[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string date_created = 11; */
        if (message.dateCreated !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.dateCreated);
        /* repeated LineItemDocument corrected_line_items = 12; */
        for (let i = 0; i < message.correctedLineItems.length; i++)
            LineItemDocument.internalBinaryWrite(message.correctedLineItems[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string owner_notes = 13; */
        if (message.ownerNotes !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.ownerNotes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionOCR
 */
export const TransactionOCR = new TransactionOCR$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionOCRList$Type extends MessageType {
    constructor() {
        super("TransactionOCRList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionOCR }
        ]);
    }
    create(value) {
        const message = { results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionOCR results */ 1:
                    message.results.push(TransactionOCR.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionOCR results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TransactionOCR.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionOCRList
 */
export const TransactionOCRList = new TransactionOCRList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CorrectedTransactionData$Type extends MessageType {
    constructor() {
        super("CorrectedTransactionData", [
            { no: 1, name: "transaction_data", kind: "message", T: () => DocumentResponse },
            { no: 2, name: "old_file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { oldFileName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* DocumentResponse transaction_data */ 1:
                    message.transactionData = DocumentResponse.internalBinaryRead(reader, reader.uint32(), options, message.transactionData);
                    break;
                case /* string old_file_name */ 2:
                    message.oldFileName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* DocumentResponse transaction_data = 1; */
        if (message.transactionData)
            DocumentResponse.internalBinaryWrite(message.transactionData, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string old_file_name = 2; */
        if (message.oldFileName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.oldFileName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CorrectedTransactionData
 */
export const CorrectedTransactionData = new CorrectedTransactionData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostRequestToOCRReq$Type extends MessageType {
    constructor() {
        super("PostRequestToOCRReq", [
            { no: 1, name: "filename", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "document_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "owner_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { filename: "", ownerId: 0, documentType: "", ownerNotes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string filename */ 1:
                    message.filename = reader.string();
                    break;
                case /* int32 owner_id */ 2:
                    message.ownerId = reader.int32();
                    break;
                case /* string document_type */ 3:
                    message.documentType = reader.string();
                    break;
                case /* string owner_notes */ 4:
                    message.ownerNotes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string filename = 1; */
        if (message.filename !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.filename);
        /* int32 owner_id = 2; */
        if (message.ownerId !== 0)
            writer.tag(2, WireType.Varint).int32(message.ownerId);
        /* string document_type = 3; */
        if (message.documentType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.documentType);
        /* string owner_notes = 4; */
        if (message.ownerNotes !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.ownerNotes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PostRequestToOCRReq
 */
export const PostRequestToOCRReq = new PostRequestToOCRReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionLineItem$Type extends MessageType {
    constructor() {
        super("TransactionLineItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "transaction_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "cost_center_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "job_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "parent_transaction_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "secondary_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 17, name: "secondary_amount_note", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, transactionId: 0, description: "", costCenterId: 0, amount: 0, jobId: 0, departmentId: 0, ownerId: 0, isActive: 0, fieldMask: [], withoutLimit: false, pageNumber: 0, orderBy: "", orderDir: "", parentTransactionId: 0, secondaryAmount: 0, secondaryAmountNote: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 transaction_id */ 2:
                    message.transactionId = reader.int32();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* int32 cost_center_id */ 4:
                    message.costCenterId = reader.int32();
                    break;
                case /* double amount */ 5:
                    message.amount = reader.double();
                    break;
                case /* int32 job_id */ 6:
                    message.jobId = reader.int32();
                    break;
                case /* int32 department_id */ 7:
                    message.departmentId = reader.int32();
                    break;
                case /* int32 owner_id */ 8:
                    message.ownerId = reader.int32();
                    break;
                case /* int32 is_active */ 9:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* bool without_limit */ 11:
                    message.withoutLimit = reader.bool();
                    break;
                case /* int32 page_number */ 12:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 13:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 14:
                    message.orderDir = reader.string();
                    break;
                case /* int32 parent_transaction_id */ 15:
                    message.parentTransactionId = reader.int32();
                    break;
                case /* double secondary_amount */ 16:
                    message.secondaryAmount = reader.double();
                    break;
                case /* string secondary_amount_note */ 17:
                    message.secondaryAmountNote = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 transaction_id = 2; */
        if (message.transactionId !== 0)
            writer.tag(2, WireType.Varint).int32(message.transactionId);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* int32 cost_center_id = 4; */
        if (message.costCenterId !== 0)
            writer.tag(4, WireType.Varint).int32(message.costCenterId);
        /* double amount = 5; */
        if (message.amount !== 0)
            writer.tag(5, WireType.Bit64).double(message.amount);
        /* int32 job_id = 6; */
        if (message.jobId !== 0)
            writer.tag(6, WireType.Varint).int32(message.jobId);
        /* int32 department_id = 7; */
        if (message.departmentId !== 0)
            writer.tag(7, WireType.Varint).int32(message.departmentId);
        /* int32 owner_id = 8; */
        if (message.ownerId !== 0)
            writer.tag(8, WireType.Varint).int32(message.ownerId);
        /* int32 is_active = 9; */
        if (message.isActive !== 0)
            writer.tag(9, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* bool without_limit = 11; */
        if (message.withoutLimit !== false)
            writer.tag(11, WireType.Varint).bool(message.withoutLimit);
        /* int32 page_number = 12; */
        if (message.pageNumber !== 0)
            writer.tag(12, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 13; */
        if (message.orderBy !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 14; */
        if (message.orderDir !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.orderDir);
        /* int32 parent_transaction_id = 15; */
        if (message.parentTransactionId !== 0)
            writer.tag(15, WireType.Varint).int32(message.parentTransactionId);
        /* double secondary_amount = 16; */
        if (message.secondaryAmount !== 0)
            writer.tag(16, WireType.Bit64).double(message.secondaryAmount);
        /* string secondary_amount_note = 17; */
        if (message.secondaryAmountNote !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.secondaryAmountNote);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionLineItem
 */
export const TransactionLineItem = new TransactionLineItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionLineItemList$Type extends MessageType {
    constructor() {
        super("TransactionLineItemList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionLineItem },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionLineItem results */ 1:
                    message.results.push(TransactionLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionLineItem results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TransactionLineItem.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionLineItemList
 */
export const TransactionLineItemList = new TransactionLineItemList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchCreateRequest$Type extends MessageType {
    constructor() {
        super("BatchCreateRequest", [
            { no: 1, name: "transactions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Transaction }
        ]);
    }
    create(value) {
        const message = { transactions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Transaction transactions */ 1:
                    message.transactions.push(Transaction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Transaction transactions = 1; */
        for (let i = 0; i < message.transactions.length; i++)
            Transaction.internalBinaryWrite(message.transactions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BatchCreateRequest
 */
export const BatchCreateRequest = new BatchCreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchCreateResponse$Type extends MessageType {
    constructor() {
        super("BatchCreateResponse", [
            { no: 1, name: "failed_transactions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { failedTransactions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string failed_transactions */ 1:
                    message.failedTransactions.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string failed_transactions = 1; */
        for (let i = 0; i < message.failedTransactions.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.failedTransactions[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BatchCreateResponse
 */
export const BatchCreateResponse = new BatchCreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionValidation$Type extends MessageType {
    constructor() {
        super("TransactionValidation", [
            { no: 1, name: "date_matched", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "amount_matched", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "cost_center_matched", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { dateMatched: false, amountMatched: false, costCenterMatched: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool date_matched */ 1:
                    message.dateMatched = reader.bool();
                    break;
                case /* bool amount_matched */ 2:
                    message.amountMatched = reader.bool();
                    break;
                case /* bool cost_center_matched */ 3:
                    message.costCenterMatched = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* bool date_matched = 1; */
        if (message.dateMatched !== false)
            writer.tag(1, WireType.Varint).bool(message.dateMatched);
        /* bool amount_matched = 2; */
        if (message.amountMatched !== false)
            writer.tag(2, WireType.Varint).bool(message.amountMatched);
        /* bool cost_center_matched = 3; */
        if (message.costCenterMatched !== false)
            writer.tag(3, WireType.Varint).bool(message.costCenterMatched);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionValidation
 */
export const TransactionValidation = new TransactionValidation$Type();
/**
 * @generated ServiceType for protobuf service TransactionService
 */
export const TransactionService = new ServiceType("TransactionService", [
    { name: "Create", options: {}, I: Transaction, O: Transaction },
    { name: "Get", options: {}, I: Transaction, O: Transaction },
    { name: "BatchGet", options: {}, I: Transaction, O: TransactionList },
    { name: "Search", options: {}, I: Transaction, O: TransactionList },
    { name: "Update", options: {}, I: Transaction, O: Transaction },
    { name: "Delete", options: {}, I: Transaction, O: Transaction },
    { name: "RecordPage", options: {}, I: RecordPageReq, O: TransactionList },
    { name: "AuditPage", options: {}, I: AuditPageReq, O: TransactionList },
    { name: "BatchCreate", options: {}, I: BatchCreateRequest, O: BatchCreateResponse },
    { name: "CreateCSIDivision", options: {}, I: CSIDivision, O: CSIDivision },
    { name: "GetCSIDivision", options: {}, I: CSIDivision, O: CSIDivision },
    { name: "BatchGetCSIDivision", options: {}, I: CSIDivision, O: CSIDivisionList },
    { name: "UpdateCSIDivision", options: {}, I: CSIDivision, O: CSIDivision },
    { name: "DeleteCSIDivision", options: {}, I: CSIDivision, O: CSIDivision },
    { name: "GetTransactionStatus", options: {}, I: TransactionStatus, O: TransactionStatus },
    { name: "BatchGetTransactionStatus", options: {}, I: TransactionStatus, O: TransactionStatusList },
    { name: "CreateTransactionDocument", options: {}, I: TransactionDocument, O: TransactionDocument },
    { name: "GetTransactionDocument", options: {}, I: TransactionDocument, O: TransactionDocument },
    { name: "BatchGetTransactionDocument", options: {}, I: TransactionDocument, O: TransactionDocumentList },
    { name: "UpdateTransactionDocument", options: {}, I: TransactionDocument, O: TransactionDocument },
    { name: "DeleteTransactionDocument", options: {}, I: TransactionDocument, O: TransactionDocument },
    { name: "CreateTransactionActivity", options: {}, I: TransactionActivity, O: TransactionActivity },
    { name: "GetTransactionActivity", options: {}, I: TransactionActivity, O: TransactionActivity },
    { name: "BatchGetTransactionActivity", options: {}, I: TransactionActivity, O: TransactionActivityList },
    { name: "UpdateTransactionActivity", options: {}, I: TransactionActivity, O: TransactionActivity },
    { name: "DeleteTransactionActivity", options: {}, I: TransactionActivity, O: TransactionActivity },
    { name: "MergeTransactionActivityLogs", options: {}, I: MergeTransactionIds, O: Empty },
    { name: "CreateTransactionAccount", options: {}, I: TransactionAccount, O: TransactionAccount },
    { name: "GetTransactionAccount", options: {}, I: TransactionAccount, O: TransactionAccount },
    { name: "BatchGetTransactionAccount", options: {}, I: TransactionAccount, O: TransactionAccountList },
    { name: "UpdateTransactionAccount", options: {}, I: TransactionAccount, O: TransactionAccount },
    { name: "DeleteTransactionAccount", options: {}, I: TransactionAccount, O: TransactionAccount },
    { name: "CreatePendingInvoiceTransaction", options: {}, I: PendingInvoiceTransaction, O: PendingInvoiceTransaction },
    { name: "GetPendingInvoiceTransaction", options: {}, I: PendingInvoiceTransaction, O: PendingInvoiceTransaction },
    { name: "BatchGetPendingInvoiceTransaction", options: {}, I: PendingInvoiceTransaction, O: PendingInvoiceTransactionList },
    { name: "UpdatePendingInvoiceTransaction", options: {}, I: PendingInvoiceTransaction, O: PendingInvoiceTransaction },
    { name: "DeletePendingInvoiceTransaction", options: {}, I: PendingInvoiceTransaction, O: PendingInvoiceTransaction },
    { name: "PostRequestToOCR", options: {}, I: PostRequestToOCRReq, O: DocumentResponse },
    { name: "GetTransactionOCR", options: {}, I: JSONTransactionOCR, O: TransactionOCR },
    { name: "UpdateTransactionOCR", options: {}, I: TransactionOCR, O: TransactionOCR },
    { name: "OcrCreateOrUpdateSimple", options: {}, I: PostRequestToOCRReq, O: Empty },
    { name: "BatchGetTransactionOCR", options: {}, I: JSONTransactionOCR, O: TransactionOCRList },
    { name: "CreateLineItem", options: {}, I: TransactionLineItem, O: TransactionLineItem },
    { name: "GetLineItem", options: {}, I: TransactionLineItem, O: TransactionLineItem },
    { name: "BatchGetLineItem", options: {}, I: TransactionLineItem, O: TransactionLineItemList },
    { name: "UpdateLineItem", options: {}, I: TransactionLineItem, O: TransactionLineItem },
    { name: "DeleteLineItem", options: {}, I: TransactionLineItem, O: TransactionLineItem }
]);
