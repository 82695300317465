import { SpiffToolAdminAction } from '@kalos/kalos-rpc';

import { timestamp } from '../../../tools/helpers';

export const getStatusFormInit = (status: number, reviewedBy?: string) => {
  const entry = SpiffToolAdminAction.create();
  entry.decisionDate = timestamp(true);
  entry.status = status;
  if (reviewedBy) {
    entry.reviewedBy = reviewedBy;
  }
  return entry;
};
