'use client';

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';
import { useEffect } from 'react';

import { Button } from '../../components/button';
import { cn } from '../../utils';
export function DataTablePagination({
  currentPage,
  pageCount,
  disabled,
  className,
  setPage,
}: {
  currentPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageCount: number;
  disabled?: boolean;
  className?: string;
}) {
  const hasPreviousPage = currentPage > 0;
  const hasNextPage = currentPage < pageCount - 1;

  useEffect(() => {
    // generic fix for https://github.com/kalos-software/kalos-frontend/issues/3409 and other such cases
    if (currentPage >= pageCount && hasPreviousPage) {
      setPage(pageCount - 1);
    }
  }, [currentPage, hasPreviousPage, pageCount, setPage]);

  return (
    <div className={cn(className, 'flex items-center justify-between px-2')}>
      {pageCount ? (
        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
          {pageCount === 0 ? '' : `Page ${currentPage + 1} of ${pageCount}`}
        </div>
      ) : (
        ''
      )}
      <div className="flex items-center space-x-2">
        <Button
          variant="outline"
          className="hidden h-8 w-8 p-0 disabled:cursor-not-allowed lg:flex"
          onClick={() => setPage(0)}
          disabled={!hasPreviousPage || disabled}
        >
          <span className="sr-only">Go to first page</span>
          <DoubleArrowLeftIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className="h-8 w-8 p-0 disabled:cursor-not-allowed"
          onClick={() => setPage((page) => page - 1)}
          disabled={!hasPreviousPage || disabled}
        >
          <span className="sr-only">Go to previous page</span>
          <ChevronLeftIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className="h-8 w-8 p-0 disabled:cursor-not-allowed"
          onClick={() => setPage((page) => page + 1)}
          disabled={!hasNextPage || disabled}
        >
          <span className="sr-only">Go to next page</span>
          <ChevronRightIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className="hidden h-8 w-8 p-0 disabled:cursor-not-allowed lg:flex"
          onClick={() => setPage(pageCount - 1)}
          disabled={!hasNextPage || disabled}
        >
          <span className="sr-only">Go to last page</span>
          <DoubleArrowRightIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
}
