import { type Event, SpiffOption } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTitle, DialogTrigger, toast } from '@kalos/ui';
import { useCallback, useMemo } from 'react';
import { useStore } from 'zustand';

import { useSpiffOptionCreateMutation } from '../../../../../hooks/react-query/useTaskClientServiceQuery';
import { createSpiffDialogStore } from './CreateSpiffOptionDialog.store';
import { SpiffOptionForm } from './SpiffOptionForm';

export const CreateSpiffOptionDialog = ({
  eventId,
  trigger,
}: {
  trigger: React.ReactNode;
  eventId: Event['id'];
}) => {
  const { open, setOpen } = useStore(createSpiffDialogStore);

  const defaultFormValues = useMemo<React.ComponentProps<typeof SpiffOptionForm>['defaultValues']>(
    () => ({
      amount: 0,
      code: '',
      eventId,
      name: '',
    }),
    [eventId],
  );

  const createSpiffOptionMutation = useSpiffOptionCreateMutation();

  const onSubmit = useCallback<React.ComponentProps<typeof SpiffOptionForm>['onSubmit']>(
    async ({ data }) => {
      try {
        await createSpiffOptionMutation.mutateAsync(SpiffOption.create(data));
        toast({
          variant: 'success',
          title: 'Success',
          description: 'Piece Work Created',
        });
        setOpen(false);
      } catch (error) {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Something went wrong during creation',
        });
        console.error(error);
      }
    },
    [createSpiffOptionMutation, setOpen],
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="@container">
        <DialogTitle>Create Piece Work</DialogTitle>
        <SpiffOptionForm
          loading={createSpiffOptionMutation.isPending}
          disabled={createSpiffOptionMutation.isPending}
          onSubmit={onSubmit}
          disableJobIdPicker
          defaultValues={defaultFormValues}
        />
      </DialogContent>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
    </Dialog>
  );
};
