import './StoredQuotes.module.less';

import { StoredQuote, StoredQuoteClient } from '@kalos/kalos-rpc';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { default as IconButton } from '@mui/material/IconButton';
import { type FC, useCallback, useEffect, useState } from 'react';

import { ENDPOINT } from '../../../constants';
import { makeFakeRows, usd } from '../../../tools/helpers';
import { ConfirmDelete } from '../ConfirmDelete';
import { Form, type Schema } from '../Form';
import { type Data, InfoTable } from '../InfoTable';
import { Modal } from '../Modal';
import { SectionBar } from '../SectionBar';

const StoredQuoteClientService = new StoredQuoteClient(ENDPOINT);

interface Props {
  label?: string;
  open: boolean;
  onClose: () => void;
  onSelect: (storedQuote: StoredQuote) => void;
}

const SCHEMA: Schema<StoredQuote> = [
  [
    {
      label: 'Description',
      name: 'description',
      multiline: true,
      required: true,
      validationOnSave(value) {
        if (value.length > 10000) return 'The description cannot exceed 10000 characters';
        if (value.length === 0) return 'The description cannot be empty';
        return '';
      },
    },
  ],
  [
    {
      label: 'Price',
      name: 'price',
      type: 'number',
      startAdornment: '$',
      required: true,
      validationOnSave(value) {
        if (Number(value) < 0) {
          return "This field can't be negative";
        }
        return '';
      },
    },
  ],
  [{ name: 'id', type: 'hidden' }],
];

export const StoredQuotes: FC<Props> = ({ label = 'Quick Add', open, onClose, onSelect }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [storedQuotes, setStoredQuotes] = useState<StoredQuote[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [editing, setEditing] = useState<StoredQuote>();
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<StoredQuote>();
  const toggleEdit = useCallback(() => () => setEdit(!edit), [edit, setEdit]);
  const handleClose = useCallback(() => {
    setEdit(false);
    onClose();
  }, [setEdit, onClose]);
  const handleSetEditing = useCallback(
    (editing?: StoredQuote) => () => setEditing(editing),
    [setEditing],
  );
  const handleSetDeleting = useCallback(
    (deleting?: StoredQuote) => () => setDeleting(deleting),
    [setDeleting],
  );
  const load = useCallback(async () => {
    setLoaded(false);
    const storedQuotes = await StoredQuoteClientService.loadStoredQuotes();
    setStoredQuotes(storedQuotes);
    setLoaded(true);
  }, [setStoredQuotes, setLoaded]);
  useEffect(() => {
    if (!loaded) {
      load();
    }
  }, [loaded, load]);
  const handleSelect = useCallback(
    (storedQuote: StoredQuote) => () => {
      onSelect(storedQuote);
      handleClose();
    },
    [onSelect, handleClose],
  );
  const handleSave = useCallback(
    async (safeQuote: StoredQuote) => {
      setSaving(true);
      const isNew = safeQuote.id;
      const req = StoredQuote.create();
      if (isNew) {
        req.id = safeQuote.id;
      }
      req.description = safeQuote.description;
      req.price = safeQuote.price;
      let safeDescription = req.description;

      safeDescription = safeDescription.replace(/\\/g, '');
      safeDescription = safeDescription.replace("'", '');
      safeDescription = safeDescription.replace(',', '');
      req.description = safeDescription;
      req.fieldMask = ['Description', 'Price'];

      if (!isNew) {
        console.log('create quote');
        await StoredQuoteClientService.Create(req);
      } else {
        console.log('update quote');
        StoredQuoteClientService.Update(req);
      }
      await load();
      setSaving(false);
      setEditing(undefined);
    },
    [setSaving, setEditing, load],
  );
  const handleDelete = useCallback(async () => {
    if (deleting) {
      setDeleting(undefined);
      const req = StoredQuote.create();
      req.id = deleting.id;
      await StoredQuoteClientService.Delete(req);
      await load();
    }
  }, [deleting, load]);
  const data: Data = loaded
    ? storedQuotes.map((storedQuote) => {
        const price = (
          <div key={0} className="StoredQuotesPrice">
            {usd(storedQuote.price)}
          </div>
        );

        const actions = edit
          ? [
              <IconButton key={1} size="small" onClick={handleSetEditing(storedQuote)}>
                <EditIcon />
              </IconButton>,
              <IconButton key={2} size="small" onClick={handleSetDeleting(storedQuote)}>
                <DeleteIcon />
              </IconButton>,
            ]
          : [];

        return [
          {
            value: (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: '10px' }}>
                <div>{storedQuote.description}</div>
                <div style={{ display: 'flex' }}></div>
              </div>
            ),
            onClick: edit ? undefined : handleSelect(storedQuote),
          },
          {
            value: (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: '10px' }}>
                <div>{price}</div>
                <div style={{ display: 'flex' }}>{actions}</div>
              </div>
            ),
            onClick: edit ? undefined : handleSelect(storedQuote),
          },
        ];
      })
    : makeFakeRows();

  return (
    <>
      {open && (
        <Modal open onClose={handleClose} fullScreen={true}>
          <SectionBar
            title={label}
            actions={[
              {
                label: 'Create',
                onClick: handleSetEditing(StoredQuote.create()),
                variant: 'outlined',
              },
              { label: 'Edit', onClick: toggleEdit(), variant: 'outlined' },
              { label: 'Close', onClick: handleClose },
            ]}
            fixedActions
          />
          <InfoTable
            className="fixed-actions"
            columns={[{ name: 'Desciption' }, { name: 'Price' }]}
            data={data}
            loading={!loaded}
          />
        </Modal>
      )}
      {editing && (
        <Modal open onClose={handleSetEditing()}>
          <Form
            title={`${editing.id === 0 ? 'Create' : 'Edit'} Quick Add`}
            data={editing}
            onClose={handleSetEditing()}
            onSave={handleSave}
            schema={SCHEMA}
            disabled={saving}
          />
        </Modal>
      )}
      {deleting && (
        <ConfirmDelete
          open
          kind="Quick Add"
          name={deleting.description}
          onClose={handleSetDeleting()}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};
