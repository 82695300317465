import { Document, File } from '@kalos/kalos-rpc';
import { LoadingIcon } from '@kalos/ui';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
} from '@mui/material';
import { type FormEvent, useCallback, useEffect, useState } from 'react';

import { useS3FileUrlQuery } from '../../../hooks/react-query/useS3';
import {
  DocumentClientService,
  FileClientService,
  PDFClientService,
  timestamp,
} from '../../../tools/helpers';
import { Loader } from '../../Loader/main';
import { Alert } from '../Alert';
import { Button } from '../Button';

type Area = {
  l: number;
  w: number;
  h: number;
};
type StringDimension = {
  value1: string;
  value2: string;
};
export interface Props {
  propertyId: number;
  jobNumber: number;
  onCreateDoc?: () => void;
}

type SellSheetType = {
  systemDescription: string;

  AHLocation: string;
  AHDimensions: Area[];

  filterLocation: string;

  voltage: string;
  phaseVoltage: string;

  breakerType: string;
  newDisconnectNeeded: string;
  disconnectType: string;
  disconnectAmps: string;
  breakerDimensions: StringDimension[];

  AHLiftNeeded: string;
  craneNeeded: string;
  craneDistance: string;
  newThermostat: string;
  thermostatType: string;
  dogHouse: string;
  singlePoint: string;
  serviceOutlet: string;
  electricalRun: string;
  curbAdapter: string;
  topCurbLength: string;
  topCurbWidth: string;
  supplyReturn: string;
  supplyReturnMeasurements: string;
  newDrain: string;
  drainSize: string;
};

type Errors = {
  systemDescription?: string;

  AHDimensions?: string;
  phaseVoltage?: string;
  disconnectType?: string;
  disconnectAmps?: string;
  filterLocation?: string;
  breakerType?: string;
  thermostatType?: string;
  craneDistance?: string;
};

const checkFormIsValid = (form: SellSheetType): Errors => {
  const errors: Errors = {};

  if (!form.systemDescription.length) errors.systemDescription = 'This field is required';

  // Checking AHDimensions if dogHouse is 'yes'
  if (form.dogHouse === 'yes') {
    const AHDimensionsErrors = form.AHDimensions.map((dim, index) =>
      dim.l === 0 || dim.w === 0 || dim.h === 0
        ? `All dimensions for A/H Dimension ${index + 1} are required`
        : '',
    ).filter((error) => error.length > 0);

    if (AHDimensionsErrors.length > 0) errors.AHDimensions = AHDimensionsErrors.join('; ');
  }

  // Checking phaseVoltage if voltage is '3 phase'
  if (form.voltage === '3 phase' && !form.phaseVoltage.length) {
    errors.phaseVoltage = 'Phase Voltage is required for 3 phase voltage';
  }

  // Checking disconnectType and disconnectAmps if newDisconnectNeeded is 'Yes'
  if (form.newDisconnectNeeded === 'Yes') {
    if (!form.disconnectType.length) errors.disconnectType = 'Disconnect Type is required';
    if (form.disconnectType === 'Fused' && !form.disconnectAmps.length) {
      errors.disconnectAmps = 'Note Amps is required for Fused Disconnect Type';
    }
  }

  // Add checks for other fields here
  if (form.filterLocation === 'Choose One') errors.filterLocation = 'This field is required';
  if (form.breakerType === 'Choose One') errors.breakerType = 'This field is required';
  if (form.newThermostat === 'Yes' && !form.thermostatType.length)
    errors.thermostatType = 'Thermostat Type is required';
  if (form.craneNeeded === 'Yes' && !form.craneDistance.length)
    errors.craneDistance = 'Crane Distance is required';

  return errors;
};

const convertToBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch image: ${response.statusText}`);
  }
  const blob = await response.blob();
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const RTUSellSheet: React.FC<Props> = ({ propertyId, jobNumber, onCreateDoc }) => {
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const [form, setForm] = useState<SellSheetType>({
    systemDescription: '',

    AHLocation: 'Choose One',

    AHDimensions: [{ l: 0, w: 0, h: 0 }],

    filterLocation: 'Choose One',

    newThermostat: 'No',
    newDisconnectNeeded: 'No',
    AHLiftNeeded: 'No',
    disconnectType: '',
    disconnectAmps: '',
    thermostatType: '',

    voltage: 'single phase',
    phaseVoltage: '',
    breakerType: 'Choose One',
    breakerDimensions: [{ value1: '', value2: '' }],

    craneNeeded: 'No',
    craneDistance: '0',
    dogHouse: 'no',
    singlePoint: 'no',
    serviceOutlet: 'no',
    electricalRun: 'no',
    curbAdapter: 'no',
    topCurbLength: '',
    topCurbWidth: '',
    supplyReturn: 'no',
    supplyReturnMeasurements: '',
    newDrain: 'no',
    drainSize: '',
  });
  const [drawing, setDrawing] = useState({
    line1: 0,
    line2: 0,
    line3: 0,
    line4: 0,
    line5: 0,
    line6: 0,
    line7: 0,
    line8: 0,
    line9: 0,
    line10: 0,
    line11: 0,
    line12: 0,
  });
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});

  const { data: RTU_img, isFetching } = useS3FileUrlQuery({
    filter: {
      bucket: 'testbuckethelios',
      fileName: 'RTU.png',
    },
  });

  useEffect(() => {
    if (RTU_img) {
      convertToBase64(RTU_img)
        .then((base64data) => {
          const base64WithTag = base64data;
          setBase64Image(base64WithTag);
        })
        .catch(console.error);
    }
  }, [RTU_img]);

  const handleSetDrawing = useCallback(
    (key: keyof typeof drawing) => (event: FormEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      if (+target.value >= 0) {
        setDrawing((prev) => ({
          ...prev,
          [key]: +target.value,
        }));
      }
    },
    [],
  );

  const createMyDocument = useCallback(
    async (formData: SellSheetType) => {
      const content = `
    <div style="font-family: Arial, sans-serif; margin: 0 auto; width: 600px;">
      <h1 style="text-align: center;">AC Quote</h1>
      <div style="margin-bottom: 16px;">
          <div style="width: 100%; position: relative;">
            <div style="position: absolute; left: 2%; top: 33.5%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line1}
            </div>
            <div style="position: absolute; left: 2%; top: 58.5%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line2}
            </div>
            <div style="position: absolute; left: 2%; top: 77%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line3}
            </div>
            <div style="position: absolute; left: 25%; top: 77%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line4}
            </div>
            <div style="position: absolute; left: 29.5%; top: 82.5%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line5}
            </div>
            <div style="position: absolute; left: 40.5%; top: 89.5%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line6}
            </div>
            <div style="position: absolute; left: 40.5%; top: 94.5%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line7}
            </div>
            <div style="position: absolute; left: 58%; top: 82.5%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line8}
            </div>
            <div style="position: absolute; right: 0.5%; top: 38%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line9}
            </div>
            <div style="position: absolute; right: 8%; top: 38%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line10}
            </div>
            <div style="position: absolute; right: 13%; top: 43.5%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line11}
            </div>
            <div style="position: absolute; right: 13%; top: 62.5%; height: 4.5%; width: 7%; text-align: center;">
              ${drawing.line12}
            </div>
            <img src="${base64Image}" alt="RTU" style="max-width: 100%; height: auto;" />
          </div>
      </div>
      <table style="width: 100%; border-collapse: collapse;">
        <!-- System Description -->
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">System Description</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.systemDescription}</td>
        </tr>
        ${
          formData.dogHouse === 'yes'
            ? `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">New Doghouse</td>
          <td style="border: 1px solid #000; padding: 10px;">Yes</td>
        </tr>
        ${formData.AHDimensions.map(
          (dim, index) => `
          <tr>
            <td colspan="2" style="border: 1px solid #000; padding: 10px;">
              <h2>AH Dimension ${index + 1}</h2>
              <p>Length: ${dim.l}</p>
              <p>Width: ${dim.w}</p>
              <p>Height: ${dim.h}</p>
            </td>
          </tr>
        `,
        ).join('')}
        `
            : `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">New Doghouse</td>
          <td style="border: 1px solid #000; padding: 10px;">No</td>
        </tr>
        `
        }
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Voltage</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.voltage}</td>
        </tr>
        ${
          formData.voltage === '3 phase'
            ? `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Phase Voltage</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.phaseVoltage}</td>
        </tr>
        `
            : ''
        }
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Single Point Box</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.singlePoint}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Service Outlet</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.serviceOutlet}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Does Electrical Run Through the Bottom of The Curb?</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.electricalRun}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Is there an existing curb adapter?</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.curbAdapter}</td>
        </tr>
        ${
          formData.curbAdapter === 'yes'
            ? `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Top of Curb Measurement</td>
          <td style="border: 1px solid #000; padding: 10px;">Curb Length: ${formData.topCurbLength} Curb Width: ${formData.topCurbWidth}</td>
        </tr>
        `
            : ''
        }
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Have You Taken Supply and Return Measurements?</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.supplyReturn}</td>
        </tr>
        ${
          formData.supplyReturn === 'yes'
            ? `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Supply & Return Measurements</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.supplyReturnMeasurements}</td>
        </tr>
        `
            : ''
        }
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Does it need a new drain?</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.newDrain}</td>
        </tr>
        ${
          formData.newDrain === 'yes'
            ? `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Size of Drain</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.drainSize}</td>
        </tr>
        `
            : ''
        }
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Filter Location</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.filterLocation}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Breaker Type</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.breakerType}</td>
        </tr>
        ${formData.breakerDimensions
          .map(
            (dim, index) => `
        <tr>
          <td colspan="2" style="border: 1px solid #000; padding: 10px;">
            <h2>Breaker Dimension ${index + 1}</h2>
            <p>Length: ${dim.value1}</p>
            <p>Width: ${dim.value2}</p>
          </td>
        </tr>
        `,
          )
          .join('')}
        ${
          formData.newThermostat === 'Yes'
            ? `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">New Thermostat?</td>
          <td style="border: 1px solid #000; padding: 10px;">Yes</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Thermostat Type</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.thermostatType}</td>
        </tr>
        `
            : `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">New Thermostat?</td>
          <td style="border: 1px solid #000; padding: 10px;">No</td>
        </tr>
        `
        }
        ${
          formData.newDisconnectNeeded === 'Yes'
            ? `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">New Disconnect Needed?</td>
          <td style="border: 1px solid #000; padding: 10px;">Yes</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Fused or Non Fused</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.disconnectType}</td>
        </tr>
        ${
          formData.disconnectType === 'Fused'
            ? `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Note Amps</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.disconnectAmps}</td>
        </tr>
        `
            : ''
        }
        `
            : `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">New Disconnect Needed?</td>
          <td style="border: 1px solid #000; padding: 10px;">No</td>
        </tr>
        `
        }
        ${
          formData.craneNeeded === 'Yes'
            ? `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Crane Needed?</td>
          <td style="border: 1px solid #000; padding: 10px;">Yes</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Distance From Roof To Unit</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.craneDistance}</td>
        </tr>
        `
            : `
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Crane Needed?</td>
          <td style="border: 1px solid #000; padding: 10px;">No</td>
        </tr>
        `
        }
      </table>
    </div>
      `;
      const html = `
        <!DOCTYPE html>
        <html>
          <head>
            <title>RTU Sell Sheet</title>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `;
      const url = await PDFClientService.getUploadedHTMLUrlEasyPrint(
        html,
        `RTUSellSheet-${jobNumber}-${timestamp()}.pdf`,
        'kalosdocs-prod',
      );
      return url;
    },
    [base64Image, drawing, jobNumber],
  );

  const uploadDocument = async () => {
    const errors = checkFormIsValid(form);

    setErrors(errors);
    setLoading(true);
    try {
      if (!Object.keys(errors).length) {
        setLoading(true);
        const url = await createMyDocument(form);
        if (url) {
          const fileRes = await FileClientService.Get(File.create({ name: url.key }));
          if (fileRes) {
            await DocumentClientService.Create(
              Document.create({
                fileId: fileRes.id,
                propertyId: propertyId,
                description: fileRes.name,
                filename: fileRes.name,
              }),
            );
          }
          onCreateDoc?.();
          setMessage(`Document Successfully Created`);
        }
      }
    } catch (error) {
      console.error('Failed to create or upload document:', error);
      setLoading(false);
      setMessage(`Error with Generating document: ${error}`);
      return;
    }
    setLoading(false);
  };

  const handleAddAHDimension = () => {
    setForm({
      ...form,
      AHDimensions: [...form.AHDimensions, { l: 0, w: 0, h: 0 }],
    });
  };
  const handleRemoveAHDimension = (index: number) => {
    setForm({
      ...form,
      AHDimensions: form.AHDimensions.filter((_, i) => i !== index),
    });
  };
  const handleAddBreakerDimension = () => {
    setForm({
      ...form,
      breakerDimensions: [...form.breakerDimensions, { value1: '', value2: '' }],
    });
  };
  const handleRemoveBreakerDimension = (index: number) => {
    setForm({
      ...form,
      breakerDimensions: form.breakerDimensions.filter((_, i) => i !== index),
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name as string]: value as string,
    }));
  };

  const handleDimensionChange =
    (index: number, key: keyof Area) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newDimensions = [...form.AHDimensions];
      newDimensions[index][key] = Number(event.target.value);
      setForm({
        ...form,
        AHDimensions: newDimensions,
      });
    };

  const handleBreakerChange =
    (index: number, key: keyof StringDimension) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newBreaker = [...form.breakerDimensions];
      newBreaker[index][key] = String(event.target.value);
      setForm({
        ...form,
        breakerDimensions: newBreaker,
      });
    };

  return (
    <div>
      <form className="space-y-3 pt-4">
        <div className="flex flex-col gap-3">
          <div className="mx-auto mb-3 max-w-[85%]">
            {isFetching ? (
              <LoadingIcon />
            ) : (
              <div className="relative">
                <input
                  value={drawing.line1}
                  type="number"
                  onInput={handleSetDrawing('line1')}
                  className="absolute left-[2%] top-[33.5%] h-[4.5%] w-[7%] border-[1px] border-black"
                />
                <input
                  value={drawing.line2}
                  type="number"
                  onInput={handleSetDrawing('line2')}
                  className="absolute left-[2%] top-[58.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
                />
                <input
                  value={drawing.line3}
                  type="number"
                  onInput={handleSetDrawing('line3')}
                  className="absolute left-[2%] top-[77%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
                />
                <input
                  value={drawing.line4}
                  type="number"
                  onInput={handleSetDrawing('line4')}
                  className="absolute left-[25%] top-[77%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
                />
                <input
                  value={drawing.line5}
                  type="number"
                  onInput={handleSetDrawing('line5')}
                  className="absolute left-[29.5%] top-[82.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
                />
                <input
                  value={drawing.line6}
                  type="number"
                  onInput={handleSetDrawing('line6')}
                  className="absolute left-[40.5%] top-[89.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
                />
                <input
                  value={drawing.line7}
                  type="number"
                  onInput={handleSetDrawing('line7')}
                  className="absolute left-[40.5%] top-[94.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
                />
                <input
                  value={drawing.line8}
                  type="number"
                  onInput={handleSetDrawing('line8')}
                  className="absolute left-[58%] top-[82.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
                />
                <input
                  value={drawing.line9}
                  type="number"
                  onInput={handleSetDrawing('line9')}
                  className="absolute right-[0.5%] top-[38%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
                />
                <input
                  value={drawing.line10}
                  type="number"
                  onInput={handleSetDrawing('line10')}
                  className="absolute right-[8%] top-[38%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
                />
                <input
                  value={drawing.line11}
                  type="number"
                  onInput={handleSetDrawing('line11')}
                  className="absolute right-[13%] top-[43.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
                />
                <input
                  value={drawing.line12}
                  type="number"
                  onInput={handleSetDrawing('line12')}
                  className="absolute right-[13%] top-[62.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
                />
                <img src={RTU_img} alt="" />
              </div>
            )}
          </div>
          <div className="space-y-3">
            <TextField
              label="System Description"
              name="systemDescription"
              value={form.systemDescription}
              onChange={handleInputChange}
              error={!!errors.systemDescription}
            />
            {errors.systemDescription && (
              <p className="pt-1 text-sm text-red-500 ">{errors.systemDescription}</p>
            )}
          </div>
          <div className="space-y-3">
            <InputLabel
              id="doghouse-label"
              className="w-full font-bold underline"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              New Doghouse?
            </InputLabel>
            <Select
              label="New Doghouse?"
              name="dogHouse"
              value={form.dogHouse}
              onChange={handleSelectChange}
              error={!!errors.systemDescription}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
            {form.dogHouse === 'yes' && (
              <div className="ml-12 flex flex-col gap-3">
                <h3 className="font-bold">A/H Area Max Dimensions</h3>
                {form.AHDimensions.map((dim, index) => (
                  <div key={index} className="flex items-center gap-3">
                    <TextField
                      label={
                        form.AHLocation === 'Attic'
                          ? `Opening dimensions? ${index + 1}`
                          : `Unit Dimension ${index + 1} Length`
                      }
                      value={dim.l}
                      onChange={handleDimensionChange(index, 'l')}
                    />
                    <TextField
                      label={
                        form.AHLocation === 'Attic'
                          ? `How far from opening is the AH? ${index + 1}`
                          : `Unit Dimension ${index + 1} Width`
                      }
                      value={dim.w}
                      onChange={handleDimensionChange(index, 'w')}
                    />
                    <TextField
                      label={
                        form.AHLocation === 'Attic'
                          ? `Attic open or tight? ${index + 1}`
                          : `Unit Dimension ${index + 1} Height`
                      }
                      value={dim.h}
                      onChange={handleDimensionChange(index, 'h')}
                    />
                    {form.AHDimensions.length > 1 && (
                      <IconButton onClick={() => handleRemoveAHDimension(index)}>
                        <RemoveIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={handleAddAHDimension}>
                      <AddIcon />
                    </IconButton>
                  </div>
                ))}
              </div>
            )}
            <div className="flex flex-col gap-3">
              <InputLabel
                id="voltage"
                className="w-full font-bold underline"
                style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
              >
                Voltage
              </InputLabel>
              <Select
                label="Voltage"
                className="max-w-40"
                name="voltage"
                value={form.voltage}
                onChange={handleSelectChange}
                error={!!errors.systemDescription}
              >
                <MenuItem value="single phase">single phase</MenuItem>
                <MenuItem value="3 phase">3 phase</MenuItem>
              </Select>
            </div>
            <div className="flex flex-col gap-3">
              {form.voltage === '3 phase' && (
                <div className="ml-12 flex flex-col gap-3">
                  <InputLabel id="phaseVoltage" className="w-full !font-bold">
                    Phase Voltage
                  </InputLabel>
                  <Select
                    label="Phase Voltage"
                    className="max-w-40"
                    name="phaseVoltage"
                    value={form.phaseVoltage}
                    onChange={handleSelectChange}
                    error={!!errors.systemDescription}
                  >
                    <MenuItem value="208v">208v</MenuItem>
                    <MenuItem value="480v">480v</MenuItem>
                  </Select>
                </div>
              )}
              <div className="flex flex-col gap-3">
                <InputLabel
                  id="singlePoint"
                  className="w-full font-bold underline"
                  style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
                >
                  Single Point Box Needed?
                </InputLabel>
                <Select
                  className="max-w-20"
                  label="singlePoint"
                  name="singlePoint"
                  value={form.singlePoint}
                  onChange={handleSelectChange}
                  error={!!errors.systemDescription}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </div>
              <div className="flex flex-col gap-3">
                <InputLabel
                  id="serviceOutlet"
                  className="w-full font-bold underline"
                  style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
                >
                  Is there a service outlet installed?
                </InputLabel>
                <Select
                  className="max-w-20"
                  label="serviceOutlet"
                  name="serviceOutlet"
                  value={form.serviceOutlet}
                  onChange={handleSelectChange}
                  error={!!errors.systemDescription}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </div>
              <div className="flex flex-col gap-3">
                <InputLabel
                  id="electricalRun"
                  className="w-full font-bold underline"
                  style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
                >
                  Does the electrical run through the bottom of the curb?
                </InputLabel>
                <Select
                  label="electricalRun"
                  name="electricalRun"
                  value={form.electricalRun}
                  onChange={handleSelectChange}
                  error={!!errors.systemDescription}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </div>
              <div className="flex flex-col gap-3">
                <InputLabel
                  id="curbAdapter"
                  className="w-full font-bold underline"
                  style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
                >
                  Is there an existing curb adapter?
                </InputLabel>
                <Grid item xs={12} sm={6}>
                  <Select
                    label="curbAdapter"
                    name="curbAdapter"
                    value={form.curbAdapter}
                    onChange={handleSelectChange}
                    error={!!errors.systemDescription}
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                </Grid>
              </div>
              {form.curbAdapter === 'yes' && (
                <div className="ml-12 flex flex-col gap-3">
                  <InputLabel id="curbMeasurement" className="w-full !font-bold">
                    Top of Curb Measurements
                  </InputLabel>
                  <div className="flex gap-3">
                    <TextField
                      label="Curb L"
                      name="topCurbLength"
                      value={form.topCurbLength}
                      onChange={handleInputChange}
                    />
                    <TextField
                      label="Curb W"
                      name="topCurbWidth"
                      value={form.topCurbWidth}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-3">
                <InputLabel
                  id="supplyReturn"
                  className="w-full font-bold underline"
                  style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
                >
                  Have you taken supply and return measurements?
                </InputLabel>
                <Select
                  label="supplyReturn"
                  name="supplyReturn"
                  value={form.supplyReturn}
                  onChange={handleSelectChange}
                  error={!!errors.systemDescription}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </div>
            </div>
            {form.supplyReturn === 'yes' && (
              <div className="ml-12 flex flex-col gap-3">
                <InputLabel id="supplyReturnMeasurements" className="w-full !font-bold">
                  Measurements Taken
                </InputLabel>
                <TextField
                  label="Measurements"
                  name="supplyReturnMeasurements"
                  value={form.supplyReturnMeasurements}
                  onChange={handleInputChange}
                />
              </div>
            )}
            <div className="flex flex-col gap-3">
              <InputLabel
                id="newDrain"
                className="w-full font-bold underline"
                style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
              >
                Does it need a new drain?
              </InputLabel>
              <Select
                label="newDrain"
                name="newDrain"
                value={form.newDrain}
                onChange={handleSelectChange}
                error={!!errors.systemDescription}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </div>
            {form.newDrain === 'yes' && (
              <div className="ml-12 flex flex-col gap-3">
                <InputLabel id="drainMeasurements" className="w-full !font-bold">
                  Size of Drain
                </InputLabel>
                <TextField
                  label="Measurements"
                  name="drainSize"
                  value={form.drainSize}
                  onChange={handleInputChange}
                />
              </div>
            )}

            <InputLabel
              id="systemType-label"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Filter Location
            </InputLabel>

            <Select
              className="min-w-[190px]"
              name="filterLocation"
              value={form.filterLocation}
              onChange={handleSelectChange}
            >
              <MenuItem value="Choose One">Choose One</MenuItem>

              <MenuItem value="In Unit">In Unit</MenuItem>
              <MenuItem value="Filter Back Return Grill">Filter Back Return Grill</MenuItem>
            </Select>

            <InputLabel
              id="breakerType-label"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Breaker Type
            </InputLabel>

            <Select
              className="min-w-[190px]"
              name="breakerType"
              value={form.breakerType}
              onChange={handleSelectChange}
            >
              <MenuItem value="Choose One">Choose One</MenuItem>

              <MenuItem value="Siemens">Siemens</MenuItem>
              <MenuItem value="Square D QO">Square D QO</MenuItem>
              <MenuItem value="Square D Homeline">Square D Homeline</MenuItem>
              <MenuItem value="GE Slimeline">GE Slimeline</MenuItem>
              <MenuItem value="GE regular">GE regular</MenuItem>
              <MenuItem value="Cutler Hammer Black">Cutler Hammer Black</MenuItem>
              <MenuItem value="Cutler Hammer Brown">Cutler Hammer Brown</MenuItem>
              <MenuItem value="WestingHouse">WestingHouse</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>

            <div>
              <InputLabel
                id="breakerSizes-label"
                style={{
                  fontWeight: 'bold',
                  paddingBottom: '10px',
                }}
              >
                Breaker Sizes
              </InputLabel>
              <ul className="flex flex-col gap-3">
                {form.breakerDimensions.map((dim, index) => (
                  <div key={index} className="flex gap-3">
                    <TextField
                      label={`AH/Furnace Breaker ${index + 1} `}
                      value={dim.value1}
                      onChange={handleBreakerChange(index, 'value1')}
                    />
                    <TextField
                      label={`Condenser Breaker ${index + 1}`}
                      value={dim.value2}
                      onChange={handleBreakerChange(index, 'value2')}
                    />
                    {form.breakerDimensions.length > 1 && (
                      <IconButton onClick={() => handleRemoveBreakerDimension(index)}>
                        <RemoveIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={handleAddBreakerDimension}>
                      <AddIcon />
                    </IconButton>
                  </div>
                ))}
              </ul>
            </div>

            <InputLabel
              id="newThermostat-label"
              className="mb-2 font-bold underline"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              New Thermostat?
            </InputLabel>
            <Select name="newThermostat" value={form.newThermostat} onChange={handleSelectChange}>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
            {form.newThermostat === 'Yes' && (
              <div className="ml-12 flex flex-col gap-3">
                <InputLabel id="thermostatType-label" className="mb-2 !font-bold">
                  Thermostat Type
                </InputLabel>
                <Select
                  className="max-w-40"
                  name="thermostatType"
                  value={form.thermostatType}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="Occupied">Occupied</MenuItem>
                  <MenuItem value="Non-occupied">Non-occupied</MenuItem>
                </Select>
              </div>
            )}

            <InputLabel
              id="newDisconnectNeeded-label"
              className="mb-2 font-bold underline"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              New Disconnect Needed?
            </InputLabel>
            <Select
              name="newDisconnectNeeded"
              value={form.newDisconnectNeeded}
              onChange={handleSelectChange}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>

            {form.newDisconnectNeeded === 'Yes' && (
              <div className="ml-12 flex flex-col gap-3">
                <InputLabel id="disconnectType-label" className="!font-bold">
                  Fused or Non Fused
                </InputLabel>
                <Select
                  name="disconnectType"
                  value={form.disconnectType}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="">Choose One</MenuItem>
                  <MenuItem value="Fused">Fused</MenuItem>
                  <MenuItem value="Non Fused">Non Fused</MenuItem>
                </Select>
                {form.disconnectType === 'Fused' && (
                  <div className="ml-12">
                    <TextField
                      label="Note Amps"
                      name="disconnectAmps"
                      value={form.disconnectAmps}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
              </div>
            )}

            <InputLabel
              id="craneNeeded-label"
              className="mb-2 font-bold underline"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Crane Needed?
            </InputLabel>
            <Select
              className="min-w-[70px]"
              name="craneNeeded"
              value={form.craneNeeded}
              onChange={handleSelectChange}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>

            {form.craneNeeded == 'Yes' && (
              <div className="ml-12">
                <InputLabel id="-label" className="mb-3">
                  Distance between Unit and Roof Edge
                </InputLabel>
                <TextField
                  label="Distance"
                  name="craneDistance"
                  value={form.craneDistance}
                  onChange={handleInputChange}
                />
                {errors.systemDescription && (
                  <p className="pt-1 text-sm text-red-500">{errors.systemDescription}</p>
                )}
              </div>
            )}
          </div>
        </div>

        <Button label="Create" onClick={() => uploadDocument()}></Button>
        {message != '' && (
          <Alert
            className="CenteredModal"
            title="Sell Sheet/AC Quote"
            open
            onClose={() => setMessage('')}
          >
            {message}
          </Alert>
        )}
        {loading && <Loader className="SellSheet-Loader" />}
      </form>
    </div>
  );
};
