import { Contract } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  Form,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { MagnifyingGlassIcon, PersonIcon } from '@radix-ui/react-icons';
import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  type OnChangeFn,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME } from '../../../../constants';
import {
  type ContractFilter,
  useContractsBatchGetQuery,
} from '../../../../hooks/react-query/useContractsQuery';
import {
  type ContractsSort,
  formatDate,
  generateDateRangeAndDateTargetFromDates,
} from '../../../../tools/helpers';
import { ExportJSON, type Status } from '../../ExportJSON';
import { useAdvancedSearchPaginationStore } from '../paginationStore';
import ContractsSearchForm from './ContractsSearchForm';
import { type ContractsSearchScheme, useContractsSearchForm } from './utils';

const loadingContracts: Contract[] = Array.from({ length: 5 }, (_) => Contract.create());

export const ContractsSearch = () => {
  const page = useAdvancedSearchPaginationStore((state) => state.page);
  const setPage = useAdvancedSearchPaginationStore((state) => state.setPage);
  const setTotalEntriesCount = useAdvancedSearchPaginationStore(
    (state) => state.setTotalEntriesCount,
  );

  const contractSearchForm = useContractsSearchForm();

  const [contractsFilter, setContractsFilter] = useState<ContractsSearchScheme>(
    contractSearchForm.getValues(),
  );

  useEffect(() => {
    const debouncedUpdate = debounce((data: Partial<ContractsSearchScheme>) => {
      setPage(0);
      setContractsFilter((prev) => ({ ...prev, ...data }));
    }, FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME);
    const updateSubscription = contractSearchForm.watch(debouncedUpdate);
    return () => updateSubscription.unsubscribe();
  }, [setPage, contractSearchForm]);

  const [contractsSort, setContractsSort] = useState<ContractsSort>({
    orderByField: 'number',
    orderBy: 'contract_number',
    orderDir: 'ASC',
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const onSortChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      setSorting((previousState) => {
        const newState = typeof updater === 'function' ? updater(previousState) : updater;
        let newStateId = newState[0].id;
        if (newStateId === 'dateStarted') {
          newStateId = 'date_Started';
        }
        if (newStateId === 'dateEnded') {
          newStateId = 'date_Ended';
        }
        if (newStateId === 'businessName') {
          newStateId = 'business_name';
        }
        if (newStateId === 'lastName') {
          newStateId = 'last_name';
        }
        setContractsSort({
          orderByField: newState[0].id as keyof Contract,
          orderDir: newState[0].desc ? 'DESC' : 'ASC',
          orderBy: `contract_${newStateId}`,
        });
        return newState;
      });
      setPage(0);
    },
    [setPage],
  );

  const contractsFilterCriteria = useMemo<ContractFilter>(() => {
    const { dateRange, dateTarget } = generateDateRangeAndDateTargetFromDates(
      contractsFilter.dateStarted,
      contractsFilter.dateEnded,
      {
        startDateFieldName: 'contract_date_Started',
        endDateFieldName: 'contract_date_Ended',
      },
    );

    return {
      isActive: 1,
      businessName: contractsFilter.businessName ? `%${contractsFilter.businessName}%` : undefined,
      lastName: contractsFilter.lastName ? `%${contractsFilter.lastName}%` : undefined,
      number: contractsFilter.number ? `%${contractsFilter.number}%` : undefined,
      notes: contractsFilter.notes ? `%${contractsFilter.notes}%` : undefined,
      paymentTerms: contractsFilter.paymentTerms ? `%${contractsFilter.paymentTerms}%` : undefined,
      paymentStatus: contractsFilter.paymentStatus ? contractsFilter.paymentStatus : undefined,

      pageNumber: page,

      dateRange,
      dateTarget,

      orderBy: contractsSort.orderBy,
      orderDir: contractsSort.orderDir,
    };
  }, [
    contractsFilter.businessName,
    contractsFilter.dateEnded,
    contractsFilter.dateStarted,
    contractsFilter.lastName,
    contractsFilter.notes,
    contractsFilter.number,
    contractsFilter.paymentStatus,
    contractsFilter.paymentTerms,
    contractsSort.orderBy,
    contractsSort.orderDir,
    page,
  ]);

  const [isJsonDataQueryEnabled, setIsJsonDataQueryEnabled] = useState<boolean>(false);

  const contractsQuery = useContractsBatchGetQuery({
    filter: contractsFilterCriteria,
  });

  const jsonExportDataQuery = useContractsBatchGetQuery({
    filter: useMemo(
      () => ({ ...contractsFilterCriteria, withoutLimit: true, pageNumber: 0 }),
      [contractsFilterCriteria],
    ),
    enabled: isJsonDataQueryEnabled,
    select(data) {
      return data.results.map((contract) => ({
        'Contract #': contract.number,
        'Contract Start Date': formatDate(String(contract.dateStarted)),
        'Expiration Date': formatDate(String(contract.dateEnded)),
        'Contract Business Name': contract.businessName,
        'Contract Last Name': contract.lastName,
      }));
    },
  });

  useEffect(() => {
    if (contractsQuery.isSuccess) {
      setTotalEntriesCount(contractsQuery.data?.totalCount || 0);
    }
  }, [contractsQuery.data?.totalCount, contractsQuery.isSuccess, setTotalEntriesCount]);

  const contractTableColumnsDefs = useMemo<ColumnDef<Contract>[]>(() => {
    const columns: ColumnDef<Contract>[] = [
      {
        accessorKey: 'number',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Contract #" column={column} />
        ),
        cell({ row }) {
          const value = row.getValue('number');
          return <div className="w-32">{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'dateStarted',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Contract Start Date" column={column} />
        ),
        cell({ row }) {
          const value = row.getValue('dateStarted');
          return <div className="w-32">{formatDate(String(value))}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'dateEnded',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Expiration Date" column={column} />
        ),
        cell({ row }) {
          const value = row.getValue('dateEnded');
          return <div className="w-32">{formatDate(String(value))}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'businessName',
        header: ({ column }) => (
          <DataTableColumnHeader
            hideVisibilityToggle
            title="Contract Business Name"
            column={column}
          />
        ),
        cell({ row }) {
          const phone = row.getValue('businessName');
          return <div className="w-32">{String(phone)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'lastName',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Contract Last Name" column={column} />
        ),
        cell({ row }) {
          const email = row.getValue('lastName');
          return <div className="w-32 break-all">{String(email)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        id: 'actions',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} hideVisibilityToggle title="Actions" />
        ),
        cell({ row }) {
          // SHould it be possible?
          if (!row.original.userId) return null;

          const customerUrl = `/customers/${row.original.userId}`;
          return (
            <div className="flex items-center space-x-2">
              <TooltipProvider delayDuration={150}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      asChild
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      variant="outline"
                    >
                      <Link to={customerUrl}>
                        <MagnifyingGlassIcon />
                      </Link>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="px-2 py-1">View Contract</TooltipContent>
                </Tooltip>

                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      asChild
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Link to={customerUrl}>
                        <PersonIcon />
                      </Link>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="px-2 py-1">View Customer</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        },
      },
    ];
    return columns;
  }, []);

  const skeletonColumns = useMemo<ColumnDef<Contract>[]>(() => {
    return contractTableColumnsDefs.map((column) => ({
      ...column,
      cell: () => <Skeleton className="bg-foreground/30 h-4 w-32" />,
    }));
  }, [contractTableColumnsDefs]);

  const table = useReactTable({
    data: contractsQuery.isPending ? loadingContracts : contractsQuery.data?.results ?? [],
    columns: contractsQuery.isPending ? skeletonColumns : contractTableColumnsDefs,
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
    },
    onSortingChange: onSortChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });
  const navigate = useNavigate();

  const onRowClick = useCallback(
    (entry: Contract) => {
      if (!entry.userId) return;
      navigate(`/customers/${entry.userId}`);
    },
    [navigate],
  );

  const handleExport = useCallback(async () => {
    setIsJsonDataQueryEnabled(true);
  }, []);
  const handleExported = useCallback(() => setIsJsonDataQueryEnabled(false), []);

  const exportJSonStatus: Status = isJsonDataQueryEnabled
    ? jsonExportDataQuery.isSuccess
      ? 'loaded'
      : 'loading'
    : 'idle';

  return (
    <>
      <div className="space-y-2 p-4">
        <Form {...contractSearchForm}>
          <ContractsSearchForm />
        </Form>

        <ExportJSON
          filename={() => `Contract_report_${format(new Date(), 'dd_MMM_yyyy')}`}
          status={exportJSonStatus}
          onExport={handleExport}
          onExported={handleExported}
          json={jsonExportDataQuery.data ?? []}
        />
      </div>

      <div className="max-w-full overflow-x-auto">
        <DataTable table={table} onRowClick={onRowClick} />
      </div>
    </>
  );
};
