import {
  Inspection,
  InspectionList,
  type InspectionStatusList,
  Permit,
  PermitGroup,
  PermitGroupList,
  PermitList,
  type PermitStatus,
  type PermitStatusList,
  PermitTrade,
  type PermitTradeList,
} from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { PermitClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type PermitGroupFilter = EntityFilter<PermitGroup>;
export const usePermitGroupsBatchGetQuery = ({
  filter = {},
}: { filter?: PermitGroupFilter } = {}) => {
  return useQuery({
    queryFn: async () => {
      return await PermitClientService.BatchGetPermitGroup(PermitGroup.create(filter));
    },
    queryKey: [
      queryKeys.permitGroups.root,
      queryKeys.permitGroups.list,
      queryKeys.permitGroups.getHash(filter),
    ],
  });
};

export const usePermitGroupQuery = ({
  filter = {},
  enabled,
}: { filter?: PermitGroupFilter; enabled?: boolean } = {}) => {
  return useQuery({
    queryFn: async () => {
      return await PermitClientService.GetPermitGroup(PermitGroup.create(filter));
    },
    queryKey: [queryKeys.permitGroups.root, queryKeys.permitGroups.getHash(filter)],
    enabled,
    retry: false,
  });
};

export const useCreatePermitGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (permitGroup: PermitGroup) => {
      const createdPermitGroup = await PermitClientService.CreatePermitGroup(permitGroup);
      return createdPermitGroup;
    },
    onSuccess(data) {
      if (data) {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.permitGroups.root],
        });
      }
    },
  });
};

export const useUpdatePermitGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (permitGroup: PermitGroup) => {
      return await PermitClientService.UpdatePermitGroup(permitGroup);
    },
    onSuccess: (updateResult) => {
      queryClient.setQueriesData<PermitGroupList | PermitGroup>(
        { queryKey: [queryKeys.permitGroups.root] },
        (cache) => {
          if (cache) {
            if ('results' in cache) {
              if (cache.results.find((pg) => pg.id === updateResult.id)) {
                return PermitGroupList.create({
                  results: cache.results.map((pg) =>
                    pg.id === updateResult.id ? updateResult : pg,
                  ),
                  totalCount: cache.totalCount,
                });
              }
            } else {
              if (cache.id === updateResult.id) {
                return updateResult;
              }
            }
            return cache;
          }
        },
      );
    },
  });
};

export const useDeletePermitGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (permitGroup: PermitGroup) => {
      return await PermitClientService.DeletePermitGroup(permitGroup);
    },
    onSuccess: async (_, passed) => {
      queryClient.setQueriesData<PermitGroupList>(
        { queryKey: [queryKeys.permitGroups.root, queryKeys.permitGroups.list] },
        (cache) => {
          if (cache) {
            if (cache.results.find((pg) => pg.id === passed.id)) {
              return PermitGroupList.create({
                results: cache.results.filter((pg) => pg.id !== passed.id),
                totalCount: cache.totalCount - 1,
              });
            }
            return cache;
          }
        },
      );
    },
  });
};

export type PermitFilter = EntityFilter<Permit>;
export const usePermitsBatchGetQuery = <TSelectData = PermitList,>({
  filter = {},
  enabled,
  select,
}: {
  filter?: PermitFilter;
  enabled?: boolean;
  select?: (data: PermitList) => TSelectData;
} = {}) => {
  return useQuery({
    queryFn: async () => {
      return await PermitClientService.BatchGetPermit(Permit.create(filter));
    },
    queryKey: [queryKeys.permits.root, queryKeys.permits.list, queryKeys.permits.getHash(filter)],
    enabled,
    select,
  });
};

const getUsePermitGetQueryConfig = (filter: PermitFilter = {}) => ({
  queryFn: async () => {
    return await PermitClientService.GetPermit(Permit.create(filter));
  },
  queryKey: [queryKeys.permits.root, queryKeys.permits.getHash(filter)],
});

export const useInlinePermitGetQuery = () => {
  const queryClient = useQueryClient();

  const fetchPermit = useCallback(
    async ({ filter = {} }: { filter?: PermitFilter } = {}) => {
      const { queryFn, queryKey } = getUsePermitGetQueryConfig(filter);
      const cache = queryClient.getQueryData<Permit>(queryKey);
      if (!cache) {
        const permit = await queryFn();
        queryClient.setQueryData(queryKey, permit);
        return permit;
      }
      return cache;
    },
    [queryClient],
  );

  return useMemo(
    () => ({
      fetchPermit,
    }),
    [fetchPermit],
  );
};

export const usePermitGetQuery = ({
  filter = {},
  enabled,
}: { filter?: PermitFilter; enabled?: boolean } = {}) => {
  return useQuery({
    ...getUsePermitGetQueryConfig(filter),
    enabled,
  });
};

export type PermitStatusFilter = EntityFilter<PermitStatus>;
export const usePermitStatusBatchGetQuery = <TSelectData = PermitStatusList,>({
  select,
  enabled,
}: { select?: (data: PermitStatusList) => TSelectData; enabled?: boolean } = {}) => {
  return useQuery({
    queryFn: async () => {
      return await PermitClientService.BatchGetPermitStatuses();
    },
    queryKey: [queryKeys.permitStatuses.root, queryKeys.permitStatuses.list],
    select,
    enabled,
  });
};

export type PermitTradeFilter = EntityFilter<PermitTrade>;
export const usePermitTradeBatchGetQuery = <TSelectData = PermitTradeList,>({
  filter = {},
  enabled,
  select,
}: {
  filter?: PermitTradeFilter;
  enabled?: boolean;
  select?: (data: PermitTradeList) => TSelectData;
} = {}) => {
  return useQuery({
    queryFn: async () => {
      return await PermitClientService.BatchGetPermitTrades(PermitTrade.create(filter));
    },
    queryKey: [
      queryKeys.permitTrade.root,
      queryKeys.permitTrade.list,
      queryKeys.permitTrade.getHash(filter),
    ],
    enabled,
    select,
  });
};

export const usePermitCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (permit: Permit) => {
      return await PermitClientService.CreatePermit(permit);
    },
    onSuccess: async (result) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.permits.root],
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.permitGroups.root],
        }),
      ]);
    },
  });
};

export const usePermitUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (permit: Permit) => {
      return await PermitClientService.UpdatePermit(permit);
    },
    onSuccess: (result) => {
      queryClient.setQueriesData<PermitList>(
        { queryKey: [queryKeys.permits.root, queryKeys.permits.list] },
        (cache) => {
          if (cache && cache.results.find((p) => p.id === result.id)) {
            return PermitList.create({
              results: cache.results.map((p) => (p.id === result.id ? result : p)),
              totalCount: cache.totalCount,
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.permitGroups.root],
      });
    },
  });
};

export const useDeletePermitMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (permit: Permit) => {
      return await PermitClientService.DeletePermit(permit);
    },
    onSuccess: async (result, deletedPermit) => {
      queryClient.setQueriesData<PermitList>(
        { queryKey: [queryKeys.permits.root, queryKeys.permits.list] },
        (cache) => {
          if (cache && cache.results.find((p) => p.id === deletedPermit.id)) {
            return PermitList.create({
              results: cache.results.filter((p) => p.id !== deletedPermit.id),
              totalCount: cache.totalCount - 1,
            });
          }
        },
      );
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.permitGroups.root],
      });
    },
  });
};

export type InspectionFilter = EntityFilter<Inspection>;
export const useInspectionsBatchGetQuery = ({
  filter = {},
}: { filter?: InspectionFilter } = {}) => {
  return useQuery({
    queryFn: async () => {
      return await PermitClientService.BatchGetInspection(Inspection.create(filter));
    },
    queryKey: [
      queryKeys.inspections.root,
      queryKeys.inspections.list,
      queryKeys.inspections.getHash(filter),
    ],
  });
};

export const useCreateInspectionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (inspection: Inspection) => {
      return await PermitClientService.CreateInspection(inspection);
    },
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.inspections.root],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.inspections.list],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.permits.root],
      });
    },
  });
};

export const useUpdateInspectionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (inspection: Inspection) => {
      return await PermitClientService.UpdateInspection(inspection);
    },
    onSuccess: (result) => {
      queryClient.setQueryData<Inspection | InspectionList>(
        [queryKeys.inspections.root],
        (cache) => {
          if (cache) {
            if ('results' in cache) {
              return InspectionList.create({
                results: cache.results.map((i) => (i.id === result.id ? result : i)),
                totalCount: cache.totalCount,
              });
            } else if (cache.id === result.id) {
              return result;
            }
          }
          return cache;
        },
      );

      queryClient.invalidateQueries({
        queryKey: [queryKeys.inspections.root],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.permits.root],
      });
    },
  });
};

export const useDeleteInspectionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (inspection: Inspection) => {
      return await PermitClientService.DeleteInspection(inspection);
    },
    onSuccess: (res, deletedInspection) => {
      queryClient.setQueriesData<InspectionList>(
        { queryKey: [queryKeys.inspections.root, queryKeys.inspections.list] },
        (cache) => {
          if (cache && cache.results.find((i) => i.id === deletedInspection.id)) {
            return InspectionList.create({
              results: cache.results.filter((i) => i.id !== deletedInspection.id),
              totalCount: cache.totalCount - 1,
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.inspections.root],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.inspections.list],
      });
    },
  });
};

export const useInspectionStatusesQuery = <TSelectData = InspectionStatusList,>({
  select,
  enabled,
}: { select?: (data: InspectionStatusList) => TSelectData; enabled?: boolean } = {}) => {
  return useQuery({
    queryFn: async () => {
      return await PermitClientService.BatchGetInspectionStatus();
    },
    queryKey: [queryKeys.inspectionStatus.root, queryKeys.inspectionStatus.list],
    select,
    enabled,
  });
};

export const useCreatePermitGroupDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      args: Parameters<typeof PermitClientService.CreatePermitGroupDocument>['0'],
    ) => {
      return await PermitClientService.CreatePermitGroupDocument(args);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.permitGroups.root],
      });
    },
  });
};

export const useDeletePermitGroupDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      args: Parameters<typeof PermitClientService.DeletePermitGroupDocument>['0'],
    ) => {
      return await PermitClientService.DeletePermitGroupDocument(args);
    },
    onSuccess: async (_, passed) => {
      queryClient.setQueriesData<PermitGroup | PermitGroupList>(
        { queryKey: [queryKeys.permitGroups.root] },
        (cache) => {
          if (cache) {
            if ('results' in cache) {
              const permitGroup = cache.results.find((pg) => pg.id === passed.permitGroupId);
              if (permitGroup) {
                return PermitGroupList.create({
                  results: cache.results.map((pg) =>
                    pg.id === permitGroup.id
                      ? PermitGroup.create({
                          ...permitGroup,
                          permitGroupDocuments: permitGroup.permitGroupDocuments.filter(
                            (doc) => doc.id !== passed.id,
                          ),
                        })
                      : pg,
                  ),
                  totalCount: cache.totalCount,
                });
              }
            } else {
              if (cache.id === passed.permitGroupId) {
                return PermitGroup.create({
                  ...cache,
                  permitGroupDocuments: cache.permitGroupDocuments.filter(
                    (doc) => doc.id !== passed.id,
                  ),
                });
              }
            }
          }
        },
      );
    },
  });
};

export const useCreatePermitDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (args: Parameters<typeof PermitClientService.CreatePermitDocument>['0']) => {
      return await PermitClientService.CreatePermitDocument(args);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.permits.root],
      });
    },
  });
};

export const useDeletePermitDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (args: Parameters<typeof PermitClientService.DeletePermitDocument>['0']) => {
      return await PermitClientService.DeletePermitDocument(args);
    },
    onSuccess: (_, passed) => {
      queryClient.setQueriesData<Permit | PermitList>(
        { queryKey: [queryKeys.permits.root] },
        (cache) => {
          if (cache) {
            if ('results' in cache) {
              const permit = cache.results.find((pg) => pg.id === passed.permitId);
              if (permit) {
                return PermitList.create({
                  results: cache.results.map((pg) =>
                    pg.id === permit.id
                      ? Permit.create({
                          ...permit,
                          permitDocuments: permit.permitDocuments.filter(
                            (doc) => doc.id !== passed.id,
                          ),
                        })
                      : pg,
                  ),
                  totalCount: cache.totalCount,
                });
              }
            } else {
              if (cache.id === passed.permitId) {
                return Permit.create({
                  ...cache,
                  permitDocuments: cache.permitDocuments.filter((doc) => doc.id !== passed.id),
                });
              }
            }
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.permitGroups.root],
      });
    },
  });
};
