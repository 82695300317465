import { type FieldType } from '.';

const typeMap = new Map<FieldType, any>([
  ['number', 0],
  ['time', '00:00'],
  ['datetime', '0001-01-01 00:00:00'],

  ['checkbox', 0],
  ['department', 0],
  ['technician', 0],
  ['eventId', 0],
  ['vendor', 0],
  ['classCode', 0],
  ['technicians', ''],
  ['boolean', 0],
]);

export const getDefaultValueByType = (type: FieldType) => {
  if (typeMap.has(type)) {
    return typeMap.get(type);
  }
  return '';
};
