// @ts-ignore
import { deflate, ungzip } from 'pako';

import { BaseClient } from '../BaseClient';
import { b64toBlob, getMimeType } from '../Common';
import { Empty, String$ } from '../compiled-protos/common';
import { BucketObject, FileObject, type FileObjects, InternalResource, InternalResourceList, MoveConfig, URLObject } from '../compiled-protos/s3';
import { S3ServiceClient } from '../compiled-protos/s3.client';

/**
 * File Client
 */
class S3Client extends BaseClient {
  self: S3ServiceClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new S3ServiceClient(this.transport);
  }

  /**
   * ListFiles returns all files in a bucket.
   * This is not scalable as this array is going to be huge.
   * The aim is to get all files in a bucket that are associated with some paramater.
   * Currenly I am listing every file in the bucket and filtering them out.
   * This function is conceived for use with the K-Copilot module and the kalos-ai-assistant-files bucket.
   * Files in that bucket are named so as to represent a folder structure deliniated by a /
   * e.g. walmart/194019/changemanagement/ELE Core Locations.pdf
   * @param bucket - the name of the bucket
   * @param prefix - an optional prefix of the file name e.g. walmart/194019/
   * @returns an array of FileObjects, filtered by prefix if so provided
   */
  public async ListFiles(bucket: string, prefix?: string): Promise<FileObject[]> {
    const req = BucketObject.create({ name: bucket });
    let res: FileObjects;

    try {
      res = await this.self.getBucket(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return [];
    }
    console.log('prefix', prefix);
    if (!prefix) return res.result;

    const filteredFiles = res.result.filter(file => file.key.startsWith(prefix));
    return filteredFiles;
  }


  // Function to check if the data is gzipped
  public isGzipped = (data: Uint8Array): boolean => {
    // Gzip files start with the bytes 1f 8b
    return data[0] === 0x1f && data[1] === 0x8b;
  };

  public async Get(req: FileObject) {
    const data = await this.self.get(req, this.getMetaData());

    console.log('Retrieved data:', data);

    if (data.response && data.response.data) {
      const responseData = new Uint8Array(data.response.data);

      // Check if the data is gzipped
      if (this.isGzipped(responseData)) {
        try {
          const unzipped = ungzip(responseData);
          data.response.data = unzipped;
          console.log('Unzipped data successfully');
        } catch (err) {
          console.error('Error unzipping data:', err);
        }
      } else {
        console.log('Data is not gzipped, proceeding without unzipping');
      }
    }

    return data;
  }


  public async Move(
    source: SimpleFile,
    destination: SimpleFile,
    preserveSource = false
  ) {
    const req = MoveConfig.create({
      source: FileObject.create({ ...source }),
      destination: FileObject.create({ ...destination }),
      preserveSource,
    });
    let res = Empty.create();
    try {
      res = await this.self.move(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Create(req: FileObject, withMessage = false) {
    const data = req.data;
    try {
      req.data = deflate(data);
    } catch (err) {
      console.log('failed to deflate filedata', err);
      console.log('filedata', data);
    }
    let res = FileObject.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: FileObject) {
    let res = FileObject.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetUploadURL(req: URLObject) {
    let res = URLObject.create();
    try {
      res = await this.self.getUploadURL(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public Client_GetDownloadURL({ authToken, req }: { authToken: string, req: URLObject }) {
    return this.self.getDownloadURL(req, this.getMetaData(authToken)).response;
  }

  public async GetDownloadURL(req: URLObject) {
    let res = URLObject.create();
    try {
      res = await this.self.getDownloadURL(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async GetExpireUri(req: string) {
    let res = URLObject.create();
    try {
      res = await this.self.getExpiringUri(String$.create({ value: req }), this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;

  }
  public async download(fileName: string, bucket: string, doInflate = false) {
    const req = FileObject.create({ bucket: bucket, key: fileName });
    const res = await this.Get(req);
    const data = res.response.data;
    const el = document.createElement('a');
    const fd = new Blob([data], { type: getMimeType(fileName) });
    el.href = URL.createObjectURL(fd);
    el.download = fileName;
    el.click();
    el.remove();
  }

  public async downloadFile({ bucket, fileName }: { bucket: string, fileName: string }): Promise<File> {
    const urlObj = URLObject.create();
    urlObj.bucket = bucket;
    urlObj.key = fileName;
    const urlRes = await this.self.getDownloadURL(urlObj);
    const downloadRes = await fetch(urlRes.response.url);

    if (downloadRes.status !== 200) throw new Error('Failed to download file');
    const blob = await downloadRes.blob();
    const file = new File([blob], fileName, { type: blob.type });

    return file;
  }


  public async downloadAlt(fileName: string, bucket: string) {
    const req = URLObject.create({ bucket: bucket, key: fileName });
    const res = await this.GetDownloadURL(req);
    const el = document.createElement('a');
    el.download = fileName;
    el.href = res!.url;
    el.click();
    el.remove();
  }


  public b64toBlob(b64Data: string, fileName: string) {
    const sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];
    const contentType = getMimeType(fileName);

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      // @ts-ignore
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public getFileS3BucketUrl = async (filename: string, bucket: string) => {
    const url = URLObject.create({ bucket: bucket, key: filename });
    const dlURL = await this.GetDownloadURL(url);
    return dlURL?.url || "";
  };

  public deleteFileFromS3Buckets = async (key: string, bucket: string) => {
    const req = FileObject.create({ key, bucket });
    return await this.Delete(req);
  };

  public moveFileBetweenS3Buckets = async (
    from: SimpleFile,
    to: SimpleFile,
    preserveSource: boolean = false
  ) => {
    try {
      const res = await this.Move(from, to, preserveSource);
      return res ? 'ok' : 'nok';
    } catch (e) {
      return 'nok';
    }
  };

  public openFile = async (filename: string, bucket: string) => {
    const url = await this.getFileS3BucketUrl(filename, bucket);
    window.open(url, '_blank');
  };

  public uploadFileToS3Bucket = async (
    fileName: string,
    fileData: string,
    bucketName: string,
    tagString?: string
  ) => {
    try {
      const urlObj = URLObject.create({
        key: fileName,
        bucket: bucketName,
        tagString,
      });
      urlObj.contentType = getMimeType(fileName) || '';
      const urlRes = await this.GetUploadURL(urlObj);
      console.log('urlRes', urlRes);
      const uploadRes = await fetch(urlRes!.url, {
        body: b64toBlob(fileData.split(';base64,')[1], fileName),
        method: 'PUT',
        headers: tagString
          ? {
            'x-amz-tagging': tagString,
          }
          : {},
      });
      console.log('uploadRes', uploadRes);
      if (uploadRes.status === 200) {
        return 'ok';
      }
      return 'nok';
    } catch (e) {
      return 'nok';
    }
  };

  public async BatchGetInternalResource(req: InternalResource): Promise<InternalResourceList> {
    let res = InternalResourceList.create();
    try {
      res = await this.self.batchGetInternalResource(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public loadInternalResources = async ({
    page,
    filter,
    sort,
  }: LoadInternalResource): Promise<InternalResourceList> => {
    const req = InternalResource.create();
    req.pageNumber = page;

    // Apply filters
    if (filter.name) {
      req.name = `%${filter.name}%`;
    }
    if (filter.description) {
      req.description = `%${filter.description}%`;
    }

    // Apply sorting
    req.orderBy = sort.orderBy;
    req.orderDir = sort.orderDir;

    return await this.BatchGetInternalResource(req);
  };
}

enum ACL {
  Private = 0,
  PublicRead = 1,
  PublicReadWrite = 2,
}

interface SimpleFile {
  bucket: string;
  key: string;
}

const CLASSIFICATION = 'Classification';
const SUBJECT = 'Subject';
const RECEIPT_TAG = `${CLASSIFICATION}=Receipt`;
const EDUCATION_TAG = `${CLASSIFICATION}=Education`;
const WORKMANSHIP_TAG = `${CLASSIFICATION}=Workmanship`;
const MAKE_SUBJECT_TAG = (s: string) => `${SUBJECT}=${s}`;
const MAKE_CLASSIFICATION_TAG = (c: string) => `${CLASSIFICATION}=${c}`;
const MAKE_TAG = (key: string, value: string) => `${key}=${value}`;

const CLASSIFICATION_TAGS = [
  {
    label: 'Receipts',
    value: RECEIPT_TAG,
  },
  {
    label: 'Education',
    value: EDUCATION_TAG,
  },
  {
    label: 'Workmanship',
    value: WORKMANSHIP_TAG,
  },
];

const SUBJECT_TAGS_TRANSACTIONS = [
  {
    label: 'Receipt',
    value: MAKE_SUBJECT_TAG('Receipt'),
  },
  {
    label: 'Pick Ticket',
    value: MAKE_SUBJECT_TAG('PickTicket'),
  },
  {
    label: 'Invoice',
    value: MAKE_SUBJECT_TAG('Invoice'),
  },
];

const SUBJECT_TAGS_ACCOUNTS_PAYABLE = [
  {
    label: 'Select tag',
    value: '0'
  },
  ...SUBJECT_TAGS_TRANSACTIONS,
];

const SUBJECT_TAGS = [
  ...SUBJECT_TAGS_ACCOUNTS_PAYABLE,
  {
    label: 'Data Tag',
    value: MAKE_SUBJECT_TAG('DataTag'),
  },
  {
    label: 'Refrigerant Report',
    value: MAKE_SUBJECT_TAG('RefrigerantReport'),
  },
];

const CHANNELS = [
  {
    label: '#repair-quotes',
    value: 'C0HMJ00P2',
  },
  {
    label: '#sales-calls',
    value: 'C0HEASA06',
  },
];

export {
  FileObject,
  URLObject,
  S3Client,
  ACL,
  SUBJECT_TAGS,
  SUBJECT_TAGS_TRANSACTIONS,
  SUBJECT_TAGS_ACCOUNTS_PAYABLE,
  InternalResource,
  InternalResourceList,
};

type LoadInternalResource = {
  page: number;
  filter: InternalResourceFilter;
  sort: InternalResourceSort;
};

type InternalResourceFilter = {
  name?: string;
  description?: string;
};

type InternalResourceSort = {
  orderBy: string;
  orderDir: 'ASC' | 'DESC';
};

export type { LoadInternalResource, InternalResourceFilter, InternalResourceSort };
