import { zodResolver } from '@hookform/resolvers/zod';
import { type EmployeeItemOption } from '@kalos/kalos-rpc';
import {
  Button,
  Combobox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
} from '@kalos/ui';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useBatchGetEmployeeItemCategoryQuery } from '../../../../hooks/react-query/employeeItems';
import { type ZodObjectWithModelKeys } from '../../../../tools/typeguargs';
import { type FormProps } from '../../../../tools/types';

type EmployeeItemOptionFormFields = Pick<
  EmployeeItemOption,
  'category' | 'name' | 'notes' | 'quantity'
>;

const employeeItemOptionFormSchema = z.object({
  category: z.string().min(1, 'Please select a category'),
  name: z.string().min(1, 'Please enter a name').max(200, 'Too long - max 200 characters'),
  notes: z.string().min(1, 'Please enter some notes').max(500, 'Too long - max 500 characters'),
  quantity: z.coerce.number().min(1, 'Please enter a quantity'),
}) satisfies ZodObjectWithModelKeys<EmployeeItemOptionFormFields>;

type EmployeeItemOptionFormSchema = z.infer<typeof employeeItemOptionFormSchema>;

const getDefaultEmployeeItemOptionFormValues = (
  data?: Partial<EmployeeItemOptionFormSchema>,
): EmployeeItemOptionFormSchema => ({
  category: '',
  name: '',
  notes: '',
  quantity: 1,
  ...data,
});

export const EmployeeItemOptionForm = ({
  defaultValues,
  onSave,
  disabled,
  isLoading,
}: FormProps<EmployeeItemOptionFormSchema>) => {
  const form = useForm<EmployeeItemOptionFormSchema>({
    resolver: zodResolver(employeeItemOptionFormSchema),
    defaultValues,
    disabled,
  });

  const categoryOptionsQuery = useBatchGetEmployeeItemCategoryQuery({
    filter: {
      isActive: true,
    },
    select(data) {
      return data.results.map((category) => ({
        value: category.name,
        label: category.name,
      }));
    },
  });

  const onSubmit = form.handleSubmit((data) => {
    onSave({
      data,
      dirtyFields: form.formState.dirtyFields,
    });
  });

  return (
    <div className="@container">
      <form onSubmit={onSubmit} className="@md:grid-cols-2 grid gap-4">
        <Form {...form}>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="@md:col-span-2">
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="MacBook M2 13'" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Category</FormLabel>
                <FormControl>
                  <Combobox values={categoryOptionsQuery.data ?? []} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="quantity"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Quantity</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    inputMode="numeric"
                    {...field}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem className="@md:col-span-2">
                <FormLabel>Notes</FormLabel>
                <FormControl>
                  <Textarea placeholder="space gray color" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            disabled={disabled || !form.formState.isDirty}
            className="@md:col-span-2"
            isLoading={isLoading}
          >
            Submit
          </Button>
        </Form>
      </form>
    </div>
  );
};

EmployeeItemOptionForm.getDefaultValues = getDefaultEmployeeItemOptionFormValues;
