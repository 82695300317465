import { Button, FormControl, FormField, FormItem, FormLabel, Input } from '@kalos/ui';

import { OPTION_ALL } from '../../../../constants';
import { TimesheetDepartmentPickerV2 } from '../../Pickers/newPickers/QueryPickerV2';
import { useEmployeeSearchFormContext } from './utils';

type EmployeeSearchFormProps = { showOwnedItemField?: boolean };
const EmployeeSearchForm: React.FC<EmployeeSearchFormProps> = ({ showOwnedItemField = true }) => {
  const form = useEmployeeSearchFormContext();

  return (
    // intentionally ignored as values are used as they are typed in
    <form
      className="grid grid-cols-2 place-content-center gap-x-4 gap-y-3 md:grid-cols-3 lg:grid-cols-5"
      onSubmit={form.handleSubmit(console.log)}
    >
      <FormField
        control={form.control}
        name="firstname"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>First Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="lastname"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Last Name</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="employeeDepartmentID"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Department</FormLabel>
            <FormControl>
              <TimesheetDepartmentPickerV2
                placeholder={OPTION_ALL}
                renderItem={(department) => `${department.value} - ${department.description}`}
                selected={field.value.toString()}
                onSelect={(department) => field.onChange(department?.id ?? 0)}
                queryArgs={{
                  filter: {
                    isActive: 1,
                  },
                }}
              />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="empTitle"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Title</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="email"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="cellphone"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Cell</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="phone"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Office</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="id"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Badge #</FormLabel>
            <FormControl>
              <Input type="number" {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      {showOwnedItemField && (
        <FormField
          control={form.control}
          name="employeeItemName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Owned Item name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />
      )}

      <Button
        type="reset"
        variant="outline"
        className="mt-auto max-w-max"
        onClick={() => form.reset()}
      >
        Reset
      </Button>
    </form>
  );
};

export default EmployeeSearchForm;
