import { type Event } from '@kalos/kalos-rpc';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

import {
  useGetDefaultSearchFormValues,
  useSearchParamsForm,
} from '../../../../hooks/useFormWithSearchParams';
import { type LoadEventsByFilter } from '../../../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../../../tools/typeguargs';

type ServiceCallsSearchFields =
  | Pick<NonNullable<Event['customer']>, 'firstname' | 'lastname' | 'businessname'>
  | Pick<NonNullable<Event['property']>, 'address' | 'city' | 'zip'>
  | Pick<
      Event,
      | 'name'
      | 'logNotes'
      | 'logTechnicianAssigned'
      | 'logJobNumber'
      | 'logPo'
      | 'isResidential'
      | 'jobTypeId'
      | 'jobSubtypeId'
      | 'logJobStatus'
      | 'departmentId'
      | 'description'
    >
  | (Pick<LoadEventsByFilter['filter'], 'dateStartedFrom' | 'dateStartedTo'> & {
      usePropertyLevelSearch: boolean;
    });

const serviceCallsSearchScheme = z.object({
  address: z.string(),
  subdivision: z.string(),
  city: z.string(),
  zip: z.string(),
  businessname: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  name: z.string(),
  logNotes: z.string(),
  logTechnicianAssigned: z.number().optional(),
  logJobNumber: z.string(),
  logPo: z.string(),
  // only 0,1,2
  isResidential: z.number(),
  jobTypeId: z.number(),
  jobSubtypeId: z.number(),
  logJobStatus: z.string(),
  dateStartedFrom: z.date().optional(),
  dateStartedTo: z.date().optional(),
  departmentId: z.number().optional(),
  description: z.string(),
  usePropertyLevelSearch: z.boolean(),
}) satisfies ZodObjectWithModelKeys<ServiceCallsSearchFields>;

export type ServiceCallsSearchScheme = z.infer<typeof serviceCallsSearchScheme>;
export const defaultServiceCallsSearchValues: ServiceCallsSearchScheme = {
  address: '',
  subdivision: '',
  city: '',
  zip: '',
  businessname: '',
  firstname: '',
  lastname: '',
  name: '',
  logNotes: '',
  logTechnicianAssigned: undefined,
  logJobNumber: '',
  logPo: '',
  isResidential: 0,
  jobTypeId: 0,
  jobSubtypeId: 0,
  logJobStatus: '',
  dateStartedFrom: undefined,
  dateStartedTo: undefined,
  departmentId: 0,
  usePropertyLevelSearch: true,
  description: '',
};

export const useServiceCallsSearchForm = ({ isSelector }: { isSelector: boolean }) => {
  return useSearchParamsForm({
    schema: serviceCallsSearchScheme,
    defaultValues: useGetDefaultSearchFormValues(
      serviceCallsSearchScheme,
      defaultServiceCallsSearchValues,
    ),
    disableParamsSync: isSelector,
    defaultResetValues: defaultServiceCallsSearchValues,
  });
};

export const useServiceCallsSearchFormContext = () => {
  return useFormContext<ServiceCallsSearchScheme>();
};
