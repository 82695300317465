import { User, UserAttributes, type UserList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { UserClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type UserFilters = EntityFilter<User>;
export const getUserQueryKeys = (filter: UserFilters) => [
  queryKeys.user.root,
  queryKeys.user.getHash(filter),
];
export const useUserQuery = <TSelectedData = User,>({
  filters = {},
  enabled = true,
  select,
}: {
  filters?: UserFilters;
  enabled?: boolean;
  select?: (data: Awaited<ReturnType<typeof UserClientService.Get>>) => TSelectedData;
}) => {
  return useQuery({
    queryFn: () => {
      const userReq = User.create(filters);
      return UserClientService.Get(userReq);
    },
    queryKey: getUserQueryKeys(filters),
    enabled,
    select,
  });
};

export const useUserQueryInline = () => {
  const queryClient = useQueryClient();

  const fetchUser = useCallback(
    async ({ filter = {} }: { filter?: UserFilters } = {}) => {
      const queryKeys = getUserQueryKeys(filter);
      const cache = queryClient.getQueryData<User>(queryKeys);
      if (!cache) {
        const userReq = User.create(filter);
        const user = await UserClientService.Get(userReq);
        queryClient.setQueryData(queryKeys, user);
        return user;
      }
      return cache;
    },
    [queryClient],
  );

  return useMemo(() => ({ fetchUser }), [fetchUser]);
};

export type UserAttributesFilter = EntityFilter<UserAttributes>;
export const useUserAttributesGetQuery = ({
  filter = {},
  enabled,
}: { filter?: UserAttributesFilter; enabled?: boolean } = {}) => {
  return useQuery({
    queryFn: async () => {
      return await UserClientService.GetUserAttributes(UserAttributes.create(filter));
    },
    queryKey: [
      queryKeys.user.root,
      queryKeys.user.userAttributes,
      queryKeys.user.getUserAttributesHash(filter),
    ],
    enabled,
  });
};

export const useBatchUserQuery = <TSelectData = UserList,>({
  filters,
  enabled,
  select,
}: {
  enabled?: boolean;
  filters: UserFilters;
  select?: (data: UserList) => TSelectData;
}) => {
  return useQuery({
    queryFn: async () => {
      const req = User.create(filters);
      return await UserClientService.BatchGet(req);
    },
    select,
    enabled,
    queryKey: [queryKeys.user.root, queryKeys.user.list, queryKeys.user.getHash(filters)],
  });
};

export const useBatchTechniciansQuery = <TSelectData = UserList,>({
  enabled,
  select,
}: {
  enabled?: boolean;
  select?: (data: User[]) => TSelectData;
}) => {
  return useQuery({
    queryFn: async () => {
      return await UserClientService.loadTechnicians();
    },
    select,
    enabled,
    queryKey: [queryKeys.technicians.root],
  });
};
// UserClientService.loadTechnicians

export const useUserUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (req: User) => {
      return await UserClientService.Update(req);
    },
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.user.root] });
    },
  });
};

export const useGetUserManagerQuery = <SelectData = User,>({
  filters = {},
  enabled = true,
  select,
}: {
  filters?: UserFilters;
  enabled?: boolean;
  select?: (data: Awaited<ReturnType<typeof UserClientService.GetUserManager>>) => SelectData;
}) => {
  return useQuery({
    queryFn: () => {
      const userReq = User.create(filters);
      return UserClientService.GetUserManager(userReq);
    },
    queryKey: [queryKeys.usersManager.root, queryKeys.usersManager.getUserManagerHash(filters)],
    enabled,
    select,
  });
};

export const useGetUsersInPermissionGroupQuery = ({
  permissionGroupId,
  enabled,
}: {
  permissionGroupId: number;
  enabled?: boolean;
}) => {
  return useQuery({
    queryFn: async () => {
      return await UserClientService.GetUserIdsInPermissionGroup(permissionGroupId);
    },
    queryKey: [
      queryKeys.user.root,
      queryKeys.user.list,
      queryKeys.user.byPermissionGroup,
      permissionGroupId,
    ],
    enabled,
  });
};
