import { z } from 'zod';

import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';
type PieceWork = {
  externalId: number;
  datePerformed: Date;
  optionId: number;
};
type SpiffPieceWorkFormFields = Pick<PieceWork, 'datePerformed' | 'optionId' | 'externalId'>;
export const pieceWorkSchema = z.object({
  externalId: z.number(),
  datePerformed: z.date(),
  optionId: z.coerce.number().min(1, 'Piece work should be selected'),
}) satisfies ZodObjectWithModelKeys<SpiffPieceWorkFormFields>;

export type PieceWorkSchema = z.infer<typeof pieceWorkSchema>;
