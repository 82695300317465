import { type EmailConfig, Event, User } from '@kalos/kalos-rpc';
import Alert from '@mui/material/Alert';
import { type FC, useCallback, useEffect, useState } from 'react';

import { CALLBACK_DISPOSITION } from '../../../constants';
import { EmailClientService, EventClientService, UserClientService } from '../../../tools/helpers';
import { Modal } from '../Modal';
import { PlainForm, type Schema } from '../PlainForm';
import { SectionBar } from '../SectionBar';

interface Props {
  onClose: () => void;
  event: Event;
  loggedUserId: number;
}

type Form = {
  message: string;
  messageRecipientId: number;
  callbackTypeId: number;
};

export const CallbackReview: FC<Props> = ({ onClose, event, loggedUserId }) => {
  const [error, setError] = useState<boolean>(false);
  const [originalEvent, setOriginalEvent] = useState<Event>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const [form, setForm] = useState<Form>({
    message: event.callbackComments,
    messageRecipientId: event.callbackTechnician,
    callbackTypeId: event.callbackDisposition,
  });
  const load = useCallback(
    () => async () => {
      const req = Event.create();
      req.id = event.callbackOriginalId;
      try {
        const result = await EventClientService.Get(req);
        setOriginalEvent(result);
      } catch {
        setError(true);
      }
    },
    [event.callbackOriginalId],
  );

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      load();
    }
  }, [loaded, setLoaded, load]);

  const handleChangeForm = useCallback(
    (form: Form) => {
      setForm(form);
      if (error) {
        setError(false);
      }
    },
    [setForm, setError, error],
  );

  const SCHEMA: Schema<Form> = [
    [
      {
        name: 'message',
        label: 'Comment',
        required: true,
        type: 'text',
        multiline: true,
      },
    ],
    [
      {
        name: 'callbackTypeId',
        label: 'Callback Type',
        options: CALLBACK_DISPOSITION,
        required: true,
      },
    ],
    [
      {
        name: 'messageRecipientId',
        label: 'Employee',
        type: 'technician',
        required: true,
      },
    ],
  ];

  const sendEmail = async () => {
    const jobNumber = event.id;
    const linktoJob = `live.kalosflorida.com/jobs/${event.id}`; //`https://app.kalosflorida.com/index.cfm?action=admin:service.editServiceCall&id=${event.id}&property_id=${event.propertyId}&user_id=${event.customer?.id}`;
    const linkToOriginalJob = `live.kalosflorida.com/jobs/${originalEvent?.id}`; // `https://app.kalosflorida.com/index.cfm?action=admin:service.editServiceCall&id=${originalEvent?.id}&property_id=${originalEvent?.propertyId}&user_id=${originalEvent?.customer?.id}`;
    const callbackDisposition = CALLBACK_DISPOSITION.find(
      (el) => el.value == form.callbackTypeId,
    )!.label;
    const body = `
    <body>

    <table cellpadding="0" cellspacing="0" class="main-table" style="max-width:600px; margin:auto; border:1px solid #ddd; background-color:#f1f1f1;" align="center">
     <tr>
    <td height="12"></td>
  </tr>
  <tr>
    <td>
    </td>
  </tr>

    <tr>
      <td height="10" colspan="3"></td>
    </tr>
    <tr>
      <td width="2%"></td>
      <td width="96%">
        <table style="width:100%; margin:auto; background-color:#fff;" cellpadding="0" cellspacing="0">
          <tr>
            <td width="2%"></td>
            <td width="96%">
              <table style="width:100%;" cellpadding="0" cellspacing="0">
                <tr>
                  <td height="12"></td>
                </tr>
                <a><img src='https://kalos-web-assets.s3.amazonaws.com/kalos-logo-new.png'	alt="email-logo" style="display:block; background-color: #711313"/></a>

                <tr>
                  <td height="23"></td>
                </tr>
                <tr>
                  <td height="22"></td>
                </tr>
                <tr>
                  <td>
                    <h3 style="font-size:20px; color:#4d4d4d; font-family:Arial, Helvetica, sans-serif; margin:0;">
                      Callback Summary
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td height="14"></td>
                </tr>
                <tr>
                  <td>
                    <p style="margin:0; font-size:14px; color:#262626; line-height:20px; font-family:Arial, Helvetica, 'sans-serif';">
  Job Number: ${jobNumber}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td height="10"></td>
                </tr>
                <tr>


                  <td height="10"></td>
                </tr>
                <tr>
                  <td>
                    <table style="width:100%;" cellpadding="1" cellspacing="1">
                      <tr>

              Callback Type: ${callbackDisposition}

                        <td valign="top" style="font-family:Arial, Helvetica, sans-serif; color:#000; font-size:12px; line-height:20px;">Job Date ${event.dateStarted}</td>
                        <br>

                    </table>
                    <br>
                    <a href="${linktoJob}">Link to Callback</a>
                    <br>
                    <br>
                    <a href="${linkToOriginalJob}">Link to Orignal Job</a>

                                  <table style="width:100%;" cellpadding="1" cellspacing="1">
                           <tr>
                                    </tr>

                      <tr>
      <br>
  <br>
              Admin  Comments:


                        <td valign="top" style="font-family:Arial, Helvetica, sans-serif; color:#000; font-size:12px; line-height:20px;"> ${form.message}</td>

                       </tr>

                    </table>
                  </td>
                </tr>
                <tr>
                  <td height="18"></td>
                </tr>
                <tr>

                </tr>
                  <tr>
                  <td height="18"></td>
                </tr>
              </table>
            </td>
            <td width="2%"></td>
          </tr>
        </table>
      </td>
      <td width="2%"></td>
    </tr>
    <tr>
      <td height="13" colspan="3"></td>
    </tr>
    <tr>
      <td width="2%"></td>
      <td width="96%">
           <table style="width:100%; border-top:1px solid #d8d8d8;" cellpadding="0" cellspacing="0">
          <tr>
            <td height="10" colspan="2"></td>
          </tr>
          <tr>
            <td width="80%" align="right" valign="middle">
              <p style="font-size:10px; color:#595959; font-family:Arial, Helvetica, sans-serif; font-weight:300; line-height:13px; align-text:left">
                KALOS SERVICES INC. 236 HATTERAS AVE CLERMONT FL 34711  352.243.7088 <br />
                Copyright 2022 Kalos Service Inc. All Rights Reserved.
              </p>
            </td>
            <td width="20%" align="right">
            </td>
          </tr>
        </table>
      </td>
      <td width="2%"></td>
    </tr>
    <tr>
      <td height="30" colspan="3"></td>
    </tr>
  </table>
  </body>`;
    const updateReq = Event.create();
    updateReq.id = event.id;
    updateReq.fieldMask = [
      'CallbackComments',
      'CallbackDisposition',
      'CallbackTechnician',
      'CallbackCommentBy',
    ];
    updateReq.callbackComments = form.message;
    updateReq.callbackDisposition = form.callbackTypeId;
    updateReq.callbackTechnician = form.messageRecipientId;
    updateReq.callbackCommentBy = loggedUserId;
    await EventClientService.Update(updateReq);
    const req = User.create();
    req.id = form.messageRecipientId;
    const recipient = await UserClientService.Get(req);
    const email: EmailConfig = {
      recipient: recipient.email,
      subject: 'Callback ',
      from: 'office@kalosflorida.com',
      body,
    };

    try {
      await EmailClientService.sendMail(email);
    } catch (err) {
      console.log(err);
      alert('An error occurred, user was not notified via email');
    }
    if (onClose) {
      onClose();
    }
  };

  const isSendBtnDisabled =
    form.messageRecipientId == 0 || form.callbackTypeId == 0 || !form.message;
  return (
    <Modal open onClose={onClose}>
      <SectionBar
        title="Callback Review"
        actions={
          onClose
            ? [
                { label: 'Close', onClick: onClose },
                {
                  label: 'Send',
                  onClick: sendEmail,
                  disabled: isSendBtnDisabled,
                },
              ]
            : [
                {
                  label: 'Send',
                  onClick: sendEmail,
                  disabled: isSendBtnDisabled,
                },
              ]
        }
      />
      <PlainForm schema={SCHEMA} data={form} onChange={handleChangeForm} />

      {error && <Alert severity="error">Could not send email.</Alert>}
    </Modal>
  );
};
