import { type EmailConfig, Inspection } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTrigger, toast } from '@kalos/ui';
import { useCallback, useMemo, useState } from 'react';

import { useEmailClientSendMutation } from '../../../../../../hooks/react-query/useEmailClientQuery';
import {
  useInspectionStatusesQuery,
  usePermitGetQuery,
  usePermitGroupQuery,
  useUpdateInspectionMutation,
} from '../../../../../../hooks/react-query/usePermits';
import { useUserQuery } from '../../../../../../hooks/react-query/useUserQuery';
import { formatBackendDate, timestamp } from '../../../../../../tools/helpers';
import { getFieldMaskFromDirtyField } from '../../../../../ComponentsLibrary/EmployeeDepartments/utils';
import { InspectionForm, PENDING_INSPECTION_STATUS_ID } from './InspectionForm';

const usePermitGroupByInspectionQuery = ({
  inspection,
  enabled,
}: {
  inspection: Inspection;
  enabled: boolean;
}) => {
  const permitQuery = usePermitGetQuery({
    filter: {
      id: inspection.permitId,
    },
    enabled,
  });

  const permitGroupQuery = usePermitGroupQuery({
    filter: {
      id: permitQuery.data?.permitGroupId,
    },
    enabled: enabled && permitQuery.isSuccess && !!permitQuery.data,
  });

  return permitGroupQuery;
};

const usePermitRequestorByInspectionQuery = ({
  inspection,
  enabled,
}: {
  inspection: Inspection;
  enabled: boolean;
}) => {
  const permitGroupQuery = usePermitGroupByInspectionQuery({ inspection, enabled });

  const isUserQueryEnabled = enabled && permitGroupQuery.isSuccess && !!permitGroupQuery.data;

  const userQuery = useUserQuery({
    filters: {
      id: permitGroupQuery.data?.requestorId,
    },
    enabled: isUserQueryEnabled,
    select(data) {
      return {
        ...data,
        fullname: `${data.firstname} ${data.lastname}`,
      };
    },
  });

  return userQuery;
};

export const InspectionEditDialog = ({
  trigger,
  inspection,
}: {
  trigger: React.ReactNode;
  inspection: Inspection;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const updateMutation = useUpdateInspectionMutation();
  const sendEmailMutation = useEmailClientSendMutation();

  const defaultValues = useMemo(
    () =>
      InspectionForm.getDefaultValues({
        ...inspection,
        scheduledDate: new Date(inspection.scheduledDate),
        requestorScheduledDate: new Date(inspection.requestorScheduledDate),
      }),
    [inspection],
  );

  const shouldIncludeNotificationCheckbox = inspection.statusId === PENDING_INSPECTION_STATUS_ID;
  const requestorQuery = usePermitRequestorByInspectionQuery({
    inspection,
    enabled: shouldIncludeNotificationCheckbox,
  });
  const permitGroupQuery = usePermitGroupByInspectionQuery({
    inspection,
    enabled: shouldIncludeNotificationCheckbox,
  });
  const permitGroupAssignedToQUery = useUserQuery({
    filters: {
      id: permitGroupQuery.data?.assignedUser,
    },
    select(data) {
      return {
        ...data,
        fullname: `${data.firstname} ${data.lastname}`,
      };
    },
    enabled:
      shouldIncludeNotificationCheckbox && permitGroupQuery.isSuccess && !!permitGroupQuery.data,
  });
  const permitQuery = usePermitGetQuery({
    filter: {
      id: inspection.permitId,
    },
    enabled: shouldIncludeNotificationCheckbox,
  });
  const inspectionsStatusQuery = useInspectionStatusesQuery({
    enabled: shouldIncludeNotificationCheckbox,
  });

  const onSubmit: React.ComponentProps<typeof InspectionForm>['onSave'] = useCallback(
    async ({ data, dirtyFields }) => {
      let emailConfig: EmailConfig | undefined = undefined;

      try {
        const req = Inspection.create({
          id: inspection.id,
          fieldMask: getFieldMaskFromDirtyField(dirtyFields),
          ...data,
          scheduledDate: timestamp(false, data.scheduledDate),
          requestorScheduledDate: timestamp(false, data.requestorScheduledDate),
        });

        if (
          data.notify &&
          requestorQuery.isSuccess &&
          permitGroupQuery.isSuccess &&
          inspectionsStatusQuery.isSuccess &&
          permitQuery.isSuccess &&
          permitGroupAssignedToQUery.isSuccess
        ) {
          const currentStatus = inspectionsStatusQuery.data?.results.find(
            (status) => status.id === inspection.statusId,
          );
          const newStatus = inspectionsStatusQuery.data?.results.find(
            (status) => status.id === data.statusId,
          );

          emailConfig = {
            type: 'permitChange',
            subject: `Inspection status update`,
            body:
              data.statusId != 1
                ? `
              <body>
              <h1>Inspection status was changed from <b>${currentStatus?.description}</b> to <b>${newStatus?.description}</b></h1>
              <h3>Inspection info</h3>
              <div>Notes: ${data.notes}</div>

              <h3>Inspection is under the following Permit:</h3>
              <div>Notes: ${permitQuery.data?.notes}</div>
              <div>JobId: ${permitQuery.data?.jobNumber}</div>
              <div>Status: ${permitQuery.data?.statusDescription}</div>

              <h3>Permit group info:</h3>
              <div>Assigned To: ${permitGroupAssignedToQUery.data?.fullname}</div>
              <div>PO: ${permitGroupQuery.data?.po}</div>
              <div>Start date: ${formatBackendDate(permitGroupQuery.data?.startDate)}</div>
              <div>End date: ${formatBackendDate(permitGroupQuery.data?.endDate)}</div>

              <h5>You were notified because you are the requestor of permit group</h5>
              </body>
            `
                : `
              <body>
              <h1>Hello, ${(requestorQuery.data.firstname, requestorQuery.data.lastname)}</b></h1>
              <h3>Inspection info</h3>
              <div>Notes: ${data.notes}</div>

              <div>The Permit team has update the following inspection as ${
                permitQuery.data?.statusDescription
              } for the Permit of ${inspection.inspectionType} for Job ${
                permitQuery.data.jobNumber
              }</div>

              <h5>You were notified because you are the requestor of permit group</h5>
              </body>
            `,
            recipient: requestorQuery.data?.email,
          };
        }

        await Promise.all([
          updateMutation.mutateAsync(req),
          ...(emailConfig ? [sendEmailMutation.mutateAsync(emailConfig)] : []),
        ]);
        setIsOpen(false);
      } catch (error) {
        toast({
          variant: 'destructive',
          title: 'Something went wrong during permit update',
        });
        console.error(error);
      }
    },
    [
      inspection.id,
      inspection.statusId,
      inspection.inspectionType,
      requestorQuery.isSuccess,
      requestorQuery.data?.email,
      permitGroupQuery.isSuccess,
      permitGroupQuery.data?.po,
      permitGroupQuery.data?.startDate,
      permitGroupQuery.data?.endDate,
      inspectionsStatusQuery.isSuccess,
      inspectionsStatusQuery.data?.results,
      permitQuery.isSuccess,
      permitQuery.data?.notes,
      permitQuery.data?.jobNumber,
      permitQuery.data?.statusDescription,
      permitGroupAssignedToQUery.isSuccess,
      permitGroupAssignedToQUery.data?.fullname,
      updateMutation,
      sendEmailMutation,
      requestorQuery.data?.firstname,
      requestorQuery.data?.lastname,
    ],
  );

  const loading =
    updateMutation.isPending ||
    (shouldIncludeNotificationCheckbox &&
      (requestorQuery.isPending ||
        permitGroupAssignedToQUery.isPending ||
        permitGroupQuery.isPending ||
        inspectionsStatusQuery.isPending ||
        permitQuery.isPending));

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="@container">
        {updateMutation.isError && (
          <p className="mb-2 text-red-500">Something went wrong during update</p>
        )}
        <InspectionForm
          defaultValues={defaultValues}
          includeNotificationCheckbox={shouldIncludeNotificationCheckbox}
          requestorName={requestorQuery.isSuccess ? requestorQuery.data.fullname : ''}
          onSave={onSubmit}
          disabled={loading}
          isLoading={loading}
        />
      </DialogContent>
    </Dialog>
  );
};
