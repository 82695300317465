import { EmployeeItemOption } from '@kalos/kalos-rpc';
import { Button, DataTable, DataTableColumnHeader, DataTablePagination } from '@kalos/ui';
import { PlusCircledIcon } from '@radix-ui/react-icons';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useGetLoadingColumns } from '../../../../components/utils';
import { ROWS_PER_PAGE } from '../../../../constants';
import { useEmployeeItemOptionsBatchGetQuery } from '../../../../hooks/react-query/employeeItems';
import { EmployeeItemOptionFormCreateDialog } from '../EmployeeItemOptionForm/EmployeeItemOptionFormCreateDialog';
import { EmployeeItemOptionsTableActions } from './EmployeeItemOptionsTableActions';

const staticData = Array.from({ length: 5 }, (_, i) => EmployeeItemOption.create({ id: i }));

export const EmployeeItemOptionsTable = () => {
  const [page, setPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const employeeItemOptionsQuery = useEmployeeItemOptionsBatchGetQuery({
    filter: {
      isActive: true,
      pageNumber: page,
    },
  });

  useEffect(() => {
    if (employeeItemOptionsQuery.data) {
      setTotalEntries(employeeItemOptionsQuery.data.totalCount);
    }
  }, [employeeItemOptionsQuery.data]);

  const employeeItemsTableColumns = useMemo<ColumnDef<EmployeeItemOption>[]>(() => {
    return [
      {
        header(props) {
          return <DataTableColumnHeader title="Name" column={props.column} />;
        },
        meta: {
          className: 'w-32',
        },
        accessorKey: 'name',
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>{props.row.original.name}</div>
          );
        },
      },
      {
        header(props) {
          return <DataTableColumnHeader title="Category" column={props.column} />;
        },
        meta: {
          className: 'w-32',
        },
        accessorKey: 'category',
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.category}
            </div>
          );
        },
      },
      {
        header(props) {
          return <DataTableColumnHeader title="Notes" column={props.column} />;
        },
        meta: {
          className: 'w-52',
        },
        accessorKey: 'notes',
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>{props.row.original.notes}</div>
          );
        },
      },
      {
        header(props) {
          return <DataTableColumnHeader title="Quantity" column={props.column} />;
        },
        meta: {
          className: 'w-32',
        },
        accessorKey: 'quantity',
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.quantity}
            </div>
          );
        },
      },
      {
        meta: {
          className: 'w-40 ml-auto flex flex-wrap gap-1',
        },
        id: 'actions',
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <EmployeeItemOptionsTableActions employeeItemOption={props.row.original} />
            </div>
          );
        },
      },
    ];
  }, []);

  const loadingColumns = useGetLoadingColumns(employeeItemsTableColumns);

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    columns: employeeItemOptionsQuery.isPending ? loadingColumns : employeeItemsTableColumns,
    data: employeeItemOptionsQuery.isPending
      ? staticData
      : employeeItemOptionsQuery.data?.results ?? [],
    enableSorting: false,
  });

  const pageCount = Math.ceil(totalEntries / ROWS_PER_PAGE);

  return (
    <div>
      <div className="flex flex-wrap justify-between gap-1 bg-gray-300 p-2">
        <div className="flex flex-wrap items-center justify-between gap-2">
          <h1 className="text-2xl">Employee Item Options</h1>
          {pageCount > 1 && (
            <DataTablePagination
              currentPage={page}
              pageCount={pageCount}
              setPage={setPage}
              className="max-w-max"
            />
          )}
          <p>Total entries: {totalEntries}</p>
        </div>
        <div>
          <EmployeeItemOptionFormCreateDialog
            trigger={
              <Button size="icon">
                <PlusCircledIcon />
              </Button>
            }
          />
        </div>
      </div>
      <div className="max-w-full overflow-auto">
        <DataTable table={table} />
      </div>
    </div>
  );
};
