import { BaseClient } from '../BaseClient';
import { timestamp } from '../Common';
import { Document, DocumentList, SubAccountDocumentRequest } from '../compiled-protos/s3';
import { S3ServiceClient as DocumentServiceClient } from '../compiled-protos/s3.client';
class DocumentClient extends BaseClient {
  self: DocumentServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new DocumentServiceClient(this.transport);
  }

  public async Create(req: Document) {
    let res = Document.create();
    try {
      res = await this.self.createDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: Document) {
    let res = Document.create();
    try {
      res = await this.self.getDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: Document) {
    const res = await this.self.updateDocument(req, this.getMetaData()).response;
    return res;
  }

  public async Delete(req: Document) {
    let res = Document.create();
    try {
      res = await this.self.deleteDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Document) {
    const res = await this.self.batchGetDocument(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async Customer_FetchSubAccountDocuments({ id, authToken }: { id: number, authToken: string }) {
    const req = SubAccountDocumentRequest.create({ subAccountId: id });
    return await this.self.fetchSubAccountDocuments(req, this.getMetaData(authToken)).response;
  }
  public async Customer_BatchGet({ request, authToken }: { request: Document, authToken: string }) {
    return await this.self.batchGetDocument(request, this.getMetaData(authToken)).response;
  }

  public updateDocumentDescription = async (
    id: number,
    description: string
  ) => {
    const req = Document.create();
    req.id = id;
    req.description = description;
    req.fieldMask = ['Description'];
    await this.Update(req);
  };

  public createTaskDocument = async (
    fileName: string,
    taskId: number,
    userId: number,
    description: string
  ) => {
    const req = Document.create();
    req.filename = fileName;
    req.dateCreated = timestamp();
    req.taskId = taskId;
    req.userId = userId;
    req.description = description;
    req.type = 5; // FIXME is 5 correct?
    await this.Create(req);
  };
}

export { Document, DocumentClient, DocumentList };
