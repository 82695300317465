import { zodResolver } from '@hookform/resolvers/zod';
import { type Task } from '@kalos/kalos-rpc';
import {
  DateInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MoneyInput,
  SimpleSelect,
  Textarea,
} from '@kalos/ui';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { JobSelector } from '../../../components/JobSelector';
import { useSpiffTypeBatchGetQuery } from '../../../hooks/react-query/useTaskClientServiceQuery';
import { escapeText, type SaveHandler } from '../../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';

type SpiffExtendedFormFields = Pick<
  Task,
  | 'spiffToolId'
  | 'referenceUrl'
  | 'referenceNumber'
  | 'timeDue'
  | 'spiffAmount'
  | 'spiffJobNumber'
  | 'datePerformed'
  | 'spiffTypeId'
  | 'briefDescription'
>;

const spiffExtendedFormSchema = z.object({
  spiffToolId: z.string(),
  referenceUrl: z.string(),
  referenceNumber: z.string(),
  timeDue: z.date(),
  spiffAmount: z.coerce.number(),
  spiffJobNumber: z.number(),
  datePerformed: z.date(),
  spiffTypeId: z.coerce.number(),
  briefDescription: z
    .string()
    .min(1, 'This field is required')
    .max(255, "This field can't contain more than 255 characters"),
}) satisfies ZodObjectWithModelKeys<SpiffExtendedFormFields>;

type SpiffExtendedFormSchema = z.infer<typeof spiffExtendedFormSchema>;
type SpiffExtendedFormProps = {
  defaultValues: SpiffExtendedFormSchema;
  onSubmit: SaveHandler<SpiffExtendedFormSchema>;
  disabled?: boolean;
  disableSpiffAmountField?: boolean;
  formId?: string;
};

export const SpiffExtendedForm = ({
  defaultValues,
  onSubmit,
  disabled,
  disableSpiffAmountField = false,
  formId,
}: SpiffExtendedFormProps) => {
  const form = useForm<SpiffExtendedFormSchema>({
    resolver: zodResolver(spiffExtendedFormSchema),
    defaultValues,
    disabled,
  });

  const spiffTypesQuery = useSpiffTypeBatchGetQuery({
    filter: {
      isActive: true,
    },
    select(data) {
      return data.results.map((type) => ({
        label: escapeText(type.type),
        value: type.id.toString(),
      }));
    },
  });

  const formSubmit = form.handleSubmit((data) =>
    onSubmit({ data, dirtyFields: form.formState.dirtyFields }),
  );
  return (
    <div className="@container">
      <form
        id={formId}
        className="@md:grid-cols-2 @xl:grid-cols-4 grid grid-cols-1 gap-2"
        onSubmit={formSubmit}
      >
        <Form {...form}>
          <FormField
            control={form.control}
            name="spiffToolId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Spiff ID #</FormLabel>
                <FormControl>
                  <Input {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="referenceUrl"
            render={({ field }) => (
              <FormItem>
                <FormLabel>External URL</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="referenceNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Reference #</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="timeDue"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Time due</FormLabel>
                <FormControl>
                  <DateInput {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="spiffAmount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Amount</FormLabel>
                <FormControl>
                  <MoneyInput
                    {...field}
                    type="number"
                    required
                    disabled={disableSpiffAmountField}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="spiffJobNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Job #</FormLabel>
                <FormControl>
                  <JobSelector {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="datePerformed"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Date Performed</FormLabel>
                <FormControl>
                  <DateInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="spiffTypeId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Spiff Type</FormLabel>
                <FormControl>
                  <SimpleSelect
                    values={spiffTypesQuery.data ?? []}
                    selectedValue={field.value.toString()}
                    {...field}
                    disabled={field.disabled || !spiffTypesQuery.isSuccess}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="briefDescription"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
      </form>
    </div>
  );
};
