import { type Task } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTitle, toast } from '@kalos/ui';
import { DialogTrigger } from '@radix-ui/react-dialog';
import { type ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import { useAuth } from '../../../../context/AuthContext';
import { SpiffToolAdminActionForm } from './SpiffToolAdminActionForm';
import { useCreateOrUpdateSpiffToolAdminAction } from './useCreateOrUpdateSpiffToolAdminAction';

export const SpiffToolAdminActionCreateFormDialog = ({
  parentTask,
  trigger,
  onSave: onSaveProp,
  open,
}: {
  parentTask: Task;
  trigger: React.ReactNode;
  onSave?: () => void;
  open?: boolean;
}) => {
  const loggedUser = useAuth().user;
  const defaultValues = useMemo<ComponentProps<typeof SpiffToolAdminActionForm>['defaultValues']>(
    () => ({
      decisionDate: new Date(),
      reviewedBy: loggedUser.firstname + ' ' + loggedUser.lastname,
      status: 1,
      reason: '',
    }),
    [loggedUser.firstname, loggedUser.lastname],
  );

  const updateAction = useCreateOrUpdateSpiffToolAdminAction({ parentTask });
  const [isOpened, setIsOpened] = useState(open || false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsOpened(open || false);
  }, [open]);

  const onSave = useCallback<ComponentProps<typeof SpiffToolAdminActionForm>['onSubmit']>(
    async ({ data }) => {
      setIsLoading(true);
      try {
        await updateAction({ adminAction: data, type: 'Create' });
        setIsOpened(false);
        onSaveProp?.();
      } catch {
        toast({
          variant: 'destructive',
          title: 'Error updating action',
        });
      }
      setIsLoading(false);
    },
    [onSaveProp, updateAction],
  );

  return (
    <Dialog open={isOpened} onOpenChange={setIsOpened}>
      <DialogContent className="max-h-screen max-w-xl overflow-auto">
        <DialogTitle>Add status</DialogTitle>
        <SpiffToolAdminActionForm
          onSubmit={onSave}
          disabled={isLoading}
          loading={isLoading}
          defaultValues={defaultValues}
        />
      </DialogContent>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
    </Dialog>
  );
};
