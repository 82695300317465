import { Button } from '@kalos/ui';
import { Frown } from 'lucide-react';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from '../../../context/AuthContext';
import { EmployeeItemOptionsTable } from './EmployeeItemOptionsTable/EmployeeItemOptionsTable';
import { getHasAccessToEmployeeItemOptions } from './utils';

export const EmployeeItemOptionsPage = () => {
  const user = useAuth().user;
  const hasAccess = useMemo(() => getHasAccessToEmployeeItemOptions(user), [user]);

  const navigate = useNavigate();

  const onGoBack = () => {
    navigate(-1);
  };

  if (!hasAccess) {
    return (
      <div className="m-32 flex items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-4">
          <Frown className="text-primary" size={32} />
          <p>{"You don't have access to this page"}</p>
          <div className="flex items-center gap-4">
            <Button onClick={onGoBack}>Go back</Button>
            <Button asChild>
              <Link to={'/home'}>Go Home</Link>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return <EmployeeItemOptionsTable />;
};
