import { zodResolver } from '@hookform/resolvers/zod';
import { type Reimbursement } from '@kalos/kalos-rpc';
import {
  Button,
  Form as FormContext,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
} from '@kalos/ui';
import { type FC, useMemo } from 'react';
import { type SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';

import { JobSelector } from '../../../components/JobSelector';
import { type MostLikelyJobRequestFilter } from '../../../hooks/react-query/useEventsQuery';
import { type DirtyModelFields, timestamp } from '../../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';
import { TechniciansComboboxPicker } from '../../ComponentsLibrary/Pickers/newPickers/TechnicianComboboxPicker';

// Schema and types
type ReimbursementEditFormFields = Pick<
  Reimbursement,
  'jobNumber' | 'userId' | 'amount' | 'description'
>;

const reimbursementEditFormSchema = z.object({
  jobNumber: z.number(),
  userId: z.number(),
  amount: z.coerce.number(),
  description: z
    .string()
    .max(255, 'Description is too long (max 255 characters)')
    .min(1, 'Description is required'),
}) satisfies ZodObjectWithModelKeys<ReimbursementEditFormFields>;

type ReimbursementEditFormSchemaType = z.infer<typeof reimbursementEditFormSchema>;

// Form component
export const ReimbursementEditForm: FC<{
  onSave: (arg: {
    data: ReimbursementEditFormSchemaType;
    dirtyFields: DirtyModelFields<ReimbursementEditFormSchemaType>;
  }) => void;
  defaultValues: ReimbursementEditFormSchemaType;
  isLoading?: boolean;
  disabled?: boolean;
  disableTechnicianPicker?: boolean;
}> = ({ onSave, disableTechnicianPicker, disabled, defaultValues, isLoading }) => {
  const form = useForm<ReimbursementEditFormSchemaType>({
    resolver: zodResolver(reimbursementEditFormSchema),
    mode: 'onBlur',
    defaultValues,
    disabled,
  });

  const userId = useWatch({ control: form.control, name: 'userId' });

  const hint = useMemo<MostLikelyJobRequestFilter>(() => {
    return {
      userId,
      targetDate: timestamp(false, new Date()),
    };
  }, [userId]);

  const handleSave: SubmitHandler<ReimbursementEditFormSchemaType> = async (data) => {
    onSave({ data, dirtyFields: form.formState.dirtyFields });
  };

  return (
    <FormContext {...form}>
      <form
        onSubmit={form.handleSubmit(handleSave)}
        className="@sm:grid-cols-2 grid max-h-screen grid-cols-1 gap-4 overflow-auto p-4"
      >
        <FormField
          control={form.control}
          name="amount"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Amount</FormLabel>
              <FormMessage />
              <FormControl>
                <Input type="number" step="0.01" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="jobNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Job Number</FormLabel>
              <FormMessage />
              <FormControl>
                <JobSelector
                  hint={hint}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem className="col-span-1 sm:col-span-2">
              <FormLabel>Description</FormLabel>
              <FormMessage />
              <FormControl>
                <Textarea {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="userId"
          render={({ field }) => (
            <FormItem className="@sm:col-span-2">
              <FormLabel>Reimbursement for:</FormLabel>
              <FormMessage />
              <FormControl>
                <TechniciansComboboxPicker
                  disabled={field.disabled || disableTechnicianPicker}
                  selected={field.value.toString()}
                  onSelect={(u) => field.onChange(u?.id ?? 0)}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button
          type="submit"
          className="@sm:col-span-2 w-full flex-1"
          isLoading={isLoading}
          disabled={!form.formState.isValid || !form.formState.isDirty}
        >
          Save
        </Button>
      </form>
    </FormContext>
  );
};
