import { type Invoice, type InvoicePayment } from '@kalos/kalos-rpc';
import { type Row } from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  createInvoicePaymentLineContext,
  InvoicePaymentLinesStoreContext,
  useInvoiceIdContext,
} from './InvoicePaymentLineContext.context';

const InvoicePaymentLineContextProvider = ({
  invoicePayment,
  children,
  currentInvoiceId,
}: {
  invoicePayment: InvoicePayment;
  children: React.ReactNode;
  currentInvoiceId: Invoice['id'];
}) => {
  const store = useMemo(() => {
    return createInvoicePaymentLineContext({ invoicePayment: invoicePayment, currentInvoiceId });
  }, [currentInvoiceId, invoicePayment]);

  return (
    <InvoicePaymentLinesStoreContext.Provider value={store}>
      {children}
    </InvoicePaymentLinesStoreContext.Provider>
  );
};

export const InvoicePaymentLineContextProviderDataTableWrapper = ({
  row,
  children,
}: {
  row: Row<InvoicePayment>;
  children: React.ReactNode;
}) => {
  const invoiceId = useInvoiceIdContext();
  return (
    <InvoicePaymentLineContextProvider currentInvoiceId={invoiceId} invoicePayment={row.original}>
      {children}
    </InvoicePaymentLineContextProvider>
  );
};
