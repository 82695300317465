import { S3ClientService } from '../../tools/helpers';

export const getEmployeeImageQueryConfig = <TSelectData = string,>({
  imageUrl,
  select,
}: {
  imageUrl: string;
  select?: (data: string) => TSelectData;
}) => ({
  queryFn: () => {
    return S3ClientService.getFileS3BucketUrl(imageUrl, 'kalos-employee-images');
  },
  queryKey: ['employeeImage', imageUrl],
  select,
});
