import { format } from 'date-fns';

import { OPTION_BLANK } from '../../../../constants';
import { type ServicesRenderedPaymentType } from './Services';

export const SERVICES_RENDERED_PAYMENT_INITIAL: ServicesRenderedPaymentType = {
  servicesRenderedId: 0,
  servicesRendered: '',
  technicianNotes: '',
  paymentType: OPTION_BLANK,
  amountCollected: 0,
  paymentCollected: 0,
  paymentId: 0,
  dateProcessed: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
};
