import { type AUTH_KEY, type User } from '@kalos/kalos-rpc';

import { type KEY_EXPIRY } from '../App';

class StorageManager<T> {
  private readonly storage?: Storage;

  constructor(type: 'local' | 'session') {
    try {
      this.storage = type === 'local' ? window.localStorage : window.sessionStorage;
    } catch (error) {
      console.error(error);
    }
  }

  private defaultSerialize(value: T[keyof T]): string {
    return JSON.stringify(value);
  }

  private defaultDeserialize<K extends keyof T>(value: string): T[K] | undefined {
    try {
      return parseJSON(value) as T[K];
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return undefined;
    }
  }

  get length() {
    return this.storage?.length;
  }

  get<K extends keyof T>(
    key: K,
    deserialize: (value: string) => T[K] | undefined = this.defaultDeserialize,
  ): T[K] | undefined {
    if (!this.storage) return;

    try {
      const value = this.storage.getItem(key as string);
      return value !== null ? deserialize(value) : undefined;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  set<K extends keyof T>(
    key: K,
    value: T[K],
    serialize: (value: T[K]) => string = this.defaultSerialize,
  ) {
    if (!this.storage) return;

    try {
      const serializedValue = serialize(value);
      this.storage.setItem(key as string, serializedValue);
    } catch (error) {
      console.error(error);
    }
  }

  remove(key: string) {
    this.storage?.removeItem(key);
  }

  clear() {
    this.storage?.clear();
  }
}

export type SessionStorage = {
  any: unknown;
};
export type LocalStorage = {
  user: User;
  [AUTH_KEY]: string;
  [KEY_EXPIRY]: string;
  serviceCallSearchAdvancedSettingsAccordionValue: string;
  sideMenuDropdownState: {
    admin: boolean;
    dispatch: boolean;
    resources: boolean;
    tools: boolean;
    profile: boolean;
    search: boolean;
  };
};

export const localStorageManager = new StorageManager<LocalStorage>('local');
export const sessionStorageManager = new StorageManager<SessionStorage>('session');

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    console.log('parsing error on', { value });
    return undefined;
  }
}
