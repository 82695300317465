import { InvoicePaymentLine } from '@kalos/kalos-rpc';
import {
  Button,
  cn,
  Input,
  toast,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import {
  CheckCircledIcon,
  CrossCircledIcon,
  Link1Icon,
  LinkBreak2Icon,
  Pencil1Icon,
  TrashIcon,
  TriangleDownIcon,
} from '@radix-ui/react-icons';
import { useStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { useInvoicePaymentLineBatchMutation } from '../../../../../hooks/react-query/useInvoicePayment';
import { prettyMoney } from '../../../../../tools/helpers';
import { isTruthy } from '../../../../../tools/typeguargs';
import { useInvoicePaymentLinesStore } from './InvoicePaymentLineContext.context';
import { getInvoicePaymentLineFieldMask } from './utils';

export const EditSaveButton = () => {
  const store = useInvoicePaymentLinesStore();
  const { isEditing, setIsEditing } = useStore(
    store,
    useShallow((state) => ({ isEditing: state.isEditing, setIsEditing: state.setIsEditing })),
  );

  const batchMutation = useInvoicePaymentLineBatchMutation();

  const handleSave = async () => {
    const { original, invoicePaymentLines } = store.getState();

    const newLines = invoicePaymentLines.filter((line) => line.id === 0);

    const originalLinesIds = original.paymentLine.map((originalLine) => originalLine.id);
    const leftLinesIds = invoicePaymentLines.filter((line) => line.id !== 0).map((line) => line.id);

    const deletedLinesIds = originalLinesIds.filter((id) => !leftLinesIds.includes(id));

    const editedLines = invoicePaymentLines
      .filter((line) => line.id !== 0 && !deletedLinesIds.includes(line.id))
      .map((line) => {
        const originalLine = original.paymentLine.find((orig) => orig.id === line.id);
        if (!originalLine) return false;
        const fieldMask = getInvoicePaymentLineFieldMask({ original: originalLine, updated: line });
        return fieldMask ? InvoicePaymentLine.create({ ...line, fieldMask }) : false;
      })
      .filter(isTruthy);

    if (!newLines.length && !editedLines.length && !deletedLinesIds.length) {
      setIsEditing(false);
      return;
    }

    try {
      await batchMutation.mutateAsync({
        toCreate: newLines,
        toUpdate: editedLines,
        toDelete: deletedLinesIds.map((id) => InvoicePaymentLine.create({ id })),
      });
      toast({
        variant: 'success',
        title: 'Saved',
      });
    } catch {
      toast({
        variant: 'destructive',
        title: 'Error during saving',
      });
    }
    setIsEditing(false);
  };

  if (!isEditing)
    return (
      <Button onClick={() => setIsEditing(true)} size="sm">
        <Pencil1Icon />
      </Button>
    );

  return (
    <Button
      disabled={batchMutation.isPending}
      isLoading={batchMutation.isPending}
      onClick={handleSave}
      size="sm"
    >
      Save
    </Button>
  );
};

const EditAmountField = ({ original }: { original: InvoicePaymentLine }) => {
  const lineItemsStore = useInvoicePaymentLinesStore();
  const setLineItems = useStore(lineItemsStore, (state) => state.setInvoicePaymentLines);
  const currentInvoiceId = useStore(lineItemsStore, (state) => state.currentInvoiceId);
  const isWithinContextOfCurrentInvoice =
    original.invoiceId === currentInvoiceId || !original.invoiceId;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = parseFloat(e.target.value);
    setLineItems((prevLineItems) => {
      return prevLineItems.map((li) => {
        if (li === original)
          return InvoicePaymentLine.create({ ...li, amount: isNaN(newValue) ? 0 : newValue });
        return li;
      });
    });
  };
  return (
    <Tooltip>
      {!isWithinContextOfCurrentInvoice && (
        <TooltipContent>
          This line cannot be modified because it is attached to the other invoice
        </TooltipContent>
      )}
      <TooltipTrigger asChild>
        <span>
          <Input
            disabled={!isWithinContextOfCurrentInvoice}
            type="number"
            step={0.01}
            value={original.amount}
            onChange={onChange}
          />
        </span>
      </TooltipTrigger>
    </Tooltip>
  );
};

export const AmountCell = ({ original }: { original: InvoicePaymentLine }) => {
  const lineItemsStore = useInvoicePaymentLinesStore();
  const isEditing = useStore(lineItemsStore, (state) => state.isEditing);

  if (!isEditing) return prettyMoney(original.amount);

  return <EditAmountField original={original} />;
};

export const InvoicePaymentLineAmountColumnHeader = () => {
  const store = useInvoicePaymentLinesStore();
  const { isValid, currentAmount, originalAmount, diff } = useStore(
    store,
    useShallow((state) => {
      const currentAmount = state.invoicePaymentLines.reduce((acc, line) => {
        return acc + line.amount;
      }, 0);
      const originalAmount = state.original.amount;
      const isValid = currentAmount === originalAmount;

      const diff = originalAmount - currentAmount;

      return { currentAmount, originalAmount, isValid, diff };
    }),
  );

  return (
    <div className="flex flex-col items-center justify-center gap-0.5">
      <div className="flex flex-col items-center justify-center gap-1 text-xs">
        <span>
          Current transaction cost:{' '}
          <span className="text-foreground font-bold">{prettyMoney(originalAmount)}</span>
        </span>
        <span>
          Current line Items cost{' '}
          <span className={cn(isValid ? 'text-emerald-500' : 'text-red-500', 'font-bold')}>
            {prettyMoney(currentAmount)}
          </span>
        </span>
        {!isValid && diff !== 0 && (
          <div className="flex items-center justify-center gap-1">
            Diff:{' '}
            <span className="text-foreground font-bold">
              {prettyMoney(Number(diff.toFixed(2)))}
            </span>
            <span>
              <TriangleDownIcon
                className={cn(
                  'size-6 text-red-500 transition-transform',
                  diff > 0 ? 'rotate-180' : 'rotate-0',
                )}
              />
            </span>
          </div>
        )}
      </div>

      {isValid ? (
        <CheckCircledIcon className="size-6 text-emerald-500" />
      ) : (
        <CrossCircledIcon className="size-6 text-red-500" />
      )}
    </div>
  );
};

export const DeleteInvoicePaymentLineAction = ({ original }: { original: InvoicePaymentLine }) => {
  const store = useInvoicePaymentLinesStore();
  const { isEditing, deleteLineItem, currentInvoiceId } = useStore(
    store,
    useShallow((state) => {
      return {
        isEditing: state.isEditing,
        deleteLineItem: state.deleteLineItem,
        currentInvoiceId: state.currentInvoiceId,
      };
    }),
  );

  const isWithinContextOfCurrentInvoice =
    original.invoiceId === currentInvoiceId || !original.invoiceId;
  const isDisabled = !isEditing || (!!original.invoiceId && !isWithinContextOfCurrentInvoice);

  const onClick = () => {
    deleteLineItem(original);
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span>
          <Button size="icon" variant="outline" disabled={isDisabled} onClick={onClick}>
            <TrashIcon />
          </Button>
        </span>
      </TooltipTrigger>
      <TooltipContent>
        {!isWithinContextOfCurrentInvoice
          ? 'Attached to other invoice'
          : isDisabled
            ? 'Start editing to delete'
            : 'Delete'}
      </TooltipContent>
    </Tooltip>
  );
};

export const InvoiceActions = ({ original }: { original: InvoicePaymentLine }) => {
  const store = useInvoicePaymentLinesStore();
  const { isEditing, currentInvoiceId, setInvoicePaymentLines } = useStore(
    store,
    useShallow((state) => {
      return {
        isEditing: state.isEditing,
        currentInvoiceId: state.currentInvoiceId,
        setInvoicePaymentLines: state.setInvoicePaymentLines,
      };
    }),
  );
  const confirm = useConfirm();

  const onApplyClick = () => {
    setInvoicePaymentLines((prevLineItems) => {
      return prevLineItems.map((li) => {
        if (li === original)
          return InvoicePaymentLine.create({ ...li, invoiceId: currentInvoiceId });
        return li;
      });
    });
  };

  const onRemoveClick = async () => {
    if (
      !(await confirm('Are you sure you want to remove this line item from the current invoice?'))
    ) {
      return;
    }
    setInvoicePaymentLines((prevLineItems) => {
      return prevLineItems.map((li) => {
        if (li === original) return InvoicePaymentLine.create({ ...li, invoiceId: 0 });
        return li;
      });
    });
  };

  const isApplicableToCurrentInvoice =
    original.invoiceId === currentInvoiceId || !original.invoiceId;

  if (!isApplicableToCurrentInvoice) return null;

  if (!original.invoiceId)
    return (
      <Tooltip>
        <TooltipContent>
          {!isEditing ? 'Start editing to apply' : 'Apply to the current invoice'}
        </TooltipContent>
        <TooltipTrigger asChild>
          <span>
            <Button onClick={onApplyClick} disabled={!isEditing} size="icon" variant="outline">
              <Link1Icon />
            </Button>
          </span>
        </TooltipTrigger>
      </Tooltip>
    );

  if (original.invoiceId)
    return (
      <Tooltip>
        <TooltipContent>
          {!isEditing
            ? 'Start editing to remove from the current invoice'
            : 'Remove from the current invoice'}
        </TooltipContent>
        <TooltipTrigger asChild>
          <span>
            <Button onClick={onRemoveClick} disabled={!isEditing} size="icon" variant="outline">
              <LinkBreak2Icon />
            </Button>
          </span>
        </TooltipTrigger>
      </Tooltip>
    );
};
