import './Form.module.less';

import { forwardRef, type ReactNode, useCallback, useState } from 'react';

import { type ActionsProps } from '../Actions';
import { type Props as ButtonProps } from '../Button';
import { type Options as FieldOptions } from '../Field';
import { getDefaultValueByType } from '../Field/Field.utils';
import {
  PlainForm,
  type PlainFormProps,
  type Schema as PlainFormSchema,
  type Validation,
} from '../PlainForm';
import { type PaginationType, SectionBar } from '../SectionBar';

export type Schema<T> = PlainFormSchema<T>;

export type Options = FieldOptions;

export interface Props<T> extends PlainFormProps<T> {
  title?: string;
  subtitle?: ReactNode;
  intro?: ReactNode;
  onSave: (data: T) => void;
  onClose?: (() => void) | null;
  onChange?: (data: T) => void;
  onClear?: (name: string) => void;
  filter?(a: any): boolean;
  actions?: ButtonProps[];
  pagination?: PaginationType;
  submitLabel?: string;
  submitDisabled?: boolean;
  cancelDisabled?: boolean;
  cancelLabel?: string;
  stickySectionBar?: boolean;
  inputFieldRefs?: any[];
  validationsProp?: Validation;
  formClassName?: string;
  validateOnChange?: boolean; // Will only display validations after the first save of the form
  attachFieldMask?: boolean;
  className?: string;
}

// ? The '| null' at the end of the type for the forward ref is simply there to ensure Typescript doesn't error
export const Form: <T>(props: Props<T>) => React.ReactNode = forwardRef(
  (
    {
      title,
      subtitle,
      intro,
      schema,
      data,
      onSave,
      onClose,
      onChange,
      onClear,
      disabled = false,
      readOnly = false,
      actions = [],
      pagination,
      submitLabel = 'Save',
      cancelLabel = 'Cancel',
      submitDisabled = false,
      cancelDisabled = false,
      error,
      warning,
      className = '',
      errorClassName,
      formClassName,
      stickySectionBar = false,
      children,
      inputFieldRefs,
      validationsProp = {},
      validateOnChange = false,
      attachFieldMask,
    },
    functionRef,
  ) => {
    const [formKey, setFormKey] = useState<number>(0);
    const [formData, setFormData] = useState(
      schema.reduce(
        (aggr, fields) => ({
          ...aggr,
          ...fields.reduce(
            (aggr, { name, type = 'text' }) =>
              name === undefined
                ? aggr
                : {
                    ...aggr,
                    [name]: data[name] !== undefined ? data[name] : getDefaultValueByType(type),
                  },
            {},
          ),
        }),
        {} as typeof data,
      ),
    );
    const [validations, setValidations] = useState<Validation>(validationsProp);
    const handleValidate = useCallback(
      async (formData: typeof data) => {
        setValidations({});
        let validation: Validation = {};
        if (Object.keys(validationsProp).length > 0) {
          validation = { ...validationsProp };
        }
        for (const fields of schema) {
          const requiredFields = fields.filter(
            ({ required, validationOnUpdate }) => required || validationOnUpdate,
          );
          for (const {
            name,
            type = 'text',
            options,
            validationOnUpdate,
            required,
          } of requiredFields) {
            if (name) {
              const value: string = '' + formData[name];
              if (required) {
                if (
                  formData[name] === undefined ||
                  value === '' ||
                  ((['classCode', 'department', 'eventId', 'technician'].includes(type) ||
                    !!options) &&
                    value === '0')
                ) {
                  validation[name as string] = 'This field is required.';
                }
                if (type === 'eventId' && typeof formData[name] === 'string') {
                  validation[name as string] = 'Invalid Job ID.';
                }
              }
              if (validationOnUpdate) {
                const result = validationOnUpdate(value);
                if (typeof result === 'string') {
                  if (result !== '') {
                    validation[name as string] = result;
                  } else {
                    delete validation[name as string];
                  }
                } else {
                  const res = await result;
                  if (res !== '') {
                    validation[name as string] = res;
                  } else {
                    delete validation[name as string];
                  }
                }
              }
            }
          }
        }
        if (Object.keys(validation).length > 0) {
          setValidations(validation);
          return;
        }
      },
      [schema, setValidations, validationsProp],
    );

    const handleChange = useCallback(
      (formData: typeof data) => {
        setFormData(formData);
        if (validateOnChange && Object.keys(validations).length > 0) {
          handleValidate(formData);
        }
        if (onChange) {
          onChange(formData);
        }
      },
      [setFormData, onChange, handleValidate, validateOnChange, validations],
    );

    const handleSave = useCallback(async () => {
      setValidations({});
      let validation: Validation = {};
      if (Object.keys(validationsProp).length > 0) {
        validation = { ...validations, ...validationsProp };
      }
      for (const fields of schema) {
        const requiredFields = fields.filter(
          ({ required, validationOnSave, validationOnUpdate, performUpdateValidationOnSave }) =>
            required || validationOnSave || (performUpdateValidationOnSave && validationOnUpdate),
        );
        for (const {
          name,
          type = 'text',
          options,
          validationOnSave,
          performUpdateValidationOnSave,
          validationOnUpdate,
          required,
        } of requiredFields) {
          if (name) {
            const value: string = '' + formData[name];
            if (required) {
              if (
                formData[name] === undefined ||
                value === '' ||
                ((['classCode', 'department', 'eventId', 'technician'].includes(type) ||
                  !!options) &&
                  value === '0')
              ) {
                validation[name as string] = 'This field is required.';
              }
              if (type === 'eventId' && typeof formData[name] === 'string') {
                validation[name as string] = 'Invalid Job ID.';
              }
            }
            if (validationOnSave || (performUpdateValidationOnSave && validationOnUpdate)) {
              const result = validationOnSave
                ? validationOnSave(value)
                : validationOnUpdate!(value);
              if (typeof result === 'string') {
                if (result !== '') {
                  validation[name as string] = result;
                } else {
                  delete validation[name as string];
                }
              } else {
                const res = await result;
                if (res !== '') {
                  validation[name as string] = res;
                } else {
                  delete validation[name as string];
                }
              }
            }
          }
        }
      }
      if (Object.keys(validation).length > 0) {
        setValidations(validation);
        return;
      }
      onSave(formData);
    }, [onSave, formData, schema, setValidations, validationsProp, validations]);

    return (
      <div className={className}>
        {title && (
          <SectionBar
            title={title}
            subtitle={subtitle}
            actions={[
              ...actions,
              ...(readOnly
                ? []
                : [
                    {
                      label: submitLabel,
                      onClick: handleSave,
                      disabled: disabled || submitDisabled,
                    },
                  ]),
              ...(onClose !== null
                ? ([
                    {
                      label: readOnly ? 'Close' : cancelLabel,
                      onClick: () => {
                        if (onClose) {
                          setFormKey((prev) => prev + 1);
                          onClose();
                        }
                      },
                      disabled: disabled || cancelDisabled,
                      variant: readOnly ? 'contained' : 'outlined',
                    },
                  ] as ActionsProps)
                : []),
            ]}
            fixedActions
            pagination={pagination}
            sticky={stickySectionBar}
          />
        )}
        {intro && <div className="FormIntro">{intro}</div>}
        <PlainForm<typeof data>
          schema={schema}
          key={formKey}
          data={data}
          onChange={handleChange}
          disabled={disabled}
          error={error}
          warning={warning}
          readOnly={readOnly}
          validations={validations}
          ref={functionRef}
          className={formClassName}
          errorClassName={errorClassName}
          inputFieldRefs={inputFieldRefs}
          onClear={onClear}
          attachFieldMask={attachFieldMask}
        >
          {children}
        </PlainForm>
        {!title && (
          <button
            // @ts-ignore
            ref={functionRef}
            onClick={handleSave}
            style={{ display: 'none' }}
          >
            submit
          </button>
        )}
      </div>
    );
  },
);
