import { type User } from '@kalos/kalos-rpc';

import { useBatchUserQuery } from '../../../../hooks/react-query/useUserQuery';
import { QueryComboboxPicker, type QueryComboboxPickerProps } from './QueryPickerV2';
import { renderUser, techniciansUserFilter } from './utils';

type TechniciansComboboxPickerProps = Omit<
  QueryComboboxPickerProps<User, Parameters<typeof useBatchUserQuery>['0']>,
  'query' | 'queryArgs' | 'renderItem'
> &
  Partial<
    Pick<QueryComboboxPickerProps<User, Parameters<typeof useBatchUserQuery>['0']>, 'renderItem'>
  >;

export const TechniciansComboboxPicker = (props: TechniciansComboboxPickerProps) => {
  return (
    <QueryComboboxPicker
      query={useBatchUserQuery}
      queryArgs={{
        filters: techniciansUserFilter,
      }}
      {...props}
      renderItem={props.renderItem ?? renderUser}
    />
  );
};
