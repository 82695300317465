import { Inspection, type Permit } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTrigger } from '@kalos/ui';
import { useCallback, useMemo, useState } from 'react';

import { useCreateInspectionMutation } from '../../../../../../hooks/react-query/usePermits';
import { timestamp } from '../../../../../../tools/helpers';
import { InspectionForm } from './InspectionForm';

export const InspectionCreateDialog = ({
  trigger,
  permitId,
}: {
  trigger: React.ReactNode;
  permitId: Permit['id'];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const createPermitMutation = useCreateInspectionMutation();

  const defaultValues = useMemo(() => InspectionForm.getDefaultValues(), []);

  const onSubmit: React.ComponentProps<typeof InspectionForm>['onSave'] = useCallback(
    async ({ data }) => {
      try {
        await createPermitMutation.mutateAsync(
          Inspection.create({
            ...data,
            scheduledDate: timestamp(false, data.scheduledDate),
            requestorScheduledDate: timestamp(false, data.requestorScheduledDate),
            permitId,
          }),
        );
        setIsOpen(false);
      } catch (error) {
        console.error(error);
      }
    },
    [createPermitMutation, permitId],
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="@container">
        {createPermitMutation.isError && (
          <p className="mb-2 text-red-500">Something went wrong during creation</p>
        )}
        <InspectionForm
          defaultValues={defaultValues}
          onSave={onSubmit}
          disabled={createPermitMutation.isPending}
          isLoading={createPermitMutation.isPending}
        />
      </DialogContent>
    </Dialog>
  );
};
