import { type ToolFundSettings } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { InventoryClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type ToolFundSettingsFilter = EntityFilter<ToolFundSettings>;

export const useGetToolFundSettings = (req: ToolFundSettings) => {
  return useQuery({
    queryKey: [
      queryKeys.toolFundSettings.root,
      queryKeys.toolFundSettings.list,
      queryKeys.toolFundSettings.getHash(req),
    ],
    queryFn: async () => {
      return await InventoryClientService.GetToolFundSettings(req);
    },
  });
};

export const useToolFundSettingsUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (settings: ToolFundSettings) => {
      return await InventoryClientService.UpdateToolFundSettings(settings);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.toolFundSettings.root],
      });
    },
  });
};

export const useToolFundCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (settings: ToolFundSettings) => {
      return await InventoryClientService.CreateToolFundSettings(settings);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.toolFundSettings.root],
      });
    },
  });
};
