import { InvoicePayment, type User } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTitle, DialogTrigger, toast } from '@kalos/ui';
import { useCallback, useMemo, useState } from 'react';

import { useAuth } from '../../../../../context/AuthContext';
import { useInvoicePaymentCreateMutation } from '../../../../../hooks/react-query/useInvoicePayment';
import { timestamp } from '../../../../../tools/helpers';
import { InvoicePaymentForm } from './InvoicePaymentForm';

export const InvoicePaymentCreateCreateDialog = ({
  trigger,
  customerId,
}: {
  trigger: React.ReactNode;
  customerId: User['id'];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const createInvoicePaymentMutation = useInvoicePaymentCreateMutation();
  const loggedUserId = useAuth().user.id;

  const defaultValues = useMemo(() => InvoicePaymentForm.getDefaultValues(), []);

  const onSubmit: React.ComponentProps<typeof InvoicePaymentForm>['onSave'] = useCallback(
    async ({ data }) => {
      try {
        await createInvoicePaymentMutation.mutateAsync(
          InvoicePayment.create({
            ...data,
            customerId,
            creatorId: loggedUserId,
            paymentDate: timestamp(true, data.paymentDate),
            createdDate: timestamp(true, new Date()),
            isActive: true,
          }),
        );
        setIsOpen(false);
        toast({
          variant: 'success',
          title: 'Invoice Payment created',
        });
      } catch (error) {
        console.error(error);
      }
    },
    [createInvoicePaymentMutation, customerId, loggedUserId],
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="@container">
        <DialogTitle>Create Invoice Payment</DialogTitle>
        {createInvoicePaymentMutation.isError && (
          <p className="mb-2 text-red-500">Something went wrong during creation</p>
        )}
        <InvoicePaymentForm
          defaultValues={defaultValues}
          onSave={onSubmit}
          disabled={createInvoicePaymentMutation.isPending}
          isLoading={createInvoicePaymentMutation.isPending}
        />
      </DialogContent>
    </Dialog>
  );
};
