import { type User } from '@kalos/kalos-rpc';

import { RequireAuth } from '../../auth';

export const withAuth = function withAuth(
  input: JSX.Element,
  userCheck?: (user: User) => boolean,
  ...permissions: number[]
) {
  // todo, extend permissions to include required flag, split permissions into required and denied permissions here
  return (
    <RequireAuth userCheck={userCheck} requiredPermissions={permissions}>
      {input}
    </RequireAuth>
  );
};
