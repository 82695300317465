import { type InvoicePaymentLine } from '@kalos/kalos-rpc';

export const getInvoicePaymentLineFieldMask = ({
  original,
  updated,
}: {
  original: InvoicePaymentLine;
  updated: InvoicePaymentLine;
}) => {
  const fieldMask: string[] = [];
  if (original.amount !== updated.amount) {
    fieldMask.push('Amount');
  }
  if (original.invoiceId !== updated.invoiceId) {
    fieldMask.push('InvoiceId');
  }

  return fieldMask.length ? fieldMask : undefined;
};
