import { zodResolver } from '@hookform/resolvers/zod';
import { type Reimbursement } from '@kalos/kalos-rpc';
import { useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';

export type ReimbursementSearchFormFields = Pick<
  Reimbursement,
  'statusId' | 'description' | 'createdDate'
>;

const reimbursementSearchFormSchema = z.object({
  createdDate: z.string(),
  description: z.string(),
  statusId: z.coerce.number(),
}) satisfies ZodObjectWithModelKeys<ReimbursementSearchFormFields>;

export type ReimbursementSearchFormSchema = z.infer<typeof reimbursementSearchFormSchema>;

export const useReimbursementSearchForm = ({
  defaultValues,
}: {
  defaultValues: ReimbursementSearchFormSchema;
}) => {
  return useForm<ReimbursementSearchFormSchema>({
    defaultValues,
    resolver: zodResolver(reimbursementSearchFormSchema),
    mode: 'onChange',
  });
};

export const useReimbursementSearchFormContext = () => {
  return useFormContext<ReimbursementSearchFormSchema>();
};
