import { zodResolver } from '@hookform/resolvers/zod';
import { type SpiffOption } from '@kalos/kalos-rpc';
import { useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { type ZodObjectWithModelKeys } from '../../../../../tools/typeguargs';

type PieceWorkSearchFormFields = Pick<SpiffOption, 'name' | 'code' | 'amount'>;
export const pieceWorkSearchScheme = z.object({
  name: z.string(),
  code: z.string(),
  amount: z.number(),
}) satisfies ZodObjectWithModelKeys<PieceWorkSearchFormFields>;
export type PieceWorkSearchScheme = z.infer<typeof pieceWorkSearchScheme>;
export const usePieceWorkSearchForm = ({
  defaultValues,
}: {
  defaultValues: PieceWorkSearchScheme;
}) => {
  return useForm<PieceWorkSearchScheme>({
    defaultValues,
    resolver: zodResolver(pieceWorkSearchScheme),
    mode: 'onChange',
  });
};

export const usePieceWorkSearchFormContext = () => {
  return useFormContext<PieceWorkSearchScheme>();
};
