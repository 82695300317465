import { zodResolver } from '@hookform/resolvers/zod';
import { Task } from '@kalos/kalos-rpc';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Combobox,
  DateInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@kalos/ui';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { format } from 'date-fns-tz';
import { useCallback, useMemo } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { useAuth } from '../../../context/AuthContext';
import { useSpiffOptionBatchGetQuery } from '../../../hooks/react-query/useTaskClientServiceQuery';
import { useEvent } from '../../../hooks/useEvent';
import { type DirtyModelFields, TaskClientService } from '../../../tools/helpers';
import { TechniciansComboboxPicker } from '../Pickers/newPickers/TechnicianComboboxPicker';
import { type PieceWorkSchema, pieceWorkSchema } from './schema';

const getDefaultPieceWorkValues = (arg?: Partial<PieceWorkSchema>) => {
  return {
    externalId: 0,
    datePerformed: new Date(),
    optionId: 0,
    ...arg,
  } satisfies PieceWorkSchema;
};

export const PieceWorkApplyForm = ({
  onSubmit,
  onClose,
  disabled,
  isLoading,
  eventId,
  onMissingOptionAlertCtaClick,
}: {
  onSubmit: (arg: {
    data: PieceWorkSchema;
    dirtyFields: DirtyModelFields<PieceWorkSchema>;
  }) => void;
  disabled?: boolean;
  onClose: () => void;
  isLoading?: boolean;
  showTradesField?: boolean;
  eventId: number;
  onMissingOptionAlertCtaClick?: () => void;
}) => {
  const auth = useAuth();

  const form = useForm<PieceWorkSchema>({
    resolver: zodResolver(pieceWorkSchema),
    defaultValues: useMemo(
      () =>
        getDefaultPieceWorkValues({
          externalId: auth.user.id,
        }),
      [auth.user.id],
    ),
    disabled,
  });
  const onSubmitEvent = useEvent(onSubmit);

  const spiffOptionQuery = useSpiffOptionBatchGetQuery({
    filter: {
      isActive: true,
      eventId,
      withoutLimit: true,
    },
    select(data) {
      const selectOptions =
        data?.results.map((option) => ({
          label: `${option.name}-${option.amount}`,
          value: option.id.toString(),
        })) ?? [];
      selectOptions.push({ value: '0', label: 'None' });
      return {
        ...data,
        selectOptions,
      };
    },
  });
  const isSubmitDisabled =
    disabled ||
    !(
      form.formState.dirtyFields && Object.values(form.formState.dirtyFields).filter(Boolean).length
    ) ||
    isLoading;

  const _onSubmit: SubmitHandler<PieceWorkSchema> = useCallback(
    async (data) => {
      console.log({ data });
      onSubmitEvent({ data, dirtyFields: form.formState.dirtyFields });
      const spiffRequest = Task.create();
      spiffRequest.externalCode = 'user';
      spiffRequest.externalId = auth.user.id;
      spiffRequest.datePerformed = format(data.datePerformed, 'yyyy-MM-dd', {
        timeZone: 'America/New_York',
      });
      spiffRequest.billableType = 'Spiff';
      spiffRequest.creatorUserId = auth.user.id;
      if (spiffOptionQuery.data && spiffOptionQuery.data.results) {
        spiffRequest.spiffAmount =
          spiffOptionQuery.data?.results.find((i) => i.id === data.optionId)?.amount || 0;
        spiffRequest.briefDescription =
          spiffOptionQuery.data?.results.find((i) => i.id === data.optionId)?.name || '';
      }
      spiffRequest.spiffTypeId = 24;
      spiffRequest.spiffJobNumber = eventId.toString();
      spiffRequest.statusId = 1;
      spiffRequest.priorityId = 2;
      const res = await TaskClientService.Create(spiffRequest);
      const id = res.id;
      const updateReq = Task.create();
      updateReq.id = id;
      updateReq.fieldMask = ['AdminActionId'];
      updateReq.adminActionId = 0;
      await TaskClientService.Update(updateReq);
      onClose();
    },
    [
      onSubmitEvent,
      form.formState.dirtyFields,
      auth.user.id,
      spiffOptionQuery.data,
      eventId,
      onClose,
    ],
  );

  return (
    <Form {...form}>
      <form
        className="@xl:grid-cols-4 grid grid-cols-1 gap-x-4 gap-y-3"
        onSubmit={form.handleSubmit(_onSubmit)}
      >
        <FormField
          control={form.control}
          name="externalId"
          render={({ field }) => {
            return (
              <FormItem className="flex-1 items-center">
                <div className="flex gap-1">
                  <FormLabel>Piece Work Apply For:</FormLabel>
                  <FormMessage />
                </div>
                <TechniciansComboboxPicker
                  selected={field.value.toString()}
                  onSelect={(user) => {
                    field.onChange(user?.id ?? 0);
                  }}
                  emptyOption={'Not selected'}
                  disabled={true}
                />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="datePerformed"
          render={({ field }) => (
            <FormItem className="flex flex-col justify-between">
              <FormLabel>Date Performed</FormLabel>
              <FormMessage />
              <DateInput value={field.value} onChange={field.onChange} />
            </FormItem>
          )}
        />

        {spiffOptionQuery.data?.results?.length === 0 && (
          <Alert className="flex flex-col" variant="info">
            <div className="flex items-center gap-2">
              <InfoCircledIcon className="size-8" />
              <div>
                <AlertTitle>No associated piece work found</AlertTitle>
                <AlertDescription>
                  You need to create associated piece work options first
                </AlertDescription>
              </div>
            </div>
            <Button
              onClick={onMissingOptionAlertCtaClick}
              type="button"
              size="sm"
              variant="secondary"
              className="mt-2 w-full"
            >
              Create Work Piece
            </Button>
          </Alert>
        )}
        <FormField
          control={form.control}
          name="optionId"
          render={({ field }) => {
            return (
              <FormItem className="flex-1 items-center">
                <div className="flex items-center gap-1">
                  <FormLabel>Piece Work Option</FormLabel>
                  <FormMessage />
                </div>
                <FormControl>
                  <Combobox
                    disabled={spiffOptionQuery.data?.results?.length === 0}
                    onChange={field.onChange}
                    value={field.value.toString()}
                    values={spiffOptionQuery.data?.selectOptions || []}
                  />
                </FormControl>
              </FormItem>
            );
          }}
        />
        <Button
          disabled={isSubmitDisabled}
          isLoading={isLoading}
          className="@sm:col-span-2"
          type="submit"
        >
          Save
        </Button>
      </form>
    </Form>
  );
};

PieceWorkApplyForm.getDefaultValues = getDefaultPieceWorkValues;
