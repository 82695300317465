import { type EmployeeItemOption } from '@kalos/kalos-rpc';
import {
  Button,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';

import { useEmployeeItemOptionDeleteMutation } from '../../../../hooks/react-query/employeeItems';
import { EmployeeItemOptionFormEditDialog } from '../EmployeeItemOptionForm/EmployeeItemOptionEditDialog';

export const EmployeeItemOptionsTableActions = ({
  employeeItemOption,
}: {
  employeeItemOption: EmployeeItemOption;
}) => {
  const confirm = useConfirm();
  const deleteEmployeeItemOptionMutation = useEmployeeItemOptionDeleteMutation();

  const handleDelete = async () => {
    if (!(await confirm('Are you sure you want to delete this item option?'))) return;

    try {
      await deleteEmployeeItemOptionMutation.mutateAsync(employeeItemOption);
      toast({
        title: 'Successfully deleted item option',
      });
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Failed to delete item option',
      });
      console.error(error);
    }
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipContent>Edit</TooltipContent>
        <EmployeeItemOptionFormEditDialog
          original={employeeItemOption}
          trigger={
            <TooltipTrigger>
              <Button size="icon" variant="outline">
                <Pencil1Icon />
              </Button>
            </TooltipTrigger>
          }
        />
      </Tooltip>

      <Tooltip>
        <TooltipContent>Delete</TooltipContent>
        <TooltipTrigger>
          <Button
            onClick={handleDelete}
            size="icon"
            disabled={deleteEmployeeItemOptionMutation.isPending}
            isLoading={deleteEmployeeItemOptionMutation.isPending}
            variant="outline"
          >
            <TrashIcon />
          </Button>
        </TooltipTrigger>
      </Tooltip>
    </TooltipProvider>
  );
};
