'use client';
import * as React from 'react';
import { createStore } from 'zustand';

import { type ScrollState, ScrollStoreContext } from './ScrollStoreContext.hooks';

export const ScrollStoreProvider = ({ children }: React.PropsWithChildren) => {
  const [store] = React.useState(() =>
    createStore<ScrollState>((set) => ({
      isScrolling: false,
      actions: {
        setIsScrolling: (isScrolling: boolean) => set({ isScrolling }),
      },
    })),
  );

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const { setIsScrolling } = store.getState().actions;

    const handleScroll = () => {
      setIsScrolling(true);

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsScrolling(false);
      }, 150);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, [store]);

  return <ScrollStoreContext.Provider value={store}>{children}</ScrollStoreContext.Provider>;
};
