import { type Reimbursement } from '@kalos/kalos-rpc';
import { memo, useMemo } from 'react';

import { REIMBURSEMENT_STATUSES } from './constants';

export const ReimbursementStatusLabel = memo(({ id }: { id: Reimbursement['statusId'] }) => {
  const status = useMemo(() => REIMBURSEMENT_STATUSES.find((s) => s.value === id), [id]);
  if (!status) return null;
  return (
    <div className="flex items-center gap-2">
      <div className="size-4 rounded-full" style={{ background: status.color }} />
      <span>{status.label}</span>
    </div>
  );
});
