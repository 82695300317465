import { type Document, File } from '@kalos/kalos-rpc';
import Alert from '@mui/material/Alert';
import { type FC, useCallback, useState } from 'react';

import {
  DocumentClientService,
  FileClientService,
  getFileExt,
  S3ClientService,
  timestamp,
} from '../../../tools/helpers';
import { Alert as AlertComponent } from '../Alert';
import { Form, type Schema } from '../Form';

interface Props {
  onClose: (() => void) | null;
  onUpload?: (() => void) | null;
  fullWidth?: boolean;
  documentReq: Document;
}

type Entry = {
  file?: string;
  description: string;
};
export type CostCenter = {
  value: number;
  label: string;
};
export const UploadCustomDocument: FC<Props> = ({
  onClose,
  documentReq,
  fullWidth = true,
  onUpload,
}) => {
  const [fileData, setFileData] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [stringError, setStringError] = useState<string | undefined>();

  const [nameValidationError, setNameValidationError] = useState<string | undefined>(undefined);
  const [formData, setFormData] = useState<Entry>({
    file: '',
    description: '',
  });
  const [formKey, setFormKey] = useState<number>(0);
  const handleFileLoad = useCallback((fileData: string) => setFileData(fileData), [setFileData]);

  const handleSubmit = useCallback(
    async (data: Entry) => {
      if (data.description.includes('/') || data.description.includes('\\')) {
        setNameValidationError('The description field cannot contain the characters "/" or "\\".');
        setError(true);
        return;
      }
      setSaved(false);
      setError(false);
      setSaving(true);

      if (data.file) {
        const ext = getFileExt(data.file);
        const name = `${data.description}-${data.file}--${Math.floor(Date.now() / 1000)}.${ext}`;
        const fileReq = File.create();

        fileReq.createTime = timestamp();
        fileReq.name = name;
        const newDocument = documentReq;
        //newDocument.type = 5;
        //fileReq.bucket = 'testbuckethelios';

        fileReq.bucket = 'kalosdocs-prod';

        try {
          const s3res = await S3ClientService.uploadFileToS3Bucket(name, fileData, fileReq.bucket);
          if (s3res === 'ok') {
            const fileRes = await FileClientService.Create(fileReq);
            newDocument.fileId = fileRes!.id;
            newDocument.filename = fileRes!.name;

            newDocument.dateCreated = fileRes!.createTime;
            newDocument.description = fileRes!.name;
            await DocumentClientService.Create(newDocument);
          } else {
            setStringError('We had an issue with the upload');
          }
        } catch {
          setStringError(
            'There was an issue uploading this Document. Please try again or notify Webtech',
          );
        }
        setTimeout(() => {
          onClose?.();
        }, 3000);
      }
      setSaving(false);
      setSaved(true);
      setFormKey(formKey + 1);

      if (onUpload) onUpload();
    },
    [onUpload, documentReq, formKey, fileData, onClose],
  );

  const SCHEMA: Schema<Entry> = [
    [
      {
        name: 'file',
        label: 'Document/Photo',
        type: 'file',
        required: true,
        validationOnSave(value) {
          return !value ? 'This field is required' : '';
        },
        onFileLoad: handleFileLoad,
      },
    ],
    [
      {
        name: 'description',
        label: 'File Name',
        type: 'text',
        required: true,
        validationOnSave(value) {
          return value.trim().replaceAll(' ', '').length <= 0 ? 'This field is required' : '';
        },
      },
    ],
  ] as Schema<Entry>;

  return (
    <>
      {stringError && (
        <AlertComponent
          open={stringError != undefined}
          onClose={() => setStringError(undefined)}
          title="Error"
        >
          {stringError}
        </AlertComponent>
      )}
      <Form<Entry>
        key={formKey}
        title={'Upload Property Document'}
        schema={SCHEMA}
        data={formData}
        onClose={onClose}
        onSave={handleSubmit}
        onChange={setFormData}
        submitLabel="Upload"
        cancelLabel="Close"
        disabled={saving || saved}
        intro={
          saved && (
            <Alert severity="success" className="-m-4">
              <big>Document Uploaded!</big>
              <br />
              You can upload another file.
            </Alert>
          )
        }
        error={
          error &&
          (nameValidationError ? (
            nameValidationError
          ) : (
            <>Error while uploading file. Please try again.</>
          ))
        }
        fullWidth={fullWidth}
      />
    </>
  );
};
