import './CustomerEdit.module.less';

import { type EmailConfig, type Group, Property, User, UserGroupLink } from '@kalos/kalos-rpc';
import { type FC, useCallback, useEffect, useState } from 'react';

import { BILLING_TERMS_OPTIONS, USA_STATES_OPTIONS } from '../../../constants';
import {
  EmailClientService,
  GroupClientService,
  makeFakeRows,
  MapClientService,
  PropertyClientService,
  UserClientService,
  UserGroupLinkClientService,
} from '../../../tools/helpers';
import { Field, type Value } from '../Field';
import { Form, type Schema } from '../Form';
import { InfoTable } from '../InfoTable';
import { SectionBar } from '../SectionBar';

interface Props {
  onSave?: (data: User) => void;
  onClose: () => void;
  userId?: number;
  customer?: User;
  groups?: Group[];
  groupLinks?: UserGroupLink[];
  viewedAsCustomer?: boolean;
}

export const CustomerEdit: FC<Props> = ({
  onSave,
  onClose,
  userId: _userId = 0,
  customer: _customer,
  groups: _groups,
  groupLinks: _groupLinks,
  viewedAsCustomer = false,
}) => {
  const [userId, setUserId] = useState<number>(_userId);
  const [formKey, setFormKey] = useState<number>(0);
  const [createPropertyFlag, setCreatePropertyFlag] = useState<number>(0);

  const [customer, setCustomer] = useState<User>(_customer || User.create());
  const [groupLinks, setGroupLinks] = useState<UserGroupLink[]>(_groupLinks || []);
  const [groupLinksInitial, setGroupLinksInitial] = useState<UserGroupLink[]>(_groupLinks || []);
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error] = useState<string>('');

  const [groups, setGroups] = useState<Group[]>(_groups || []);

  const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const zipCodePlus4Regex = /^\d{5}(-\d{4})?$/;

  const load = useCallback(async () => {
    if (userId) {
      if (!_customer) {
        const customer = await UserClientService.loadUserById(userId);
        setCustomer(customer);
      }
      if (!_groupLinks) {
        const groupLinks = await UserGroupLinkClientService.loadUserGroupLinksByUserId(userId);
        setGroupLinks(groupLinks!.results);
        setGroupLinksInitial(groupLinks!.results);
      }
    }
    if (!_groups) {
      const groups = await GroupClientService.loadGroups();
      setGroups(groups!.results);
    }
    setFormKey(formKey + 1);
    setLoading(false);
  }, [
    setGroups,
    userId,
    setCustomer,
    formKey,
    setLoading,
    setGroupLinks,
    setGroupLinksInitial,
    _customer,
    _groups,
    _groupLinks,
  ]);
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      load();
    }
  }, [loaded, setLoaded, load]);
  const sendEmail = useCallback(
    async (newUser: User) => {
      const body = `<body>

    <table cellpadding="0" cellspacing="0" class="main-table" style="max-width:800; margin:auto; border:1px solid #ddd; background-color:#f1f1f1;" align="center">
     <tr>
    <td height="12"></td>
  </tr>
  <tr>
    <td>
    </td>
  </tr>

    <tr>
      <td height="10" colspan="3"></td>
    </tr>
    <tr>
      <td width="2%"></td>
      <td width="96%">
        <table style="width:100%; margin:auto; background-color:#fff;" cellpadding="0" cellspacing="0">
          <tr>
            <td width="2%"></td>
            <td width="96%">
              <table style="width:100%;" cellpadding="0" cellspacing="0">
                <tr>
                  <td height="12"></td>
                </tr>
                <a><img src='https://kalos-web-assets.s3.amazonaws.com/kalos-logo-new.png'	alt="email-logo" style="display:block; background-color: #711313"/></a>

                <tr>
                  <td height="23"></td>
                </tr>
                <tr>
                  <td height="22"></td>
                </tr>
                <tr>
                  <td>
                    <h3 style="font-size:20px; color:#4d4d4d; font-family:Arial, Helvetica, sans-serif; margin:0;">
                         <b>Welcome to Kalos Services!</b>
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td height="14"></td>
                </tr>
                <tr>
                  <td>
                    Customer Account Login:
                    <p style="margin:1; font-size:14px; color:#262626; line-height:20px; font-family:Arial, Helvetica, 'sans-serif';">

            Your username is:<b>${newUser.email} </b> <br>
            Your password is: <b>${newUser.pwd} </b>(case-sensitive - must be changed upon initial login)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td height="10"></td>
                </tr>
                <tr>


                  <td height="10"></td>
                </tr>
                <tr>
                  <td>
                    <table style="width:100%;" cellpadding="1" cellspacing="1">
                      <tr>

        Thanks again for choosing Kalos Services Inc.
                        <br>
                        <br>
                                         <b>The Kalos Services Staff</b>
                        <br>
                                          (352) 243-7088
<p style="margin:1; font-size:14px; color:#262626; line-height:20px; font-family:Arial, Helvetica, 'sans-serif';">
      Call or text 352-243-7099 for simply great service!
<br>
  <br>
We offer 24 hour HVAC emergency service & air conditioning preventative maintenance.
    <br>
<a href="https://www.kalosflorida.com/service/air-conditioning-maintenance/">https://www.kalosflorida.com/service/air-conditioning-maintenance/</a>

                    </p>



                    </table>

                  </td>
                </tr>
                <tr>
                  <td height="18"></td>
                </tr>
                <tr>

                </tr>
                  <tr>
                  <td height="18"></td>
                </tr>
              </table>
            </td>
            <td width="2%"></td>
          </tr>
        </table>
      </td>
      <td width="2%"></td>
    </tr>
    <tr>
      <td height="13" colspan="3"></td>
    </tr>
    <tr>
      <td width="2%"></td>
      <td width="96%">
           <table style="width:100%; border-top:1px solid #d8d8d8;" cellpadding="0" cellspacing="0">
          <tr>
            <td height="10" colspan="2"></td>
          </tr>
          <tr>
            <td width="80%" align="right" valign="middle">
              <p style="font-size:10px; color:#595959; font-family:Arial, Helvetica, sans-serif; font-weight:300; line-height:13px; text-align:left" >
               This message has been sent automatically from http://app.KalosFlorida.com.
                                          If you have any questions or concerns regarding this document,
                                          please either reply to this email or call (352) 243-7088<br />

              </p>
            </td>
            <td width="20%" align="right">
            </td>
          </tr>
        </table>
      </td>
      <td width="2%"></td>
    </tr>
    <tr>
      <td height="30" colspan="3"></td>
    </tr>
  </table>
  </body>`;

      const email: EmailConfig = {
        recipient: newUser.email,
        subject: 'Subj. KalosFlorida.com New Account Confirmation ',
        from: 'office@kalosflorida.com',
        body,
      };

      try {
        await EmailClientService.sendMail(email);
      } catch (err) {
        console.log(err);
        alert('An error occurred, user was not notified via email');
      }
      if (onClose) {
        onClose();
      }
    },
    [onClose],
  );

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let returnString = '';
    if (email.length !== 0) {
      if (!re.test(String(email).toLowerCase())) {
        returnString = 'Please use a valid email address';
      }
    } else {
      returnString = 'Please enter an email address';
    }
    return returnString;
  };

  const validateUniqueEmail = useCallback(
    async (email: string) => {
      let returnString = '';
      if (email.length !== 0) {
        try {
          const res = await UserClientService.Get(User.create({ email: email, isActive: 1 }));
          if (res.id !== 0 && customer.id == 0) {
            returnString = 'Please use a unique email address';
          }
        } catch {
          returnString = '';
        }
      } else {
        returnString = 'Please enter an email address';
      }
      console.log(returnString);
      return returnString;
    },
    [customer],
  );
  const SCHEMA: Schema<User> = [
    [{ label: 'Personal Details', headline: true }],
    [{ name: 'id', type: 'hidden' }],
    [
      { label: 'First Name', name: 'firstname', required: true },
      { label: 'Last Name', name: 'lastname', required: true },
      { label: 'Business Name', name: 'businessname', multiline: true },
    ],
    [{ label: 'Contact Details', headline: true }],
    [
      {
        label: 'Primary Phone',
        name: 'phone',
        validationOnUpdate: (value) => {
          if (value.length > 0 && !phoneRegex.test(value)) {
            return 'Please enter a valid phone number';
          } else {
            return '';
          }
        },
        performUpdateValidationOnSave: true,
      },
      {
        label: 'Alternate Phone',
        name: 'altphone',
        validationOnUpdate: (value) => {
          if (value.length > 0 && !phoneRegex.test(value)) {
            return 'Please enter a valid phone number';
          } else {
            return '';
          }
        },
        performUpdateValidationOnSave: true,
      },
      {
        label: 'Cell Phone',
        name: 'cellphone',
        validationOnUpdate: (value) => {
          if (value.length > 0 && !phoneRegex.test(value)) {
            return 'Please enter a valid phone number';
          } else {
            return '';
          }
        },
        performUpdateValidationOnSave: true,
      },
    ],
    [
      {
        label: 'Email',
        name: 'email',
        required: true,
        validationOnUpdate: validateEmail,
        validationOnSave: validateUniqueEmail,
      },

      {
        label: 'Alternate Email(s)',
        name: 'altEmail',
        helperText: 'Separate multiple email addresses w/comma',
      },
      {
        label: 'Wishes to receive promotional emails',
        name: 'receiveemail',
        type: 'checkbox',
      },
    ],
    [{ label: 'Address Details', headline: true }],
    [
      {
        label: 'Billing Address',
        name: 'address',
        multiline: true,
        required: true,
      },
      { label: 'Billing City', name: 'city', required: true },
      {
        label: 'Billing State',
        name: 'state',
        options: USA_STATES_OPTIONS,
        required: true,
      },
      {
        label: 'Billing Zip Code',
        name: 'zip',
        required: true,
        validationOnUpdate: (value) => {
          if (!value.length) {
            return 'This field is required';
          } else if (value.length > 0 && !zipCodePlus4Regex.test(value)) {
            return 'Please enter a valid zip code';
          } else {
            return '';
          }
        },
        performUpdateValidationOnSave: true,
      },
    ],
    [{ label: 'Billing Details', headline: true }],
    [
      {
        label: 'Billing Terms',
        name: 'billingTerms',
        options: BILLING_TERMS_OPTIONS,
      },
      {
        label: 'Discount',
        name: 'discount',
        required: true,
        type: 'number',
        endAdornment: '%',
        validationOnUpdate: (value) => {
          const val = Number(value);
          if (value === '') {
            return 'Please set any discount';
          } else if (val < 0) {
            return 'Discount cannot be negative';
          } else {
            return '';
          }
        },
        performUpdateValidationOnSave: true,
      },
      {
        label: 'Rebate',
        name: 'rebate' as keyof User,
        required: true,
        type: 'number',
        endAdornment: '%',
        validationOnUpdate: (value) => {
          const val = Number(value);
          if (value === '') {
            return 'Please set any Rebate';
          } else if (val < 0) {
            return 'Rebate cannot be negative';
          } else {
            return '';
          }
        },
        performUpdateValidationOnSave: true,
      },
      {
        label: 'Referred By',
        name: 'recommendedBy',
        type: 'text',
      },
    ],
    [{ label: 'Notes', headline: true }],
    [
      {
        label: viewedAsCustomer ? 'Additional Notes' : 'Customer Notes',
        name: 'notes',
        helperText: viewedAsCustomer ? '' : 'Visible to customer',
        multiline: true,
      },
      {
        label: 'Internal Notes',
        name: 'intNotes',
        helperText: 'NOT visible to customer',
        multiline: true,
      },
    ],
  ];

  const saveGroupLinks = useCallback(
    async (groupLinks: UserGroupLink[], groupLinksInitial: UserGroupLink[], userId: number) => {
      const operations: {
        operation: 'Create' | 'Delete';
        entry: UserGroupLink;
      }[] = [];
      for (let i = 0; i < groups.length; i += 1) {
        const id = groups[i].id;
        const isInGroupLinks = groupLinks.find((g) => g.groupId === id);
        const isInGroupLinksInitial = groupLinksInitial.find((g) => g.groupId === id);
        const entry = UserGroupLink.create();
        if (isInGroupLinksInitial && !isInGroupLinks) {
          entry.id = isInGroupLinksInitial.id;
          operations.push({ operation: 'Delete', entry });
        }
        if (!isInGroupLinksInitial && isInGroupLinks) {
          entry.userId = userId;
          entry.groupId = id;
          operations.push({ operation: 'Create', entry });
        }
      }
      await Promise.all(
        operations.map(
          async ({ operation, entry }) => await UserGroupLinkClientService[operation](entry),
        ),
      );
      setGroupLinksInitial(groupLinks);
    },
    [setGroupLinksInitial, groups],
  );
  const handleSave = useCallback(
    async (data: User) => {
      setSaving(true);
      const temp = User.clone(data);
      let result = temp;
      if (temp.fieldMask.length > 0) {
        temp.firstname = temp.firstname.trim();
        temp.lastname = temp.lastname.trim();
        temp.businessname = temp.businessname.trim();
        if (temp.id == 0) {
          temp.login = temp.email;
          temp.pwd = temp.phone;
          result = await UserClientService.Create(temp);
          await sendEmail(result);
        } else {
          await UserClientService.Update(temp);
        }

        if (createPropertyFlag) {
          const property = Property.create();
          property.userId = result.id;
          property.address = temp.address;
          property.zip = temp.zip;
          property.city = temp.city;
          property.state = temp.state;

          try {
            const geo = await MapClientService.loadGeoLocationByAddress(
              `${temp.address}, ${temp.city}, ${temp.state} ${temp.zip}`,
            );
            if (geo) {
              property.geolocationLat = geo.geolocationLat;
              property.geolocationLng = geo.geolocationLng;
            }
          } catch {
            console.log('could not geolocate');
          }
          console.log('we create property', property);
          await PropertyClientService.Create(property);
        }

        setCustomer(result);
        setUserId(result.id);
        await saveGroupLinks(groupLinks, groupLinksInitial, result.id);
        setSaving(false);
        if (onSave) {
          onSave(result);
        }
      } else {
        await saveGroupLinks(groupLinks, groupLinksInitial, customer.id);
        setSaving(false);
        if (onSave) {
          onSave(customer);
        }
      }
    },
    [
      createPropertyFlag,
      saveGroupLinks,
      groupLinks,
      groupLinksInitial,
      onSave,
      sendEmail,
      customer,
    ],
  );
  const handleChangeLinkGroup = useCallback(
    (groupId: number) => (value: Value) => {
      console.log('changing');
      const newGroupLink = UserGroupLink.create();
      newGroupLink.groupId = groupId;
      newGroupLink.userId = userId;
      console.log('new link', newGroupLink);
      const newGroupLinks = value
        ? [...groupLinks, newGroupLink]
        : groupLinks.filter((item) => item.groupId !== groupId);
      setGroupLinks(newGroupLinks);
      console.log('new group links', newGroupLinks);
    },
    [groupLinks, userId, setGroupLinks],
  );
  return (
    <div className="flex flex-col lg:flex-row">
      <Form<User>
        key={formKey}
        title={userId ? 'Edit Customer Information' : 'Add Customer'}
        schema={SCHEMA}
        data={customer}
        onSave={handleSave}
        onClose={onClose}
        submitDisabled={!!error}
        disabled={saving || loading}
        className="flex-1"
        validateOnChange={true}
      >
        {!userId ? (
          <Field
            className="mb-4"
            type="checkbox"
            name={'createPropertyFlag'}
            value={createPropertyFlag}
            label="Create Property From Address?"
            onChange={(data) => setCreatePropertyFlag(data as number)}
          />
        ) : undefined}
      </Form>

      {!viewedAsCustomer && (
        <div className="lg:ml-2 lg:w-72">
          <SectionBar title="Groups" />
          {loading ? (
            <InfoTable data={makeFakeRows(1, 8)} loading />
          ) : (
            <div className="flex flex-col py-2 pl-4">
              {groups.map((g) => (
                <div key={g.id} className="CustomerEditGroup">
                  <Field
                    key={g.id + g.name}
                    label={g.name}
                    type="checkbox"
                    onChange={handleChangeLinkGroup(g.id)}
                    value={groupLinks.find((group) => group.groupId === g.id) ? 1 : 0}
                    name={`group_${g.id}`}
                    disabled={saving}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
