import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { lazy } from 'react';
import {
  createBrowserRouter,
  isRouteErrorResponse,
  Outlet,
  RouterProvider,
  useRouteError,
} from 'react-router-dom';

import { Layout, Logout, SelectLoginPage } from './auth';
import { SuspenseWithLoader } from './components/SuspenseWithLoader';
import useEnhancedEffect from './hooks/useEnhancedEffect';
import { type Kind } from './modules/ComponentsLibrary/AdvancedSearch';
import { CallWindow } from './modules/ComponentsLibrary/CallWindow';
import { EmployeeItemOptionsPage } from './modules/EmployeeItems/EmployeeItemOptions/EmployeeItemOptionsPage';
import PermitInspectionsPage from './modules/Permits/PermitsAdminPage/PermitGroupDetails/PermitDetails/PermitInspectionsPage';
import { lodgingRequestsnUrl } from './modules/SideMenu/main';
import { withAuth } from './tools/AuthWrappers/withAuth';
import { withoutAuth } from './tools/AuthWrappers/withoutAuth';
import {
  creditCardsPagePermissionCheck,
  lodgingAdminCheck,
  permitAdminCheck,
} from './tools/permissionChecks';

const properties = ['properties'] satisfies Kind[];
const customers = ['customers'] satisfies Kind[];
const employees = ['employees'] satisfies Kind[];
const serviceCalls = ['serviceCalls'] satisfies Kind[];
const search = ['customers', 'properties', 'contracts'] satisfies Kind[];

const DocumentTitleSetter = ({ title, children }: { title: string; children: JSX.Element }) => {
  useEnhancedEffect(() => {
    document.title = title;
  }, [title]);

  return children;
};

const ProjectPageLazy = lazy(async () => ({
  default: (await import('./modules/Projects/ProjectPage')).default,
}));
const ProjectResourcesPageLazy = lazy(async () => ({
  default: (await import('./modules/ProjectResources')).ProjectResourcesPage,
}));
const LodgingRequestsLazy = lazy(async () => ({
  default: (await import('./modules/Lodging/LodgingRequests/main')).default,
}));
const LodgingAdminPage = lazy(async () => ({
  default: (await import('./modules/Lodging/LodgingAdminPage')).LodgingAdminPage,
}));
const LodgingDetails = lazy(async () => ({
  default: (await import('./modules/Lodging/LodgingAdminPage')).LodgingDetails,
}));
const EmployeeDepartments = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/EmployeeDepartments')).EmployeeDepartments,
}));
const EditEmployeeFunction = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/EmployeeDepartments/EditEmployeeFunction'))
    .EditEmployeeFunction,
}));
const AddEmployeeFunction = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/EmployeeDepartments/AddEmployeeFunction'))
    .AddEmployeeFunction,
}));
const NitrogenCalculatorLazy = lazy(async () => ({
  default: (await import('./tools/Nitrogen')).default,
}));
const AdvancedSearchLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/AdvancedSearch')).AdvancedSearch,
}));
const SpiffToolLazy = lazy(async () => ({
  default: (await import('./modules/SpiffToolLogs/components/SpiffTool')).SpiffTool,
}));
const ServiceCalendarLazy = lazy(async () => ({
  default: (await import('./modules/ServiceCalendar/main')).ServiceCalendar,
}));
const TransactionLazy = lazy(async () => ({
  default: (await import('./modules/Transactions/main')).default,
}));
const TimesheetLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/Timesheet')).Timesheet,
}));
const TransactionUserLazy = lazy(async () => ({
  default: (await import('./modules/TransactionUser/main')).default,
}));
const ReimbursmentLogLazy = lazy(async () => ({
  default: (await import('./modules/ReimbursementLog/main')).ReimbursementLogPage,
}));
const PropertyInfoLazy = lazy(async () => ({
  default: (await import('./modules/PropertyInformation/components/PropertyInfo')).PropertyInfo,
}));
const PrintStripeInvoiceReceiptLazy = lazy(async () => ({
  default: (await import('./modules/PrintStripeInvoiceReceipt/main')).PrintStripeInvoiceReceipt,
}));
const HRDashboardLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/HRDashboard')).HRDashBoard,
}));
const NewHireLazy = lazy(async () => ({
  default: (await import('./modules/NewHire/main')).default,
}));
const NewHireCreateLazy = lazy(async () => ({
  default: (await import('./modules/NewHire/components/NewHireCreate')).NewHireCreate,
}));
const NewHireEditLazy = lazy(async () => ({
  default: (await import('./modules/NewHire/components/NewHireEdit')).NewHireEdit,
}));
const ReportsLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/Reports')).Reports,
}));
const DashboardLazy = lazy(async () => ({
  default: (await import('./modules/Dashboard/main')).Dashboard,
}));
const PerDiemManagerLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/PerDiemManager/PerDiemManagerPage'))
    .PerDiemManagerPage,
}));
const CustomerInformationWithPropertyPageLazy = lazy(
  async () =>
    await import(
      './modules/ComponentsLibrary/CustomerInformation/CustomerInformationWithPropertyPage'
    ),
);
const DispatchDashboardLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/Dispatch')).DispatchDashboard,
}));
const MemosLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/Memo')).MemoList,
}));
const ServiceCallsPendingLazy = lazy(async () => ({
  default: (await import('./modules/PendingBilling/components/ServiceCallsPending'))
    .ServiceCallsPending,
}));
const ProjectSelectLazy = lazy(async () => ({
  default: (await import('./modules/Estimates/main')).ProjectSelect,
}));
const TransactionTableLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/TransactionTable')).TransactionTable,
}));
const FirstCallDashboardLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/FirstCalls')).FirstCallDashboard,
}));
const ServiceCallLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/ServiceCall')).ServiceCall,
}));
const PayrollLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/Payroll')).Payroll,
}));
const MemoPreviewLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/Memo/components/MemoPreview')).MemoPreview,
}));
const CustomerDetailsPage = lazy(async () => ({
  default: (await import('./modules/CustomerDetails/main')).CustomerDetailsPage,
}));
const PerDiemComponentLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/PerDiem')).PerDiemComponent,
}));
const InternalDocumentsLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/InternalDocuments')).InternalDocuments,
}));
const DriverVehicleDashboardLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/DriverDashboard')).DriverVehicleDashboard,
}));
const DownloadInvoiceLazy = lazy(async () => ({
  default: (await import('./modules/DownloadInvoice/main')).DownloadInvoice,
}));
const CustomerEditLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/CustomerEdit')).CustomerEdit,
}));
const CreditCardsPageLaze = lazy(async () => ({
  default: (await import('./modules/CreditCards/CreditCardsPage')).CreditCardsPage,
}));
const PermitsAdminLazy = lazy(async () => ({
  default: (await import('./modules/Permits/PermitsAdminPage')).PermitsAdminPage,
}));
const PermitGroupDetailsLazy = lazy(async () => ({
  default: (await import('./modules/Permits/PermitsAdminPage/PermitGroupDetails'))
    .PermitGroupDetailsPage,
}));
const VehicleDashboardLazy = lazy(async () => ({
  default: (await import('./modules/VehicleDashboard')).VehicleDashboard,
}));
const ToolFundDashboardLazy = lazy(async () => ({
  default: (await import('./modules/ToolFundDashboard/main')).default,
}));
const ToolFundLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/ToolFund')).ToolFund,
}));
const UserTimeOffPageLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/TimeOff/UserTimeOffPage')).TimeOffPageByUser,
}));
const RequestTimeOffPageLazy = lazy(async () => ({
  default: (await import('./modules/ComponentsLibrary/TimeOff/RequestTimeOffPage'))
    .TimeOffPageByRequest,
}));

function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <div className="flex h-screen">
        <div className="m-auto text-center">
          <h1>404</h1>
          <p>Page not found</p>
        </div>
      </div>
    );
  }
  // Uncaught ReferenceError: path is not defined
  return <div>We had an error, check the console for information</div>;
}

export const employeesRoute = 'employees';
export const employeesItemOptionsRoute = `${employeesRoute}/items/options`;
export const employeeDepartmentRoute = 'departments';
export const permitRoute = 'permits';

const defaultUserCheck = () => true;

export const permitAdminRoute = '/permitAdmin';
export const lodgingAdminUrl = '/lodgingAdmin';
export const projectResourcesURL = '/projectResources';

const appRouter = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorBoundary />,

    children: [
      { path: '/callWindow', element: <CallWindow /> },
      {
        path: permitAdminRoute,
        element: withAuth(
          <DocumentTitleSetter title="Permits Admin">
            <SuspenseWithLoader>
              <PermitsAdminLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
          permitAdminCheck,
        ),
        children: [
          {
            path: ':permitGroupId',
            element: (
              <SuspenseWithLoader>
                <PermitGroupDetailsLazy />
              </SuspenseWithLoader>
            ),
            children: [
              {
                path: `${permitRoute}/:permitId`,
                element: <PermitInspectionsPage />,
              },
            ],
          },
        ],
      },
      {
        path: lodgingAdminUrl,
        element: withAuth(
          <DocumentTitleSetter title="Lodging">
            <SuspenseWithLoader>
              <LodgingAdminPage />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
          lodgingAdminCheck,
        ),
        children: [
          {
            path: ':lodgingRequestId',
            element: (
              <SuspenseWithLoader>
                <LodgingDetails />
              </SuspenseWithLoader>
            ),
          },
        ],
      },
      {
        path: lodgingRequestsnUrl,
        element: withAuth(
          <DocumentTitleSetter title="Lodging Requests">
            <SuspenseWithLoader>
              <LodgingRequestsLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: projectResourcesURL,
        element: withAuth(
          <DocumentTitleSetter title="Project Resources">
            <SuspenseWithLoader>
              <ProjectResourcesPageLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
        children: [
          {
            path: ':tab',
            element: <Outlet />,
            children: [
              {
                path: ':id',
                element: <ProjectPageLazy />,
              },
            ],
          },
        ],
      },
      {
        path: '/home',
        element: withAuth(
          <SuspenseWithLoader>
            <DashboardLazy />
          </SuspenseWithLoader>,
        ),
      },
      {
        path: '/',
        element: withoutAuth(
          <DocumentTitleSetter title="Login">
            <SelectLoginPage />
          </DocumentTitleSetter>,
        ),
      },
      /*
      {
        path: '/legacyLogin',
        element: (
          <DocumentTitleSetter title="Login">
            <LegacyLoginPage />
          </DocumentTitleSetter>
        ),
      },
      */
      {
        path: '/transactions',
        element: withAuth(
          <DocumentTitleSetter title="Transactions">
            <SuspenseWithLoader>
              <TransactionUserLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/reports',
        element: withAuth(
          <DocumentTitleSetter title="Reports">
            <SuspenseWithLoader>
              <ReportsLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/properties',
        element: withAuth(
          <DocumentTitleSetter title="Properties">
            <SuspenseWithLoader>
              <AdvancedSearchLazy title="Property Search" kinds={properties} />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/properties/:propertyId',
        element: withAuth(
          <DocumentTitleSetter title="Property View">
            <SuspenseWithLoader>
              <CustomerInformationWithPropertyPageLazy />
              <PropertyInfoLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/customers',
        element: withAuth(
          <DocumentTitleSetter title="Customers">
            <SuspenseWithLoader>
              <AdvancedSearchLazy title="Customers" kinds={customers} />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/customers/:userId',
        element: withAuth(
          <DocumentTitleSetter title="Customer View">
            <SuspenseWithLoader>
              <CustomerDetailsPage />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/customers/add',
        element: withAuth(
          <DocumentTitleSetter title="Add Customer">
            <SuspenseWithLoader>
              <CustomerEditLazy
                onClose={() => {
                  console.log('should nav to use page or something idk');
                }}
              />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/customers/:userId/properties/:propertyId',
        element: withAuth(
          <DocumentTitleSetter title="Customer Property View">
            <SuspenseWithLoader>
              <PropertyInfoLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/timesheet/:userId/:startOnWeek?/:week?',
        element: withAuth(
          <SuspenseWithLoader>
            <TimesheetLazy enableTimesheetUrlSearchParamsSync={false} />
          </SuspenseWithLoader>,
        ),
      },
      {
        path: '/timeoff/:requestId',
        element: withAuth(
          <SuspenseWithLoader>
            <RequestTimeOffPageLazy />
          </SuspenseWithLoader>,
        ),
      },
      {
        path: '/tools',
        element: (
          <DocumentTitleSetter title="Tools">
            <Paper>
              <Typography>Tools</Typography>
              <Outlet />
            </Paper>
          </DocumentTitleSetter>
        ),
        children: [
          {
            path: 'nitrogen',
            element: (
              <DocumentTitleSetter title="Nitrogen Calculator">
                <SuspenseWithLoader>
                  <NitrogenCalculatorLazy />
                </SuspenseWithLoader>
              </DocumentTitleSetter>
            ),
          },
        ],
      },
      {
        path: employeesRoute,
        element: withAuth(
          <DocumentTitleSetter title="Employee Directory">
            <SuspenseWithLoader>
              <AdvancedSearchLazy title="Employee Directory" kinds={employees} printableEmployees />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: employeesItemOptionsRoute,
        element: withAuth(<EmployeeItemOptionsPage />),
      },
      {
        path: employeeDepartmentRoute,
        element: withAuth(
          <DocumentTitleSetter title="Employee Directory">
            <SuspenseWithLoader>
              <EmployeeDepartments />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
        children: [
          {
            path: 'add',
            element: (
              <SuspenseWithLoader>
                <AddEmployeeFunction />
              </SuspenseWithLoader>
            ),
          },
          {
            path: 'edit/:functionId',
            element: (
              <SuspenseWithLoader>
                <EditEmployeeFunction />
              </SuspenseWithLoader>
            ),
          },
        ],
      },
      {
        path: '/dispatch',
        element: withAuth(
          <DocumentTitleSetter title="Dispatch">
            <SuspenseWithLoader>
              <DispatchDashboardLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/firstcalls',
        element: withAuth(
          <DocumentTitleSetter title="First Calls">
            <SuspenseWithLoader>
              <FirstCallDashboardLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/spiffLog/:ownerId?',
        element: withAuth(
          <DocumentTitleSetter title="Spiff Log">
            <SuspenseWithLoader>
              <SpiffToolLazy type="Spiff" />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/toolLog/:ownerId?',
        element: withAuth(
          <DocumentTitleSetter title="Tool Log">
            <SuspenseWithLoader>
              <SpiffToolLazy type="Tool" disableActions={true} />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/reimbursements/:ownerId?',
        element: withAuth(
          <DocumentTitleSetter title="Reimbursements">
            <SuspenseWithLoader>
              <ReimbursmentLogLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/jobs',
        element: withAuth(
          <DocumentTitleSetter title="Jobs">
            <SuspenseWithLoader>
              <AdvancedSearchLazy
                title="Jobs"
                kinds={serviceCalls}
                deletableEvents
                eventsWithAccounting
                eventsWithAdd
                className="Jobs-Page"
              />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/jobs/:jobId',
        element: withAuth(
          <DocumentTitleSetter title="Job View">
            <SuspenseWithLoader>
              <ServiceCallLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },

      {
        path: '/perdiem',
        element: withAuth(
          <DocumentTitleSetter title="Per Diems">
            <SuspenseWithLoader>
              <PerDiemComponentLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/calendar/:initialCustomerId?',
        element: withAuth(
          <DocumentTitleSetter title="Service Calendar">
            <SuspenseWithLoader>
              <ServiceCalendarLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/search',
        element: withAuth(
          <DocumentTitleSetter title="Search">
            <SuspenseWithLoader>
              <AdvancedSearchLazy
                title="Search"
                kinds={search}
                editableCustomers
                editableProperties
              />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/payroll',
        element: withAuth(
          <DocumentTitleSetter title="Payroll">
            <SuspenseWithLoader>
              <PayrollLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/payroll/manage-perdiem',
        element: withAuth(
          <SuspenseWithLoader>
            <PerDiemManagerLazy />
          </SuspenseWithLoader>,
        ),
      },
      {
        path: '/accountsPayable',
        element: withAuth(
          <DocumentTitleSetter title="Accounts Payable">
            <SuspenseWithLoader>
              <TransactionTableLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/transactionAdmin',
        element: withAuth(
          <DocumentTitleSetter title="Transaction Admin">
            <SuspenseWithLoader>
              <TransactionLazy isAdmin />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/creditCards',
        element: withAuth(
          <SuspenseWithLoader>
            <CreditCardsPageLaze />
          </SuspenseWithLoader>,
          creditCardsPagePermissionCheck,
        ),
      },
      {
        path: '/documents/invoices/:id',
        element: (
          <DocumentTitleSetter title="Invoice">
            <SuspenseWithLoader>
              <DownloadInvoiceLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>
        ),
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        path: '/hrDashboard/:prId?',
        element: withAuth(
          <DocumentTitleSetter title="HR Dashboard">
            <SuspenseWithLoader>
              <HRDashboardLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/hrDashboard/newHire/create',
        element: withAuth(
          <DocumentTitleSetter title="HR Dashboard - New Hire">
            <SuspenseWithLoader>
              <NewHireCreateLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/hrDashboard/newHire/edit/:id',
        element: withAuth(
          <DocumentTitleSetter title="HR Dashboard - New Hire">
            <SuspenseWithLoader>
              <NewHireEditLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/newHire/',
        element: withAuth(
          <DocumentTitleSetter title="New Hire">
            <SuspenseWithLoader>
              <NewHireLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/driverDashboard',
        element: withAuth(
          <DocumentTitleSetter title="Driver Dashboard">
            <SuspenseWithLoader>
              <DriverVehicleDashboardLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/documents',
        element: withAuth(
          <DocumentTitleSetter title="Internal Resources">
            <SuspenseWithLoader>
              <InternalDocumentsLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/callsPending',
        element: withAuth(
          <DocumentTitleSetter title="Pending Calls">
            <SuspenseWithLoader>
              <ServiceCallsPendingLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/projects',
        element: withAuth(<Outlet />, defaultUserCheck, 70),
        children: [
          {
            path: 'estimates',
            element: (
              <DocumentTitleSetter title="Project Estimates">
                <SuspenseWithLoader>
                  <ProjectSelectLazy />
                </SuspenseWithLoader>
              </DocumentTitleSetter>
            ),
          },
        ],
      },
      {
        path: '/stripe/success/:token',
        element: (
          <DocumentTitleSetter title="Payment Success">
            <SuspenseWithLoader>
              <PrintStripeInvoiceReceiptLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>
        ),
        /*children: [
          { path: 'success/:token', element: <PrintStripeInvoiceReceipt /> },
          {
            path: 'cancel',
            element: <PrintStripeInvoiceReceipt />,
          },
        ],*/
      },
      {
        path: '/notes/:report_ids?',
        element: withAuth(
          <DocumentTitleSetter title="Notes">
            <SuspenseWithLoader>
              <MemosLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/memoPreview/:memoId',
        element: withAuth(
          <DocumentTitleSetter title="Field Note Preview">
            <SuspenseWithLoader>
              <MemoPreviewLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/vehicles/:vehicleDashboardTab?',
        element: withAuth(
          <DocumentTitleSetter title="Vehicles">
            <SuspenseWithLoader>
              <VehicleDashboardLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/toolFund',
        element: withAuth(
          <DocumentTitleSetter title="Tool Fund">
            <SuspenseWithLoader>
              <ToolFundLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/toolFundDashboard',
        element: withAuth(
          <DocumentTitleSetter title="Tool Fund Dashboard">
            <SuspenseWithLoader>
              <ToolFundDashboardLazy />
            </SuspenseWithLoader>
          </DocumentTitleSetter>,
        ),
      },
      {
        path: '/user/time-off/:userId',
        element: withAuth(
          <SuspenseWithLoader>
            <UserTimeOffPageLazy />
          </SuspenseWithLoader>,
        ),
      },
    ],
  },
]);

export const AppRouter = () => <RouterProvider router={appRouter} />;
