import React from 'react';
import { type StoreApi, useStore } from 'zustand';

export interface ScrollState {
  isScrolling: boolean;
  actions: {
    setIsScrolling: (isScrolling: boolean) => void;
  };
}

export const ScrollStoreContext = React.createContext<StoreApi<ScrollState> | null>(null);

const selectIsScrolling = (state: ScrollState) => state.isScrolling;

export const useScroll = () => {
  const store = React.useContext(ScrollStoreContext);
  if (!store) {
    throw new Error('useScroll must be used within a ScrollStoreProvider');
  }
  return useStore(store, selectIsScrolling);
};
