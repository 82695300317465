import { type TimesheetDepartment, type TransactionAccount, type User } from '@kalos/kalos-rpc';

import { type FileTagsFilters } from '../../../../hooks/react-query/useFileTagsQuery';
import { type TransactionAccountFilters } from '../../../../hooks/react-query/useTransactionAccountsQuery';
import { type UserFilters } from '../../../../hooks/react-query/useUserQuery';

export const adminUserFilter: UserFilters = {
  isAdmin: 1,
  isActive: 1,
  overrideLimit: true,
};

export const renderDepartment = (dep: TimesheetDepartment) => dep.description;

export const fileTagsQueryFilter: FileTagsFilters = {
  isActive: true,
};

export const renderUser = (user: Pick<User, 'firstname' | 'lastname' | 'id'>) =>
  `${user.firstname} ${user.lastname} (ID: ${user.id})`;

export const techniciansUserFilter: UserFilters = {
  isEmployee: 1,
  overrideLimit: true,
  isActive: 1,
};

export const transactionAccountFilter: TransactionAccountFilters = {
  isActive: 1,
  orderBy: 'description',
  orderDir: 'ASC',
};

export const renderTransactionAccount = (account: TransactionAccount) =>
  `${account.id} - ${account.shortDescription}`;
