// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "permit.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "permit.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class PermitGroup$Type extends MessageType {
    constructor() {
        super("PermitGroup", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "requestor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "po", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "assigned_user", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "plan_grid", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "on_behalf", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "permits", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Permit },
            { no: 14, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "permit_group_documents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PermitGroupDocument },
            { no: 22, name: "internal_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "scope_of_work", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "valuation", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 25, name: "search_trades", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "search_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, requestorId: 0, po: "", startDate: "", endDate: "", assignedUser: 0, createdDate: "", planGrid: false, onBehalf: false, isActive: false, fieldMask: [], permits: [], dateRange: [], dateTarget: [], orderBy: "", orderDir: "", pageNumber: 0, withoutLimit: false, notEquals: [], permitGroupDocuments: [], internalNotes: "", scopeOfWork: "", valuation: 0, searchTrades: "", searchStatus: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 requestor_id */ 2:
                    message.requestorId = reader.int32();
                    break;
                case /* string po */ 3:
                    message.po = reader.string();
                    break;
                case /* string start_date */ 4:
                    message.startDate = reader.string();
                    break;
                case /* string end_date */ 5:
                    message.endDate = reader.string();
                    break;
                case /* int32 assigned_user */ 6:
                    message.assignedUser = reader.int32();
                    break;
                case /* string created_date */ 7:
                    message.createdDate = reader.string();
                    break;
                case /* bool plan_grid */ 8:
                    message.planGrid = reader.bool();
                    break;
                case /* bool on_behalf */ 9:
                    message.onBehalf = reader.bool();
                    break;
                case /* bool is_active */ 10:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 12:
                    message.fieldMask.push(reader.string());
                    break;
                case /* repeated Permit permits */ 13:
                    message.permits.push(Permit.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string date_range */ 14:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 15:
                    message.dateTarget.push(reader.string());
                    break;
                case /* string order_by */ 16:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 17:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 18:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 19:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string not_equals */ 20:
                    message.notEquals.push(reader.string());
                    break;
                case /* repeated PermitGroupDocument permit_group_documents */ 21:
                    message.permitGroupDocuments.push(PermitGroupDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string internal_notes */ 22:
                    message.internalNotes = reader.string();
                    break;
                case /* string scope_of_work */ 23:
                    message.scopeOfWork = reader.string();
                    break;
                case /* float valuation */ 24:
                    message.valuation = reader.float();
                    break;
                case /* string search_trades */ 25:
                    message.searchTrades = reader.string();
                    break;
                case /* string search_status */ 26:
                    message.searchStatus = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 requestor_id = 2; */
        if (message.requestorId !== 0)
            writer.tag(2, WireType.Varint).int32(message.requestorId);
        /* string po = 3; */
        if (message.po !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.po);
        /* string start_date = 4; */
        if (message.startDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.startDate);
        /* string end_date = 5; */
        if (message.endDate !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.endDate);
        /* int32 assigned_user = 6; */
        if (message.assignedUser !== 0)
            writer.tag(6, WireType.Varint).int32(message.assignedUser);
        /* string created_date = 7; */
        if (message.createdDate !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.createdDate);
        /* bool plan_grid = 8; */
        if (message.planGrid !== false)
            writer.tag(8, WireType.Varint).bool(message.planGrid);
        /* bool on_behalf = 9; */
        if (message.onBehalf !== false)
            writer.tag(9, WireType.Varint).bool(message.onBehalf);
        /* bool is_active = 10; */
        if (message.isActive !== false)
            writer.tag(10, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 12; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* repeated Permit permits = 13; */
        for (let i = 0; i < message.permits.length; i++)
            Permit.internalBinaryWrite(message.permits[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated string date_range = 14; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 15; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(15, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* string order_by = 16; */
        if (message.orderBy !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 17; */
        if (message.orderDir !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 18; */
        if (message.pageNumber !== 0)
            writer.tag(18, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 19; */
        if (message.withoutLimit !== false)
            writer.tag(19, WireType.Varint).bool(message.withoutLimit);
        /* repeated string not_equals = 20; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(20, WireType.LengthDelimited).string(message.notEquals[i]);
        /* repeated PermitGroupDocument permit_group_documents = 21; */
        for (let i = 0; i < message.permitGroupDocuments.length; i++)
            PermitGroupDocument.internalBinaryWrite(message.permitGroupDocuments[i], writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* string internal_notes = 22; */
        if (message.internalNotes !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.internalNotes);
        /* string scope_of_work = 23; */
        if (message.scopeOfWork !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.scopeOfWork);
        /* float valuation = 24; */
        if (message.valuation !== 0)
            writer.tag(24, WireType.Bit32).float(message.valuation);
        /* string search_trades = 25; */
        if (message.searchTrades !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.searchTrades);
        /* string search_status = 26; */
        if (message.searchStatus !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.searchStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermitGroup
 */
export const PermitGroup = new PermitGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermitGroupList$Type extends MessageType {
    constructor() {
        super("PermitGroupList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PermitGroup },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PermitGroup results */ 1:
                    message.results.push(PermitGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PermitGroup results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PermitGroup.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermitGroupList
 */
export const PermitGroupList = new PermitGroupList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Permit$Type extends MessageType {
    constructor() {
        super("Permit", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "permit_group_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "job_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "trade_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "status_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "inspections", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Inspection },
            { no: 11, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "permit_documents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PermitDocument }
        ]);
    }
    create(value) {
        const message = { id: 0, permitGroupId: 0, jobNumber: 0, tradeId: 0, statusId: 0, statusDescription: "", notes: "", isActive: false, fieldMask: [], inspections: [], orderBy: "", orderDir: "", pageNumber: 0, withoutLimit: false, permitDocuments: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 permit_group_id */ 2:
                    message.permitGroupId = reader.int32();
                    break;
                case /* int32 job_number */ 3:
                    message.jobNumber = reader.int32();
                    break;
                case /* int32 trade_id */ 4:
                    message.tradeId = reader.int32();
                    break;
                case /* int32 status_id */ 5:
                    message.statusId = reader.int32();
                    break;
                case /* string status_description */ 6:
                    message.statusDescription = reader.string();
                    break;
                case /* string notes */ 7:
                    message.notes = reader.string();
                    break;
                case /* bool is_active */ 8:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 9:
                    message.fieldMask.push(reader.string());
                    break;
                case /* repeated Inspection inspections */ 10:
                    message.inspections.push(Inspection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string order_by */ 11:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 12:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 13:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 14:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated PermitDocument permit_documents */ 15:
                    message.permitDocuments.push(PermitDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 permit_group_id = 2; */
        if (message.permitGroupId !== 0)
            writer.tag(2, WireType.Varint).int32(message.permitGroupId);
        /* int32 job_number = 3; */
        if (message.jobNumber !== 0)
            writer.tag(3, WireType.Varint).int32(message.jobNumber);
        /* int32 trade_id = 4; */
        if (message.tradeId !== 0)
            writer.tag(4, WireType.Varint).int32(message.tradeId);
        /* int32 status_id = 5; */
        if (message.statusId !== 0)
            writer.tag(5, WireType.Varint).int32(message.statusId);
        /* string status_description = 6; */
        if (message.statusDescription !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.statusDescription);
        /* string notes = 7; */
        if (message.notes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.notes);
        /* bool is_active = 8; */
        if (message.isActive !== false)
            writer.tag(8, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 9; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* repeated Inspection inspections = 10; */
        for (let i = 0; i < message.inspections.length; i++)
            Inspection.internalBinaryWrite(message.inspections[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string order_by = 11; */
        if (message.orderBy !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 12; */
        if (message.orderDir !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 13; */
        if (message.pageNumber !== 0)
            writer.tag(13, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 14; */
        if (message.withoutLimit !== false)
            writer.tag(14, WireType.Varint).bool(message.withoutLimit);
        /* repeated PermitDocument permit_documents = 15; */
        for (let i = 0; i < message.permitDocuments.length; i++)
            PermitDocument.internalBinaryWrite(message.permitDocuments[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Permit
 */
export const Permit = new Permit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermitList$Type extends MessageType {
    constructor() {
        super("PermitList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Permit },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Permit results */ 1:
                    message.results.push(Permit.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Permit results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Permit.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermitList
 */
export const PermitList = new PermitList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Inspection$Type extends MessageType {
    constructor() {
        super("Inspection", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "permit_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "status_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "requestor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "creator_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "requestor_scheduled_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "scheduled_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "inspection_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, permitId: 0, notes: "", statusId: 0, statusDescription: "", fieldMask: [], requestorId: 0, creatorId: 0, requestorScheduledDate: "", scheduledDate: "", inspectionType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 permit_id */ 2:
                    message.permitId = reader.int32();
                    break;
                case /* string notes */ 3:
                    message.notes = reader.string();
                    break;
                case /* int32 status_id */ 4:
                    message.statusId = reader.int32();
                    break;
                case /* string status_description */ 5:
                    message.statusDescription = reader.string();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 requestor_id */ 7:
                    message.requestorId = reader.int32();
                    break;
                case /* int32 creator_id */ 8:
                    message.creatorId = reader.int32();
                    break;
                case /* string requestor_scheduled_date */ 9:
                    message.requestorScheduledDate = reader.string();
                    break;
                case /* string scheduled_date */ 10:
                    message.scheduledDate = reader.string();
                    break;
                case /* string inspection_type */ 11:
                    message.inspectionType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 permit_id = 2; */
        if (message.permitId !== 0)
            writer.tag(2, WireType.Varint).int32(message.permitId);
        /* string notes = 3; */
        if (message.notes !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.notes);
        /* int32 status_id = 4; */
        if (message.statusId !== 0)
            writer.tag(4, WireType.Varint).int32(message.statusId);
        /* string status_description = 5; */
        if (message.statusDescription !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.statusDescription);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 requestor_id = 7; */
        if (message.requestorId !== 0)
            writer.tag(7, WireType.Varint).int32(message.requestorId);
        /* int32 creator_id = 8; */
        if (message.creatorId !== 0)
            writer.tag(8, WireType.Varint).int32(message.creatorId);
        /* string requestor_scheduled_date = 9; */
        if (message.requestorScheduledDate !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.requestorScheduledDate);
        /* string scheduled_date = 10; */
        if (message.scheduledDate !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.scheduledDate);
        /* string inspection_type = 11; */
        if (message.inspectionType !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.inspectionType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Inspection
 */
export const Inspection = new Inspection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InspectionList$Type extends MessageType {
    constructor() {
        super("InspectionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Inspection },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Inspection results */ 1:
                    message.results.push(Inspection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Inspection results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Inspection.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InspectionList
 */
export const InspectionList = new InspectionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermitStatus$Type extends MessageType {
    constructor() {
        super("PermitStatus", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermitStatus
 */
export const PermitStatus = new PermitStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermitStatusList$Type extends MessageType {
    constructor() {
        super("PermitStatusList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PermitStatus },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PermitStatus results */ 1:
                    message.results.push(PermitStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PermitStatus results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PermitStatus.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermitStatusList
 */
export const PermitStatusList = new PermitStatusList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InspectionStatus$Type extends MessageType {
    constructor() {
        super("InspectionStatus", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InspectionStatus
 */
export const InspectionStatus = new InspectionStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InspectionStatusList$Type extends MessageType {
    constructor() {
        super("InspectionStatusList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InspectionStatus },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated InspectionStatus results */ 1:
                    message.results.push(InspectionStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated InspectionStatus results = 1; */
        for (let i = 0; i < message.results.length; i++)
            InspectionStatus.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InspectionStatusList
 */
export const InspectionStatusList = new InspectionStatusList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermitTrade$Type extends MessageType {
    constructor() {
        super("PermitTrade", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", isActive: false, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* bool is_active */ 3:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* bool is_active = 3; */
        if (message.isActive !== false)
            writer.tag(3, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermitTrade
 */
export const PermitTrade = new PermitTrade$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermitTradeList$Type extends MessageType {
    constructor() {
        super("PermitTradeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PermitTrade },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PermitTrade results */ 1:
                    message.results.push(PermitTrade.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PermitTrade results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PermitTrade.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermitTradeList
 */
export const PermitTradeList = new PermitTradeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermitDocument$Type extends MessageType {
    constructor() {
        super("PermitDocument", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "permit_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "file_bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, permitId: 0, fileId: 0, fileName: "", fileBucket: "", mimeType: "", fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 permit_id */ 2:
                    message.permitId = reader.int32();
                    break;
                case /* int32 file_id */ 3:
                    message.fileId = reader.int32();
                    break;
                case /* string file_name */ 4:
                    message.fileName = reader.string();
                    break;
                case /* string file_bucket */ 5:
                    message.fileBucket = reader.string();
                    break;
                case /* string mime_type */ 6:
                    message.mimeType = reader.string();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 permit_id = 2; */
        if (message.permitId !== 0)
            writer.tag(2, WireType.Varint).int32(message.permitId);
        /* int32 file_id = 3; */
        if (message.fileId !== 0)
            writer.tag(3, WireType.Varint).int32(message.fileId);
        /* string file_name = 4; */
        if (message.fileName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.fileName);
        /* string file_bucket = 5; */
        if (message.fileBucket !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.fileBucket);
        /* string mime_type = 6; */
        if (message.mimeType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.mimeType);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermitDocument
 */
export const PermitDocument = new PermitDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermitDocumentList$Type extends MessageType {
    constructor() {
        super("PermitDocumentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PermitDocument },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PermitDocument results */ 1:
                    message.results.push(PermitDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PermitDocument results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PermitDocument.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermitDocumentList
 */
export const PermitDocumentList = new PermitDocumentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermitGroupDocument$Type extends MessageType {
    constructor() {
        super("PermitGroupDocument", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "permit_group_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "file_bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, permitGroupId: 0, fileId: 0, fileName: "", fileBucket: "", mimeType: "", fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 permit_group_id */ 2:
                    message.permitGroupId = reader.int32();
                    break;
                case /* int32 file_id */ 3:
                    message.fileId = reader.int32();
                    break;
                case /* string file_name */ 4:
                    message.fileName = reader.string();
                    break;
                case /* string file_bucket */ 5:
                    message.fileBucket = reader.string();
                    break;
                case /* string mime_type */ 6:
                    message.mimeType = reader.string();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 permit_group_id = 2; */
        if (message.permitGroupId !== 0)
            writer.tag(2, WireType.Varint).int32(message.permitGroupId);
        /* int32 file_id = 3; */
        if (message.fileId !== 0)
            writer.tag(3, WireType.Varint).int32(message.fileId);
        /* string file_name = 4; */
        if (message.fileName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.fileName);
        /* string file_bucket = 5; */
        if (message.fileBucket !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.fileBucket);
        /* string mime_type = 6; */
        if (message.mimeType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.mimeType);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermitGroupDocument
 */
export const PermitGroupDocument = new PermitGroupDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermitGroupDocumentList$Type extends MessageType {
    constructor() {
        super("PermitGroupDocumentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PermitGroupDocument },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PermitGroupDocument results */ 1:
                    message.results.push(PermitGroupDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PermitGroupDocument results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PermitGroupDocument.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PermitGroupDocumentList
 */
export const PermitGroupDocumentList = new PermitGroupDocumentList$Type();
/**
 * @generated ServiceType for protobuf service PermitService
 */
export const PermitService = new ServiceType("PermitService", [
    { name: "GetPermitStatus", options: {}, I: PermitStatus, O: PermitStatus },
    { name: "BatchGetPermitStatus", options: {}, I: Empty, O: PermitStatusList },
    { name: "GetInspectionStatus", options: {}, I: InspectionStatus, O: InspectionStatus },
    { name: "BatchGetInspectionStatus", options: {}, I: Empty, O: InspectionStatusList },
    { name: "CreatePermitGroup", options: {}, I: PermitGroup, O: PermitGroup },
    { name: "GetPermitGroup", options: {}, I: PermitGroup, O: PermitGroup },
    { name: "BatchGetPermitGroup", options: {}, I: PermitGroup, O: PermitGroupList },
    { name: "UpdatePermitGroup", options: {}, I: PermitGroup, O: PermitGroup },
    { name: "DeletePermitGroup", options: {}, I: PermitGroup, O: Empty },
    { name: "CreatePermit", options: {}, I: Permit, O: Permit },
    { name: "GetPermit", options: {}, I: Permit, O: Permit },
    { name: "BatchGetPermit", options: {}, I: Permit, O: PermitList },
    { name: "UpdatePermit", options: {}, I: Permit, O: Permit },
    { name: "DeletePermit", options: {}, I: Permit, O: Empty },
    { name: "CreateInspection", options: {}, I: Inspection, O: Inspection },
    { name: "GetInspection", options: {}, I: Inspection, O: Inspection },
    { name: "BatchGetInspection", options: {}, I: Inspection, O: InspectionList },
    { name: "UpdateInspection", options: {}, I: Inspection, O: Inspection },
    { name: "DeleteInspection", options: {}, I: Inspection, O: Empty },
    { name: "GetPermitTrade", options: {}, I: PermitTrade, O: PermitTrade },
    { name: "BatchGetPermitTrade", options: {}, I: PermitTrade, O: PermitTradeList },
    { name: "CreatePermitDocument", options: {}, I: PermitDocument, O: PermitDocument },
    { name: "GetPermitDocument", options: {}, I: PermitDocument, O: PermitDocument },
    { name: "BatchGetPermitDocument", options: {}, I: PermitDocument, O: PermitDocumentList },
    { name: "UpdatePermitDocument", options: {}, I: PermitDocument, O: PermitDocument },
    { name: "DeletePermitDocument", options: {}, I: PermitDocument, O: Empty },
    { name: "CreatePermitGroupDocument", options: {}, I: PermitGroupDocument, O: PermitGroupDocument },
    { name: "GetPermitGroupDocument", options: {}, I: PermitGroupDocument, O: PermitGroupDocument },
    { name: "BatchGetPermitGroupDocument", options: {}, I: PermitGroupDocument, O: PermitGroupDocumentList },
    { name: "UpdatePermitGroupDocument", options: {}, I: PermitGroupDocument, O: PermitGroupDocument },
    { name: "DeletePermitGroupDocument", options: {}, I: PermitGroupDocument, O: Empty }
]);
