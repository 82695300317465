import { type DateRange, type SelectRangeEventHandler } from 'react-day-picker';

import { type DateRangePicker } from '.';

/**
 * When calendar is in range mode, this function will modify the selected range to allow click on the start date to select the same date as end date
 */
export const modifySelectRange = (
  onChangeFn: React.ComponentProps<typeof DateRangePicker>['onChange'],
  currentValue: DateRange | undefined,
) => {
  const returnValue: SelectRangeEventHandler = (dateRange) => {
    if (!dateRange && currentValue?.from) {
      onChangeFn({ from: currentValue?.from, to: currentValue?.from });
      return;
    }
    onChangeFn(dateRange);
  };
  return returnValue;
};
