import { Button, FormControl, FormField, FormItem, FormLabel, Input } from '@kalos/ui';

import {
  type PieceWorkSearchScheme,
  usePieceWorkSearchFormContext,
} from './PieceWorkOptionsSearchForm.hooks';

const getDefaultPieceWorkSearchFormValues = () => {
  return {
    name: '',
    code: '',
    amount: 0,
  } satisfies PieceWorkSearchScheme;
};

export const PieceWorkOptionsSearchForm = () => {
  const form = usePieceWorkSearchFormContext();

  return (
    <form
      className="@sm:grid-cols-4 @2xl:grid-cols-8 grid grid-cols-2 items-end gap-2"
      onSubmit={form.handleSubmit(console.log)}
    >
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="code"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Code</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="amount"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Amount</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      <Button
        onClick={() => form.reset(form.formState.defaultValues)}
        className="max-w-max"
        type="reset"
        variant="outline"
        size="sm"
      >
        Reset
      </Button>
    </form>
  );
};

PieceWorkOptionsSearchForm.getDefaultValues = getDefaultPieceWorkSearchFormValues;
