import './App.css';

import {
  ApiKey,
  ApiKeyClient,
  AUTH_KEY,
  GoogleAuthClient,
  User,
  UserClient,
} from '@kalos/kalos-rpc';
import { AlertDialogProvider, ScrollStoreProvider } from '@kalos/ui';
import { googleLogout, GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { utcToZonedTime } from 'date-fns-tz';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ENDPOINT } from './constants';
import { AuthContext, type AuthContextType, type TokenExchangeResult } from './context/AuthContext';
import { useUserQuery } from './hooks/react-query/useUserQuery';
import useEnhancedEffect from './hooks/useEnhancedEffect';
import { useLocalStorage } from './hooks/useLocalStorage';
import { AppRouter } from './Router';
import { queryClient } from './tools/queryClient';

const gClient = new GoogleAuthClient(ENDPOINT);
const userClient = new UserClient(ENDPOINT);
const keyClient = new ApiKeyClient(ENDPOINT);
// Constants
export const KEY_EXPIRY = 'key_expiry';
const SEPARATOR = ' $$$$$ ';

/**
 * Handles the token exchange process.
 *
 * @param {string} code - The code used for token exchange.
 * @return {object} An object containing the access token, refresh token, and expiry date.
 */
const handleTokenExchange = async (code: string) => {
  try {
    const token = await gClient.GetAuthToken(code);
    if (!token) {
      throw new Error('Token exchange failed');
    }
    const [accessToken, refreshToken, expiryDate] = token.split(SEPARATOR);
    return { accessToken, refreshToken, expiryDate };
  } catch (error) {
    console.error('Token exchange error:', error);
    throw error;
  }
};

const updateAPIKey = async function (
  userID: number,
  key: string,
  refresh: string,
  expiryDate: string,
) {
  const req = ApiKey.create({
    apiUser: userID,
    textId: 'google_auth_token',
  });
  try {
    const keyRes = await keyClient.Get(req);
    if (keyRes) {
      console.log('seems user has a key so lets update it', keyRes);
      req.apiKey = key;
      req.apiDescription = refresh;
      req.expireDate = expiryDate;
      req.fieldMask = ['ApiKey', 'ApiDescription', 'ExpireDate'];
      await keyClient.Update(req);
    } else {
      console.log('keyRes is null');
      throw new Error('no key');
    }
  } catch (err) {
    console.log('seems that user has no key so we create one', err);
    req.apiKey = key;
    req.apiDescription = refresh;
    req.expireDate = expiryDate;
    await keyClient.Create(req);
  }
};

const checkAndRefreshToken = async function checkAndRefreshToken(
  userId: number,
): Promise<TokenExchangeResult> {
  const apiUser = ApiKey.create({
    apiUser: userId,
    textId: 'google_auth_token',
  });
  try {
    const apiKey = await keyClient.Get(apiUser);
    if (apiKey) {
      await userClient.VerifyGoogleToken(apiKey!.apiKey);
      return {
        accessToken: apiKey.apiKey,
        refreshToken: apiKey.apiDescription,
        expiryDate: apiKey.expireDate,
      };
    } else {
      throw new Error('no api key found in check and refresh token');
    }
  } catch (err) {
    console.log('error while varifying api key', err);
    try {
      const key = await gClient.RefreshToken(userId);
      if (key && key.value) {
        const [accessToken, refreshToken, expiryDate] = key.value.split(SEPARATOR);
        await updateAPIKey(userId, accessToken, refreshToken, expiryDate);
        return {
          accessToken,
          refreshToken,
          expiryDate,
        };
      } else {
        throw new Error('failed to refresh token in check and refresh token');
      }
    } catch (err) {
      console.log('error while refreshing token', err);
      return { accessToken: '', refreshToken: '', expiryDate: '' };
    }
  }
};

export let outsideAuthSignout: AuthContextType['signout'] | null = null;
export let outsideAuthUpdateToken: (() => Promise<void>) | null = null;

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authChecked, setAuthChecked] = useState(false);
  const [user, setCurrentUser] = useLocalStorage(
    'user',
    useMemo(() => User.create(), []),
  );
  const [, setAccessToken] = useLocalStorage(AUTH_KEY, '');
  const [expiryString, setExpiryDate] = useLocalStorage(KEY_EXPIRY, '');

  const queryClient = useQueryClient();
  const currentUserQuery = useUserQuery({
    filters: { id: user.id },
    enabled: !!user.id,
  });

  useEffect(() => {
    if (currentUserQuery.isSuccess) {
      setCurrentUser(currentUserQuery.data);
    }
  }, [currentUserQuery.data, currentUserQuery.isSuccess, setCurrentUser]);

  const signin = useCallback(
    async (code: string, cb?: VoidFunction) => {
      try {
        console.log({ code });
        const { accessToken, refreshToken, expiryDate } = await handleTokenExchange(code);
        console.log({ accessToken });
        const userID = await userClient.VerifyGoogleToken(accessToken);
        setAccessToken(accessToken);
        setExpiryDate(expiryDate);
        await updateAPIKey(userID, accessToken, refreshToken, expiryDate);
        const userResult = await userClient.Get(User.create({ id: userID }));
        setCurrentUser(userResult);
        if (cb) {
          cb();
        }
      } catch (err) {
        console.error('user was not found in Kalos system', err);
        throw err;
      }
    },
    [setAccessToken, setCurrentUser, setExpiryDate],
  );

  const signout = useCallback(
    (callback?: VoidFunction) => {
      googleLogout();
      localStorage.removeItem(AUTH_KEY);
      localStorage.removeItem(KEY_EXPIRY);
      setCurrentUser(User.create());
      setAccessToken('');
      setExpiryDate('');
      queryClient.resetQueries();
      if (callback) {
        callback();
      }
    },
    [queryClient, setAccessToken, setCurrentUser, setExpiryDate],
  );

  const setUser = useCallback(
    (user: User) => {
      setCurrentUser(user);
    },
    [setCurrentUser],
  );

  useEnhancedEffect(() => {
    if (!authChecked) {
      (async () => {
        if (user && user.id !== 0) {
          const { accessToken, expiryDate } = await checkAndRefreshToken(user.id);
          if (accessToken !== '') {
            setAccessToken(accessToken);
            setExpiryDate(expiryDate);
          }
        }
        setAuthChecked(true);
      })();
    }
  }, [user, authChecked, setAccessToken, setExpiryDate]);

  /**
   * @throws {Error} - If the token exchange fails or payload does not satisfy the expected format.
   */
  const updateToken = useCallback(async () => {
    if (user.id !== 0) {
      const key = await gClient.RefreshToken(user.id);
      if (key && key.value) {
        const [accessToken, refreshToken, expiryDate] = key.value.split(SEPARATOR);
        await updateAPIKey(user.id, accessToken, refreshToken, expiryDate);
        setAccessToken(accessToken);
        setExpiryDate(expiryDate);
        console.log('Token refreshed successfully');
      } else {
        throw new Error('failed to refresh token in useEnhancedEffect of AuthProvider');
      }
    } else {
      throw new Error('Current user is not authenticated');
    }
  }, [setAccessToken, setExpiryDate, user.id]);

  outsideAuthSignout = signout;
  outsideAuthUpdateToken = updateToken;

  useEnhancedEffect(() => {
    const interval = setInterval(
      async () => {
        if (expiryString) {
          const expiryDate = new Date(expiryString);
          const now = new Date();
          // convert the time to EST in case user is in another timezone (expiry is in EST on server)
          const estTime = utcToZonedTime(now, 'America/New_York');
          // Check if the token will expire in the next 10 minutes
          if (expiryDate.getTime() - estTime.getTime() < 10 * 60 * 1000) {
            console.log("Token will expire in the next 10 minutes, let's refresh it");
            try {
              updateToken();
            } catch (err) {
              console.log(err);
              signout();
            }
          }
        }
      },
      2.5 * 60 * 1000, // Check every 2.5 minutes
    );

    return () => clearInterval(interval);
  }, [user, expiryString, setAccessToken, setExpiryDate, signout]);

  const value = useMemo(() => {
    return {
      user,
      signin,
      signout,
      setUser,
      authChecked,
      refresh: checkAndRefreshToken,
    };
  }, [user, signin, signout, setUser, authChecked]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const GOOGLE_CLIENT_ID =
  '493739388494-17m5c8kv2gl8pbvreotftn6d39602c6j.apps.googleusercontent.com';
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
        <ScrollStoreProvider>
          <AuthProvider>
            <AlertDialogProvider>
              <AppRouter />
            </AlertDialogProvider>
          </AuthProvider>
        </ScrollStoreProvider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
}

export default App;
