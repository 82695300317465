import { Event, EventList, MostLikelyJobRequest } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { type DeletedEventsFilterForm } from '../../modules/ComponentsLibrary/DeletedServiceCallsReport';
import {
  applyEventsFilter,
  EventClientService,
  loadEventsByFilterDeleted,
} from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type EventFilters = EntityFilter<Event>;

export const useEventsQuery = ({
  enabled = true,
  filters,
}: {
  enabled?: boolean;
  filters: Parameters<typeof applyEventsFilter>['0'];
}) => {
  const requestWithAppliedFilters = useMemo(() => {
    const { req, ...rest } = filters;
    return applyEventsFilter({ req, ...rest });
  }, [filters]);

  return useQuery({
    queryKey: [
      queryKeys.events.root,
      queryKeys.events.list,
      queryKeys.events.getEventsListHash(requestWithAppliedFilters),
    ],
    queryFn: () => {
      return EventClientService.BatchGet(requestWithAppliedFilters);
    },
    enabled,
  });
};

const getEventQueryKeys = (filter: EventFilter) => [
  queryKeys.events.root,
  queryKeys.events.getEventHash(filter),
];

export const useEventQueryInline = () => {
  const queryClient = useQueryClient();

  const fetchEvent = useCallback(
    async ({ filter = {} }: { filter?: EventFilter } = {}) => {
      const queryKeys = getEventQueryKeys(filter);
      const cache = queryClient.getQueryData<Event>(queryKeys);
      if (!cache) {
        const userReq = Event.create(filter);
        const event = await EventClientService.Get(userReq);
        if (!event) throw new Error('Event not found');
        queryClient.setQueryData(queryKeys, event);
        return event;
      }
      return cache;
    },
    [queryClient],
  );

  return useMemo(() => ({ fetchEvent }), [fetchEvent]);
};

export type EventFilter = EntityFilter<Event>;
export const eventQuery = {
  getQueryKeys: (filter: EventFilter) => [
    queryKeys.events.root,
    queryKeys.events.getEventHash(filter),
  ],
  getQueryFn: (filter: EventFilter) => () => {
    const req = Event.create(filter);
    return EventClientService.Get(req);
  },
};

export const useEventQuery = <TSelectedData = Event,>({
  enabled = true,
  filter = {},
  select,
}: {
  enabled?: boolean;
  filter?: EventFilter;
  select?: (data: Awaited<ReturnType<typeof EventClientService.Get>>) => TSelectedData;
}) => {
  return useQuery({
    queryFn: eventQuery.getQueryFn(filter),
    queryKey: eventQuery.getQueryKeys(filter),
    enabled,
    select,
  });
};

export const useEventBatchGetQuery = <TSelectData = EventList,>({
  enabled = true,
  filters,
  select,
}: {
  enabled?: boolean;
  select?: (data: EventList | undefined) => TSelectData;
  filters: EventFilters;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.events.root,
      queryKeys.events.list,
      queryKeys.events.getEventsBatchGetHash(filters),
    ],
    queryFn: async () => {
      const req = Event.create(filters);
      return await EventClientService.BatchGet(req);
    },
    select,
    enabled,
  });
};

export const useDeletedEventsQuery = ({
  enabled = true,
  page,
  filter,
}: {
  enabled?: boolean;
  filter: DeletedEventsFilterForm;
  page: number;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.events.root,
      queryKeys.events.deletedEvents,
      queryKeys.events.getDeletedEventsHash({ filter, page }),
    ],
    queryFn: () => {
      return loadEventsByFilterDeleted({
        page: page,
        filter: filter,
        sort: {
          orderBy: 'date_started',
          orderByField: 'dateStarted',
          orderDir: 'ASC',
        },
        req: Event.create(),
      });
    },
    enabled,
  });
};

export const useEventCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (req: Event) => {
      return await EventClientService.Create(req);
    },
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.events.root] });
    },
  });
};

export const useEventDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (req: Event) => {
      return await EventClientService.Delete(req);
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [queryKeys.events.root] });
    },
  });
};

export const useEventUpdateMutation = ({
  onSuccess: propOnSuccess,
}: {
  onSuccess?: (data: Event | undefined, variables: Event, context: unknown) => unknown;
} = {}) => {
  const queryClient = useQueryClient();

  const onSuccess: (data: Event | undefined, variables: Event, context: unknown) => unknown = (
    data,
  ) => {
    if (data) {
      queryClient.setQueriesData<Event | EventList>(
        { queryKey: [queryKeys.events.root] },
        (cache) => {
          if (cache) {
            if ('results' in cache) {
              if (cache.results.find((event) => event.id === data.id)) {
                console.log({ cache: cache.results });
                console.log({ dataId: data.id });

                return EventList.create({
                  ...cache,
                  results: cache.results.map((event) => (event.id === data.id ? data : event)),
                });
              }
            } else {
              if (cache.id === data.id) {
                return data;
              }
            }
          }
        },
      );
    }
  };

  return useMutation({
    mutationFn: async (req: Event) => {
      return await EventClientService.Update(req);
    },
    onSuccess: propOnSuccess || onSuccess,
  });
};

export type MostLikelyJobRequestFilter = EntityFilter<MostLikelyJobRequest>;
export const useEventHintQuery = <TSelectData = EventList,>({
  filter,
  enabled,
  select,
}: {
  filter: MostLikelyJobRequestFilter;
  enabled?: boolean;
  select?: (data: EventList) => TSelectData;
}) => {
  return useQuery({
    queryKey: [queryKeys.jobHits.root, queryKeys.jobHits.hash(filter)],
    queryFn: async () => {
      return await EventClientService.BatchGetJobHints(MostLikelyJobRequest.create(filter));
    },
    enabled,
    select,
  });
};
