import {
  Button,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SimpleSelect,
} from '@kalos/ui';
import { useMemo } from 'react';

import { OPTION_ALL } from '../../../constants';
import { getWeekOptions } from '../../../tools/helpers';
import { REIMBURSEMENT_STATUSES } from './constants';
import {
  type ReimbursementSearchFormSchema,
  useReimbursementSearchFormContext,
} from './ReimbursementSearchForm.hooks';
import { ReimbursementStatusLabel } from './ReimbursementStatusesLabel';

const getDefaultReimbursementSearchFormValues = (
  data?: Partial<ReimbursementSearchFormSchema>,
): ReimbursementSearchFormSchema => {
  return {
    createdDate: OPTION_ALL,
    description: '',
    statusId: 0,
    ...data,
  };
};

const reimbursementStatusesSelectOptions: React.ComponentProps<typeof SimpleSelect>['values'] =
  REIMBURSEMENT_STATUSES.map((status) => ({
    label: <ReimbursementStatusLabel id={status.value} />,
    value: status.value.toString(),
  }));

export const ReimbursementSearchForm = () => {
  const form = useReimbursementSearchFormContext();

  const WEEK_OPTIONS = useMemo(() => {
    const weekOptions = getWeekOptions(52, 0, -1).map((week) => ({
      label: week.label,
      value: week.value.toString(),
    }));
    const allOption = { label: OPTION_ALL, value: OPTION_ALL };
    weekOptions.unshift(allOption);
    return weekOptions;
  }, []);

  const handleReset = () => {
    form.reset(getDefaultReimbursementSearchFormValues());
  };

  return (
    <div>
      <form className="grid grid-cols-1 gap-4 sm:grid-cols-3" onSubmit={console.log}>
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Search Reimbursables</FormLabel>
              <FormMessage />
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="createdDate"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Week</FormLabel>
              <FormMessage />
              <FormControl>
                <SimpleSelect
                  values={WEEK_OPTIONS}
                  onChange={field.onChange}
                  placeholder={OPTION_ALL}
                  selectedValue={field.value}
                  disabled={field.disabled}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="statusId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Status</FormLabel>
              <FormMessage />
              <FormControl>
                <SimpleSelect
                  values={reimbursementStatusesSelectOptions}
                  onChange={field.onChange}
                  placeholder={OPTION_ALL}
                  selectedValue={field.value.toString()}
                  disabled={field.disabled}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </form>
      <div className="mt-4 flex justify-end">
        <Button onClick={handleReset}>Reset</Button>
      </div>
    </div>
  );
};

ReimbursementSearchForm.getDefaultValues = getDefaultReimbursementSearchFormValues;
