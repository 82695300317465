import {
  Button,
  DateInput,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  SimpleSelect,
} from '@kalos/ui';

import { PAYMENT_STATUS_OPTIONS } from '../../../CustomerDetails/components/constants';
import { useContractsSearchFormContext } from './utils';

const ContractsSearchForm = () => {
  const form = useContractsSearchFormContext();
  return (
    // intentionally ignored as values are used as they are typed in
    <form
      className="grid grid-cols-2 place-content-center items-end gap-x-4 gap-y-3 lg:grid-cols-6"
      onSubmit={form.handleSubmit(console.log)}
    >
      <FormField
        control={form.control}
        name="number"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Contract Number</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="lastName"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="businessName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Business Name</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="dateStarted"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Contract Start Date</FormLabel>
            <DateInput value={field.value} onChange={field.onChange} />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="dateEnded"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Expiration Date</FormLabel>
            <DateInput value={field.value} onChange={field.onChange} />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="notes"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Notes</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="paymentTerms"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Payment Terms</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="paymentStatus"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Payment status</FormLabel>
            <FormControl>
              <SimpleSelect
                values={PAYMENT_STATUS_OPTIONS}
                selectedValue={field.value}
                {...field}
              />
            </FormControl>
          </FormItem>
        )}
      />

      <Button
        type="reset"
        variant="outline"
        className="mt-auto max-w-max"
        onClick={() => form.reset()}
      >
        Reset
      </Button>
    </form>
  );
};

export default ContractsSearchForm;
