import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const toolFundSettingsSchema = z.object({
  id: z.number(),
  userId: z.number(),
  isEligible: z.boolean(),
  monthlyAllowance: z.number().min(0, 'Must be a positive number'),
  lastUpdated: z.string(),
  updatedBy: z.number(),
  tradeTypeId: z.number().optional(),
});

export type ToolFundSettingsFormType = z.infer<typeof toolFundSettingsSchema>;

const defaultFormValues: ToolFundSettingsFormType = {
  id: 0,
  userId: 0,
  isEligible: false,
  monthlyAllowance: 0,
  lastUpdated: new Date().toISOString(),
  updatedBy: 0,
  tradeTypeId: undefined,
};
export const useToolFundSettingsForm = ({
  defaultValues,
}: {
  defaultValues?: Partial<ToolFundSettingsFormType>;
}) => {
  return useForm<ToolFundSettingsFormType>({
    resolver: zodResolver(toolFundSettingsSchema),
    mode: 'onSubmit',
    defaultValues: {
      ...defaultFormValues,
      ...defaultValues,
    },
  });
};
