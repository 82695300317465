// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "reports.proto" (syntax proto3)
// tslint:disable
import { ReportService } from "./reports";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service ReportService
 */
export class ReportServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = ReportService.typeName;
        this.methods = ReportService.methods;
        this.options = ReportService.options;
    }
    /**
     * @generated from protobuf rpc: GetSpiffReportData(SpiffReportLine) returns (SpiffReport);
     */
    getSpiffReportData(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPromptPaymentData(PromptPaymentReportLine) returns (PromptPaymentReport);
     */
    getPromptPaymentData(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTransactionDumpData(TransactionReportLine) returns (TransactionDumpReport);
     */
    getTransactionDumpData(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTimeoffReportData(TimeoffReportRequest) returns (TimeoffReport);
     */
    getTimeoffReportData(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetReceiptJournalReport(ReceiptJournalReportLine) returns (ReceiptJournalReport);
     */
    getReceiptJournalReport(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesJournalReport(SalesJournalReportLine) returns (SalesJournalReport);
     */
    getSalesJournalReport(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProcessedReport(ProcessedReportRequest) returns (ProcessedReport);
     */
    getProcessedReport(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCostItemReport(DateRange) returns (CostItemReport);
     */
    getCostItemReport(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBillingAuditReport(DateRange) returns (BillingAuditReport);
     */
    getBillingAuditReport(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInvoiceJournalReport(DateRange) returns (InvoiceJournalReport);
     */
    getInvoiceJournalReport(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetJobHoursEmployeeReport(DateRange) returns (JobHoursEmployeeReport);
     */
    getJobHoursEmployeeReport(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDailyJobReport(Int32) returns (DailyJobReport);
     */
    getDailyJobReport(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPromptPaymentSummary(PromptPaymentSummaryRequest) returns (PromptPaymentSummaryResponse);
     */
    getPromptPaymentSummary(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateEmailsForPromptPayment(PromptPaymentSummaryRequest) returns (String);
     */
    createEmailsForPromptPayment(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ParseTokenForRequest(String) returns (PromptPaymentSummaryRequest);
     */
    parseTokenForRequest(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPromptPaymentDetails(PromptPaymentSummaryRequest) returns (PromptPaymentDetailsResponse);
     */
    getPromptPaymentDetails(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPromptPaymentListFinalized(PromptPaymentListFinalized) returns (PromptPaymentListFinalizedResponse);
     */
    getPromptPaymentListFinalized(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Create(Report) returns (Report);
     */
    create(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(Report) returns (ReportFile);
     */
    update(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(ReportFile) returns (ReportFile);
     */
    get(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(ReportFile) returns (ReportFileList);
     */
    batchGet(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllReadyToBeProcessedItems(DateRange) returns (CostItemReport);
     */
    getAllReadyToBeProcessedItems(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ProcessAllApproved(DateRange) returns (CostItemReport);
     */
    processAllApproved(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetWeeklyEmployeeReport(WeeklyEmployeeReportRequest) returns (TimesheetAuditReportCollection);
     */
    getWeeklyEmployeeReport(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEmployeeKPI(EmployeeKpiRequest) returns (EmployeeKpi);
     */
    getEmployeeKPI(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: KpiReport(KpiReportRequest) returns (EmployeeKpiList);
     */
    kpiReport(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEmployeeHoursRevenue(EmployeeHoursRevenue) returns (EmployeeHoursRevenue);
     */
    getEmployeeHoursRevenue(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBatchEmployeeHoursRevenue(EmployeeHoursRevenue) returns (EmployeeHoursRevenueList);
     */
    getBatchEmployeeHoursRevenue(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
