import { BaseClient } from '../BaseClient';
import { Double, Int32 } from '../compiled-protos/common';
import {
  CalendarData,
  CallCountRequest,
  CallCountResponse,
  CostReportData,
  type CostReportReq,
  Event,
  EventList,
  InvoicePayment,
  InvoicePaymentLine, InvoicePaymentLineList,
  InvoicePaymentList,
  JobAdmin,
  JobAdminList,
  type JobNumberRequest,
  MostLikelyJobRequest,
  Quotable,
  QuotableList,
  QuotableRead,
  SubAccountEventRequest
} from '../compiled-protos/event';
import { EventServiceClient } from '../compiled-protos/event.client';
import { ProjectTask } from '../compiled-protos/task';
import { ProjectTaskList } from '../Task';

class EventClient extends BaseClient {
  self: EventServiceClient;

  req: Event;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.req = Event.create();
    this.self = new EventServiceClient(this.transport);
  }

  public async Create(req: Event) {
    let res = Event.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: Event) {
    let res = Event.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetCalendarData(req: Event) {
    let res = CalendarData.create();
    try {
      res = await this.self.getCalendarData(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetCostReportData(req: CostReportReq) {
    let res = CostReportData.create();
    try {
      res = await this.self.batchGetCostReportData(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: Event) {
    let res = Event.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: Event) {
    let res = Event.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Event) {

    const res = await this.self.batchGet(req, this.getMetaData()).response;
    return res;
  }

  public async WriteQuote(req: Quotable) {
    let res = Quotable.create();
    try {
      res = await this.self.writeQuote(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async ReadQuotes(req: QuotableRead) {
    const res = await this.self.readQuotes(req, this.getMetaData()).response;
    return res;
  }

  public async GetProjectTasks(req: ProjectTask) {
    let res = ProjectTaskList.create();
    try {
      res = await this.self.getProjectTasks(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async CreateJobAdmin(req: JobAdmin) {
    return await this.self.createJobAdmin(req, this.getMetaData()).response;
  }

  public async GetJobAdmin(req: JobAdmin) {
    let res = JobAdmin.create();
    try {
      res = await this.self.getJobAdmin(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }



  public async UpdateJobAdmin(req: JobAdmin) {
    return await this.self.updateJobAdmin(req, this.getMetaData()).response;
  }

  public async DeleteJobAdmin(req: JobAdmin) {
    return await this.self.deleteJobAdmin(req, this.getMetaData()).response;
  }

  public async BatchGetJobAdmin(req: JobAdmin) {
    let res = JobAdminList.create();
    try {
      res = await this.self.batchGetJobAdmin(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async GetEventDetails(req: JobNumberRequest) {
    return await this.self.getEventDetails(req, this.getMetaData()).response;
  }
  /**
   * Returns tasks associated with the provided event ID
   * @param ID ID of an event
   */
  public async loadTasksByEventID(ID: number) {
    const req = ProjectTask.create();
    req.eventId = ID;
    req.isActive = true;
    req.orderBy = 'hourly_start';
    req.orderDir = 'asc';
    return await this.GetProjectTasks(req);
  }

  /**
   * Checks event.log_technicianAssigned for presence of provided user_id
   * @param ID the user ID
   * @param page
   */
  public async loadEventsByUserID(ID: number, page = 0) {
    const req = Event.create();
    req.logTechnicianAssigned = `%${ID}%`;
    req.pageNumber = page;
    return await this.BatchGet(req);
  }

  /**
   *  Loads an event by a service call Id
   * @param ServiceCallID The service call Id
   * @param Page
   */
  public async LoadEventByServiceCallID(ID: number, omitArchive = false) {
    const req = Event.create({ id: ID, notEquals: ['PropertyId'] });
    if (omitArchive) {
      req.logJobStatus = 'Archived';
      req.notEquals = ['PropertyId', 'LogJobStatus'];
    }
    return await this.Get(req);
  }

  /**
   *  Loads an event by a service call Id
   * @param ServiceCallID The service call Id
   * @param Page
   */
  public async LoadEventsByServiceCallID(ID: number) {
    const req = Event.create();
    req.id = ID;
    return await this.BatchGet(req);
  }

  public async deleteEventById(ID: number) {
    const req = Event.create();
    req.id = ID;
    await this.Delete(req);
  }

  /**
   * Loads all upcoming events for a sub account using the current date as the cutoff
   * @param id the sub account ID
   * @returns EventList
   */
  public async GetUpcomingSubAccountEvents(id: number) {
    const req = SubAccountEventRequest.create({ subAccountId: id });
    const res = EventList.create();
    try {
      await this.self.fetchUpcomingSubAccountEvents(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  /**
   * Loads all past events for a sub account using the current date as the cutoff
   * @param id the sub account ID
   * @returns EventList
   */
  public async GetSubAccountEventHistory(id: number) {
    const req = SubAccountEventRequest.create({ subAccountId: id });
    const res = EventList.create();

    try {
      await this.self.fetchSubAccountEventHistory(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  /**
   * Gets a single event with the provided ID
   * @param ID
   */
  public async loadEvent(ID: number) {
    const req = Event.create();
    req.id = ID;
    return this.Get(req);
  }

  public loadProjectTasks = async (eventId: number) => {
    const req = Event.create();
    const data = await this.loadTasksByEventID(eventId);
    return data?.results.sort((a, b) => {
      const A = a.startDate;
      const B = b.startDate;
      if (A < B) return -1;
      if (A > B) return 1;
      return 0;
    });
  };

  public loadQuotable = async (id: number) => {
    const req = QuotableRead.create();
    req.eventId = id;
    req.isActive = true;
    req.fieldMask = ['IsActive'];
    const res = await this.ReadQuotes(req);
    return res;
  };

  public loadQuotableList = async (req: QuotableRead) => {
    let res = QuotableList.create();
    try {
      res = await this.self.readQuotes(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  };

  public async getLaborHoursByEventID(eventID: number) {
    const req = Int32.create();
    let res = Double.create();
    try {
      res = await this.self.getLaborHoursByEventID(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  /**
   * Returns loaded QuoteParts
   * @returns QuotePart[]
   */
  public async loadQuoteParts(filter: QuotableRead) {
    const results: Quotable[] = [];
    try {
      const res = await await this.loadQuotableList(filter);
      const list = res?.data;
      const listLen = list?.length;
      const totalCount = res?.totalCount;
      results.concat(list!);
      if (totalCount! > listLen!) {
        const batchesAmount = Math.ceil((totalCount! - listLen!) / listLen!);
        const batchResults = await Promise.all(
          Array.from(Array(batchesAmount)).map(async (_) => {
            return (await this.loadQuotableList(filter))!.data;
          }),
        );
        results.push(...batchResults.reduce((aggr, item) => [...aggr, ...item], []));
      }
      return results;
    } catch (err) {
      return err;
    }
  }

  public async updateMaterialUsed(
    serviceCallId: number,
    materialUsed: string,
    materialTotal: number,
  ) {
    const req = Event.create();
    req.id = serviceCallId;
    req.materialUsed = materialUsed;
    req.materialTotal = materialTotal;
    req.fieldMask = ['MaterialUsed', 'MaterialTotal'];
    await this.Update(req);
  }

  public async Customer_getUpcomingEventsBySubAccountId({
    subAccountId,
    authToken,
  }: {
    subAccountId: number;
    authToken: string;
  }) {
    const req = SubAccountEventRequest.create({ subAccountId: subAccountId });

    return await this.self.fetchUpcomingSubAccountEvents(req, this.getMetaData(authToken)).response;
  }

  public async Customer_getEventsHistoryBySubAccountId({
    subAccountId,
    authToken,
  }: {
    subAccountId: number;
    authToken: string;
  }) {
    const req = SubAccountEventRequest.create({ subAccountId: subAccountId });

    return await this.self.fetchSubAccountEventHistory(req, this.getMetaData(authToken)).response;
  }
  public async Customer_BatchGet({ request, authToken }: { request: Event; authToken: string }) {
    return await this.self.batchGet(request, this.getMetaData(authToken)).response;
  }
  public async Customer_Get({ request, authToken }: { request: Event; authToken: string }) {
    return await this.self.get(request, this.getMetaData(authToken)).response;
  }

  /**
   * Returns loaded Events by property id
   * @param propertyId: property id
   * @returns Event[]
   */
  public async loadEventsByPropertyId(propertyId: number) {
    const req = Event.create();
    req.isActive = 1;
    req.propertyId = propertyId;
    req.withoutLimit = true;
    const res = await this.BatchGet(req);
    return res!.results.sort((a, b) => {
      const A = a.logJobNumber.toLocaleLowerCase();
      const B = b.logJobNumber.toLocaleLowerCase();
      if (A < B) return -1;
      if (A > B) return 1;
      return 0;
    });
  }


  public async getCurrentDayServiceCallWindows(req: CallCountRequest) {
    // Get the current date
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months start at 0!
    const dd = String(today.getDate()).padStart(2, '0');
    const startTime = `${yyyy}-${mm}-${dd} 00:00:00`;
    const endTime = `${yyyy}-${mm}-${dd} 23:59:59`;
    // Create the request object
    const request = {
      startTime: startTime,
      endTime: endTime
    };

    try {
      const res = await this.self.getCallCountForTimeRange(req, this.getMetaData()).response;
      return res;
    } catch (err) {
      console.log(err);
      return;
    }
  }


  public upsertEvent = async (data: Event) => {
    return await this[data.id ? 'Update' : 'Create'](data);
  };

  public async BatchGetJobHints(req: MostLikelyJobRequest) {
    return await this.self.mostLikelyJob(req, this.getMetaData()).response
  }

  /* INVOICE PAYMENT */
  public async BatchGetInvoicePayment(req: InvoicePayment) {
    return await this.self.batchGetInvoicePayment(req, this.getMetaDataWithoutCache()).response
  }

  public async GetInvoicePayment(req: InvoicePayment) {
    return await this.self.getInvoicePayment(req, this.getMetaDataWithoutCache()).response
  }

  public async CreateInvoicePayment(req: InvoicePayment) {
    return await this.self.createInvoicePayment(req, this.getMetaData()).response
  }

  public async UpdateInvoicePayment(req: InvoicePayment) {
    return await this.self.updateInvoicePayment(req, this.getMetaData()).response
  }

  public async DeleteInvoicePayment(req: InvoicePayment) {
    return await this.self.deleteInvoicePayment(req, this.getMetaData()).response
  }

  /* INVOICE PAYMENT LINE */
  public async BatchGetInvoicePaymentLine(req: InvoicePaymentLine) {
    return await this.self.batchGetInvoicePaymentLine(req, this.getMetaDataWithoutCache()).response
  }

  public async GetInvoicePaymentLine(req: InvoicePaymentLine) {
    return await this.self.getInvoicePaymentLine(req, this.getMetaDataWithoutCache()).response
  }

  public async CreateInvoicePaymentLine(req: InvoicePaymentLine) {
    return await this.self.createInvoicePaymentLine(req, this.getMetaData()).response
  }

  public async UpdateInvoicePaymentLine(req: InvoicePaymentLine) {
    return await this.self.updateInvoicePaymentLine(req, this.getMetaData()).response
  }

  public async DeleteInvoicePaymentLine(req: InvoicePaymentLine) {
    return await this.self.deleteInvoicePaymentLine(req, this.getMetaData()).response
  }
}

export { Event, EventList, EventClient, Quotable, QuotableList, MostLikelyJobRequest, InvoicePayment, InvoicePaymentLine, InvoicePaymentList, InvoicePaymentLineList, CallCountRequest, CallCountResponse };
