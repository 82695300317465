// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "s3.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "s3.proto" (syntax proto3)
// tslint:disable
import { IDInt32 } from "./common";
import { String$ } from "./common";
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf enum ACL
 */
export var ACL;
(function (ACL) {
    /**
     * @generated from protobuf enum value: Private = 0;
     */
    ACL[ACL["Private"] = 0] = "Private";
    /**
     * @generated from protobuf enum value: PublicRead = 1;
     */
    ACL[ACL["PublicRead"] = 1] = "PublicRead";
    /**
     * @generated from protobuf enum value: PublicReadWrite = 2;
     */
    ACL[ACL["PublicReadWrite"] = 2] = "PublicReadWrite";
})(ACL || (ACL = {}));
// @generated message type with reflection information, may provide speed optimized methods
class SubAccountDocumentRequest$Type extends MessageType {
    constructor() {
        super("SubAccountDocumentRequest", [
            { no: 1, name: "sub_account_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "document", kind: "message", T: () => Document }
        ]);
    }
    create(value) {
        const message = { subAccountId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sub_account_id */ 1:
                    message.subAccountId = reader.int32();
                    break;
                case /* Document document */ 2:
                    message.document = Document.internalBinaryRead(reader, reader.uint32(), options, message.document);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 sub_account_id = 1; */
        if (message.subAccountId !== 0)
            writer.tag(1, WireType.Varint).int32(message.subAccountId);
        /* Document document = 2; */
        if (message.document)
            Document.internalBinaryWrite(message.document, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubAccountDocumentRequest
 */
export const SubAccountDocumentRequest = new SubAccountDocumentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Document$Type extends MessageType {
    constructor() {
        super("Document", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "invoice_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "contract_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "task_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "filename", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "version", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "invoice", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "quote", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "maintenance", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "other", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, invoiceId: 0, propertyId: 0, contractId: 0, userId: 0, taskId: 0, fileId: 0, filename: "", dateCreated: "", description: "", type: 0, version: 0, invoice: 0, quote: 0, maintenance: 0, other: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", notEquals: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 invoice_id */ 2:
                    message.invoiceId = reader.int32();
                    break;
                case /* int32 property_id */ 3:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 contract_id */ 4:
                    message.contractId = reader.int32();
                    break;
                case /* int32 user_id */ 5:
                    message.userId = reader.int32();
                    break;
                case /* int32 task_id */ 6:
                    message.taskId = reader.int32();
                    break;
                case /* int32 file_id */ 7:
                    message.fileId = reader.int32();
                    break;
                case /* string filename */ 8:
                    message.filename = reader.string();
                    break;
                case /* string date_created */ 9:
                    message.dateCreated = reader.string();
                    break;
                case /* string description */ 10:
                    message.description = reader.string();
                    break;
                case /* int32 type */ 11:
                    message.type = reader.int32();
                    break;
                case /* int32 version */ 12:
                    message.version = reader.int32();
                    break;
                case /* int32 invoice */ 13:
                    message.invoice = reader.int32();
                    break;
                case /* int32 quote */ 14:
                    message.quote = reader.int32();
                    break;
                case /* int32 maintenance */ 15:
                    message.maintenance = reader.int32();
                    break;
                case /* int32 other */ 16:
                    message.other = reader.int32();
                    break;
                case /* repeated string field_mask */ 17:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 18:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 19:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 20:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string not_equals */ 21:
                    message.notEquals.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 invoice_id = 2; */
        if (message.invoiceId !== 0)
            writer.tag(2, WireType.Varint).int32(message.invoiceId);
        /* int32 property_id = 3; */
        if (message.propertyId !== 0)
            writer.tag(3, WireType.Varint).int32(message.propertyId);
        /* int32 contract_id = 4; */
        if (message.contractId !== 0)
            writer.tag(4, WireType.Varint).int32(message.contractId);
        /* int32 user_id = 5; */
        if (message.userId !== 0)
            writer.tag(5, WireType.Varint).int32(message.userId);
        /* int32 task_id = 6; */
        if (message.taskId !== 0)
            writer.tag(6, WireType.Varint).int32(message.taskId);
        /* int32 file_id = 7; */
        if (message.fileId !== 0)
            writer.tag(7, WireType.Varint).int32(message.fileId);
        /* string filename = 8; */
        if (message.filename !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.filename);
        /* string date_created = 9; */
        if (message.dateCreated !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.dateCreated);
        /* string description = 10; */
        if (message.description !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.description);
        /* int32 type = 11; */
        if (message.type !== 0)
            writer.tag(11, WireType.Varint).int32(message.type);
        /* int32 version = 12; */
        if (message.version !== 0)
            writer.tag(12, WireType.Varint).int32(message.version);
        /* int32 invoice = 13; */
        if (message.invoice !== 0)
            writer.tag(13, WireType.Varint).int32(message.invoice);
        /* int32 quote = 14; */
        if (message.quote !== 0)
            writer.tag(14, WireType.Varint).int32(message.quote);
        /* int32 maintenance = 15; */
        if (message.maintenance !== 0)
            writer.tag(15, WireType.Varint).int32(message.maintenance);
        /* int32 other = 16; */
        if (message.other !== 0)
            writer.tag(16, WireType.Varint).int32(message.other);
        /* repeated string field_mask = 17; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(17, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 18; */
        if (message.pageNumber !== 0)
            writer.tag(18, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 19; */
        if (message.orderBy !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 20; */
        if (message.orderDir !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string not_equals = 21; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(21, WireType.LengthDelimited).string(message.notEquals[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Document
 */
export const Document = new Document$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DocumentList$Type extends MessageType {
    constructor() {
        super("DocumentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Document },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Document results */ 1:
                    message.results.push(Document.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Document results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Document.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DocumentList
 */
export const DocumentList = new DocumentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InternalDocument$Type extends MessageType {
    constructor() {
        super("InternalDocument", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "doc_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "filename", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "tag", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "date_modified", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "tag_data", kind: "message", T: () => DocumentKey },
            { no: 10, name: "file", kind: "message", T: () => File },
            { no: 11, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, docUserId: 0, filename: "", dateCreated: "", description: "", tag: 0, fileId: 0, dateModified: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 doc_user_id */ 2:
                    message.docUserId = reader.int32();
                    break;
                case /* string filename */ 3:
                    message.filename = reader.string();
                    break;
                case /* string date_created */ 4:
                    message.dateCreated = reader.string();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* int32 tag */ 6:
                    message.tag = reader.int32();
                    break;
                case /* int32 file_id */ 7:
                    message.fileId = reader.int32();
                    break;
                case /* string date_modified */ 8:
                    message.dateModified = reader.string();
                    break;
                case /* DocumentKey tag_data */ 9:
                    message.tagData = DocumentKey.internalBinaryRead(reader, reader.uint32(), options, message.tagData);
                    break;
                case /* File file */ 10:
                    message.file = File.internalBinaryRead(reader, reader.uint32(), options, message.file);
                    break;
                case /* repeated string field_mask */ 11:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 12:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 13:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 14:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 doc_user_id = 2; */
        if (message.docUserId !== 0)
            writer.tag(2, WireType.Varint).int32(message.docUserId);
        /* string filename = 3; */
        if (message.filename !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.filename);
        /* string date_created = 4; */
        if (message.dateCreated !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.dateCreated);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* int32 tag = 6; */
        if (message.tag !== 0)
            writer.tag(6, WireType.Varint).int32(message.tag);
        /* int32 file_id = 7; */
        if (message.fileId !== 0)
            writer.tag(7, WireType.Varint).int32(message.fileId);
        /* string date_modified = 8; */
        if (message.dateModified !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.dateModified);
        /* DocumentKey tag_data = 9; */
        if (message.tagData)
            DocumentKey.internalBinaryWrite(message.tagData, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* File file = 10; */
        if (message.file)
            File.internalBinaryWrite(message.file, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 11; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 12; */
        if (message.pageNumber !== 0)
            writer.tag(12, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 13; */
        if (message.orderBy !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 14; */
        if (message.orderDir !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InternalDocument
 */
export const InternalDocument = new InternalDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InternalDocumentList$Type extends MessageType {
    constructor() {
        super("InternalDocumentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InternalDocument },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated InternalDocument results */ 1:
                    message.results.push(InternalDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated InternalDocument results = 1; */
        for (let i = 0; i < message.results.length; i++)
            InternalDocument.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InternalDocumentList
 */
export const InternalDocumentList = new InternalDocumentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DocumentKey$Type extends MessageType {
    constructor() {
        super("DocumentKey", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", color: "", isActive: false, dateCreated: "", fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string color */ 3:
                    message.color = reader.string();
                    break;
                case /* bool is_active */ 4:
                    message.isActive = reader.bool();
                    break;
                case /* string date_created */ 5:
                    message.dateCreated = reader.string();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string color = 3; */
        if (message.color !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.color);
        /* bool is_active = 4; */
        if (message.isActive !== false)
            writer.tag(4, WireType.Varint).bool(message.isActive);
        /* string date_created = 5; */
        if (message.dateCreated !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dateCreated);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DocumentKey
 */
export const DocumentKey = new DocumentKey$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DocumentKeyList$Type extends MessageType {
    constructor() {
        super("DocumentKeyList", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DocumentKey }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DocumentKey data */ 1:
                    message.data.push(DocumentKey.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated DocumentKey data = 1; */
        for (let i = 0; i < message.data.length; i++)
            DocumentKey.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DocumentKeyList
 */
export const DocumentKeyList = new DocumentKeyList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class File$Type extends MessageType {
    constructor() {
        super("File", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "create_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "geolocation_lat", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "geolocation_lng", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "creation_dt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "file_transcript", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "file_summary", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", bucket: "", mimeType: "", createTime: "", ownerId: 0, eventId: 0, geolocationLat: 0, geolocationLng: 0, fieldMask: [], pageNumber: 0, creationDt: "", fileTranscript: "", fileSummary: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string bucket */ 3:
                    message.bucket = reader.string();
                    break;
                case /* string mime_type */ 4:
                    message.mimeType = reader.string();
                    break;
                case /* string create_time */ 5:
                    message.createTime = reader.string();
                    break;
                case /* int32 owner_id */ 8:
                    message.ownerId = reader.int32();
                    break;
                case /* int32 event_id */ 9:
                    message.eventId = reader.int32();
                    break;
                case /* double geolocation_lat */ 10:
                    message.geolocationLat = reader.double();
                    break;
                case /* double geolocation_lng */ 11:
                    message.geolocationLng = reader.double();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                case /* string creation_dt */ 12:
                    message.creationDt = reader.string();
                    break;
                case /* string file_transcript */ 13:
                    message.fileTranscript = reader.string();
                    break;
                case /* string file_summary */ 14:
                    message.fileSummary = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string bucket = 3; */
        if (message.bucket !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.bucket);
        /* string mime_type = 4; */
        if (message.mimeType !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.mimeType);
        /* string create_time = 5; */
        if (message.createTime !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.createTime);
        /* int32 owner_id = 8; */
        if (message.ownerId !== 0)
            writer.tag(8, WireType.Varint).int32(message.ownerId);
        /* int32 event_id = 9; */
        if (message.eventId !== 0)
            writer.tag(9, WireType.Varint).int32(message.eventId);
        /* double geolocation_lat = 10; */
        if (message.geolocationLat !== 0)
            writer.tag(10, WireType.Bit64).double(message.geolocationLat);
        /* double geolocation_lng = 11; */
        if (message.geolocationLng !== 0)
            writer.tag(11, WireType.Bit64).double(message.geolocationLng);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        /* string creation_dt = 12; */
        if (message.creationDt !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.creationDt);
        /* string file_transcript = 13; */
        if (message.fileTranscript !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.fileTranscript);
        /* string file_summary = 14; */
        if (message.fileSummary !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.fileSummary);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message File
 */
export const File = new File$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileList$Type extends MessageType {
    constructor() {
        super("FileList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => File },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated File results */ 1:
                    message.results.push(File.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated File results = 1; */
        for (let i = 0; i < message.results.length; i++)
            File.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FileList
 */
export const FileList = new FileList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MoveConfig$Type extends MessageType {
    constructor() {
        super("MoveConfig", [
            { no: 1, name: "source", kind: "message", T: () => FileObject },
            { no: 2, name: "destination", kind: "message", T: () => FileObject },
            { no: 3, name: "preserve_source", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { preserveSource: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* FileObject source */ 1:
                    message.source = FileObject.internalBinaryRead(reader, reader.uint32(), options, message.source);
                    break;
                case /* FileObject destination */ 2:
                    message.destination = FileObject.internalBinaryRead(reader, reader.uint32(), options, message.destination);
                    break;
                case /* bool preserve_source */ 3:
                    message.preserveSource = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* FileObject source = 1; */
        if (message.source)
            FileObject.internalBinaryWrite(message.source, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* FileObject destination = 2; */
        if (message.destination)
            FileObject.internalBinaryWrite(message.destination, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool preserve_source = 3; */
        if (message.preserveSource !== false)
            writer.tag(3, WireType.Varint).bool(message.preserveSource);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MoveConfig
 */
export const MoveConfig = new MoveConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileObject$Type extends MessageType {
    constructor() {
        super("FileObject", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "tag_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 8, name: "acl", kind: "enum", T: () => ["ACL", ACL] },
            { no: 10, name: "no_write_to_database", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, bucket: "", key: "", tagString: "", mimeType: "", createdAt: "", uri: "", data: new Uint8Array(0), acl: 0, noWriteToDatabase: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string bucket */ 2:
                    message.bucket = reader.string();
                    break;
                case /* string key */ 3:
                    message.key = reader.string();
                    break;
                case /* string tag_string */ 9:
                    message.tagString = reader.string();
                    break;
                case /* string mime_type */ 4:
                    message.mimeType = reader.string();
                    break;
                case /* string created_at */ 5:
                    message.createdAt = reader.string();
                    break;
                case /* string uri */ 6:
                    message.uri = reader.string();
                    break;
                case /* bytes data */ 7:
                    message.data = reader.bytes();
                    break;
                case /* ACL acl */ 8:
                    message.acl = reader.int32();
                    break;
                case /* bool no_write_to_database */ 10:
                    message.noWriteToDatabase = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string bucket = 2; */
        if (message.bucket !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.bucket);
        /* string key = 3; */
        if (message.key !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.key);
        /* string tag_string = 9; */
        if (message.tagString !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.tagString);
        /* string mime_type = 4; */
        if (message.mimeType !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.mimeType);
        /* string created_at = 5; */
        if (message.createdAt !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.createdAt);
        /* string uri = 6; */
        if (message.uri !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.uri);
        /* bytes data = 7; */
        if (message.data.length)
            writer.tag(7, WireType.LengthDelimited).bytes(message.data);
        /* ACL acl = 8; */
        if (message.acl !== 0)
            writer.tag(8, WireType.Varint).int32(message.acl);
        /* bool no_write_to_database = 10; */
        if (message.noWriteToDatabase !== false)
            writer.tag(10, WireType.Varint).bool(message.noWriteToDatabase);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FileObject
 */
export const FileObject = new FileObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class URLObject$Type extends MessageType {
    constructor() {
        super("URLObject", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "content_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "acl", kind: "enum", T: () => ["ACL", ACL] },
            { no: 6, name: "tag_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { url: "", bucket: "", contentType: "", key: "", acl: 0, tagString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string bucket */ 2:
                    message.bucket = reader.string();
                    break;
                case /* string content_type */ 3:
                    message.contentType = reader.string();
                    break;
                case /* string key */ 4:
                    message.key = reader.string();
                    break;
                case /* ACL acl */ 5:
                    message.acl = reader.int32();
                    break;
                case /* string tag_string */ 6:
                    message.tagString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string bucket = 2; */
        if (message.bucket !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.bucket);
        /* string content_type = 3; */
        if (message.contentType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.contentType);
        /* string key = 4; */
        if (message.key !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.key);
        /* ACL acl = 5; */
        if (message.acl !== 0)
            writer.tag(5, WireType.Varint).int32(message.acl);
        /* string tag_string = 6; */
        if (message.tagString !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.tagString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message URLObject
 */
export const URLObject = new URLObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileObjects$Type extends MessageType {
    constructor() {
        super("FileObjects", [
            { no: 1, name: "result", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FileObject }
        ]);
    }
    create(value) {
        const message = { result: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated FileObject result */ 1:
                    message.result.push(FileObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated FileObject result = 1; */
        for (let i = 0; i < message.result.length; i++)
            FileObject.internalBinaryWrite(message.result[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FileObjects
 */
export const FileObjects = new FileObjects$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BucketObject$Type extends MessageType {
    constructor() {
        super("BucketObject", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "region", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { name: "", region: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string region */ 2:
                    message.region = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string region = 2; */
        if (message.region !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.region);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BucketObject
 */
export const BucketObject = new BucketObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileTranscript$Type extends MessageType {
    constructor() {
        super("FileTranscript", [
            { no: 1, name: "file_transcript_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "file_transcript", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "file_summary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "file_transcript_job_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "file_transcript_job_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "file_transcript_job_error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "updated_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "user_firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "user_lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "transcript_language", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { fileTranscriptId: 0, fileId: 0, fileTranscript: "", fileSummary: "", fileTranscriptJobName: "", fileTranscriptJobStatus: "", fileTranscriptJobError: "", fieldMask: [], createdDate: "", updatedDate: "", userId: 0, userFirstname: "", userLastname: "", transcriptLanguage: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 file_transcript_id */ 1:
                    message.fileTranscriptId = reader.int32();
                    break;
                case /* int32 file_id */ 2:
                    message.fileId = reader.int32();
                    break;
                case /* string file_transcript */ 3:
                    message.fileTranscript = reader.string();
                    break;
                case /* string file_summary */ 4:
                    message.fileSummary = reader.string();
                    break;
                case /* string file_transcript_job_name */ 5:
                    message.fileTranscriptJobName = reader.string();
                    break;
                case /* string file_transcript_job_status */ 6:
                    message.fileTranscriptJobStatus = reader.string();
                    break;
                case /* string file_transcript_job_error */ 7:
                    message.fileTranscriptJobError = reader.string();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string created_date */ 9:
                    message.createdDate = reader.string();
                    break;
                case /* string updated_date */ 10:
                    message.updatedDate = reader.string();
                    break;
                case /* int32 user_id */ 11:
                    message.userId = reader.int32();
                    break;
                case /* string user_firstname */ 12:
                    message.userFirstname = reader.string();
                    break;
                case /* string user_lastname */ 13:
                    message.userLastname = reader.string();
                    break;
                case /* string transcript_language */ 14:
                    message.transcriptLanguage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 file_transcript_id = 1; */
        if (message.fileTranscriptId !== 0)
            writer.tag(1, WireType.Varint).int32(message.fileTranscriptId);
        /* int32 file_id = 2; */
        if (message.fileId !== 0)
            writer.tag(2, WireType.Varint).int32(message.fileId);
        /* string file_transcript = 3; */
        if (message.fileTranscript !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.fileTranscript);
        /* string file_summary = 4; */
        if (message.fileSummary !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.fileSummary);
        /* string file_transcript_job_name = 5; */
        if (message.fileTranscriptJobName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.fileTranscriptJobName);
        /* string file_transcript_job_status = 6; */
        if (message.fileTranscriptJobStatus !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.fileTranscriptJobStatus);
        /* string file_transcript_job_error = 7; */
        if (message.fileTranscriptJobError !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.fileTranscriptJobError);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string created_date = 9; */
        if (message.createdDate !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.createdDate);
        /* string updated_date = 10; */
        if (message.updatedDate !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.updatedDate);
        /* int32 user_id = 11; */
        if (message.userId !== 0)
            writer.tag(11, WireType.Varint).int32(message.userId);
        /* string user_firstname = 12; */
        if (message.userFirstname !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.userFirstname);
        /* string user_lastname = 13; */
        if (message.userLastname !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.userLastname);
        /* string transcript_language = 14; */
        if (message.transcriptLanguage !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.transcriptLanguage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FileTranscript
 */
export const FileTranscript = new FileTranscript$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InternalResource$Type extends MessageType {
    constructor() {
        super("InternalResource", [
            { no: 1, name: "content_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "date_modified", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { contentType: "", id: 0, name: "", url: "", description: "", tag: "", dateCreated: "", dateModified: "", userId: 0, fileId: 0, isActive: false, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string content_type */ 1:
                    message.contentType = reader.string();
                    break;
                case /* int32 id */ 2:
                    message.id = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string url */ 4:
                    message.url = reader.string();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* string tag */ 6:
                    message.tag = reader.string();
                    break;
                case /* string date_created */ 7:
                    message.dateCreated = reader.string();
                    break;
                case /* string date_modified */ 8:
                    message.dateModified = reader.string();
                    break;
                case /* int32 user_id */ 9:
                    message.userId = reader.int32();
                    break;
                case /* int32 file_id */ 10:
                    message.fileId = reader.int32();
                    break;
                case /* bool is_active */ 11:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 12:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 13:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 14:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 15:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string content_type = 1; */
        if (message.contentType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.contentType);
        /* int32 id = 2; */
        if (message.id !== 0)
            writer.tag(2, WireType.Varint).int32(message.id);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string url = 4; */
        if (message.url !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.url);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* string tag = 6; */
        if (message.tag !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.tag);
        /* string date_created = 7; */
        if (message.dateCreated !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dateCreated);
        /* string date_modified = 8; */
        if (message.dateModified !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.dateModified);
        /* int32 user_id = 9; */
        if (message.userId !== 0)
            writer.tag(9, WireType.Varint).int32(message.userId);
        /* int32 file_id = 10; */
        if (message.fileId !== 0)
            writer.tag(10, WireType.Varint).int32(message.fileId);
        /* bool is_active = 11; */
        if (message.isActive !== false)
            writer.tag(11, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 12; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 13; */
        if (message.pageNumber !== 0)
            writer.tag(13, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 14; */
        if (message.orderBy !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 15; */
        if (message.orderDir !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InternalResource
 */
export const InternalResource = new InternalResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InternalResourceList$Type extends MessageType {
    constructor() {
        super("InternalResourceList", [
            { no: 1, name: "resources", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InternalResource },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { resources: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated InternalResource resources */ 1:
                    message.resources.push(InternalResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated InternalResource resources = 1; */
        for (let i = 0; i < message.resources.length; i++)
            InternalResource.internalBinaryWrite(message.resources[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InternalResourceList
 */
export const InternalResourceList = new InternalResourceList$Type();
/**
 * @generated ServiceType for protobuf service S3Service
 */
export const S3Service = new ServiceType("S3Service", [
    { name: "Create", options: {}, I: FileObject, O: FileObject },
    { name: "Get", options: {}, I: FileObject, O: FileObject },
    { name: "Delete", options: {}, I: FileObject, O: FileObject },
    { name: "Move", options: {}, I: MoveConfig, O: Empty },
    { name: "GetUploadURL", options: {}, I: URLObject, O: URLObject },
    { name: "GetDownloadURL", options: {}, I: URLObject, O: URLObject },
    { name: "CreateFile", options: {}, I: File, O: File },
    { name: "GetFile", options: {}, I: File, O: File },
    { name: "BatchGetFile", options: {}, I: File, O: FileList },
    { name: "UpdateFile", options: {}, I: File, O: File },
    { name: "DeleteFile", options: {}, I: File, O: File },
    { name: "GetFilesFromIdList", options: {}, I: String$, O: FileList },
    { name: "CreateInternalDocument", options: {}, I: InternalDocument, O: InternalDocument },
    { name: "GetInternalDocument", options: {}, I: InternalDocument, O: InternalDocument },
    { name: "BatchGetInternalDocument", options: {}, I: InternalDocument, O: InternalDocumentList },
    { name: "UpdateInternalDocument", options: {}, I: InternalDocument, O: InternalDocument },
    { name: "DeleteInternalDocument", options: {}, I: InternalDocument, O: InternalDocument },
    { name: "GetDocumentKeys", options: {}, I: DocumentKey, O: DocumentKeyList },
    { name: "WriteDocumentKey", options: {}, I: DocumentKey, O: DocumentKey },
    { name: "DeleteDocumentKey", options: {}, I: DocumentKey, O: Empty },
    { name: "CreateDocument", options: {}, I: Document, O: Document },
    { name: "GetDocument", options: {}, I: Document, O: Document },
    { name: "BatchGetDocument", options: {}, I: Document, O: DocumentList },
    { name: "UpdateDocument", options: {}, I: Document, O: Document },
    { name: "DeleteDocument", options: {}, I: Document, O: Document },
    { name: "CreateBucket", options: {}, I: BucketObject, O: BucketObject },
    { name: "GetBucket", options: {}, I: BucketObject, O: FileObjects },
    { name: "DeleteBucket", options: {}, I: BucketObject, O: BucketObject },
    { name: "CreateFileTranscript", options: {}, I: FileTranscript, O: FileTranscript },
    { name: "UpdateFileTranscript", options: {}, I: FileTranscript, O: Empty },
    { name: "DeleteFileTranscript", options: {}, I: IDInt32, O: Empty },
    { name: "GetFileTranscript", options: {}, I: FileTranscript, O: FileTranscript },
    { name: "FetchSubAccountDocuments", options: {}, I: SubAccountDocumentRequest, O: DocumentList },
    { name: "CreateExpiringUri", options: {}, I: URLObject, O: String$ },
    { name: "GetExpiringUri", options: {}, I: String$, O: URLObject },
    { name: "BatchGetInternalResource", options: {}, I: InternalResource, O: InternalResourceList }
]);
